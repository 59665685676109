import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/system';
import "./index.scss";

export default function CenturyModel() {
    return (
        <>
            <Helmet>
                <title>{`Century Model | Safe Haven Security`}</title>
                <meta name="description" content="Explore partnership opportunities with Safe Haven Security, including the Agent Referral Program and Homebuilder Program. Gain access to exclusive benefits and grow your business." />
                <meta name="keywords" content="Safe Haven Security, partner programs, agent referral program, homebuilder program, business growth, security solutions" />
            </Helmet>

            <Box>
                <iframe className="microsoft-365-iframe"
                    src="https://forms.office.com/Pages/ResponsePage.aspx?id=5P95IiH0hkOXuC6co5COQ1qYPkch6blGl3LmgpNUB5NUQlU1TU5JVFlYVExKRDJCVEkxWFozQ0pEUi4u&amp;embed=true"
                    width="100%"
                    height="1700px"
                >
                </iframe>
            </Box>
        </>
    );
} 