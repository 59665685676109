import React from 'react';
import Router from './Router';
import { setUserInfo } from './App/State/generalSlice';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import './App.scss';
import '@fontsource-variable/montserrat'
import useAnalytics from './utils/useAnalytics';
function App() {
  const dispatch = useDispatch();
 useAnalytics();
  //TODO: move to service
  const getIp = async () => {
    const result = await axios.get('https://api.ipify.org/?format=json');
    return result.data.ip;
  };

  React.useEffect(() => {
    //const ip = getIp();
    //dispatch(setUserInfo({ ip }));
  }, []);

  return (
    <>
      <Router></Router>
    </>
  );
}

export default App;
