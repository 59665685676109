import roundPointFam from "../../../../../Assets/Templates/roundpoint-1.png";
import familyPic from "../../../../../Assets/Partner/FreedomMortgage/Stock_HappyFamilyHouseADTsign.png";
import installTech from "../../../../../Assets/Partner/FreedomMortgage/Install-Tech.jpg";
import { Typography, Button, Grid } from "@mui/material";
import { Container, Box } from "@mui/system";

export default function AlternatingShowcase(props: any) {
    const { formRef } = props;

    const handleScrollToForm = () => {
        if (formRef.current) {
            const elementTop = formRef.current.getBoundingClientRect().top;
            const offsetPosition =
                elementTop + window.pageYOffset - window.innerHeight * 0.2;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    };

    return (
        <Container
            maxWidth={false}
            sx={{
                borderTop: "1px solid #ebebeb",
                borderBottom: "1px solid #ebebeb",
                paddingTop: "36px",
                maxWidth: "1300px",
            }}
        >
            <Grid
                container
                rowSpacing={{ xs: 5 }}
                columnSpacing={{ xs: 5 }}
                className="margin-bottom-35"
            >
                {/* IMG */}
                <Grid item xs={12} sm={6}>
                    <Box>
                        <Box
                            component="img"
                            sx={{
                                maxWidth: "100%",
                                height: "auto",
                            }}
                            src={roundPointFam}
                        />
                    </Box>
                </Grid>

                {/* Text */}
                <Grid item xs={12} sm={6}>
                    <Typography
                        className="white-glove-header"
                        sx={{ marginBottom: "36px!important" }}
                    >
                        You Deserve Peace of Mind
                    </Typography>
                    <Typography className="white-glove-body">
                        Feeling confident that you are protected is something everyone
                        deserves. A sense of safety can be difficult to restore if you or
                        your family’s well-being has been put in jeopardy. Don’t take that
                        chance. Get protection today to feel safe tomorrow.
                    </Typography>
                    <Box sx={{ textAlign: { xs: 'center', md: "left" }, marginTop: "36px" }}>
                        <Button
                            className="blue-rounded-btn"
                            type="submit"
                            variant="contained"
                            onClick={() => { handleScrollToForm(); }}
                            sx={{ fontWeight: 700, minHeight: "50px" }}
                        >
                            GET STARTED NOW
                        </Button>
                    </Box>
                </Grid>

                {/* IMG (Mobile) */}
                <Grid item xs={12} sm={6} sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <Box>
                        <Box
                            component="img"
                            sx={{
                                maxWidth: "100%",
                                height: "auto",
                            }}
                            src={installTech}
                        />
                    </Box>
                </Grid>

                {/* Text */}
                <Grid item xs={12} sm={6}>
                    <Typography className="white-glove-header">
                        White-Glove Installation is included!
                    </Typography>
                    <Typography className="white-glove-body">
                        SafeHaven Installation Techs are the leaders in ADT Smart Security
                        installations. We offer same-day or next-day installations
                        depending on your area.
                    </Typography>
                    <Box sx={{ marginTop: "36px" }}>
                        <ul className="blue-checkmark white-glove-body">
                            <li>Same-Day or Next Day Installations</li>
                            <li>ADT Theft Protection Guarantee+</li>
                            <li>Service &amp; Repairs Included</li>
                            <li>Award-Winning Technology++</li>
                        </ul>
                    </Box>
                    <Box sx={{ textAlign: "center", marginTop: "36px" }}>
                        <Button
                            className="freedom-mortgage-submit"
                            type="submit"
                            variant="contained"
                            onClick={() => { handleScrollToForm(); }}
                        >
                            GET A FREE QUOTE
                        </Button>
                    </Box>
                </Grid>

                {/* IMG */}
                <Grid item xs={12} sm={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Box>
                        <Box
                            component="img"
                            sx={{
                                maxWidth: "100%",
                                height: "auto",
                            }}
                            src={installTech}
                        />
                    </Box>
                </Grid>

                {/* IMG */}
                <Grid item xs={12} sm={6}>
                    <Box>
                        <Box
                            component="img"
                            sx={{
                                maxWidth: "100%",
                                height: "auto",
                            }}
                            src={familyPic}
                        />
                    </Box>
                </Grid>

                {/* Text */}
                <Grid item xs={12} sm={6}>
                    <Typography className="white-glove-header">Why ADT.</Typography>
                    <Typography className="white-glove-body">
                        When helping to protect your family or home, you shouldn’t cut
                        corners. ADT has proven that they are the leader in home security
                        and automation monitoring.
                    </Typography>
                    <Box>
                        <ul className="blue-checkmark white-glove-body">
                            <li>145+ Years of Experience</li>
                            <li>24/7 Professional Monitoring</li>
                            <li>A+ BBB Rating</li>
                            <li>ADT 6-Month Money Back Guarantee+</li>
                        </ul>
                    </Box>
                    <Box sx={{ textAlign: "center", marginTop: "36px" }}>
                        <Button
                            className="freedom-mortgage-submit"
                            type="submit"
                            variant="contained"
                            onClick={() => { handleScrollToForm(); }}
                        >
                            GET A FREE QUOTE
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}
