import {
	Box,
	Container,
	Grid,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { Helmet } from "react-helmet";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
export default function CountDownToRebranding() {
	const navigate = useNavigate();
	const handleNavigate = (route: string) => navigate(`${route}`);
	const getCountdown = () => {
		const targetDate = moment.tz("2024-11-01 14:00:00", "America/Chicago");
		const now = moment(); // Current date and time
		const duration = moment.duration(targetDate.diff(now)); // Calculate the duration

		const days = Math.floor(duration.asDays());
		const hours = duration.hours();
		const minutes = duration.minutes();
		const seconds = duration.seconds();
		setDays(days);
		setHours(hours);
		setMinutes(minutes);
		setSeconds(seconds);
	};
	const [days, setDays] = useState(0);
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			getCountdown();
		}, 1000);

		return () => clearInterval(interval); // Clean up on component unmount
	}, []);

	return (
		<>
			<Helmet>
				<title>Safe Haven Security</title>
				<meta
					name="description"
					content="Learn about Safe Haven Strong, your trusted provider for home security solutions."
				/>
				<meta
					name="keywords"
					content="home security, ADT authorized dealer, Safe Haven Strong"
				/>
			</Helmet>
			<span style={{ marginTop: "20px" }}></span>
			<Typography
				variant="h2"
				sx={{
					textAlign: "center",
					fontWeight: "bold",
					marginTop: "20px",
					fontSize: { sm: "32px", lg: "89px" },
				}}
			>
				New Look. New Us.
			</Typography>

			<Grid
				container
				alignItems="center"
				justifyContent="center"
				spacing={2}
				sx={{
					marginTop: "5px",
					marginBottom: "10px",
					backgroundColor: "#1260a8",
				}}
			>
				<Grid
					item
					sx={{
						marginRight: { xs: "0px", sm: "10px", lg: "80px" },
					}}
				>
					<Typography
						variant="h4"
						className="countdown-number"
						sx={{ textAlign: "center", fontSize: { sm: "24px", lg: "39px" } }}
					>
						{days}
					</Typography>
					<Typography
						className="countdown-text"
						sx={{ textAlign: "center", fontSize: { sm: "14px", lg: "36px" } }}
					>
						Day{days > 1 ? "s" : ""}
					</Typography>
				</Grid>
				<Grid
					item
					sx={{
						marginRight: { xs: "0px", sm: "10px", lg: "80px" },
					}}
				>
					<Typography
						variant="h4"
						sx={{
							textAlign: "center",
							fontSize: { sm: "24px", lg: "39px" },
							color: "white",
						}}
					>
						:
					</Typography>
				</Grid>
				<Grid
					item
					sx={{
						marginRight: { xs: "0px", sm: "10px", lg: "80px" },
					}}
				>
					<Typography
						variant="h4"
						className="countdown-number"
						sx={{ textAlign: "center", fontSize: { sm: "24px", lg: "39px" } }}
					>
						{hours}
					</Typography>
					<Typography
						className="countdown-text"
						sx={{ textAlign: "center", fontSize: { sm: "14px", lg: "36px" } }}
					>
						Hour{hours > 1 ? "s" : ""}
					</Typography>
				</Grid>
				<Grid
					item
					sx={{
						marginRight: { xs: "0px", sm: "10px", lg: "80px" },
					}}
				>
					<Typography
						variant="h4"
						sx={{
							textAlign: "center",
							fontSize: { sm: "24px", lg: "39px" },
							color: "white",
						}}
					>
						:
					</Typography>
				</Grid>
				<Grid
					item
					sx={{
						marginRight: { xs: "0px", sm: "10px", lg: "80px" },
					}}
				>
					<Typography
						variant="h4"
						className="countdown-number"
						sx={{ textAlign: "center", fontSize: { sm: "24px", lg: "39px" } }}
					>
						{minutes}
					</Typography>
					<Typography
						className="countdown-text"
						sx={{ textAlign: "center", fontSize: { sm: "14px", lg: "36px" } }}
					>
						Minute{minutes > 1 ? "s" : ""}
					</Typography>
				</Grid>
				<Grid
					item
					sx={{
						marginRight: { xs: "0px", sm: "10px", lg: "80px" },
					}}
				>
					<Typography
						variant="h4"
						sx={{
							textAlign: "center",
							fontSize: { sm: "24px", lg: "39px" },
							color: "white",
						}}
					>
						:
					</Typography>
				</Grid>
				<Grid
					item
					sx={{
						marginRight: { xs: "0px", sm: "10px", lg: "80px" },
					}}
				>
					<Typography
						variant="h4"
						className="countdown-number"
						sx={{ textAlign: "center", fontSize: { sm: "24px", lg: "39px" } }}
					>
						{seconds}
					</Typography>
					<Typography
						className="countdown-text"
						sx={{ textAlign: "center", fontSize: { sm: "14px", lg: "36px" } }}
					>
						Second{seconds > 1 ? "s" : ""}
					</Typography>
				</Grid>
			</Grid>
			<div
				style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}
			>
				<video
					className="responsive-video"
					autoPlay
					controls
					muted
					playsInline // Important for mobile autoplay
				>
					<source
						src={`${process.env.PUBLIC_URL}/video1.mp4`}
						type="video/mp4"
					/>
					<source
						src={`https://itsformsattachments.blob.core.windows.net/formattachments/Box teaser_FINAL 01.mp4`}
						type="video/mp4"
					/>
					Your browser does not support videos.
				</video>
			</div>
		</>
	);
}
