import { Paper, Typography } from "@mui/material";
import "./index.scss";
export default function PartnerStackLegal() {
	return (
		<>
			<div className="gtr-container">
				<Paper className="gtr-paper">
					<Typography variant="h6" className="gtr-header">
						<b>
							Terms and Conditions for Safe Haven Security Services, LLC’s
							PartnerStack Users
						</b>
					</Typography>
					<Typography>Last updated May 10, 2023</Typography>
					<Typography>
						Safe Haven Security Services, LLC (“Safe Haven”) provides certain
						individuals and business partners with access to a partner
						management platform provided by PartnerStack, Inc. (“PartnerStack”).
						This document sets forth the Terms and Conditions (“Terms”) that
						govern Your legal rights to access or use Safe Haven’s PartnerStack
						platform (the “Platform”).
					</Typography>

					<br />
					<Typography variant="body1">
						Please read these Terms carefully. These Terms create a legal
						agreement between You and any entity You represent (collectively,
						“You”). By accessing or using the Platform, You acknowledge that You
						have read, understood, and agree to be bound by these Terms and to
						comply with all applicable laws and regulations. If You do not agree
						to these Terms, do not access, use, or create an account on the
						Platform. Safe Haven reserves the right to revise these Terms at any
						time. If You violate any of these Terms, Your permission to use the
						Platform automatically terminates.
					</Typography>
					<br />
					<Typography variant="body1">
						YOU AGREE TO THE LIMITATION OF LIABILITY AND WARRANTY PROVISIONS
						DESCRIBED IN SECTION 5 AND TO THE ARBITRATION AGREEMENT AND CLASS
						ACTION WAIVER DESCRIBED IN SECTION 6 TO RESOLVE ANY DISPUTES WITH
						SAFE HAVEN.
					</Typography>
					<br />
					<Typography variant="body1">
						1. <b>Referral of Leads.</b> You will be able to use the Platform to
						refer customers or potential customers (“Leads”) to Safe Haven. You
						may be eligible to receive certain referral commissions
						(“Commissions”) for Your Leads who make a purchase from Safe Haven
						after being referred to Safe Haven by You. For any Lead You refer to
						Safe Haven, You must ensure that:
						<p>
							1. The Lead is interested in making a purchase from Safe Haven;
						</p>
						<p>
							2. The Lead has provided the necessary authorization to allow Safe
							Haven to contact the Lead regarding the potential purchase from
							Safe Haven, and You have maintained evidence of such
							authorization;
						</p>
						<p>
							3. The Lead is the owner of the property for which the purchase
							will be made; and
						</p>
						<p>
							4. The Lead’s property address is within Safe Haven’s installation
							coverage area.
						</p>
					</Typography>
					<Typography variant="body1">
						2.<b> Payment of Commissions.</b>
						<p>
							1. In order to receive any Commissions, You must create an account
							on the Platform and complete any other necessary steps to enroll
							with and receive payments on the Platform (e.g., by providing
							direct deposit or other information necessary to receive
							payments). As noted in Section 7.b, below, Your use of the
							Platform is subject to PartnerStack’s Terms of Service, and You
							may be subject to additional requirements and/or fees in order to
							receive or withdraw payments.
						</p>
						<p>
							2. Safe Haven will pay Commissions to You via the Platform only
							after: (i) the Lead completes a purchase from Safe Haven; (ii) the
							Lead has not canceled such purchase within the period when the
							Lead would be eligible to do so under applicable law; and (iii)
							Safe Haven has received all necessary payments resulting from the
							Lead’s purchase. The specific amounts, conditions, and timing for
							these Commissions are controlled by Business Partner’s agreement
							with Safe Haven. Please contact the Business Partner for further
							details.
						</p>
					</Typography>
					<Typography variant="body1">
						3. <b>Compliance with Laws.</b> You agree that You will, at all
						times, comply with: (i) all applicable federal, state, and local
						laws, rules, regulations, and orders that relate to consumer
						protection, unfair competition, electronic and internet marketing,
						commercial electronic messages, telemarketing, outbound telephone
						solicitations, advertising, privacy, data security, and intellectual
						property rights, including but not limited to the Telephone Consumer
						Protection Act, the Telemarketing and Consumer Fraud and Abuse
						Prevention Act, the Federal Trade Commission’s Telemarketing Sales
						Rules, the CAN-SPAM Act of 2003, the Lanham Act, and the Copyright
						Act of 1976 (collectively, “Applicable Laws”); and (ii) any policies
						communicated to You in writing by Safe Haven (“Applicable
						Policies”). You are solely responsible for Your compliance with all
						Applicable Laws and Applicable Policies relating to these Terms.
					</Typography>

					<Typography variant="body1">
						4. <b>Consent to Be Contacted by Safe Haven.</b> By agreeing to
						these Terms and creating an account on the Platform, You agree to be
						contacted via telephone, text message, or email by Safe Haven
						Security Services, LLC or its affiliates, even if You are on a Do
						Not Call, Do Not Text, or Do Not Email list. In addition, You
						consent to receive text messages or to be called back by Safe Haven
						or its affiliates at the phone number You provide when creating Your
						account on the Platform, including cellular. These telephone calls
						and text messages may be generated from an automated phone dialing
						system. Standard message and data rates may apply to text messages.
						You may opt out of receiving further text messages at any time. You
						are not required to provide this consent to make any purchase from
						Safe Haven.
					</Typography>
					<Typography variant="body1">
						5. <b>Limitation of Liability and Warranty</b>
						<p>
							1.<b>LIMITATION OF LIABILITY AND EXCLUSIVE REMEDY.</b> EXCEPT AS
							LIMITED BY LAW, AND DUE TO THE DIFFICULTY OF DETERMINING ANY
							ACTUAL DAMAGES, SAFE HAVEN’S LIABILITY FOR ANY LOSS OR DAMAGE
							ARISING OUT OF MISTAKES, OMISSIONS, INTERRUPTIONS, DELAYS, ERRORS,
							OR DEFECTS SHALL NOT EXCEED THE AMOUNT(S) PAID TO YOU VIA THE
							PLATFORM. THIS AGREED-UPON AMOUNT IS NOT A PENALTY. RATHER, IT IS
							YOUR SOLE AND EXCLUSIVE REMEDY
						</p>
						<p>
							2. SAFE HAVEN SHALL NOT BE LIABLE FOR ANY MISTAKE, OMISSION,
							INTERRUPTION, OR DEFECT CAUSED BY THE NEGLIGENCE OR WILLFUL ACT OF
							ANY PARTY OTHER THAN SAFE HAVEN, OR WHEN CAUSED BY ACTS OF GOD,
							FIRE, WAR, RIOTS, GOVERNMENT AUTHORITIES, OR OTHER CAUSES BEYOND
							SAFE HAVEN’S CONTROL, INCLUDING WITHOUT LIMITATION DEFECT IN OR
							FAILURE OF EQUIPMENT PROVIDED BY ANY PARTY OTHER THAN SAFE HAVEN.
						</p>
						<p>
							{" "}
							3. UNDER NO CIRCUMSTANCES WILL YOU ATTEMPT TO HOLD SAFE HAVEN
							LIABLE FOR ANY CONSEQUENTIAL OR INCIDENTAL DAMAGES, INCLUDING
							WITHOUT LIMITATION, DAMAGES FOR PERSONAL INJURY OR DAMAGES TO
							PROPERTY.
						</p>
						<p>
							4. YOU AGREE THAT ANY DUTIES OWED TO ME BY SAFE HAVEN ARE SET
							FORTH EXCLUSIVELY IN THESE TERMS. THEREFORE, YOU EXPRESSLY WAIVE
							ANY CLAIMS OR DEFENSES BASED ON TORTIOUS CONDUCT, INCLUDING
							WILLFUL OR INTENTIONAL TORTS.
						</p>
						<p>
							5. <b> NO WARRANTY.</b> EXCEPT AS REQUIRED BY LAW, THE PLATFORM IS
							PROVIDED “AS IS” AND “AS AVAILABLE” WITH ALL FAULTS AND WITHOUT
							WARRANTY OF ANY KIND. SAFE HAVEN DOES NOT GUARANTEE, REPRESENT, OR
							WARRANT THAT YOUR USE OF THE PLATFORM WILL BE UNINTERRUPTED OR
							ERROR-FREE.
						</p>
						<p>
							6. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
							WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR CERTAIN
							TYPES OF DAMAGES. THEREFORE, NOTHING IN THESE TERMS SHALL AFFECT
							ANY NON-WAIVABLE STATUTORY RIGHTS THAT APPLY TO YOU.
						</p>
					</Typography>
					<Typography variant="body1">
						6. <b>Arbitration Agreement</b>
						<p>
							1. SAFE HAVEN AND YOU AGREE THAT ANY AND ALL DISPUTES BETWEEN THEM
							SHALL BE GOVERNED BY THIS BINDING ARBITRATION AGREEMENT.
							Arbitration is a dispute-resolution process that does not involve
							a judge or jury. Instead, Disputes are decided by a neutral
							third-party arbitrator in a process that is less formal than
							court. As used herein, the term “Dispute” means any claim or
							controversy, including, but not limited to, initial claims,
							counterclaims, cross-claims, and third-party claims, whether based
							on contract; tort; fraud; intentional acts; violations of any
							statute, code or regulation; or other legal theory. The term
							“Dispute” shall be given the broadest possible meaning and will
							apply to, without limitation, all claims and controversies arising
							from this Arbitration Agreement; Your use of the Platform; sales
							activities; goods and services; advertisements, promotions, and
							other statements; billing and collection practices; privacy; and
							any other dispute arising from Your interaction or relationship
							with Safe Haven. Safe Haven agrees not to elect arbitration if You
							file a Dispute in a small claims court in Your state of residency
							so long as the Dispute is individual and non-representative in
							nature and remains exclusively as such in small claims court.
						</p>
						<p>
							2. <b>Pre-Arbitration Notice Requirement.</b> Before initiating an
							arbitration or a small claims matter, Safe Haven and You agree to
							first provide to the other a written “Notice of Dispute” that will
							contain: (a) a written description of the issue and the supporting
							documents and information, and (b) a specific request for money or
							other relief. A Notice of Dispute to Safe Haven should be sent to
							Safe Haven Security Services, LLC, Attn: General Counsel, 520 E
							19th Ave., North Kansas City, MO 64116, or as You may otherwise be
							directed by Safe Haven or its assignee. Safe Haven will mail a
							Notice of Dispute to Your residential address. Safe Haven and You
							agree to make attempts to resolve the Dispute prior to commencing
							an arbitration or small claims action.
						</p>
						<p>
							3. <b>Initiation of Arbitration Proceeding.</b> If either party
							elects to arbitrate a Dispute, the Dispute shall be resolved by
							arbitration pursuant to this Arbitration Agreement and the
							then-current code of proceedings of the American Arbitration
							Association (“AAA”). To obtain a copy of the procedures, or to
							file a Dispute, You may contact AAA at 335 Madison Avenue, New
							York, NY 10017, <a href="adr.org."> adr.org.</a>
						</p>
						<p>
							4. IF EITHER PARTY ELECTS TO ARBITRATE A DISPUTE, SAFE HAVEN AND
							YOU WAIVE THE RIGHT TO A JURY TRIAL AND TO OTHERWISE LITIGATE THE
							DISPUTE IN COURT. BY AGREEING TO ARBITRATE, THE PARTIES MAY ALSO
							WAIVE OTHER RIGHTS THAT WOULD OTHERWISE BE AVAILABLE IN COURT.
							FURTHER, IF EITHER PARTY ELECTS TO ARBITRATE A DISPUTE, YOU WAIVE
							YOUR RIGHT TO PARTICIPATE IN A REPRESENTATIVE CAPACITY OR TO
							PARTICIPATE AS A MEMBER OF ANY CLASS ACTION RELATING TO THE
							DISPUTE. This means that all Disputes selected for arbitration
							will be arbitrated on an individual basis, between Safe Haven and
							You only, without exception. A Dispute cannot be joined or
							consolidated with any other claim or action.
						</p>
						<p>
							5. <b>Arbitration Proceedings.</b> Because Your transaction(s)
							with Safe Haven involves interstate commerce, this Arbitration
							Agreement and any Dispute arbitrated hereunder shall be governed
							by the Federal Arbitration Act (“FAA”). The AAA Code of Procedures
							will govern the arbitration, but if there is a conflict between
							the AAA Code of Procedures and this Arbitration Agreement, this
							Arbitration Agreement shall control to the fullest extent
							permitted by the FAA. Unless otherwise agreed to by the parties,
							the arbitration will be conducted by a single, neutral arbitrator
							at a location within the federal judicial district in which You
							reside. Upon Your request, Safe Haven will reimburse You for all
							filing and administrative fees required for initiating the
							arbitration. Otherwise, each party is responsible for its own
							respective costs and fees, including, but not limited to, attorney
							and expert fees. The arbitrator shall apply applicable substantive
							law and, upon the request of either party, issue a written
							explanation of the basis for the decision. Judgment on the
							arbitration award may be entered in any court having proper
							jurisdiction. EXCEPT AS FOLLOWS, THE ARBITRATOR’S DECISION WILL BE
							FINAL AND BINDING. A party may appeal the arbitrator’s initial
							award to a three-arbitrator panel administered by the AAA upon
							written notice within 30 days of the initial award. The AAA will
							notify the other party of the appeal. The panel shall consider any
							aspect of the initial award objected to by the appealing party and
							issue a decision within 120 days of the date of the notice of
							appeal. The majority decision by the three-member panel shall be
							final and binding. Any dispute regarding the applicability,
							enforcement, or interpretation of Section 5 above or Section 6,
							shall be resolved by a court having proper jurisdiction. This
							Arbitration Agreement will not prevent You from bringing a Dispute
							to the attention of any federal, state, or local government
							agency. This Arbitration Agreement shall survive the termination
							of Your use of the Platform.
						</p>
					</Typography>
					<Typography variant="body1">
						7. <b>Miscellaneous</b>
						<p>
							1. <b>Governing Law. </b>These Terms shall be governed by and
							construed in accordance with the laws of the state of Missouri
							without regard to conflict of laws provisions.
						</p>
						<p>
							2. <b>PartnerStack Terms. </b>Your use of the Platform will be
							governed by PartnerStack’s Terms of Service, available at
							https://partnerstack.com/policies#terms-of-service. Your
							acceptance of PartnerStack’s Terms of Service may create an
							agreement between You and PartnerStack and not with Safe Haven.
							Safe Haven does not warrant or accept any liability or obligation
							to You with respect to PartnerStack’s Terms of Service. Nothing in
							these Terms shall be construed to supersede or modify
							PartnerStack’s Terms of Service.
						</p>
						<p>
							4. <b>Other Third-Party Terms. </b>Your use of the Platform may
							also involve websites, software, or services provided by third
							parties. The use of any third-party software or services is
							governed by the third-party provider and not Safe Haven. Your
							acceptance of any such terms may create an agreement between You
							and the third party and not with Safe Haven. Safe Haven does not
							warrant or accept any liability or obligation to You with respect
							to third-party software and services. You may request a copy of
							any agreement with a third party from that third party.
						</p>
						<p>
							5. <b>Changes to Terms and Assignment. </b>Safe Haven may, from
							time to time, change these Terms. Such revisions shall be
							effective immediately; provided, however, for existing
							Subscribers, such revisions shall, unless otherwise stated, be
							effective 30 days after posting. Safe Haven may assign its
							agreement with You to any affiliated company or to any entity that
							succeeds to all or substantially all of Safe Haven’s business or
							assets related to the applicable Safe Haven service.
						</p>
						<p>
							6. <b>Survival.</b> If any provision or provisions of these Terms
							shall be held to be invalid, illegal, or unenforceable, the
							validity, legality, and enforceability of the remaining provisions
							shall remain in full force and effect.
						</p>
						<p>
							7. <b>Communication Preferences. </b>Safe Haven will send You
							information relating to Your use of the Platform (e.g., notices or
							information about Commissions) in electronic form only, for
							example via messages posted to the Platform or emails to Your
							email address provided during enrollment. You agree that any
							notices, agreements, disclosures, or other communications that
							Safe Haven sends to You electronically will satisfy any legal
							communication requirements, including that such communications be
							in writing.
						</p>
					</Typography>
				</Paper>
			</div>
		</>
	);
}
