import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControl,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ContactUsForm from '../../Components/Forms/ContactUsForm';
import { useState } from 'react';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { useMediaQuery } from 'react-responsive';
import './index.scss';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function Contact() {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 1200 });
  const navigate = useNavigate();
  const handleNavigate = (route: string) => navigate(`${route}`);

  return (
    <>
      <Helmet>
        <title>Contact Us | Safe Haven Security</title>
        <meta
          name="description"
          content="Get in touch with Safe Haven Security. We're here to answer your questions and help you find the right security solution for your needs."
        />
        <meta
          name="keywords"
          content="Safe Haven Security, contact us, security solutions, customer service, questions, inquiries, info@mysafehaven.com, 877-842-0818"
        />
      </Helmet>
      <Box sx={{ backgroundColor: 'white', paddingBottom: '128px' }}>
        <Container maxWidth={'xl'} sx={{ paddingTop: '70px' }}>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={5}>
              <Stack>
                <Typography
                  sx={{
                    color: '#075faa',
                    fontWeight: '700',
                    fontSize: '48px',
                    marginBottom: '35px',
                  }}
                >
                  CONTACT INFO
                </Typography>
                <Typography sx={{ fontSize: '22px', marginBottom: '35px' }}>
                  For Customer Service Inquires Contact Us Here:
                </Typography>
                <List>
                  <ListItem alignItems='flex-start'>
                    <ListItemAvatar sx={{ marginTop: '0' }}>
                      <LocalPhoneIcon
                        sx={{ color: '#075faa' }}
                      ></LocalPhoneIcon>
                    </ListItemAvatar>
                    <ListItemText sx={{ margin: '0 auto' }}>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          color: '#4d4f51',
                        }}
                      >
                        877.842.0818
                      </Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem alignItems='flex-start'>
                    <ListItemAvatar sx={{ marginTop: '0' }}>
                      <MailOutlineIcon
                        sx={{ color: '#075faa' }}
                      ></MailOutlineIcon>
                    </ListItemAvatar>
                    <ListItemText sx={{ margin: '0 auto' }}>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          color: '#4d4f51',
                        }}
                      >
                        Info@MySafeHaven.com{' '}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem alignItems='flex-start'>
                    <ListItemAvatar sx={{ margin: 'auto' }}>
                      <LocationOnIcon
                        sx={{ color: '#075faa' }}
                      ></LocationOnIcon>
                    </ListItemAvatar>
                    <ListItemText sx={{ margin: '0 auto' }}>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          color: '#4d4f51',
                        }}
                      >
                        520 E 19TH, NORTH KANSAS CITY, MO
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem alignItems='flex-start'>
                    <ListItemAvatar sx={{ marginTop: '0' }}>
                      <AccessTimeIcon
                        sx={{ color: '#075faa' }}
                      ></AccessTimeIcon>
                    </ListItemAvatar>
                    <ListItemText sx={{ margin: '0 auto' }}>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          color: '#4d4f51',
                        }}
                      >
                        Monday - Friday: 8:00am - 5:00pm
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>

                <Box sx={{ display: 'flex', marginTop: '35px' }}>
                  <Link
                    className='aio-tooltip'
                    href='https://www.facebook.com/safehavensecure'
                    target='_blank'
                    rel='noopener'
                    data-toggle='tooltip'
                    data-placement='top'
                    title='Facebook'
                    sx={{ marginRight: '5px' }}
                  >
                    <Box
                      className='aio-icon advanced '
                      data-animation=''
                      data-animation-delay='03'
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </Box>
                  </Link>
                  <Link
                    className='aio-tooltip'
                    href='https://twitter.com/safehavensecure'
                    target='_blank'
                    rel='noopener'
                    data-toggle='tooltip'
                    data-placement='top'
                    title='Twitter'
                  >
                    <Box
                      className='aio-icon advanced '
                      data-animation=''
                      data-animation-delay='03'
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </Box>
                  </Link>
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} lg={7}>
              <Box
                sx={{
                  padding: { xs: '60px 0', lg: '0px 60px' },
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1 1 0%,',
                  margin: '0 auto',
                }}
              >
                {!isDesktop && <Divider />}

                {!confirmationOpen && <>
                  <Typography
                    sx={{
                      color: '#075faa',
                      fontWeight: '700',
                      fontSize: '48px',
                      marginBottom: '35px',
                    }}
                  >
                    SEND US A MESSAGE
                  </Typography>
                  <Typography sx={{ fontSize: '22px', marginBottom: '35px' }}>
                    We’d love to hear from you! <br></br>Please fill out this form
                    and we’ll get in touch with you shortly.
                  </Typography></>}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: '290px',
                    margin: confirmationOpen ? '0' : '2em 0 0px',
                  }}
                >
                  <Box
                    sx={{
                      '& .MuiTextField-root': { m: 1 },
                      '& .MuiTextarea-root': { m: 1 },
                      '& .half-width': { width: 'calc(50% - 16px)' },
                      '& .full-width': { width: 'calc(100% - 16px)' },
                    }}
                  >
                    {!confirmationOpen && <ContactUsForm setConfirmationOpen={setConfirmationOpen}></ContactUsForm>}

                    {confirmationOpen && <Container
                      maxWidth={false}
                      sx={{
                        maxWidth: '700px',
                        minHeight: '400px',
                        height: 'auto',
                        bgcolor: '#fff',
                        borderRadius: '9px',
                        boxShadow: 24,
                        padding: '0!important',
                      }}
                    >
                      <Box sx={{ background: 'rgb(18, 96, 168)', width: '100%', minHeight: '64px', borderRadius: '9px 9px 0 0px', padding: '10px 0', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                        <Typography sx={{ fontSize: '24px', fontWeight: '700', textAlign: 'center', color: '#fff' }}>
                          Thank you for contacting Safe Haven!
                        </Typography>
                      </Box>
                      <Stack
                        direction={'row'}
                        sx={{ alignItems: 'center', justifyContent: 'space-evenly' }}
                      >

                        <Box
                          sx={{
                            paddingLeft: '36px',
                            minHeight: '350px',
                            minWidth: '250px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                          }}
                        >
                          <Box>
                            <Box sx={{ width: '100%', display: 'flex' }}>
                              <CheckCircleOutlineIcon sx={{ color: 'rgb(18, 96, 168)', margin: 'auto', height: '4rem', width: 'auto', marginBottom: '15px' }}></CheckCircleOutlineIcon>
                            </Box>

                            <Typography sx={{ fontSize: '24px', maxWidth: '600px' }}>
                              We received your request for more information and a member of our team will be in touch with you shortly to answer any questions you have.
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '24px 0' }}>
                              <Button
                                variant={'contained'}
                                onClick={() => {
                                  handleNavigate('/');
                                }}
                                sx={{
                                  minWidth: '100px',
                                  minHeight: '45px',
                                  fontSize: '16px',
                                  textTransform: 'none',
                                  fontWeight: '700',
                                }}
                              >
                                Back To Home
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Stack>
                    </Container>}

                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
