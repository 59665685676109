import {
  Box,
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  Autocomplete,
  Container,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import useFetch from 'use-http';
import './index.scss';
import { STATE_LIST } from '../../../Constants';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup'; // For form validation
import { useSearchParams } from 'react-router-dom';

interface PartnerRequestFormProps {
  setConfirmationOpen: (arg0: boolean) => void;
}

export default function AffirmationForm({ setConfirmationOpen }: PartnerRequestFormProps) {
  const [queryParameters] = useSearchParams();
  let apiKey = process.env.REACT_APP_API__ACCESS_KEY
    ? process.env.REACT_APP_API__ACCESS_KEY
    : '';
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Connection: 'keep-alive',
      'Accept-Encoding': 'gzip, deflate, br',
      'X-API-Key': apiKey,
    },
  };
  const { post, response, loading, error } = useFetch(
    process.env.REACT_APP_API,
    options
  );

  // Formik Validation Schema
  const initialValues = {
    firstName: '',
    lastName: '',
    address: '',
    addressExt: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    email: '',
    acknowledge: false,
    submitee: 0,
    action: 0,
    actionReason: ''
  };
  // Formik Validation Schema
  const validationSchema = Yup.object({
    firstName: Yup.string().required('Required')
      .min(2, 'First name must be at least 2 characters')
      .max(50, 'First name must be at most 50 characters')
      .matches(/^[a-zA-Z '-]+$/, 'First name can only contain letters, hyphens, apostrophes, and spaces'),
    lastName: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    Zip: Yup.string().matches(/^\d{5}(-\d{4})?$/, 'Invalid ZIP code format').required('Required'),
    phone: Yup.string().required('Required')
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Required'),
    acknowledge: Yup.boolean().required('Required'),
  });

  return (
    <Container sx={{
      padding: '32px',
      background: '#eeeeee',
      borderRadius: '4px',
      maxWidth: '1000px',
      marginBottom: '70px'
    }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            let recipients = 'privacy@mysafehaven.com';

            if (queryParameters.get("msh-test")) {
              recipients = 'Samual.Tarpenning@mysafehaven.com, codey.bower@mysafehaven.com, wai.cheng@mysafehaven.com';
            }

            const subject = `Affirmation Form Submitted by: ${values.firstName} ${' '} ${values.lastName}`;
            const content = `
              <table style="max-width: 1024px; margin: auto;">
                <tbody>
                  ${Object.entries(values).map(([key, value], index) => `
                    <tr style="background-color: ${index % 2 === 0 ? '#f2f2f2' : 'white'};">
                      <th style="padding: 8px; text-align: left; border-bottom: 1px solid #ddd;, width: auto;">${key}:</th>
                      <td style="padding: 8px; text-align: left; border-bottom: 1px solid #ddd;">${value}</td>
                    </tr>
                  `).join('')}
                </tbody>
              </table>
            `;

            const emailReqBody = {
              to: recipients,
              subject: subject,
              content: content,
            }

            const postResponse = await post(`/Email/SendEmail`, emailReqBody)
              .then((res: any) => {
                if (res) {
                  setConfirmationOpen(true);
                  resetForm();
                } else {
                  console.error("Error caught by API", error);
                }
              }
              ).catch((error: any) => {
                console.error("Catastrophic error in API", error);
              }).finally(() => {
                setSubmitting(false);
              });
          } catch (error) {
            console.error("Error sending request:", error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => (
          <Form>
            <Box className='' sx={{
              maxWidth: '1024px',
              margin: 'auto',
              '& .quarter-width': { width: { xs: 'calc(50%)', sm: 'calc(25%)' }, minHeight: '90px' },
              '& .half-width': { width: { xs: 'calc(100%)', sm: 'calc(50%)' }, minHeight: '90px' },
              '& .full-width': { width: 'calc(100%)', minHeight: '90px' },
              '& .quarter-width > div': { margin: '0 5px 24px 5px' },
              '& .half-width > div': { margin: '0 5px 24px 5px' },
              '& .full-width > div': { margin: '0 5px 24px 5px' },
            }}>
              <Field type="hidden" name="partnerCompanyId" />
              <Field type="hidden" name="PartnerCampaignId" />

              {/* Submitee Radio */}
              <FormControl
                className='half-width'
                sx={{ paddingLeft: '10px', paddingBottom: '16px' }}
                error={touched.submitee && Boolean(errors.submitee)}
              >
                <FormLabel id='demo-radio-buttons-group-label'>
                  I am submitting a request for: *
                </FormLabel>
                <RadioGroup
                  aria-labelledby='demo-radio-buttons-group-label'
                  defaultValue='myself'
                  name='radio-buttons-group'
                  value={values.submitee}
                  onChange={(event: { target: { value: any; }; }) => { setFieldValue("submitee", event.target.value) }}
                >
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label='Myself'
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label='Someone Else'
                  />
                </RadioGroup>
              </FormControl>

              {/* Action Radio */}
              <FormControl
                className='half-width'
                sx={{ paddingLeft: '16px', paddingBottom: '16px' }}
                error={touched.action && Boolean(errors.action)}
              >
                <FormLabel id='demo-radio-buttons-group-label'>
                  I want to: *
                </FormLabel>
                <RadioGroup
                  aria-labelledby='demo-radio-buttons-group-label'
                  defaultValue='female'
                  name='radio-buttons-group'
                  value={values.action}
                  onChange={(event: { target: { value: any; }; }) => { setFieldValue("action", event.target.value) }}
                >
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label='Request Personal Information'
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label='Correct Inaccurate Personal Information'
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label='Delete Personal Information'
                  />
                </RadioGroup>
              </FormControl>

              {(values.action === 1 || values.action === 2) && <Field
                as={TextField}
                className='half-width '
                name="actionReason"
                label="Personal Information Needing Correction *"
                value={values.actionReason}
                onChange={(event: any) => { setFieldValue("actionReason", event.target.value) }}
                error={touched.actionReason && Boolean(errors.actionReason)}
                helperText={touched.actionReason && errors.actionReason && (
                  <div key={`error-actionReason`}>{errors.actionReason}</div>
                )}
              />}

              <Field
                as={TextField}
                className='half-width '
                name="firstName"
                label="First Name *"
                value={values.firstName}
                onChange={(event: any) => { setFieldValue("firstName", event.target.value) }}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName && (
                  <div key={`error-firstName`}>{errors.firstName}</div>
                )} />

              <Field
                as={TextField}
                className='half-width '
                name="lastName"
                label="Last Name *"
                value={values.lastName}
                onChange={(event: { target: { value: any; }; }) => setFieldValue("lastName", event.target.value)}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />

              <Field
                as={TextField}
                className='half-width '
                name="phone"
                label="Phone Number *"
                value={values.phone}
                onChange={(event: { target: { value: any; }; }) => setFieldValue("phone", event.target.value)}
                error={touched.phone && Boolean(errors.phone)}
                helperText={touched.phone && errors.phone}
              />

              <Field
                as={TextField}
                className='half-width '
                name="email"
                label="Email *"
                value={values.email}
                onChange={(event: { target: { value: any; }; }) => setFieldValue("email", event.target.value)}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />

              <Field
                as={TextField}
                className='half-width '
                name="address"
                label="Address *"
                value={values.address}
                onChange={(event: { target: { value: any; }; }) => setFieldValue("address", event.target.value)}
                error={touched.address && Boolean(errors.address)}
                helperText={touched.address && errors.address}
              />

              <Field
                as={TextField}
                className='quarter-width '
                name="city"
                label="City *"
                value={values.city}
                onChange={(event: { target: { value: any; }; }) => setFieldValue("city", event.target.value)}
                error={touched.city && Boolean(errors.city)}
                helperText={touched.city && errors.city}
              />

              <Autocomplete
                disablePortal
                className={`quarter-width `}
                sx={{ display: 'inline-flex', margin: "0!important" }}
                options={STATE_LIST}
                inputValue={values.state}
                onInputChange={(event, newInputValue) => {
                  setFieldValue("state", newInputValue);
                }}

                renderInput={params => (
                  <TextField
                    sx={{ marginLeft: '4px' }}
                    {...params}
                    name="state"
                    label="State *"
                    error={touched.state && Boolean(errors.state)}
                    helperText={touched.state && errors.state}
                  />
                )}
              />

              <Field
                as={TextField}
                className='half-width '
                name="addressExt"
                label="Apartment, suite, etc. *"
                value={values.addressExt}
                onChange={(event: { target: { value: any; }; }) => setFieldValue("addressExt", event.target.value)}
                error={touched.addressExt && Boolean(errors.addressExt)}
                helperText={touched.addressExt && errors.addressExt}
              />

              <Field
                as={TextField}
                className='half-width '
                name="zip"
                label="Zip Code *"
                value={values.zip}
                onChange={(event: { target: { value: any; }; }) => setFieldValue("zip", event.target.value)}
                error={touched.zip && Boolean(errors.zip)}
                helperText={touched.zip && errors.zip}
              />

              <Box sx={{ paddingTop: '10px' }}>
                <FormControlLabel
                  name='acknowledge'
                  className={touched.acknowledge && !values.acknowledge ? 'error-checkbox' : ''}
                  control={
                    <Checkbox
                      checked={values.acknowledge}
                      onChange={(event: { target: { checked: boolean; }; }) => { setFieldValue("acknowledge", event.target.checked) }
                      }
                    />
                  }
                  label=" By submitting this request, I confirm that (1) the information I have provided is true and accurate; (2) I understand the information will be handled by Safe Haven in accordance with its Privacy Policy; and (3) Safe Haven has the right to contact me to verify my identity and to process this request. *"
                  sx={{
                    margin: '0 8px 8px 8px',
                    alignItems: 'flex-start',
                    '& .MuiFormControlLabel-asterisk': {
                      paddingTop: '9px',
                      height: '5px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'space-around',
                    },
                    '& .MuiTypography-root': {
                      paddingTop: '8px',
                    },
                  }}
                />
              </Box>

              <Box sx={{ padding: '2rem', display: 'flex', justifyContent: 'center' }}>
                <LoadingButton
                  variant='contained'
                  className='contact-form-submit'
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  sx={{
                    minWidth: '100px',
                    fontSize: '16px',
                    textTransform: 'none',
                    fontWeight: '700',
                  }}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Container >
  );
}

const defaultFormValues =
{
  firstName: { value: '', error: false, errorMessage: '' },
  lastName: { value: '', error: false, errorMessage: '' },
  phone: { value: '', error: false, errorMessage: '' },
  email: { value: '', error: false, errorMessage: '' },
  city: { value: '', error: false, errorMessage: '' },
  stateCode: { value: '', error: false, errorMessage: '' },
  address: { value: '', error: false, errorMessage: '' },
  zip: { value: '', error: false, errorMessage: '' },
  submitee: { value: '', error: false, errorMessage: '' },
  action: { value: '', error: false, errorMessage: '' },
  actionReason: { value: '', error: false, errorMessage: '' },
  optInContact: { value: false, error: false, errorMessage: '' },
}




