import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSuitcase,
  faUsers,
  faTrophy,
  faKey,
  faLocationArrow,
} from '@fortawesome/free-solid-svg-icons';
import { Box, Typography } from '@mui/material';
import './index.scss';

export default function IconList() {
  const navigate = useNavigate();
  const handleNavigate = (route: string) => navigate(`${route}`);

  return (
    <>
      <Box sx={{ padding: '70px 0' }}>
        <ul className='fa-ul'>
          <li
            className='fa-li fa-li-first'
            onClick={() => {
              {
                handleNavigate('/careers');
              }
            }}
          >
            <Box className='fa-icon-wrapper'>
              <FontAwesomeIcon className='fa-icon' icon={faSuitcase} />
            </Box>
            <Box className='icon-list-heading-wrapper'>
              <Typography className='icon-list-heading'>CAREERS</Typography>
            </Box>
            <Box className='fa-icon-connector'></Box>
          </li>

          <li
            className='fa-li'
            onClick={() => {
              {
                handleNavigate('/partners');
              }
            }}
          >
            <Box className='fa-icon-wrapper'>
              <FontAwesomeIcon className='fa-icon' icon={faUsers} />
            </Box>
            <Box className='icon-list-heading-wrapper'>
              <Typography className='icon-list-heading'>PARTNERS</Typography>
            </Box>
            <Box className='fa-icon-connector'></Box>
          </li>

          <li
            className='fa-li'
            onClick={() => {
              {
                handleNavigate('/about-us');
              }
            }}
          >
            <Box className='fa-icon-wrapper'>
              <FontAwesomeIcon className='fa-icon' icon={faTrophy} />
            </Box>
            <Box className='icon-list-heading-wrapper'>
              <Typography className='icon-list-heading'>AWARDS</Typography>
            </Box>
            <Box className='fa-icon-connector'></Box>
          </li>

          <li
            className='fa-li'
            onClick={() => {
              {
                handleNavigate('/customer-solutions');
              }
            }}
          >
            <Box className='fa-icon-wrapper'>
              <FontAwesomeIcon className='fa-icon' icon={faKey} />
            </Box>
            <Box className='icon-list-heading-wrapper'>
              <Typography className='icon-list-heading'>SOLUTIONS</Typography>
            </Box>
            <Box className='fa-icon-connector'></Box>
          </li>

          <li
            className='fa-li'
            onClick={() => {
              {
                handleNavigate('/about-us');
              }
            }}
          >
            <Box className='fa-icon-wrapper'>
              <FontAwesomeIcon className='fa-icon' icon={faLocationArrow} />
            </Box>
            <Box className='icon-list-heading-wrapper'>
              <Typography className='icon-list-heading'>MISSION</Typography>
            </Box>
          </li>
        </ul>
      </Box>
    </>
  );
}
