import React, { useEffect } from 'react';

const ApplyNow = () => {
	useEffect(() => {
		// const path = window.location.pathname;
		// const pathArray = path.split('/');
		// const jobId = pathArray[pathArray.length - 1];
		// console.log(jobId);
	}, []);
	return (
		// fullscreen iFrame
		<iframe
			src='https://mysafehaven.hire.trakstar.com/jobs/fk0xdwx/'
			title='Apply Now'
			width='100%'
			height='100%'
			frameBorder='0'
			marginHeight={0}
			marginWidth={0}
			scrolling='auto'
			allowFullScreen
		></iframe>
	);
};

export default ApplyNow;
