import ReactDOM from "react-dom/client";
import "./index";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./Assets/Global/Fonts/Palanquin_Dark/PalanquinDark-Regular.ttf";
import { store } from "./App/State/store";
import { ReactTagManager } from "react-gtm-ts";
import reportWebVitals from "./utils/reportWebVitals";


const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
);

const shouldReportWebVitals = localStorage.getItem("cookie-consent") === "true";

if (shouldReportWebVitals) {
	reportWebVitals(console.log);
}
