import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { disable as hideHeader } from "../../../App/State/headerSlice.tsx";

import "./index.scss";
import Nerdwallet from "../Affiliates/Templates/7";
import { fetchPartnerCompanyInfoByPagePath } from "../Affiliates/Functions/index";

export default function NerdWalletLandingPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleNavigate = (route: string) => navigate(`${route}`);

	const { pagePath = "default" } = useParams();
	const [template, setTemplate] = useState<string>("7");
	const [partnerInfo, setPartnerInfo] = useState<PartnerInfo>({
		PartnerName: "Default Partner",
		PartnerId: "0000",
		CampaignName: "Default Campaign",
		CampaignId: "0000",
		Template: "0",
	});

	useEffect(() => {
		if (!pagePath) {
			handleNavigate("/partners");
		} else {
			if (pagePath) {
				let pageConfig;
				console.log(pagePath);
				// fetchPartnerCompanyInfoByPagePath(pagePath).then(
				// 	(response) => (
				// 		setPartnerInfo(response),
				// 		console.log("response", response),
				// 		setTemplate(response.template)
				// 	),
				// );
			} else {
				setTemplate("0");
			}
		}
		dispatch(hideHeader());
	}, []);

	if (!template) {
		return <div>Loading landing page content...</div>;
	} else {
		return (
			<>
				<div
					className="landing-page-container"
					style={{
						overflow: "hidden",
					}}
				>
					<Nerdwallet />
				</div>
			</>
		);
	}
}
