import { Box, Button, Container, Grid, IconButton, Modal, Slide, Stack, Typography } from '@mui/material';
import './index.scss';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import aRounds from '../../../Assets/RealtorTeam/Agents/Realtor_aRounds.jpg';
import rLoomis from '../../../Assets/RealtorTeam/Agents/Realtor_rLoomis.jpg';
import RealtorAgentReferralForm from '../../../Components/Forms/RealtorAgentReferralForm';
import { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function Individual() {
  const { AgentName } = useParams();
  const navigate = useNavigate();
  const handleNavigate = (route: string) => navigate(`${route}`);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const handleConfirmationOpen = () => {
    setConfirmationOpen(true);
  };
  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  return (
    <>

      <Helmet>
        <title>Agent Page{AgentName ? ": " + AgentName : ""} | Safe Haven Security</title>
        <meta
          name="description"
          content=""
        />
        <meta
          name="keywords"
          content="Safe Haven Security,"
        />
      </Helmet>
      <Box sx={{ background: '#fff' }}>
        <Container>
          <Grid container spacing={{ xs: 0 }} sx={{ padding: '40px' }}>
            <Grid item xs={12} lg={6}>
              <Stack>
                {AgentName === "alex-rounds" &&
                  <Box
                    component='img'
                    sx={{
                      width: 'auto',
                      maxWidth: '100%',
                      maxHeight: '500px',
                      margin: 'auto',
                    }}
                    alt='The Realtor Team as a whole.'
                    src={aRounds}
                  />}
                {AgentName === "russell-loomis" &&
                  <Box
                    component='img'
                    sx={{
                      width: 'auto',
                      maxWidth: '70%',
                      maxHeight: '500px',
                      margin: 'auto',
                    }}
                    alt='The Realtor Team as a whole.'
                    src={rLoomis}
                  />}
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Stack sx={{ width: '100%' }}>
                {AgentName === "alex-rounds" && <><Typography
                  sx={{
                    textAlign: 'center',
                    color: '#1260a8',
                    fontSize: { xs: '32px', md: '36px', lg: '48px' },
                    fontWeight: '700'
                  }}
                >
                  Alex Rounds
                </Typography>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: { xs: '24px', md: '28px', lg: '32px' },
                    }}
                  >
                    National Sales Director
                    <br></br>
                    (571) 252-7267
                    <br></br>
                    arounds@mysafehaven.com
                  </Typography></>}
                {AgentName === "russell-loomis" && <><Typography
                  sx={{
                    textAlign: 'center',
                    color: '#1260a8',
                    fontSize: { xs: '32px', md: '36px', lg: '48px' },
                    fontWeight: '700'
                  }}
                >
                  Russell Loomis
                </Typography>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: { xs: '24px', md: '28px', lg: '32px' },
                    }}
                  >
                    National Sales Director
                    <br></br>
                    (678) 582-9188
                    <br></br>
                    rloomis@mysafehaven.com
                  </Typography></>}

                {/* <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '32px 0' }}>
                  <Button
                    variant={'contained'}
                    className='homebuilder-learn-more-btn'
                    sx={{
                      minWidth: "150px",
                      margin: '0 16px',
                      fontWeight: '700',
                      font: "normal normal 700 14px 'PT Sans', Helvetica, Arial, Verdana, sans-serif",
                    }}

                  >
                    Enrollment
                  </Button>
                  <Button
                    variant={'contained'}
                    className='homebuilder-learn-more-btn'
                    sx={{
                      minWidth: "150px",
                      margin: '0 16px',
                      fontWeight: '700',
                      font: "normal normal 700 14px 'PT Sans', Helvetica, Arial, Verdana, sans-serif",
                    }}

                  >
                    About
                  </Button>
                </Box> */}
              </Stack>
            </Grid>

          </Grid>
        </Container>
      </Box>

      <Container>

        <RealtorAgentReferralForm setConfirmationOpen={setConfirmationOpen}></RealtorAgentReferralForm>

        <Modal
          open={confirmationOpen}
          closeAfterTransition
          onClose={() => {
            handleConfirmationClose();
          }}
          className=''
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Slide direction='up' in={confirmationOpen}>
            <Container
              maxWidth={false}
              sx={{
                maxWidth: '700px',
                minHeight: '400px',
                height: 'auto',
                bgcolor: '#fff',
                borderRadius: '9px',
                boxShadow: 24,
                padding: '0!important'
              }}
            >
              <Box sx={{ background: 'rgb(18, 96, 168)', width: '100%', minHeight: '64px', borderRadius: '9px 9px 0 0px', padding: '10px 0', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                <Typography sx={{ fontSize: '24px', fontWeight: '700', textAlign: 'center', color: '#fff', maxWidth: "500px" }}>
                  Thank you for your interest in becoming an ADT Referral Partner!
                </Typography>
                <Box sx={{
                  position: "absolute",
                  right: "5px",
                }}>
                  <IconButton
                    sx={{}}
                    onClick={() => {
                      handleConfirmationClose();
                    }}
                  >
                    <ClearIcon sx={{ color: '#fff' }}></ClearIcon>
                  </IconButton>
                </Box>
              </Box>
              <Stack
                direction={'row'}
                sx={{ alignItems: 'center', justifyContent: 'space-evenly' }}
              >

                <Box
                  sx={{
                    paddingLeft: '36px',
                    minHeight: '350px',
                    minWidth: '250px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                  }}
                >
                  <Box>
                    <Box sx={{ width: '100%', display: 'flex' }}>
                      <CheckCircleOutlineIcon sx={{ color: 'rgb(18, 96, 168)', margin: 'auto', height: '4rem', width: 'auto', marginBottom: '15px' }}></CheckCircleOutlineIcon>
                    </Box>

                    <Typography sx={{ fontSize: '24px', maxWidth: '600px' }}>
                      We received your request for more information and a member of our team will be in touch with you shortly to discuss the program's benefits and answer any questions you may have.
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '24px 0' }}>
                      <Button
                        variant={'contained'}
                        onClick={() => {
                          handleNavigate('/');
                        }}
                        sx={{
                          minWidth: '100px',
                          minHeight: '45px',
                          fontSize: '16px',
                          textTransform: 'none',
                          fontWeight: '700',
                        }}
                      >
                        Back To Home
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Stack>
            </Container>
          </Slide>
        </Modal>
      </Container>

    </>
  );
}
