import React from "react";
import { Container, Grid, Box, IconButton, useMediaQuery } from "@mui/material"; // Assuming Material-UI is used
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"; // Material-UI ChevronLeft icon
import ChevronRightIcon from "@mui/icons-material/ChevronRight"; // Material-UI ChevronRight icon
import { products } from "../../../../../../Constants/NerdWallet";
const ScrollableImageGrid = () => {
	const [currentSlide, setCurrentSlide] = React.useState(0);
	const contentRef = React.useRef(null); // Ref to store the content container
	const isMobile = useMediaQuery("(max-width: 600px)"); // Define your breakpoint for mobile

	const itemsToShow = isMobile ? 3 : 5; // Determine number of items based on screen size

	const handlePrevSlide = () => {
		setCurrentSlide((prevSlide) => Math.max(prevSlide - 1, 0));
	};

	const handleNextSlide = () => {
		setCurrentSlide((prevSlide) =>
			Math.min(prevSlide + 1, products.length - itemsToShow),
		);
	};
	return (
		<Container
			sx={{
        width: { xs: "90%", md: "65%" },
				margin: "auto",
				marginTop: "39px",
				marginBottom: "64px",
				borderRadius: "18px",
				position: "relative",
				overflow: "hidden",
				"&::-webkit-scrollbar": {
					display: "none",
				},
			}}
		>
			<IconButton
				disabled={currentSlide === 0}
				onClick={handlePrevSlide}
				sx={{
					position: "absolute",
					left: 0,
					top: "50%",
					transform: "translateY(-50%)",
					zIndex: 1,
				}}
			>
				<ChevronLeftIcon />
			</IconButton>

			<div
				ref={contentRef}
				style={{
					display: "flex",
					overflowX: "hidden",
					overflowY: "hidden",
					scrollBehavior: "smooth",
					flex: "1 1 0",
					padding: "10px",
					marginLeft: "15px",
					scrollbarColor: "transparent transparent",
				}}
			>
				{products
					.slice(currentSlide, currentSlide + itemsToShow)
					.map((product, index) => (
						<Grid item xs={12} md={2.4} key={index}>
							<Box
								component="img"
								sx={{
									maxWidth: "96%",
									height: "auto",
									marginRight: "22px",
								}}
								src={product.image}
								alt={`Image ${currentSlide + index + 1}`}
							/>
						</Grid>
					))}
			</div>

			<IconButton
				disabled={currentSlide >= products.length - itemsToShow}
				onClick={handleNextSlide}
				sx={{
					position: "absolute",
					right: 0,
					top: "50%",
					transform: "translateY(-50%)",
					zIndex: 1,
				}}
			>
				<ChevronRightIcon />
			</IconButton>
		</Container>
	);
};

export default ScrollableImageGrid;
