import { Button, Link, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import './index.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import CompanyLogo from '../../../../Components/Header/CompanyLogo';
import devices from '../../../../Assets/Templates/System-image.png';
import LogoBanner from '../Components/LogoBanner';

export default function ConfirmationPage(props: any) {
    const location = useLocation();
    const newLeadId = location.state?.newLeadId;
    const partnerInfo = location.state?.partnerInfo;
    const navigate = useNavigate();
    const handleNavigate = (route: string, location: string | null = null) => {
        if (location === '_blank') {
            window.open(route, location);
        } else {
            navigate(route);
        }
    };

    return (
        <>
            <Container>
                <LogoBanner partnerInfo={partnerInfo} variant='confirmation'></LogoBanner>

                <Box sx={{ color: '#545859', font: 'normal 600 34px/44px "Montserrat",Helvetica,Arial,Verdana,sans-serif', textAlign: 'center', paddingBottom: '24px' }}>
                    A representative will contact you within 24 hours.
                    We’re excited to share all these amazing features with you!
                    Confirmation Number:  <span style={{ color: 'blue', font: 'normal 600 34px/44px "Montserrat",Helvetica,Arial,Verdana,sans-serif' }}>{newLeadId}</span>
                </Box>
                <Box
                    component="img"
                    sx={{
                        maxWidth: "100%",
                        width: "100%",
                        height: "auto",
                    }}
                    src={devices}
                />
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
                    <Button
                        variant={'contained'}
                        className='partner-call-btn'
                        sx={{ fontWeight: 700, minHeight: '50px' }}
                        onClick={() => {
                            navigate(-1);
                        }}
                        onMouseDown={(event) => {
                            if (event.button === 1) {
                                event.preventDefault();
                                const previousUrl = document.referrer;
                                if (previousUrl) {
                                    window.open(previousUrl, '_blank');
                                } else {
                                    console.warn('No previous URL found.');
                                }
                            }
                        }}
                    >
                        Return to Submission Page
                    </Button>
                </Box>
            </Container>

            <Box className='disclaimer-footer' sx={{
                fontSize: '12px',
                lineHeight: '15px',
                textAlign: 'center',
                font: 'normal 12px / 22px "Montserrat", Helvetica, Arial, Verdana, sans-serif',
            }}>
                License numbers by State: AL-1493, AR- CMPY.0002629, AZ- ROC-332758,18404-0, CA-ACO8016, CT-HIC.0661825, DC-602521000002, DE-17-39, FL-EF20000905, GA-LVU406182, IA-AS-0158, C115438, IL-127.001386, LA-F1672, MA-8150 A1, SS-002127, MD-107-1696, MI-3601208054, MN-TS710056, MS-15019531, NC-1150-CSA, SP.FA/LV.28805, NH-064, NJ- 34BX00006600, NM-403946, NV-0079533, NY-12000077734, OK-AC1802, OR-234026, PA-PA040539, RI-4604, TSC8204, SC-BAC.13574, TN-2280, TX-B14839,ACR-2625584, UT-10999117-6501, VA-11-6402, WA-SAFEHHS791CH, WV-WV060562

                <br></br>
                <br></br>

                Copyright: Smart Home Advantage 2021 | <Link sx={{
                    color: '#fff',
                    font: 'normal 12px / 22px "Montserrat", Helvetica, Arial, Verdana, sans-serif',
                    cursor: 'pointer'
                }}
                    onMouseDown={(event) => {
                        if (event.button === 1) {
                            handleNavigate('/terms-and-conditions', '_blank');
                        }
                    }}
                    onClick={(event) => {
                        handleNavigate('/terms-and-conditions');
                    }}
                >
                    Terms & Conditions
                </Link>
            </Box>
        </>
    );
}
