import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import bannerImage1 from '../../../Assets/ContactUs/contact-us.jpg';
import bannerImage3 from '../../../Assets/ContactUs/contact-us-3.jpg';
import bannerImage5 from '../../../Assets/ContactUs/office-space.png';
import iconPhone from '../../../Assets/ContactUs/icon-phone.png';
import iconEmail from '../../../Assets/ContactUs/icon-email.png';
import iconSpeechBubble from '../../../Assets/ContactUs/speech-bubble.png';
import iconLocation from '../../../Assets/ContactUs/icon-location.png';
import iconTeamIdea from '../../../Assets/ContactUs/team-idea.png';
import iconHeadset from '../../../Assets/ContactUs/headset.png';
import ContactUsForm from './ContactUsForm';
import { useState } from 'react';
import OfficeMap from './OfficeMap';

export default function ContactUs() {
  const [emailFormDisplay, setEmailFormDisplay] = useState(false);

  return (
    <>
      <Box sx={{ backgroundColor: 'white', paddingBottom: '128px' }}>
        <Box
          sx={{
            backgroundImage: `url("${bannerImage5}")`,
            backgroundSize: 'cover',
            backgroundColor: 'rgb(0 0 53 / 62%)',
            backgroundBlendMode: 'multiply',
            backgroundPosition: '0px 55%',
            height: '350px',
            minHeight: '350px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              backdropFilter: 'blur(4px)',
              width: '100%',
              height: '100%',
              margin: 'auto',
              color: 'white',
              display: 'flex',
              zIndex: '3',
            }}
          >
            <Container
              sx={{
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'center',
                textAlign: 'center',
                maxWidth: '600px',
                paddingBottom: '75px',
              }}
            >
              <Typography
                variant='h1'
                sx={{
                  height: '100%',
                  fontWeight: '600',
                  lineHeight: 'calc(1em + 8px)',
                  fontFamily: 'Sharp Sans, Arial, sans-serif',
                  fontSize: '72px',
                  marginRight: '25px',
                  marginTop: '12px',
                }}
              >
                Contact Us
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignSelf: 'center',
                }}
              >
                <Box
                  sx={{
                    maxWidth: '90px',
                    maxHeight: '90px',
                    borderRadius: '50%',
                    backgroundColor: '#006fb9',
                    marginRight: '-10px',
                    border: '1px solid white',
                    zIndex: '2',
                  }}
                >
                  <Box
                    component='img'
                    sx={{ width: '50px', padding: '20px' }}
                    alt='The house from the offer.'
                    src={iconPhone}
                  />
                </Box>
                <Box
                  sx={{
                    maxWidth: '90px',
                    maxHeight: '90px',
                    borderRadius: '50%',
                    backgroundColor: '#006fb9',
                    border: '1px solid white',
                    marginTop: '20px',
                    marginRight: '-10px',
                    zIndex: '0',
                  }}
                >
                  <Box
                    component='img'
                    sx={{ width: '50px', padding: '20px' }}
                    alt='The house from the offer.'
                    src={iconEmail}
                  />
                </Box>
                <Box
                  sx={{
                    maxWidth: '90px',
                    maxHeight: '90px',
                    borderRadius: '50%',
                    backgroundColor: '#006fb9',
                    border: '1px solid white',
                    zIndex: '1',
                  }}
                >
                  <Box
                    component='img'
                    sx={{ width: '50px', padding: '20px' }}
                    alt='The house from the offer.'
                    src={iconLocation}
                  />
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>

        <Container sx={{ marginTop: '-75px', zIndex: '4' }}>
          <Grid
            columns={2}
            container
            spacing={0}
            columnSpacing={3}
            sx={{ background: 'transparent', height: '400px' }}
          >
            <Grid item xs={1} sx={{ display: 'flex', zIndex: 1 }}>
              <Box
                sx={{
                  backgroundColor: '#eee',
                  border: '1px solid #c5c5c5',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  width: '100%',
                  padding: '32px 40px 40px',
                }}
              >
                <Box
                  component='img'
                  sx={{ width: '60px', padding: '20px' }}
                  alt='The house from the offer.'
                  src={iconHeadset}
                />
                <Typography
                  sx={{
                    marginBottom: '24px',
                    fontWeight: 600,
                    fontSize: '22px',
                  }}
                >
                  Contact Sales Representative
                </Typography>
                <Typography
                  sx={{
                    marginBottom: '24px',
                    fontWeight: 300,
                    fontSize: '20px',
                    lineHeight: 1.75,
                  }}
                >
                  Interested in Safe Haven Security? <br></br>Give us a call and
                  we'll be happy to answer your questions.
                </Typography>
                <Button sx={{ fontSize: '18px' }}>+1 855 123 1234</Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                display: 'flex',
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#eee',
                  border: '1px solid #c5c5c5',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  width: '100%',
                  padding: '32px 40px 40px',
                }}
              >
                <Box
                  component='img'
                  sx={{ width: '60px', padding: '20px' }}
                  alt='The house from the offer.'
                  src={iconSpeechBubble}
                />
                <Typography
                  sx={{
                    marginBottom: '24px',
                    fontWeight: 600,
                    fontSize: '22px',
                  }}
                >
                  Contact Customer Support
                </Typography>
                <Typography
                  sx={{
                    marginBottom: '24px',
                    fontWeight: 300,
                    fontSize: '20px',
                    lineHeight: 1.75,
                  }}
                >
                  Need help getting started?<br></br> We've compiled a list of
                  FAQs to help you get on your way.
                </Typography>
                <Button
                  variant='contained'
                  sx={{ padding: '12px 32px' }}
                  onClick={() => { }}
                >
                  FAQ
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Divider
          variant='middle'
          sx={{ maxWidth: '600px', margin: '96px auto' }}
        ></Divider>

        <Container
          sx={{
            backgroundColor: '#eee',
            padding: '60px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: '1 1 0%,',
            margin: '0 auto',
            border: '1px solid #c5c5c5',
          }}
        >
          <Typography
            sx={{
              fontSize: '28px',
              lineHeight: '32px',
              fontWeight: 'bold',
              marginBottom: '0.6em',
              textAlign: 'center',
            }}
          >
            Questions? We Have Answers
          </Typography>
          <Typography
            sx={{
              width: '66%',
              fontSize: '18px',
              lineHeight: '1.5em',
              textAlign: 'center',
              marginBottom: '1.5em',
            }}
          >
            Check out our FAQs for answers to popular questions. If you need to
            get in touch, email us at support@safehaven.com, or text/call at +1
            (855) 123-1234. We're on the phones 6am - 5:30pm PT Monday-Thursday
            and 6am - 4:30pm PT Friday.
          </Typography>
          <Button
            variant='contained'
            sx={{ padding: '12px 32px' }}
            onClick={() => {
              setEmailFormDisplay(!emailFormDisplay);
            }}
          >
            Email Us
          </Button>
          {emailFormDisplay && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '60%',
                minWidth: '290px',
                margin: '2em auto 0px',
              }}
            >
              <ContactUsForm></ContactUsForm>
            </Box>
          )}
        </Container>

        <Divider
          variant='middle'
          sx={{ maxWidth: '600px', margin: '96px auto' }}
        ></Divider>

        <Container sx={{ padding: '0!important' }}>
          <Box sx={{ padding: '0em 0 1em 0' }}>
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '2rem',
                fontWeight: '600',
                lineHeight: '1.38',
                margin: '0.5em 0',
                maxWidth: '710px',
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: '0 1rem',
                paddingBottom: '2rem',
              }}
            >
              Connect With Our Office
            </Typography>
          </Box>
          <Grid
            columns={3}
            container
            spacing={0}
            sx={{ border: '1px solid #c5c5c5', height: '600px' }}
          >
            <Grid item xs={2}>
              <Box>
                <OfficeMap></OfficeMap>
              </Box>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ backgroundColor: '#eee', height: '100%', display: 'flex' }}
            >
              <Stack
                sx={{
                  padding: '2rem',
                  margin: 'auto 0',
                }}
              >
                <Typography
                  variant={'h4'}
                  sx={{
                    fontWeight: '500',
                    lineHeight: '1.42',
                    margin: '0.5em 0',
                  }}
                >
                  Headquarters
                </Typography>
                <Typography
                  variant={'h6'}
                  sx={{
                    fontWeight: 300,
                    lineHeight: 1.75,
                  }}
                >
                  Address
                </Typography>
                <Typography
                  variant={'body1'}
                  sx={{
                    paddingBottom: '1em',
                    fontWeight: 300,
                    lineHeight: 1.75,
                  }}
                >
                  1234 N Main St
                  <br></br>
                  Kansas City, MO 64115
                  <br></br>
                  United States
                </Typography>
                <Typography
                  variant={'h6'}
                  sx={{
                    fontWeight: 300,
                    lineHeight: 1.75,
                  }}
                >
                  Phone
                </Typography>
                <Typography
                  variant={'body1'}
                  sx={{
                    paddingBottom: '1em',
                    fontWeight: 300,
                    lineHeight: 1.75,
                  }}
                >
                  + 1 888 123 1234{' '}
                </Typography>
                <Typography
                  variant={'h6'}
                  sx={{
                    fontWeight: 300,
                    lineHeight: 1.75,
                  }}
                >
                  Hours
                </Typography>
                <Typography
                  variant={'body1'}
                  sx={{
                    paddingBottom: '1em',
                    fontWeight: 300,
                    lineHeight: 1.75,
                  }}
                >
                  Monday - Friday: 8:00am - 5:00pm
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
