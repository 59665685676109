import Box from '@mui/material/Box';
import { useEffect } from 'react';

export default function BirdeyeWidget() {
  useEffect(() => {
    const scriptElement = document.createElement('script');
    scriptElement.src =
      'https://birdeye.com/embed/v4/153357839059541/7/2602589841';
    scriptElement.async = true;
    document.body.appendChild(scriptElement);
  }, []);

  return (
    <>
      <Box id='bf-revz-widget-2602589841'></Box>
    </>
  );
}
