import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import image1 from '../../Assets/Partner/career-meeting-1.png';
import testimonialBg from '../../Assets/Partner/mark-team-002.jpg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupsIcon from '@mui/icons-material/Groups';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import './index.scss';
import AccoladesRibbon from '../../Components/NumbersBanner';
import { Helmet } from 'react-helmet';

export default function Partners() {
  const navigate = useNavigate();
  const handleNavigate = (route: string) => navigate(`${route}`);
  const [expanded, setExpanded] = useState<string | false>('');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <>
      <Helmet>
        <title>Safe Haven Partner Programs | Safe Haven Security</title>
        <meta name="description" content="Explore partnership opportunities with Safe Haven Security, including the Agent Referral Program and Homebuilder Program. Gain access to exclusive benefits and grow your business." />
        <meta name="keywords" content="Safe Haven Security, partner programs, agent referral program, homebuilder program, business growth, security solutions" />
      </Helmet>
      <Box sx={{ display: 'flex', background: '#ffffff' }}>
        <Box
          component='img'
          sx={{
            width: 'auto',
            maxWidth: '100%',
            maxHeight: '75vh',
            margin: 'auto',
          }}
          alt='The house from the offer.'
          src={image1}
        />
      </Box>
      <Container maxWidth='xl'>
        <Typography
          sx={{
            paddingTop: '70px',
            color: '#1260a8',
            fontWeight: '700',
            fontSize: '48px',
            marginBottom: '35px',
          }}
        >
          OUR PARTNER PROGRAMS
        </Typography>
        <Grid container spacing={10} sx={{ paddingBottom: '80px' }}>
          <Grid item xs={12} md={6}>
            <Stack sx={{ alignItems: 'start' }}>
              <Typography sx={{ fontSize: '32px', marginBottom: '35px' }}>
                Agent Referral Program
              </Typography>
              <Typography sx={{ fontSize: '16px', marginBottom: '35px' }}>
                As the largest and oldest Agent Referral Program in the U.S.,
                Safe Haven has more realtor relationships than any other ADT
                Authorized Dealer. We make sure our clients are taken care of
                with the best personalized customer service available.
              </Typography>
              <Button
                variant={'contained'}
                className='partners-learn-more-buttons'
                endIcon={
                  <NavigateNextIcon sx={{ height: '40px', width: '40px' }} />
                }
                onClick={() => {
                  {
                    handleNavigate('/partners/agent-referral');
                  }
                }}
                sx={{ fontWeight: '700' }}
              >
                Learn More
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack>
              <Typography sx={{ fontSize: '32px', marginBottom: '35px' }}>
                Homebuilder Program
              </Typography>
              <Typography sx={{ fontSize: '16px', marginBottom: '35px' }}>
                Designed for homebuilders by a builder and developer, our
                Homebuilder Program allows builders and representatives to
                participate in the revenue stream the way they deserve, while
                giving their clients the brand they want.
              </Typography>
              <Box>
                <Button
                  variant={'contained'}
                  className='partners-learn-more-buttons'
                  endIcon={
                    <NavigateNextIcon sx={{ height: '40px', width: '40px' }} />
                  }
                  onClick={() => {
                    {
                      handleNavigate('/partners/homebuilder-partners');
                    }
                  }}
                  sx={{ fontWeight: '700' }}
                >
                  Learn More
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <AccoladesRibbon></AccoladesRibbon>

      <Container maxWidth={"xl"} sx={{
        padding: '70px 0', background: '#ffffff'
      }}>
        <Box>
          <Typography
            variant='h1'
            sx={{
              color: '#1260a8',
              fontSize: '48px',
              paddingBottom: '70px',
              fontWeight: '700',
            }}
          >
            CUSTOMER TESTIMONIALS
          </Typography>
        </Box>

        <Grid
          container
          spacing={{ xs: 0, lg: 3 }}
          rowSpacing={{ xs: 3, lg: 0 }}
          maxWidth={'xl'}
          sx={{
            margin: 'auto',
            color: '#ffffff',
            width: '100%'
          }}
        >
          <Grid item xs={12} lg={4}>
            <Box
              sx={{
                height: '500px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundImage: `url(${testimonialBg})`,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(18,96,168,0.93)',
                  padding: '0 20px',
                  height: '100%',
                }}
              >
                <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}>
                  Voice control is my favorite Pulse feature. It’s so convenient
                  to use when I’m on the run. Safe Haven did a great job!
                </Typography>
                <Typography sx={{ fontSize: '22px', textAlign: 'right' }}>
                  Diana Q.
                </Typography>
                <Typography sx={{ fontSize: '22px', textAlign: 'right' }}>
                  Denver, CO
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box
              sx={{
                height: '500px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundImage: `url(${testimonialBg})`,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(18,96,168,0.93)',
                  padding: '0 20px',
                  height: '100%',
                }}
              >
                <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}>
                  I love being able to look on my Pulse camera and make sure my
                  dog is happy when I’m gone!
                </Typography>
                <Typography sx={{ fontSize: '22px', textAlign: 'right' }}>
                  Erin C.
                </Typography>
                <Typography sx={{ fontSize: '22px', textAlign: 'right' }}>
                  Kansas City, MO
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box
              sx={{
                height: '500px',
                width: '100%',
                backgroundImage: `url(${testimonialBg})`,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(18,96,168,0.93)',
                  padding: '0 20px',
                  height: '100%',
                }}
              >
                <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}>
                  I couldn’t believe how much I saved on my gas bill! ADT and Safe
                  Haven you’re the best. Thank you for making everything so easy
                  and convenient on one app.
                </Typography>
                <Typography sx={{ fontSize: '22px', textAlign: 'right' }}>
                  Diana Q.
                </Typography>
                <Typography sx={{ fontSize: '22px', textAlign: 'right' }}>
                  Seattle, WA
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
