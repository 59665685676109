import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, CircularProgress, Rating, Avatar } from '@mui/material';

interface PropsWrapper {
  placeId: string;
}

export default function GoogleReviews({ placeId }: PropsWrapper) {
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);

  useEffect(() => {
    const fetchReviews = async () => {
      const apiUrl = process.env.REACT_APP_API + `/Google/Reviews/${placeId}`;

      try {
        const response = await fetch(apiUrl);
        //const data = await response.json();

        console.log(response)

        // if (data && data.result && data.result.reviews) {
        //   setReviews(data.result.reviews);
        // } else {
        //   setError('No reviews found');
        // }
      } catch (error) {
        console.error('Error fetching reviews:', error);
        setError('Failed to fetch reviews');
      } finally {
        setIsLoading(false);
      }
    };

    fetchReviews();
  }, [placeId]);

  return (
    <Container className="google-reviews">
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        reviews.map((review, index) => (
          <Box key={index} className="review" sx={{ mb: 2 }}>
            {/* <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Avatar alt={review.author_name} src={review.profile_photo_url} />
              <Typography variant="h6" sx={{ ml: 1 }}>{review.author_name}</Typography>
            </Box>
            <Rating name="read-only" value={review.rating} precision={0.5} readOnly />
            <Typography>{review.text}</Typography> */}
          </Box>
        ))
      )}
    </Container>
  );
}
