import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import partner from '../../../Assets/Partner/Remodel/become-a-partner.png';
import puzzle from '../../../Assets/Partner/puzzle.png';
import curvyBg from '../../../Assets/Partner/curvy-bg.png';
import curvyBg2 from '../../../Assets/Partner/curvy-bg-2.png';
import partnerlogo1 from '../../../Assets/Partner/paddio.png';
import './index.scss';
import RequestDemoForm from './RequestDemoForm';
import { useState } from 'react';
import ReviewStars from './ReviewStars';
import { useNavigate } from 'react-router-dom';

const partnerLogos = [
  {
    index: 1,
    label: '',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/1.png',
  },
  {
    index: 1,
    label: 'Careers',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png',
  },
  {
    index: 1,
    label: 'Partners',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png',
  },
  {
    index: 1,
    label: 'Partners',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png',
  },
  {
    index: 1,
    label: 'Partners',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png',
  },
  {
    index: 1,
    label: 'Partners',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png',
  },
  {
    index: 1,
    label: 'Partners',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png',
  },
  {
    index: 1,
    label: '',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/1.png',
  },
  {
    index: 1,
    label: 'Careers',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png',
  },
  {
    index: 1,
    label: 'Partners',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png',
  },
  {
    index: 1,
    label: 'Partners',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png',
  },
  {
    index: 1,
    label: 'Partners',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png',
  },
  {
    index: 1,
    label: 'Partners',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png',
  },
  {
    index: 1,
    label: 'Partners',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png',
  },
];

const partnerReviews = [
  {
    index: 1,
    starCount: '5',
    quote: 'Safe Haven Security is phenomenal.',
    img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png',
    name: 'John Smith',
    title: 'Co-Founder & CEO, NFT',
  },
  {
    index: 1,
    starCount: '5',
    quote: 'Safe Haven Security is phenomenal.',
    img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png',
    name: 'John Smith',
    title: 'Co-Founder & CEO, NFT',
  },
  {
    index: 1,
    starCount: '5',
    quote: 'Safe Haven Security is phenomenal.',
    img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png',
    name: 'John Smith',
    title: 'Co-Founder & CEO, NFT',
  },
  {
    index: 1,
    starCount: '5',
    quote: 'Safe Haven Security is phenomenal.',
    img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png',
    name: 'John Smith',
    title: 'Co-Founder & CEO, NFT',
  },
  {
    index: 1,
    starCount: '5',
    quote: 'Safe Haven Security is phenomenal.',
    img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png',
    name: 'John Smith',
    title: 'Co-Founder & CEO, NFT',
  },
  {
    index: 1,
    starCount: '5',
    quote: 'Safe Haven Security is phenomenal.',
    img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png',
    name: 'John Smith',
    title: 'Co-Founder & CEO, NFT',
  },
];

export default function Partners() {
  const navigate = useNavigate();
  const handleNavigate = (route: string) => navigate(`${route}`);
  const [expanded, setExpanded] = useState<string | false>('');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <>
      <Box sx={{ backgroundColor: 'white' }}>
        <Container maxWidth={'xl'}>
          <Grid
            container
            sx={{
              padding: { xs: '0', md: '4rem' },
              flexDirection: { xs: 'column-reverse', md: 'row' },
            }}
          >
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography
                className='animated animatedFadeInUp fadeInUp twoHundred'
                sx={{
                  color: '#616467',
                  textTransform: 'uppercase',
                  fontSize: { xs: '16px', md: '1.2rem' },
                  textAlign: { xs: 'center', md: 'initial' },
                  fontWeight: '700',
                  lineHeight: '1.5',
                }}
              >
                BECOME A SAFE HAVEN AFFILIATE
              </Typography>
              <Typography
                variant='h1'
                className='animated animatedFadeInUp fadeInUp fourHundred'
                sx={{
                  fontSize: { xs: '2.5rem', md: '3.6rem' },
                  textAlign: { xs: 'center', md: 'initial' },
                  fontWeight: 700,
                  lineHeight: 1.1,
                  margin: '16px 0',
                }}
              >
                Generate qualified leads and get paid
              </Typography>
              <Typography
                className='animated animatedFadeInUp fadeInUp sixHundred'
                sx={{
                  marginTop: '1rem',
                  lineHeight: 1.5,
                  fontSize: { sm: '1rem', md: '1.25rem' },
                  textAlign: { xs: 'center', md: 'initial' },
                  fontWeight: 300,
                }}
              >
                We are the leading security company for growing businesses, and
                we want to partner with you. Our goal is to help you protect
                your assets, while ultimately helping you grow your business.
              </Typography>

              <Box
                className='animated animatedFadeInUp fadeInUp eightHundred'
                sx={{
                  marginTop: { xs: '2rem', md: '3rem' },
                  marginBottom: { xs: '2rem', md: '0' },
                  textAlign: 'center',
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    width: { xs: '50%', md: 'initial' },
                    margin: { xs: 'auto', md: '0' },
                  }}
                >
                  <Button
                    variant='contained'
                    sx={{
                      padding: { sm: '12px 20px', md: '12px 32px' },
                    }}
                  >
                    Learn More
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: { xs: '50%', md: 'initial' },
                    margin: { xs: 'auto', md: '0' },
                  }}
                >
                  <Button
                    variant='text'
                    sx={{
                      marginLeft: '10px',
                      padding: { sm: '12px 20px', md: '12px 32px' },
                      lineHeight: { xs: 'normal', md: '1.75' },
                      outline: 'solid 1px #ffffff',
                      transition:
                        'outline-color 250ms cubic-bezier(0.4, 0, 0.2, 1)',

                      '&:hover': {
                        outlineColor: '#1976d2',
                      },
                    }}
                  >
                    Become a Partner
                    <Box
                      sx={{
                        width: '16px',
                        height: '18px',
                        marginLeft: '8px',
                        marginBottom: '2px',
                      }}
                    >
                      <svg
                        width='100%'
                        height='100%'
                        viewBox='0 0 18 11'
                        fill='#1976d2'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M17.9129 5.856L12.5849 0.888L11.4089 2.064L14.6249 4.92H0.584906V6.768H14.6249L11.4089 9.648L12.5849 10.8L17.9129 5.856Z'
                          fill='inherit'
                        ></path>
                      </svg>
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={0} md={1}></Grid>
            <Grid item xs={12} md={6}>
              <Box
                component='img'
                sx={{ margin: '32px 0', width: '100%', borderRadius: '10px' }}
                alt='The house from the offer.'
                src={partner}
              />
            </Grid>
          </Grid>
        </Container>
        <Container
          maxWidth={'xl'}
          sx={{ marginTop: { xs: '4rem', md: 'initial' }, overflow: 'hidden' }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              sx={{
                color: '#475467',
                fontSize: '1rem',
              }}
            >
              Afilliate companies of all sizes trust Safe Haven Security's
              Partnership Programs with their success
            </Typography>
          </Box>
          <Box
            sx={{
              maskImage: {
                xs: 'linear-gradient(to right,rgba(255,255,255,0) calc(0% ),white calc(0%),white calc(100% - 5.25rem),rgba(255,255,255,0) calc(100%))',
                md: 'linear-gradient(to right,rgba(255,255,255,0) calc(0%),white calc(0% + 5.25rem),white calc(100% - 5.25rem),rgba(255,255,255,0) calc(100%))',
              },
            }}
          >
            <Box
              className='slider'
              sx={{
                height: '100px',
                margin: 'auto',
                overflow: 'hidden',
                position: 'relative',
                width: '960px',
              }}
            >
              <Box
                className='slide-track'
                sx={{ display: 'flex', width: 'calc(250px * 14)' }}
              >
                {partnerLogos.map((logo) => (
                  <>
                    <Box sx={{ height: '100px', width: '250px' }}>
                      <Box
                        component='img'
                        sx={{ width: '100%', borderRadius: '10px' }}
                        alt='The house from the offer.'
                        src={logo.src}
                      />
                    </Box>
                  </>
                ))}
              </Box>
            </Box>
          </Box>
        </Container>

        <Box
          className='curvy-bg'
          sx={{
            background: `url(${curvyBg2}) top no-repeat`,
            backgroundSize: '100% 75%',
          }}
        >
          <Container sx={{ margin: '4rem auto' }}>
            <Box
              sx={{
                textAlign: 'center',
                marginBottom: '64px',
                paddingTop: '64px',
              }}
            >
              <Typography
                sx={{
                  color: '#000',
                  textTransform: 'uppercase',
                  marginBottom: '12px',
                  fontSize: { xs: '16px', md: '1rem' },
                  fontWeight: 600,
                  lineWeight: 1.5,
                }}
              >
                BECOME A SAFE HAVEN SECURITY PARTNER
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '2.5rem', md: '3rem' },
                  fontWeight: 600,
                  lineHeight: 1.2,
                  marginBottom: '16px',
                  color: 'white',
                }}
              >
                Find the right program for you
              </Typography>
              <Typography
                sx={{ fontSize: '1.125rem', lineHeight: 1.5, color: '#000' }}
              >
                Whether you want to generate revenue or provide more value to
                your customers, we have a partner program for you.
              </Typography>
            </Box>
            <Box>
              <Grid
                container
                spacing={4}
                sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
              >
                <Grid item xs={12} md={6}>
                  <Card
                    className='program-card'
                    sx={{ boxShadow: '0 5px 29px rgba(0,0,0,.1)' }}
                  >
                    <Box>
                      <Box sx={{ marginBottom: '32px', maxWidth: '62px' }}>
                        <Box
                          component='img'
                          sx={{ width: '100%', borderRadius: '10px' }}
                          alt='The house from the offer.'
                          src={puzzle}
                        />
                      </Box>
                      <Typography
                        variant='h2'
                        sx={{
                          fontSize: '32px',
                          lineHeight: '40px',
                          marginBottom: '16px',
                          fontWeight: 700,
                        }}
                      >
                        Agent Referral
                      </Typography>
                    </Box>
                    <Typography
                      variant='body1'
                      sx={{
                        fontSize: '16px',
                        lineHeight: '24px',
                      }}
                    >
                      Become a trusted Agent and get access to the #1 ADT
                      Authorized Dealer and market leader in agent referrals.
                    </Typography>
                    <Divider
                      sx={{
                        background: '#f2f4f5',
                        height: '1px',
                        margin: '16px auto 24px',
                        width: '100%',
                      }}
                    ></Divider>
                    <Typography
                      variant='body1'
                      sx={{
                        fontSize: '16px',
                        lineHeight: '24px',
                      }}
                    >
                      Collaborate with our team for a tailor-made approach to
                      increase the value you offer your clients and attract new
                      ones.
                    </Typography>{' '}
                    <Box
                      sx={{
                        margin: '16px 0 40px 0',
                        fontSize: '16px',
                        lineHeight: '24px',
                        ul: {
                          listStyle: 'none',
                        },
                      }}
                    >
                      <ul className='partner-card-list'>
                        <li>
                          Μembers access to the world’s leading hiring platform
                        </li>
                        <li>Easy link-based referral system</li>
                        <li>
                          Drive membership growth though exclusive savings
                        </li>
                      </ul>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Button
                        variant='contained'
                        sx={{
                          padding: {
                            sm: '12px 20px',
                            md: '12px 32px',
                            marginRight: '10px',
                          },
                        }}
                        onClick={() => {
                          {
                            handleNavigate('/partners/agent-referral');
                          }
                        }}
                      >
                        Find Out More
                      </Button>
                      <Box>
                        or
                        <Button
                          variant='text'
                          sx={{ padding: { sm: '12px 20px', md: '12px 32px' } }}
                        >
                          Apply Now
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Card
                    className='program-card'
                    sx={{ boxShadow: '0 5px 29px rgba(0,0,0,.1)' }}
                  >
                    <Box>
                      <Box sx={{ marginBottom: '32px', maxWidth: '62px' }}>
                        <Box
                          component='img'
                          sx={{ width: '100%', borderRadius: '10px' }}
                          alt='The house from the offer.'
                          src={puzzle}
                        />
                      </Box>
                      <Typography
                        variant='h2'
                        sx={{
                          fontSize: '32px',
                          lineHeight: '40px',
                          marginBottom: '16px',
                          fontWeight: 700,
                        }}
                      >
                        Home-builder
                      </Typography>
                    </Box>

                    <Typography
                      variant='body1'
                      sx={{
                        fontSize: '16px',
                        lineHeight: '24px',
                      }}
                    >
                      Participate directly in the revenue stream, while bringing
                      affordable home automation systems to clients.
                    </Typography>
                    <Divider
                      sx={{
                        background: '#f2f4f5',
                        height: '1px',
                        margin: '16px auto 24px',
                        width: '100%',
                      }}
                    ></Divider>
                    <Typography
                      variant='body1'
                      sx={{
                        fontSize: '16px',
                        lineHeight: '24px',
                      }}
                    >
                      For home-builders by a home-builder, this program ensures
                      your clients have access to the best home security and
                      support.
                    </Typography>
                    <Box
                      sx={{
                        margin: '16px 0 40px 0',
                        fontSize: '16px',
                        lineHeight: '24px',
                        ul: {
                          listStyle: 'none',
                        },
                      }}
                    >
                      <ul className='partner-card-list'>
                        <li>
                          Μembers access to the world’s leading hiring platform
                        </li>
                        <li>Easy link-based referral system</li>
                        <li>
                          Drive membership growth though exclusive savings
                        </li>
                      </ul>
                    </Box>

                    <Box sx={{ display: 'flex' }}>
                      <Button
                        variant='contained'
                        sx={{
                          padding: {
                            sm: '12px 20px',
                            md: '12px 32px',
                            marginRight: '10px',
                          },
                        }}
                        onClick={() => {
                          {
                            handleNavigate('/partners/home-builder');
                          }
                        }}
                      >
                        Find Out More
                      </Button>
                      <Box>
                        or
                        <Button
                          variant='text'
                          sx={{ padding: { sm: '12px 20px', md: '12px 32px' } }}
                        >
                          Apply Now
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>

        <Container
          maxWidth={'xl'}
          sx={{
            paddingTop: '32px',
            paddingBottom: '32px',
            margin: '10rem auto 6rem auto',
            textAlign: 'center',
            backgroundColor: 'rgb(235 246 252)',
          }}
        >
          <Box sx={{ marginBottom: '64px' }}>
            <Typography
              sx={{
                color: '#1976d2',
                textTransform: 'uppercase',
                marginBottom: '12px',
                fontSize: '1rem',
                fontWeight: 600,
                lineWeight: 1.5,
              }}
            >
              OUR CUSTOMERS SPEAK FOR US
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '2.5rem', md: '3rem' },
                fontWeight: 600,
                lineHeight: 1.2,
                marginBottom: '16px',
              }}
            >
              Don't just take our word for it
            </Typography>
            <Typography sx={{ fontSize: '1.125rem', lineHeight: 1.5 }}>
              Hear from some of our amazing customers who are scaling their
              partner program faster.
            </Typography>
          </Box>
          <Box>
            <Grid container spacing={5}>
              {partnerReviews.map((review) => (
                <>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ padding: '32px', backgroundColor: '#f9fafb' }}>
                      <Box
                        sx={{
                          marginBottom: '24px',
                          display: 'flex',
                          color: '#fec84b',
                        }}
                      >
                        <ReviewStars StarCount={review.starCount}></ReviewStars>
                      </Box>
                      <Box sx={{ marginBottom: '24px' }}>
                        <Typography
                          sx={{ fontSize: '20px', lineHeight: '30px' }}
                        >
                          {review.quote}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <Box>
                          <Box
                            component='img'
                            sx={{ width: '100%', borderRadius: '10px' }}
                            alt='The house from the offer.'
                            src={review.img}
                          />
                        </Box>
                        <Box>
                          <Typography>{review.name}</Typography>
                          <Typography>{review.title}</Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                </>
              ))}
            </Grid>
          </Box>
        </Container>
        {/* Already A Partner */}
        {/* <Container maxWidth={'xl'} sx={{ padding: '64px' }}>
          <Card
            sx={{ padding: '30px', backgroundColor: '#e5e5e5', margin: 'auto' }}
          >
            <Stack
              flexDirection={'column'}
              sx={{ width: 'fit-content', margin: 'auto' }}
            >
              <Box>
                <Typography variant='h3'>Already a member?</Typography>
                <Typography>
                  Log in to our Partner Hub <br></br>to get certified, submit
                  leads, and more.
                </Typography>
              </Box>

              <Box>
                <Button variant='contained' sx={{ padding: '12px 32px' }}>
                  Partner Login
                </Button>
              </Box>
            </Stack>
          </Card>
        </Container> */}
        <Box sx={{ background: '#eee', paddingBottom: '64px' }}>
          <Container maxWidth={'xl'}>
            <Grid container sx={{ height: '100%', padding: { md: '4rem' } }}>
              <Grid
                item
                xs={12}
                md={5}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant='h1'
                  className='animated animatedFadeInUp fadeInUp twoHundred'
                  sx={{
                    fontSize: { xs: '2.4rem', md: '3.6rem' },
                    textAlign: { xs: 'initial', md: 'initial' },
                    fontWeight: 700,
                    lineHeight: 1.1,
                    margin: '25px 0',
                  }}
                >
                  Take your business to the next level
                </Typography>
                <Typography
                  className='animated animatedFadeInUp fadeInUp fourHundred'
                  sx={{
                    marginTop: '1rem',
                    marginBottom: 'calc(1rem + 25px)',
                    lineHeight: 1.5,
                    fontSize: '1.25rem',
                    fontWeight: 300,
                  }}
                >
                  Find out how Safe Haven Security's partnership management
                  platform can help you effectively orchestrate the entire
                  partner lifecycle for sustained growth.
                </Typography>
                <Box sx={{ textAlign: { xs: 'center', md: 'initial' } }}>
                  <Button variant='contained' sx={{ padding: '12px 32px' }}>
                    Request A Demo
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={0} md={1}></Grid>
              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    marginTop: { xs: '64px', md: '0' },
                    padding: '45px 45px 55px 45px',
                  }}
                >
                  <Typography
                    variant='h3'
                    sx={{
                      fontSize: '36px',
                      fontWeight: 700,
                      lineHeight: '46px',
                      margin: '0 5px 30px',
                    }}
                  >
                    Request a Demo
                  </Typography>
                  <RequestDemoForm></RequestDemoForm>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box sx={{ backgroundColor: 'white', padding: '0 16px' }}>
          <Container sx={{ padding: '4rem 0' }}>
            <Box sx={{ paddingBottom: '3rem' }}>
              <Typography
                variant='h3'
                sx={{
                  fontSize: '32px',
                  fontWeight: 700,
                  lineHeight: '42px',
                  margin: '20px 0 16px 0',
                }}
              >
                FAQ
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '30px',
                }}
              >
                New partnerships mean new questions. Here are some FAQs that
                commonly pop up before joining the Safe Haven Security Affiliate
                Program.
              </Typography>
            </Box>
            <Box>
              <Accordion
                className='accordian'
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
                sx={{ paddingRight: '0' }}
              >
                <AccordionSummary
                  aria-controls='panel1d-content'
                  id='panel1d-header'
                  sx={{ maxWidth: '100%' }}
                >
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: 700,
                    }}
                  >
                    {expanded === 'panel1' ? '-' : '+'} How do we make money?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingRight: '0' }}>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                    blandit leo lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className='accordian'
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
                sx={{ paddingRight: '0' }}
              >
                <AccordionSummary
                  aria-controls='panel2d-content'
                  id='panel2d-header'
                >
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: 700,
                    }}
                  >
                    {expanded === 'panel2' ? '-' : '+'} What is the turnover
                    rate?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                    blandit leo lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className='accordian'
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
                sx={{ paddingRight: '0' }}
              >
                <AccordionSummary
                  aria-controls='panel3d-content'
                  id='panel3d-header'
                >
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: 700,
                    }}
                  >
                    {expanded === 'panel3' ? '-' : '+'} Are these programs
                    offered to any company?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                    blandit leo lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}
