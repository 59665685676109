import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box } from '@mui/system';
import "./index.scss";


export default function ScrollToTopButton(props: { showButton: any; }) {
    const { showButton } = props;

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            {showButton && (
                <Box className="scroll-top-button" onClick={handleScrollToTop}>
                    <ArrowUpwardIcon className="arrow-icon"></ArrowUpwardIcon>
                </Box>
            )}
        </>
    );
}
