import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Modal,
  Slide,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import culdesacPicture from '../../../Assets/AgentReferral/Wide-neighborhood-1.jpg';
import checkBoxes from '../../../Assets/AgentReferral/MicrosoftTeams-image-2.png';
import respaLogo from '../../../Assets/AgentReferral/RESPA-logo.jpg';
import appImage1 from '../../../Assets/AgentReferral/App-image-01.jpg';
import appImage2 from '../../../Assets/AgentReferral/App-image-02.jpg';
import phoneAndIcons from '../../../Assets/AgentReferral/Phone-and-Icons.png';
import { useEffect, useState } from 'react';
import ContactUsForm from '../../Remodel/ContactUs/ContactUsForm';
import AgentReferralForm from '../../../Components/Forms/AgentReferralForm';
import { Helmet } from 'react-helmet';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function AgentReferral() {
  const navigate = useNavigate();
  const handleNavigate = (route: string) => navigate(`${route}`);

  const [formOpen, setFormOpen] = useState(false);
  const handleFormOpen = () => {
    setFormOpen(true);
  };
  const handleFormClose = () => {
    setFormOpen(false);
  };

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  useEffect(() => {
    if (confirmationOpen) {
      handleFormClose();
    }
  }, [confirmationOpen]);
  return (
    <>
      <Helmet>
        <title>ADT's #1 Agent Referral Program | Safe Haven Security</title>
        <meta
          name="description"
          content="Become a referral agent and earn money referring customers to ADT."

        />
        <meta name="keywords" content="ADT referral program, security referral program, agent referral program, home security referral, earn money referring customers, ADT security systems" />
      </Helmet>
      <Box sx={{ backgroundColor: '#ffffff' }}>
        <Container maxWidth='xl'>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={7}>
              <Stack direction={'column'}>
                <Typography
                  sx={{
                    fontSize: { xs: '32px', lg: '48px' },
                    color: '#2783f4',
                    marginBottom: '10px',
                  }}
                >
                  ADT'S #1 AGENT REFERRAL PROGRAM IN THE COUNTRY!
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: '20px' },
                    marginBottom: '35px',
                  }}
                >
                  30,000+ REFFERAL AGENTS WITH 600,000+ SATISFIED CUSTOMERS.
                </Typography>

                <Stack direction={{ xs: 'column', lg: 'row' }}>
                  <Button
                    variant='contained'
                    sx={{
                      backgroundColor: '#2783f4',
                      marginRight: '15px',
                      fontSize: '16px',
                      minHeight: '60px',
                    }}
                    onClick={handleFormOpen}
                  >
                    Become a Referral Agent Today!
                  </Button>

                  <Modal
                    open={formOpen}
                    closeAfterTransition
                    onClose={handleFormClose}
                    className=''
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Slide direction='up' in={formOpen}>
                      <Box
                        sx={{
                          maxWidth: 900,
                          bgcolor: '#fff',
                          border: '2px solid #000',
                          boxShadow: 24,
                          p: 4,
                          overflowX: 'auto',
                          maxHeight: '100vh',
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Typography sx={{ paddingLeft: '8px', fontSize: '24px', fontWeight: 700 }}>
                            Become a Referral Agent Today!
                          </Typography>
                          <Box className="" sx={{ display: 'flex', marginRight: 0, maxWidth: '100%' }}>
                            <IconButton
                              sx={{ margin: 'auto 0 auto auto' }}
                              onClick={() => { handleFormClose() }}
                            >
                              <ClearIcon></ClearIcon>
                            </IconButton>
                          </Box>
                        </Box>

                        <Divider sx={{ margin: '10px' }}></Divider>
                        <AgentReferralForm setConfirmationOpen={setConfirmationOpen}></AgentReferralForm>
                      </Box>
                    </Slide>
                  </Modal>

                  <Modal
                    open={confirmationOpen}
                    closeAfterTransition
                    onClose={() => {
                      handleConfirmationClose();
                    }}
                    className=''
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Slide direction='up' in={confirmationOpen}>
                      <Container
                        maxWidth={false}
                        sx={{
                          maxWidth: '700px',
                          minHeight: '400px',
                          height: 'auto',
                          bgcolor: '#fff',
                          borderRadius: '9px',
                          boxShadow: 24,
                          padding: '0!important'
                        }}
                      >
                        <Box sx={{ background: 'rgb(18, 96, 168)', width: '100%', minHeight: '64px', borderRadius: '9px 9px 0 0px', padding: '10px 0', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                          <Typography sx={{ fontSize: '24px', fontWeight: '700', textAlign: 'center', color: '#fff' }}>
                            Thank you for contacting Safe Haven!
                          </Typography>
                          <Box sx={{
                            position: "absolute",
                            right: "5px",
                          }}>
                            <IconButton
                              sx={{}}
                              onClick={() => {
                                handleConfirmationClose();
                                handleFormClose();
                              }}
                            >
                              <ClearIcon sx={{ color: '#fff' }}></ClearIcon>
                            </IconButton>
                          </Box>
                        </Box>
                        <Stack
                          direction={'row'}
                          sx={{ alignItems: 'center', justifyContent: 'space-evenly' }}
                        >

                          <Box
                            sx={{
                              paddingLeft: '36px',
                              minHeight: '350px',
                              minWidth: '250px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-around',
                            }}
                          >
                            <Box>
                              <Box sx={{ width: '100%', display: 'flex' }}>
                                <CheckCircleOutlineIcon sx={{ color: 'rgb(18, 96, 168)', margin: 'auto', height: '4rem', width: 'auto', marginBottom: '15px' }}></CheckCircleOutlineIcon>
                              </Box>

                              <Typography sx={{ fontSize: '24px', maxWidth: '600px' }}>
                                We received your request for more information and a member of our team will be in touch with you shortly to answer any questions you have.
                              </Typography>
                              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '24px 0' }}>
                                <Button
                                  variant={'contained'}
                                  onClick={() => {
                                    handleNavigate('/');
                                  }}
                                  sx={{
                                    minWidth: '100px',
                                    minHeight: '45px',
                                    fontSize: '16px',
                                    textTransform: 'none',
                                    fontWeight: '700',
                                  }}
                                >
                                  Back To Home
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Stack>
                      </Container>
                    </Slide>
                  </Modal>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Typography sx={{ fontWeight: '700', fontSize: '18px' }}>
                      or call{' '}
                      <Button
                        variant='text'
                        sx={{ padding: '0', fontWeight: '700', fontSize: '18px' }}
                      >
                        877-842-0818
                      </Button>
                    </Typography>
                    <Typography sx={{ fontWeight: '700', fontSize: '18px' }}>
                      for more information
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Box
                component='img'
                sx={{
                  width: '100%',
                }}
                alt='The house from the offer.'
                src={culdesacPicture}
              />
            </Grid>
          </Grid>

          <Box sx={{ padding: '35px 0' }}>
            <Box
              component='img'
              sx={{
                width: '100%',
              }}
              alt='The house from the offer.'
              src={checkBoxes}
            />
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Grid
              container
              maxWidth={'xl'}
              sx={{
                margin: 'auto',
              }}
            >
              <Grid item xs={12} md={9} sx={{ display: 'flex' }}>
                <Typography sx={{ margin: 'auto', marginRight: { lg: '30px' } }}>
                  Started in 1999, Safe Haven’s Agent Referral Program has grown
                  to be the largest, most credible referral program in the
                  security industry today. With more than 30,000 active agents
                  participating in the program across the United States and over
                  600,000 satisfied customers, we guarantee the very best deal and
                  service to every client we have the opportunity of working with.
                  Our sales representatives understand that every home, family and
                  lifestyle is unique and deserves a tailored approach to home
                  monitoring. We personally listen to and work with clients to
                  build a customized solution specific to their needs, while
                  taking the utmost pride in representing you and your business
                  with the utmost professionalism. You’ll never have to worry
                  about your clients being directed to a random 1-800 number or
                  having to talk with someone who hasn’t taken the time to
                  understand their unique situations.
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box
                  component='img'
                  sx={{
                    marginTop: { xs: '35px', lg: '0' },
                    width: '100%',
                  }}
                  alt='The house from the offer.'
                  src={respaLogo}
                />
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ margin: '35px 0 45px 0', borderTopWidth: '2px' }} />

          <Grid container id='grid-container' columns={4} sx={{ justifyContent: 'center', background: 'rgba(0, 0, 0, 0.12)', padding: '48px' }}>
            <Grid
              id='grid-1'
              item
              xs={4}
              lg={1.5}
              sx={{ display: 'flex', marginBottom: { xs: '60px', lg: '0' }, alignContent: 'center', justifyContent: "center", alignItems: "center" }}
            >
              <Stack direction={'column'} sx={{ marginBottom: '32px', alignItems: 'center' }}>
                <Typography
                  sx={{
                    maxWidth: '600px',
                    fontSize: '32px',
                    textAlign: 'center',
                    marginBottom: '35px',
                    fontWeight: 700,
                  }}
                >
                  We pay our referral agents faster and more conveniently than any
                  competitor!!
                </Typography>
                <Box sx={{ margin: 'auto' }}>
                  <Button
                    variant='contained'
                    sx={{ padding: { xs: '10px 20px', lg: '10px' }, fontWeight: 700, fontSize: '24px', maxWidth: '350px' }}
                    onClick={handleFormOpen}
                  >
                    Become a Referral Agent Today!
                  </Button>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={4} sm={2} lg={1} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                component='img'
                sx={{
                  maxWidth: '100%',
                  marginBottom: { xs: '60px', lg: '0' },
                }}
                alt='The house from the offer.'
                src={appImage1}
              />
            </Grid>
            <Grid item xs={4} sm={2} lg={1} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                component='img'
                sx={{
                  maxWidth: '100%',
                }}
                alt='The house from the offer.'
                src={appImage2}
              />
            </Grid>
          </Grid>

          <Divider sx={{ margin: '35px 0 45px 0', borderTopWidth: '2px' }} />

          <Typography sx={{ fontSize: '32px' }}>
            Home owners love the security and convenience of smart home
            technology, all controlled through one simple app.
          </Typography>

          <Box sx={{ padding: '70px 0 140px 0' }}>
            <Box
              component='img'
              sx={{
                width: '100%',
              }}
              alt='The house from the offer.'
              src={phoneAndIcons}
            />
          </Box>
        </Container>
      </Box>
    </>

  );
}
