import Box from '@mui/material/Box';
import { Button, Container, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import BirdeyeWidget from '../Birdeye';
import { useNavigate } from 'react-router-dom';

export default function Footer() {
    const navigate = useNavigate();
    const handleNavigate = (route: string) => navigate(`${route}`);

    useEffect(() => {
        const scriptElement = document.createElement('script');
        scriptElement.src =
            'https://birdeye.com/embed/v4/153357839059541/7/2602589841';
        scriptElement.async = true;
        document.body.appendChild(scriptElement);
    }, []);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: '#1260a8',
                    borderTop: '1px solid black',
                    color: '#ffffff',
                }}
            >
                <Container>
                    <Grid
                        container
                        spacing={{ xs: 5, md: 0 }}
                        maxWidth={'xl'}
                        flexDirection={{ xs: 'column-reverse', md: 'row' }}
                        sx={{
                            padding: '2rem 0',
                            margin: 'auto',
                        }}
                    >
                        <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'center', md: 'initial' } }}>
                            <Typography sx={{ fontSize: '24px', marginBottom: '0px' }}>
                                Contact Info
                            </Typography>
                            <Typography sx={{ fontSize: '18px' }}>
                                Info@MySafeHaven.com
                            </Typography>
                            <Typography sx={{ fontSize: '18px' }}>
                                Phone: 877.842.0818
                            </Typography>
                            <Typography sx={{ fontSize: '18px' }}>
                                520 E 19TH, NORTH KANSAS CITY, MO
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <BirdeyeWidget></BirdeyeWidget>
                        </Grid>
                    </Grid>
                </Container>
                <Box
                    sx={{
                        backgroundColor: 'rgba(255,255,255,.15)',
                        padding: '2rem',
                    }}
                >
                    <Container>
                        <Typography sx={{ fontSize: '14px', marginBottom: '35px' }}>
                            License numbers by State: AL-1493, AR- CMPY.0002629, AZ-
                            ROC-332758,18404-0, CA-ACO8016, CT-HIC.0661825, DC-602521000002,
                            DE-17-39, FL-EF20000905, GA-LVU406182, IA-AS-0158, C115438,
                            IL-127.001386, LA-F1672, MA-8150 A1, SS-002127, MD-107-1696,
                            MI-3601208054, MN-TS807121, MS-15019531, NC-1150-CSA,
                            SP.FA/LV.28805, NH-064, NJ- 34BX00006600, NM-403946, NV-0079533,
                            NY-12000058790, OH-1804841, OK-AC1802, OR-234026, PA-PA040539,
                            RI-4604, TSC8204, SC-BAC.13574, TN-2280, TX-B14839,ACR-2625584,
                            UT-10999117-6501, VA-11-6402, WA-SAFEHHS791CH, WV-WV060562
                        </Typography>
                        <Typography sx={{ fontSize: '14px' }}>
                            Copyright: Safe Haven Security 2022 |{' '}
                            <Button
                                variant='text'
                                sx={{
                                    color: '#ffffff',
                                    textTransform: 'inherit',
                                    textDecoration: 'underline',
                                    padding: '0',
                                    minWidth: 'initial',
                                }}
                                onClick={() => {
                                    {
                                        handleNavigate('/legal');
                                    }
                                }}
                            >
                                Legal
                            </Button>{' '}
                            |{' '}
                            <Button
                                variant='text'
                                sx={{
                                    color: '#ffffff',
                                    textTransform: 'inherit',
                                    textDecoration: 'underline',
                                    padding: '0',
                                    minWidth: 'initial',
                                }}
                                onClick={() => {
                                    {
                                        handleNavigate('/legal/privacy/');
                                    }
                                }}
                            >
                                Privacy Policy
                            </Button>
                            |{' '}
                            <Button
                                variant='text'
                                sx={{
                                    color: '#ffffff',
                                    textTransform: 'inherit',
                                    textDecoration: 'underline',
                                    padding: '0',
                                    minWidth: 'initial',
                                }}
                                onClick={() => {
                                    {
                                        handleNavigate('/terms-and-conditions/');
                                    }
                                }}
                            >
                                Terms & Conditions for ADT Security Services
                            </Button>
                        </Typography>
                    </Container>
                </Box>
            </Box>
        </>
    );
}
