import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import image1 from '../../Assets/CustomerSolutions/inc-auth-bbb-horizontal2.png';
import concierge from '../../Assets/CustomerSupport/Concierge-phones.jpg';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useState } from 'react';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ContactUsForm from '../../Components/Forms/ContactUsForm';
import EmployeeVerification from '../../Components/EmployeeVerification';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import {
  LIST_SECTION,
  PANEL_ONE,
  PANEL_TWO,
  PANEL_THREE,
  SECTION_ONE,
  CUSTOMER_REVIEWS,
} from '../../Constants/CustomerSupport';
import './index.scss';
import Reviews from './Reviews';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

export default function CustomerSupport() {
  const navigate = useNavigate();
  const handleNavigate = (route: string) => navigate(`${route}`);

  const [activePanel, setActivePanel] = useState(0);
  const togglePanelVisibility = (panelIndex: number) => {
    if (panelIndex === activePanel) {
      setActivePanel(0);
    } else if (panelIndex !== activePanel) {
      setActivePanel(panelIndex);
    }
  };

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Customer Support | Safe Haven Security</title>
        <meta
          name="description"
          content="Get help and support from Safe Haven Security. Find answers to your questions, troubleshoot issues, and learn more about using your security system."
        />
        <meta
          name="keywords"
          content="Safe Haven Security, customer support, help, troubleshooting, security system, questions, contact us, phone number, email, hours of operation"
        />
      </Helmet>
      <Box sx={{ background: '#ffffff' }}>
        <Container
          maxWidth='xl'
          sx={{ marginTop: { xs: '0', lg: '35px' }, marginBottom: '70px' }}
        >
          <Grid container spacing={{ xs: 0, md: 5 }}>
            <Grid item xs={12} lg={6}>
              <Stack direction={'column'}>{SECTION_ONE}</Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box
                component='img'
                sx={{
                  width: '100%',
                }}
                alt='The house from the offer.'
                src={concierge}
              />
            </Grid>
          </Grid>

          <List sx={{ marginTop: '35px' }}>
            <ListItem alignItems='flex-start' sx={{ paddingLeft: '0' }}>
              <ListItemAvatar sx={{ marginTop: '0' }}>
                <LocalPhoneIcon sx={{ color: '#075faa' }}></LocalPhoneIcon>
              </ListItemAvatar>
              <ListItemText sx={{ margin: '0 auto' }}>
                <Typography
                  sx={{
                    fontSize: '18px',
                    color: '#4d4f51',
                  }}
                >
                  844.413.1920
                </Typography>
              </ListItemText>
            </ListItem>

            <ListItem alignItems='flex-start' sx={{ paddingLeft: '0' }}>
              <ListItemAvatar sx={{ marginTop: '0' }}>
                <MailOutlineIcon sx={{ color: '#075faa' }}></MailOutlineIcon>
              </ListItemAvatar>
              <ListItemText sx={{ margin: '0 auto' }}>
                <Typography
                  sx={{
                    fontSize: '18px',
                    color: '#4d4f51',
                  }}
                >
                  customerservice@mysafehaven.com
                </Typography>
              </ListItemText>
            </ListItem>

            <ListItem alignItems='flex-start' sx={{ paddingLeft: '0' }}>
              <ListItemAvatar sx={{ marginTop: '0' }}>
                <AccessTimeIcon sx={{ color: '#075faa' }}></AccessTimeIcon>
              </ListItemAvatar>
              <ListItemText sx={{ margin: '0 auto' }}>
                <Typography
                  sx={{
                    fontSize: '18px',
                    color: '#4d4f51',
                  }}
                >
                  Monday - Friday: 8:00am – 6:00pm CST
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Container>

        <Box
          sx={{
            backgroundColor: 'rgb(241, 241, 241)',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <EmployeeVerification></EmployeeVerification>
        </Box>

        <Box sx={{ backgroundColor: '', padding: '70px 0' }}>
          <Container maxWidth='xl'>
            <Grid container spacing={{ xs: 0, lg: 5 }} sx={{}}>
              <Grid item xs={12} lg={6}>
                <Stack direction={'column'}>{LIST_SECTION}</Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack sx={{ alignItems: 'center' }}>
                  <Button
                    id='button1'
                    variant={'contained'}
                    className='support-demo-buttons'
                    endIcon={
                      <AddCircleIcon
                        sx={{
                          height: '40px',
                          width: '40px',
                          paddingBottom: '3px',
                        }}
                      />
                    }
                    onClick={() => {
                      togglePanelVisibility(1);
                    }}
                  >
                    Getting Started
                  </Button>

                  {activePanel === 1 && PANEL_ONE}

                  <Button
                    id='button2'
                    variant={'contained'}
                    className='support-demo-buttons'
                    endIcon={
                      <AddCircleIcon
                        sx={{
                          height: '40px',
                          width: '40px',
                          paddingBottom: '3px',
                        }}
                      />
                    }
                    onClick={() => {
                      togglePanelVisibility(2);
                    }}
                  >
                    Once You Log In
                  </Button>

                  {activePanel === 2 && PANEL_TWO}

                  <Button
                    id='button3'
                    variant={'contained'}
                    className='support-demo-buttons'
                    endIcon={
                      <AddCircleIcon
                        sx={{
                          height: '40px',
                          width: '40px',
                          paddingBottom: '3px',
                        }}
                      />
                    }
                    onClick={() => {
                      togglePanelVisibility(3);
                    }}
                  >
                    My ADT
                  </Button>

                  {activePanel === 3 && PANEL_THREE}
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box sx={{ backgroundColor: 'white' }}>
          <Box sx={{ backgroundColor: 'rgb(7, 95, 170)' }}>
            <Stack sx={{ padding: '95px 0 75px 0 ', textAlign: 'center' }}>
              <Typography
                sx={{
                  color: '#98b0d2',
                  fontWeight: '700',
                  fontSize: { xs: '45px', lg: '65px' },
                }}
              >
                QUESTIONS?
              </Typography>
              <Typography
                sx={{
                  fontWeight: '700',
                  fontSize: { xs: '32px', lg: '40px' },
                  color: '#ffffff',
                }}
              >
                CALL US AT 877.842.0818 OR
              </Typography>
              <Box>
                <Button
                  sx={{
                    background: '#ffffff',
                    marginTop: '14px',
                    color: '#1260a8',
                    fontWeight: '700',
                    padding: '12px 24px',
                    fontSize: '20px',
                    lineHeight: ' 20px;',
                    boxShadow: '2px 2px gray;',
                  }}
                  onClick={handleModalOpen}
                >
                  Click Here
                </Button>
                <Modal
                  open={modalOpen}
                  closeAfterTransition
                  onClose={handleModalClose}
                  className=''
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Slide direction='up' in={modalOpen}>
                    <Box
                      sx={{
                        maxWidth: 900,
                        bgcolor: '#fff',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        overflowX: 'auto',
                        maxHeight: '100vh',
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ paddingLeft: '8px' }}>
                          Contact Us
                        </Typography>
                        <Box className="full-width" sx={{ display: 'flex', marginRight: 0 }}>
                          <IconButton
                            sx={{ margin: 'auto 0 auto auto' }}
                            onClick={() => { handleModalClose() }}
                          >
                            <ClearIcon></ClearIcon>
                          </IconButton>
                        </Box>
                      </Box>
                      <Divider sx={{ margin: '10px' }}></Divider>

                      {!confirmationOpen && <ContactUsForm setConfirmationOpen={setConfirmationOpen}></ContactUsForm>}

                      <Modal
                        open={confirmationOpen}
                        closeAfterTransition
                        onClose={() => {
                          handleConfirmationClose();
                        }}
                        className=''
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Slide direction='up' in={confirmationOpen}>
                          <Container
                            maxWidth={false}
                            sx={{
                              maxWidth: '700px',
                              minHeight: '400px',
                              height: 'auto',
                              bgcolor: '#fff',
                              borderRadius: '9px',
                              boxShadow: 24,
                              padding: '0!important'
                            }}
                          >
                            <Box sx={{ background: 'rgb(18, 96, 168)', width: '100%', minHeight: '64px', borderRadius: '9px 9px 0 0px', padding: '10px 0', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                              <Typography sx={{ fontSize: '24px', fontWeight: '700', textAlign: 'center', color: '#fff' }}>
                                Thank you for contacting Safe Haven!
                              </Typography>
                              <Box sx={{
                                position: "absolute",
                                right: "5px",
                              }}>
                                <IconButton
                                  sx={{}}
                                  onClick={() => {
                                    handleConfirmationClose();
                                    handleModalClose();
                                  }}
                                >
                                  <ClearIcon sx={{ color: '#fff' }}></ClearIcon>
                                </IconButton>
                              </Box>
                            </Box>
                            <Stack
                              direction={'row'}
                              sx={{ alignItems: 'center', justifyContent: 'space-evenly' }}
                            >

                              <Box
                                sx={{
                                  paddingLeft: '36px',
                                  minHeight: '350px',
                                  minWidth: '250px',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-around',
                                }}
                              >
                                <Box>
                                  <Box sx={{ width: '100%', display: 'flex' }}>
                                    <CheckCircleOutlineIcon sx={{ color: 'rgb(18, 96, 168)', margin: 'auto', height: '4rem', width: 'auto', marginBottom: '15px' }}></CheckCircleOutlineIcon>
                                  </Box>

                                  <Typography sx={{ fontSize: '24px', maxWidth: '600px' }}>
                                    We received your request for more information and a member of our team will be in touch with you shortly to answer any questions you have.
                                  </Typography>
                                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '24px 0' }}>
                                    <Button
                                      variant={'contained'}
                                      onClick={() => {
                                        handleNavigate('/');
                                      }}
                                      sx={{
                                        minWidth: '100px',
                                        minHeight: '45px',
                                        fontSize: '16px',
                                        textTransform: 'none',
                                        fontWeight: '700',
                                      }}
                                    >
                                      Back To Home
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </Stack>
                          </Container>
                        </Slide>
                      </Modal>

                    </Box>
                  </Slide>
                </Modal>
              </Box>
            </Stack>
          </Box>

          <Box
            sx={{
              padding: '60px 0',
              display: 'flex',
              backgroundColor: '#ffffff',
            }}
          >
            <Box
              component='img'
              sx={{
                width: 'auto',
                maxWidth: '100%',
                maxHeight: '75vh',
                margin: 'auto',
              }}
              alt='The house from the offer.'
              src={image1}
            />
          </Box>

          <Reviews></Reviews>
        </Box>
      </Box>
    </>
  );
}
