import { createSlice } from '@reduxjs/toolkit';

export const navigationSlice = createSlice({
  name: 'pageNavigation',
  initialState: {
    selectedPage: { page: 'home', date: new Date().getTime() },
    history: [{ page: '', date: new Date().getTime() }],
  },
  reducers: {
    navigateChange: (state, action) => {
      state.selectedPage = action.payload;
      state.history.push(action.payload);
    },

    clearHistory: (state) => {
      state.history = [];
    },
  },
});

export const { navigateChange, clearHistory } = navigationSlice.actions;

export default navigationSlice.reducer;
