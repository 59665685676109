import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Modal,
  Slide,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import subdivision from '../../../Assets/Homebuilder/subdivison.png';
import testimonialBg from '../../../Assets/Partner/mark-team-002.jpg';
import './index.scss';
import AccoladesRibbon from '../../../Components/NumbersBanner';
import { useEffect, useState } from 'react';
import PartnerRequestForm from '../../../Components/Forms/PartnerRequestForm';
import { Helmet } from 'react-helmet';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function HomebuilderPartners() {
  const navigate = useNavigate();
  const handleNavigate = (route: string) => navigate(`${route}`);

  const [partnerRequestOpen, setPartnerRequestOpen] = useState(false);
  const handlePartnerRequestOpen = () => {
    setPartnerRequestOpen(true);
  };
  const handlePartnerRequestClose = () => {
    setPartnerRequestOpen(false);
  };

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const handleConfirmationOpen = () => {
    setConfirmationOpen(true);
  };
  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Safe Haven Homebuilder Program | Safe Haven Security</title>
        <meta name="description" content="Become a Safe Haven Homebuilder Partner and offer affordable home automation systems to your clients through ADT Pulse. Get connected and request information today!" />
        <meta name="keywords" content="homebuilder program, home automation, ADT Pulse, Safe Haven Security, partner, builder, affordable systems, client protection" />
      </Helmet>

      <Box sx={{ backgroundColor: '#ffffff' }}>
        <Container maxWidth='xl' sx={{ marginTop: '35px', marginBottom: '70px' }}>
          <Grid container spacing={5}>
            <Grid item xs={12} lg={6}>
              <Stack direction={'column'}>
                <Typography
                  sx={{
                    fontSize: '42px',
                    color: '#1260a8',
                    fontWeight: '700',
                    marginBottom: '16px',
                    lineHeight: 1.2,
                  }}
                >
                  SAFE HAVEN HOMEBUILDER PROGRAM
                </Typography>
                <Typography sx={{ marginBottom: '10px', fontSize: '18px' }}>
                  Our Homebuilder Program was designed specifically for
                  homebuilders by a builder and developer, so we understand how
                  important builders’ relationships with clients are. That’s why
                  we designed our program to take care of the client first.
                </Typography>
                <Typography sx={{ marginBottom: '10px' }}>
                  We also know how to take care of our partners. Instead of going
                  through a third party, by signing up for our program, builders
                  and representatives can participate directly in the revenue
                  stream while bringing affordable home automation systems to
                  clients through ADT Pulse.
                </Typography>
                <Typography sx={{ marginBottom: '35px' }}>
                  Request information to become a partner today
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Button
                    variant={'contained'}
                    className='homebuilder-learn-more-btn'
                    sx={{
                      margin: { xs: 'auto', lg: 'initial' },
                      fontWeight: '700',
                      font: "normal normal 700 14px 'PT Sans', Helvetica, Arial, Verdana, sans-serif",
                    }}
                    onClick={handlePartnerRequestOpen}
                  >
                    Learn More
                  </Button>
                  <Modal
                    open={partnerRequestOpen}
                    closeAfterTransition
                    onClose={handlePartnerRequestClose}
                    className=''
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Slide direction='up' in={partnerRequestOpen}>
                      <Box
                        sx={{
                          maxWidth: 900,
                          bgcolor: '#fff',
                          border: '2px solid #000',
                          boxShadow: 24,
                          p: 4,
                          overflowX: 'auto',
                          maxHeight: '100vh',
                        }}
                      >


                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Typography sx={{ paddingLeft: '8px', fontSize: '24px', fontWeight: 700, }}>
                            Partner Information Request Form
                          </Typography>
                          <Box className="" sx={{ display: 'flex', marginRight: 0, maxWidth: '100%' }}>
                            <IconButton
                              sx={{ margin: 'auto 0 auto auto' }}
                              onClick={() => { handlePartnerRequestClose() }}
                            >
                              <ClearIcon></ClearIcon>
                            </IconButton>
                          </Box>
                        </Box>

                        <Divider sx={{ margin: '10px 10px 18px 10px' }}></Divider>

                        {!confirmationOpen && <PartnerRequestForm setConfirmationOpen={setConfirmationOpen}></PartnerRequestForm>}

                        <Modal
                          open={confirmationOpen}
                          closeAfterTransition
                          onClose={() => {
                            handleConfirmationClose();
                            handlePartnerRequestClose();
                          }}
                          className=''
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Slide direction='up' in={confirmationOpen}>
                            <Container
                              maxWidth={false}
                              sx={{
                                maxWidth: '700px',
                                minHeight: '400px',
                                height: 'auto',
                                bgcolor: '#fff',
                                borderRadius: '9px',
                                boxShadow: 24,
                                padding: '0!important'
                              }}
                            >
                              <Box sx={{ background: 'rgb(18, 96, 168)', width: '100%', minHeight: '64px', borderRadius: '9px 9px 0 0px', padding: '10px 0', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                                <Typography sx={{ fontSize: '24px', fontWeight: '700', textAlign: 'center', color: '#fff' }}>
                                  Thank you for your interest in becoming an ADT Homebuilder Program Partner!
                                </Typography>
                                <Box sx={{
                                  position: "absolute",
                                  right: "5px",
                                }}>
                                  <IconButton
                                    sx={{}}
                                    onClick={() => {
                                      handlePartnerRequestClose();
                                      handleConfirmationClose();
                                    }}
                                  >
                                    <ClearIcon sx={{ color: '#fff' }}></ClearIcon>
                                  </IconButton>
                                </Box>
                              </Box>
                              <Stack
                                direction={'row'}
                                sx={{ alignItems: 'center', justifyContent: 'space-evenly' }}
                              >

                                <Box
                                  sx={{
                                    paddingLeft: '36px',
                                    minHeight: '350px',
                                    minWidth: '250px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-around',
                                  }}
                                >
                                  <Box>
                                    <Box sx={{ width: '100%', display: 'flex' }}>
                                      <CheckCircleOutlineIcon sx={{ color: 'rgb(18, 96, 168)', margin: 'auto', height: '4rem', width: 'auto', marginBottom: '15px' }}></CheckCircleOutlineIcon>
                                    </Box>

                                    <Typography sx={{ fontSize: '24px', maxWidth: '600px' }}>
                                      We received your request for more information and a member of our team will be in touch with you shortly to discuss the program's benefits and answer any questions you may have.
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '24px 0' }}>
                                      <Button
                                        variant={'contained'}
                                        onClick={() => {
                                          handleNavigate('/');
                                        }}
                                        sx={{
                                          minWidth: '100px',
                                          minHeight: '45px',
                                          fontSize: '16px',
                                          textTransform: 'none',
                                          fontWeight: '700',
                                        }}
                                      >
                                        Back To Home
                                      </Button>
                                    </Box>
                                  </Box>
                                </Box>
                              </Stack>
                            </Container>
                          </Slide>
                        </Modal>

                      </Box>
                    </Slide>
                  </Modal>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box
                component='img'
                sx={{
                  width: '100%',
                }}
                alt='The house from the offer.'
                src={subdivision}
              />
            </Grid>
          </Grid>
        </Container>

        <AccoladesRibbon></AccoladesRibbon>

        <Container maxWidth={'xl'}>
          <Box
            sx={{
              padding: { xs: '70px 0', lg: '70px 45px' },
              background: '#ffffff',
            }}
          >
            <Typography
              variant='h1'
              sx={{
                margin: 'auto',
                color: '#1260a8',
                fontSize: { xs: '42px', lg: '48px' },
                paddingBottom: '70px',
                fontWeight: '700',
              }}
            >
              BUILDER TESTIMONIALS
            </Typography>
            <Stack
              spacing={3}
              direction={{ xs: 'column', lg: 'row' }}
              sx={{
                width: '100%',
                color: '#ffffff',
              }}
            >
              <Box
                sx={{
                  height: '500px',
                  width: { lg: '33%' },
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  backgroundImage: `url(${testimonialBg})`,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(18,96,168,0.93)',
                    padding: '0 20px',
                    height: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '22px',
                      marginBottom: '20px',
                      height: '175px',
                      maxHeight: '330px',
                      overflowY: 'auto',
                    }}
                  >
                    Great company to work with! Safe Haven worked with us to
                    design a package that fits the lifestyle of today’s home
                    buyer.
                  </Typography>
                  <Typography sx={{ fontSize: '22px', textAlign: 'right' }}>
                    Craig Archer
                  </Typography>
                  <Typography sx={{ fontSize: '22px', textAlign: 'right' }}>
                    CEO, New Mark Homes
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  height: '500px',
                  width: { lg: '33%' },
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  backgroundImage: `url(${testimonialBg})`,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(18,96,168,0.93)',
                    padding: '0 20px',
                    height: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '22px',
                      marginBottom: '20px',
                      height: '175px',
                      maxHeight: '330px',
                      overflowY: 'auto',
                    }}
                  >
                    Safe Haven’s “Smart Home” packages are awesome and super
                    affordable for our clients. It helped us stand out in Denver’s
                    very competitive market.
                  </Typography>
                  <Typography sx={{ fontSize: '22px', textAlign: 'right' }}>
                    Jeff Gates
                  </Typography>
                  <Typography sx={{ fontSize: '22px', textAlign: 'right' }}>
                    Owner, Denver Design Build
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  height: '500px',
                  width: { lg: '33%' },
                  backgroundImage: `url(${testimonialBg})`,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(18,96,168,0.93)',
                    padding: '0 20px',
                    height: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '22px',
                      marginBottom: '20px',
                      height: '175px',
                      maxHeight: '330px',
                      overflowY: 'auto',
                    }}
                  >
                    Partnering with Safe Haven has helped us to protect our homes
                    under construction as well as allow our customers to protect
                    their home on the very first day. Jon is great to work with
                    and extremely responsive whenever we call.
                  </Typography>
                  <Typography sx={{ fontSize: '22px', textAlign: 'right' }}>
                    Joan Johnson
                  </Typography>
                  <Typography sx={{ fontSize: '22px', textAlign: 'right' }}>
                    Habitat for Humanity, Sacramento, CA
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>
    </>
  );
}
