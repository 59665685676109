import {
	Box,
	TextField,
	Checkbox,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Autocomplete,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import useFetch from "use-http";
import "./index.scss";
import { STATE_LIST } from "../../../Constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup'; // For form validation

interface ContactRequestFormProps {
	setConfirmationOpen: (arg0: boolean) => void;
}

export default function ContactUsForm({ setConfirmationOpen }: ContactRequestFormProps) {
	const [queryParameters] = useSearchParams();
	const navigate = useNavigate();
	const handleNavigate = (route: string) => navigate(`${route}`);

	let apiKey = process.env.REACT_APP_API__ACCESS_KEY
		? process.env.REACT_APP_API__ACCESS_KEY
		: "";
	const options = {
		headers: {
			"Content-Type": "application/json",
			Connection: "keep-alive",
			"Accept-Encoding": "gzip, deflate, br",
			"X-API-Key": apiKey,
		},
	};
	const { post, response, loading, error } = useFetch(
		process.env.REACT_APP_API,
		options,
	);

	// Formik Validation Schema
	const initialValues = {
		firstName: '',
		lastName: '',
		address: '',
		city: '',
		state: '',
		clientType: '',
		isBuilderPartner: false,
		builderPartner: '',
		email: '',
		phone: '',
		contactType: '',
		contactTime: '',
		comments: '',
		acknowledge: false,
	};
	// Formik Validation Schema
	const validationSchema = Yup.object({
		firstName: Yup.string().required('Required')
			.min(2, 'First name must be at least 2 characters')
			.max(50, 'First name must be at most 50 characters')
			.matches(/^[a-zA-Z '-]+$/, 'First name can only contain letters, hyphens, apostrophes, and spaces'),
		lastName: Yup.string().required('Required'),
		address: Yup.string().required('Required'),
		city: Yup.string().required('Required'),
		state: Yup.string().required('Required'),
		phone: Yup.string().required('Required')
			.matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format'),
		email: Yup.string()
			.email('Invalid email address')
			.required('Required'),
		acknowledge: Yup.boolean().required('Required'),
		comments: Yup.string().required('Required'),

		clientType: Yup.string().oneOf(['Current Customer', 'Homeowner', 'Home Builder Client', 'Realtor', 'Builder/Developer', 'Landlord or Leasing Agency', 'Renter']).required('Required'),
		isBuilderPartner: Yup.boolean(),
		builderPartner: Yup.string().when('isBuilderPartner', {
			is: true,
			then: () => Yup.string().required('Required'),
			otherwise: () => Yup.string().nullable()
		}),
		contactType: Yup.string().oneOf(['email', 'phone', 'no preference']).required('Required'),
		contactTime: Yup.string().oneOf(['morning', 'noon', 'evening']).required('Required'),
	});

	return (
		<>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={async (values, { setSubmitting, resetForm }) => {
					try {
						let recipients = 'privacy@mysafehaven.com';

						let subject = 'Contact Us Form';

						const clientType = values.clientType;
						const currentUrl = window.location.href;

						if (queryParameters.get("msh-test") || values.firstName === "CodeyTest") {
							subject = `Contact Us Form Test`;
							recipients = "Samual.Tarpenning@mysafehaven.com, codey.bower@mysafehaven.com, wai.cheng@mysafehaven.com";
						} else {
							switch (clientType) {
								case "Realtor":
									subject = `New realtor contact form request`;
									recipients = "ARounds@mysafehaven.com, rloomis@mysafehaven.com";
									break;
								case "Current Customer":
									subject = `Form request: I am a customer in need of customer service`;
									recipients = "customerservice@mysafehaven.com";
									break;
								case "Homeowner":
								case "Builder/Developer":
								case "Landlord or Leasing Agency":
								case "Renter":
								case "Home Builder Client":
									subject = `New ${clientType.toLowerCase()} contact form request`;
									recipients = "lcombs@mysafehaven.com";
									break;
								default:
									subject = `New contact form request with no clientType`;
									recipients = "Samual.Tarpenning@mysafehaven.com";
							}
						}

						const content = `
							<table style="max-width: 1024px; margin: auto;">
								<tbody>
								<th style="padding: 8px; text-align: left; border-bottom: 1px solid #ddd;, width: auto;">URL:</th>
								<td style="padding: 8px; text-align: left; border-bottom: 1px solid #ddd;">${currentUrl}</td>
								${Object.entries(values).map(([key, value], index) => `
									<tr style="background-color: ${index % 2 === 0 ? '#f2f2f2' : 'white'};">
									<th style="padding: 8px; text-align: left; border-bottom: 1px solid #ddd;, width: auto;">${key}:</th>
									<td style="padding: 8px; text-align: left; border-bottom: 1px solid #ddd;">${value}</td>
									</tr>
								`).join('')}
								</tbody>
							</table>
						`;

						const postResponse = await post(`/Email/SendEmail`, {
							to: recipients,
							subject: subject,
							content: content,
						});

						console.log("Request sent successfully:", postResponse);

						setConfirmationOpen(true);

						resetForm();
					} catch (error) {
						console.error("Error sending request:", error);
					} finally {
						setSubmitting(false);
					}
				}}
			>
				{({ errors, touched, values, setFieldValue, isSubmitting }) => (
					<Form>
						<Box className='fields-container'
							sx={{
								'& .quarter-width': { width: { xs: 'calc(50%)', sm: 'calc(25%)' }, minHeight: '90px' },
								'& .half-width': { width: { xs: 'calc(100%)', sm: 'calc(50%)' }, minHeight: '90px' },
								'& .full-width': { width: 'calc(100%)', minHeight: '90px' },
								'& .quarter-width > div': { margin: '0 5px 24px 5px' },
								'& .half-width > div': { margin: '0 5px 24px 5px' },
								'& .full-width > div': { margin: '0 5px 24px 5px' },
							}}
						>
							<Field
								as={TextField}
								className='half-width '
								name="firstName"
								label="First Name *"
								value={values.firstName}
								sx={{ margin: '0!important' }}
								onChange={(event: any) => { setFieldValue("firstName", event.target.value) }}
								error={touched.firstName && Boolean(errors.firstName)}
								helperText={touched.lastName && errors.lastName} />

							<Field
								as={TextField}
								className='half-width '
								name="lastName"
								label="Last Name *"
								value={values.lastName}
								sx={{ margin: '0!important' }}
								onChange={(event: { target: { value: any; }; }) => setFieldValue("lastName", event.target.value)}
								error={touched.lastName && Boolean(errors.lastName)}
								helperText={touched.lastName && errors.lastName}
							/>

							<Field
								as={TextField}
								className='half-width '
								name="address"
								label="Address *"
								value={values.address}
								sx={{ margin: '0!important' }}
								onChange={(event: { target: { value: any; }; }) => setFieldValue("address", event.target.value)}
								error={touched.address && Boolean(errors.address)}
								helperText={touched.address && errors.address}
							/>

							<Field
								as={TextField}
								className='quarter-width '
								name="city"
								label="City *"
								value={values.city}
								sx={{ margin: '0!important' }}
								onChange={(event: { target: { value: any; }; }) => setFieldValue("city", event.target.value)}
								error={touched.city && Boolean(errors.city)}
								helperText={touched.city && errors.city}
							/>

							<Autocomplete
								disablePortal
								className={`quarter-width `}
								sx={{ display: 'inline-flex', margin: "0!important" }}
								options={STATE_LIST}
								inputValue={values.state}
								onInputChange={(event, newInputValue) => {
									setFieldValue("state", newInputValue);
									console.log(errors)
								}}

								renderInput={params => (
									<TextField
										sx={{ marginLeft: '4px' }}
										{...params}
										name="state"
										label="State *"
										error={touched.state && Boolean(errors.state)}
										helperText={touched.state && errors.state}
									/>
								)}
							/>

							<FormControl className="full-width" >
								<InputLabel>I Am A: </InputLabel>
								<Field
									as={Select}
									name="clientType"
									label="I Am A: "
									value={values.clientType}
									onChange={(event: { target: { value: any; }; }) => setFieldValue("clientType", event.target.value)}
									error={touched.clientType && Boolean(errors.clientType)}
									helpertext={touched.clientType && errors.clientType}
								>
									<MenuItem value="Current Customer">Current Customer</MenuItem>
									<MenuItem value="Homeowner">Homeowner</MenuItem>
									<MenuItem value="Home Builder Client">Home Builder Client</MenuItem>
									<MenuItem value="Realtor">Realtor</MenuItem>
									<MenuItem value="Builder/Developer">Builder/Developer</MenuItem>{" "}
									<MenuItem value="Landlord or Leasing Agency">
										Landlord or Leasing Agency
									</MenuItem>{" "}
									<MenuItem value="Renter">Renter</MenuItem>
								</Field>
							</FormControl>

							<FormControlLabel
								className={`${values.isBuilderPartner ? "half-width" : "full-width"
									}`}
								name="isBuilderPartner"
								control={
									<Checkbox
										checked={values.isBuilderPartner}
										onChange={(event: { target: { checked: boolean; }; }) => { setFieldValue("isBuilderPartner", event.target.checked) }}
									/>
								}
								label="Are you with one of our Builder Partners?"
								sx={{ margin: "0 0 36px 0!important" }}
							/>

							{values.isBuilderPartner === true && (
								<Field
									as={TextField}
									className='half-width'
									name="builderPartner"
									label="Please Tell Us Who"
									sx={{ margin: '0!important' }}
									value={values.builderPartner}
									onChange={(event: { target: { value: any; }; }) => setFieldValue("builderPartner", event.target.value)}
									error={touched.builderPartner && Boolean(errors.builderPartner)}
									helperText={touched.builderPartner && errors.builderPartner}
								/>
							)}

							<Field
								as={TextField}
								className='half-width '
								name="email"
								label="Email *"
								value={values.email}
								sx={{ margin: '0!important' }}
								onChange={(event: { target: { value: any; }; }) => setFieldValue("email", event.target.value)}
								error={touched.email && Boolean(errors.email)}
								helperText={touched.email && errors.email}
							/>

							<Field
								as={TextField}
								className='half-width '
								name="phone"
								label="Phone Number *"
								value={values.phone}
								sx={{ margin: '0!important' }}
								onChange={(event: { target: { value: any; }; }) => setFieldValue("phone", event.target.value)}
								error={touched.phone && Boolean(errors.phone)}
								helperText={touched.phone && errors.phone}
							/>

							<FormControl className="half-width " >
								<InputLabel>Best way to reach you: </InputLabel>
								<Field
									as={Select}
									name="contactType"
									label="Best way to reach you: "
									value={values.contactType}
									onChange={(event: { target: { value: any; }; }) => setFieldValue("contactType", event.target.value)}
									error={touched.contactType && Boolean(errors.contactType)}
									helpertext={touched.contactType && errors.contactType}
								>
									<MenuItem value={'email'}>Email</MenuItem>
									<MenuItem value={'phone'}>Phone</MenuItem>
									<MenuItem value={'no preference'}>No Preference</MenuItem>
								</Field>
							</FormControl>

							<FormControl className="half-width " >
								<InputLabel>Best time to reach you: </InputLabel>
								<Field
									as={Select}
									name="contactTime"
									label="Best time to reach you: "
									value={values.contactTime}
									onChange={(event: { target: { value: any; }; }) => setFieldValue("contactTime", event.target.value)}
									error={touched.contactTime && Boolean(errors.contactTime)}
									helpertext={touched.contactTime && errors.contactTime}
								>
									<MenuItem value={'morning'}>Morning</MenuItem>
									<MenuItem value={'noon'}>Noon</MenuItem>
									<MenuItem value={'evening'}>Evening</MenuItem>
								</Field>
							</FormControl>

							<Field
								as={TextField}
								name="comments"
								label="Comments"
								className="full-width"
								multiline
								rows={4}
								value={values.comments}
								onChange={(event: { target: { value: any; }; }) => setFieldValue("comments", event.target.value)}
								error={touched.comments && Boolean(errors.comments)}
								helperText={touched.comments && errors.comments}
							/>

							<Box sx={{ paddingTop: '10px' }}>
								<FormControlLabel
									name='acknowledge'
									className={touched.acknowledge && !values.acknowledge ? 'error-checkbox' : ''}
									control={
										<Checkbox
											checked={values.acknowledge}
											onChange={(event: { target: { checked: boolean; }; }) => { setFieldValue("acknowledge", event.target.checked) }
											}
										/>
									}
									label=" By clicking 'Submit,' I consent to being contacted by Safe Haven Security Services, LLC, Safe Haven Solar, LLC, or All Access Service, LLC regarding products and services via live, automated, or prerecorded telephone calls, text messages*, or emails, even if I'm on a Do Not Call list. I can revoke this consent by emailing dnc@mysafehaven.com."
									sx={{
										margin: '0 8px 8px 8px',
										alignItems: 'flex-start',
										'& .MuiFormControlLabel-asterisk': {
											paddingTop: '9px',
											height: '5px',
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'flex-start',
											justifyContent: 'space-around',
										},
										'& .MuiTypography-root': {
											paddingTop: '8px',
										},
									}}
								/>
							</Box>
							<Box sx={{ display: 'flex', justifyContent: 'center', margin: '24px 0' }}>
								<LoadingButton
									variant='contained'
									className='contact-form-submit'
									type="submit"
									loading={isSubmitting}
									disabled={isSubmitting}
									sx={{
										minWidth: '100px',
										fontSize: '16px',
										textTransform: 'none',
										fontWeight: '700',
									}}
								>
									{isSubmitting ? 'Submitting...' : 'Submit'}
								</LoadingButton>
							</Box>
						</Box>
					</Form>
				)}
			</Formik>
			<Typography variant="body2">
				* Standard message and data rates may apply to text messages. You may
				opt out of receiving further text messages at any time by replying STOP.
			</Typography>
			<br />
			<Typography variant="body2">
				† Your information is collected and used in accordance with Safe Haven’s{" "}
				<a
					style={{
						color: "#007bff",
						textDecoration: "underline",
						cursor: "pointer",
					}}
					onClick={() => handleNavigate("/legal/privacy/")}
				>
					Privacy Policy.
				</a>
			</Typography>
		</>
	);
}
