import { Box, Link, Typography } from "@mui/material";

export const AGREEMENT_ONE = (
    <>

        <Typography variant='body2'>
            Safe Haven collects and stores certain information from or about you
            that can be used to uniquely contact, identify, or locate you
            (“Personal Information”). When using any User Interface, you may
            provide us with Personal Information including, but not limited to,
            your name, home or business address, phone number, email address,
            location, or zip code. If you are a Safe Haven employee, Personal
            Information may also include certain employee- and work-related
            information. We also collect non-personally identifiable
            information, including your IP address, search preferences related
            to specific searches, location, and interactions with our User
            Interfaces. When we link non-personally identifiable information
            with Personal Information, we will treat it as Personal Information
            under this Privacy Policy.
        </Typography>
        <Typography variant='body2'>
            If you use the User Interfaces to obtain advice services for or
            about another person, we may collect the name of, and information
            about, that individual. You must make sure that you have the right
            to provide such information. By providing any Personal Information
            of another person, you represent that you have all necessary
            authority and/or have obtained all necessary consents from such
            person to enable us to collect, use and disclose such Personal
            Information as set forth in this Privacy Policy.
        </Typography>
    </>
);

export const AGREEMENT_TWO = (
    <>
        <Typography variant='body2'>
            Safe Haven only shares your Personal Information as authorized by
            you or as disclosed in this Privacy Policy, including the following:
        </Typography>

        <ul>
            <li>
                <strong>Third-Party Partners or Providers:</strong> To provide you
                with certain services, we may share your Personal Information with
                third-party partners and service providers or require that you
                transact directly with a third-party partner or service provider.
                Third-party partners and service providers consist of companies
                with which Safe Haven has contracted in order to provide you with
                the Services, including, for example, services or products of ADT
                LLC d/b/a ADT Security Services or Alarm.com.
            </li>
            <li>
                <strong>Safe Haven’s Agents:</strong> Many of the services we
                provide require us to hire other companies to help us with these
                tasks (e.g., handling e-mail, analyzing data, marketing our
                products and services, or handling credit card transactions). The
                companies we employ to help us with these tasks have access to
                Personal Information to perform their functions, but they may not
                use it for other purposes. Safe Haven acts in good faith to
                require all agents to whom Personal Information is disclosed to
                enter into confidentiality agreements and to agree to act in a
                manner consistent with the relevant principles articulated in this
                Policy.
            </li>
            <li>
                <strong>
                    Advertisements, Marketing Material and Other Offers:
                </strong>
                Unless you tell us that you do not want to receive these offers
                and subject to applicable law, Safe Haven or those companies that
                we have a commercial relationship with, may occasionally send
                advertisements, marketing material, or other offers that we think
                might be of interest to you. If you do not want to receive these
                advertisements, marketing material or other offers, please email{' '}
                <a href='mailto:DNC@mysafehaven.com' className='link-color'>
                    DNC@mysafehaven.com
                </a>
                .
            </li>
            <Typography sx={{ fontWeight: 700, paddingLeft: '40px' }}>
                Where required by law, we will seek your consent prior to sending
                you any marketing materials. To the extent allowed by law, the
                provision of your email and phone number to us constitutes your
                prior express written consent and electronic signature,
                authorizing us to contact you at that email address and phone
                number, including through the possible use of an automatic
                telephone dialing system or artificial or prerecorded voice, live
                calls, and text messages, for both promotional and informational
                reasons. You agree that this consent may be assigned by us to
                third parties and affiliates. You are not required to provide such
                consent to make a purchase. By providing your telephone number to
                us, you certify that this is your own number that you own, and not
                a line owned or used by another, and that you will immediately
                notify us if your number changes or is reassigned. You agree to
                indemnify us if this is not the case and if the future owner or
                user of the number makes a claim against us for contact at that
                number. You may opt out of such contact at any time and through
                any of the reasonable methods outlined herein. A copy of Safe
                Haven’s written Do-Not-Call Policy will be provided upon request.
            </Typography>
            <li>
                <strong>Future Business Transfers:</strong> We may transfer your
                information to a third party in the event of any reorganization,
                merger, sale, joint venture, assignment, transfer, or other
                disposition of all or any portion of our business, assets or
                stock, including in connection with any bankruptcy or similar
                proceedings.
            </li>
            <li>
                <strong>Compliance with Law and Other Disclosures:</strong> Safe
                Haven reserves the right to release Personal Information in order
                to comply with applicable law and when we believe that disclosure
                is necessary to comply with a judicial proceeding, court order, or
                legal process served on us. We may also release Personal
                Information to enforce or apply the terms and conditions
                applicable to our products and services, protect us or others
                against fraudulent or inappropriate activities, or otherwise
                protect the rights, property or safety of Safe Haven, our
                affiliated companies, our customers, or others.
            </li>
        </ul>

        <Typography variant='body2' sx={{}}>
            Safe Haven’s User Interfaces may include links to third-party
            websites and use third-party services. If you submit personal
            information to any third-party websites or service providers, your
            information is governed by their privacy policies. This Privacy
            Policy does not address the privacy practices of any third-party
            websites or service providers.
        </Typography>
    </>
);

export const AGREEMENT_THREE = (
    <>
        <Typography className='title' variant='body2'>3. <span className='indention'>Consent</span></Typography>
        <Typography variant='body2'>
            Each time you provide any personal information to us through our
            User Interfaces or the Service, you consent to the collection, use,
            and disclosure/sharing such information according to this Privacy
            Policy. Where required, consent for the collection, use, and
            disclosure/sharing of your information may be obtained either
            expressly or implied. Your express consent may be given in writing,
            verbally or electronically. Your implied consent may be given
            through an action you have taken, such as supplying your personal
            information for a specified purpose. If you do not notify us, we
            will consider you to have consented to our continued use and
            disclosure/sharing of your personal information. Revoking consent to
            collection, use and/or disclose/share your information for certain
            purposes may affect our ability to provide you with Service(s). You
            may contact us using the information below.
        </Typography>
    </>
);

export const AGREEMENT_FOUR = (
    <>
        <Typography className='title' variant='body2'>4. <span className='indention'>Information Protection</span></Typography>
        <Typography variant='body2'>
            Safe Haven limits access to your Personal Information and seeks to
            use reasonable organizational, technical, and administrative
            measures to protect your Personal Information. Unfortunately, no
            information transmission or storage system can be guaranteed to be
            100% secure. If you have reason to believe that your interaction
            with us is no longer secure, please contact us immediately using the
            information below.
        </Typography>
    </>
);

export const AGREEMENT_FIVE = (
    <>
        <Typography className='title' variant='body2'>
            5. <span className='indention'>Accessing or Changing Your Personal Information</span>
        </Typography>

        <Typography variant='body2'>
            If you want to change, access, or delete your Personal Information,
            please contact us at <Link href="mailto:privacy@mysafehaven.com">privacy@mysafehaven.com</Link>. To protect your
            privacy and security, we may need to verify your identity before
            granting access or making changes to your Personally Information. If
            you have created a profile on any of the Services, your e-mail
            address and password may be required to access your profile
            information.{' '}
            <Typography>
                <br></br>
                We will retain your information for as long as your account is
                active or as needed to provide you services. In addition, we will
                retain and use your information as necessary to comply with our
                legal obligations, resolve disputes, and enforce our agreements.
            </Typography>
            <Typography>
                <br></br>
                If you are a California resident, please see the “California
                Privacy Rights” section below for more information about your
                rights and choices.
            </Typography>
        </Typography>
    </>
);

export const AGREEMENT_SIX = (
    <>
        <Typography className='title' variant='body2'>
            6. <span className='indention'>Children’s Privacy</span>
        </Typography>

        <Typography variant='body2'>
            Safe Haven’s User Interfaces are intended for a general audience,
            and Safe Haven does not knowingly collect any Personal Information
            from individuals under the age of 18. In addition, individuals under
            the age of 18 are not eligible to use any of the Services. Thus,
            Safe Haven will confirm you are at least 18 years old before selling
            any Services to you, by obtaining your date of birth and running
            your credit over the phone with you (once we obtain your consent).
        </Typography>
    </>
);

export const AGREEMENT_SEVEN = (
    <>
        <Typography className='title' variant='body2'>
            7. <span className='indention'>State-Specific Privacy Notices</span>
        </Typography>

        <Typography variant='body2'>
            The following notices apply to certain Safe Haven customers who
            reside in states that have adopted consumer privacy laws and
            regulations (collectively, “State Privacy Laws”), including the following:
        </Typography>

        <ul>
            <li>
                California: the California Consumer Privacy Act of 2018 (CCPA) and the California Privacy Rights Act (CPRA);
            </li>
            <li>
                Colorado: the Colorado Privacy Act;
            </li>
            <li>
                Connecticut: Connecticut Data Privacy Act;
            </li>
            <li>
                Delaware: the Delaware Personal Data Privacy Act;
            </li>
            <li>
                Florida: the Florida Digital Bill of Rights;
            </li>
            <li>
                Indiana: the Indiana Data Privacy Law;
            </li>
            <li>
                Iowa: the Iowa Act Relating to Consumer Data Protection;
            </li>
            <li>
                Kentucky: the Kentucky Consumer Data Protection Act;
            </li>
            <li>
                Montana: the Montana Consumer Data Privacy Act;
            </li>
            <li>
                Nebraska: the Nebraska Data Privacy Act;
            </li>
            <li>
                New Hampshire: the New Hampshire Privacy Act;
            </li>
            <li>
                New Jersey: the New Jersey Data Privacy Act;
            </li>
            <li>
                Oregon: the Oregon Consumer Privacy Act;
            </li>
            <li>
                Tennessee: the Tennessee Information Privacy Act;
            </li>
            <li>
                Texas: the Texas Data Privacy and Security Act;
            </li>
            <li>
                Utah: the Utah Consumer Privacy Act;
            </li>
            <li>
                Virginia: the Virginia Consumer Data Protection Act;
            </li>
        </ul>

        <Typography variant='body2'>
            Not all of the State Privacy Laws are effective as of the date of this Privacy Policy, and several of the State Privacy Laws do not apply to Safe Haven.
        </Typography>

        <Typography
            sx={{
                paddingLeft: '40px',
                fontWeight: '700',
            }}
        >
            A. <span className='indention headline-underline'>Privacy Notices Regarding Personal Information Collected or Maintained by Safe Haven</span>
        </Typography>

        <Typography variant='body2'>
            Under certain State Privacy Laws, including the CCPA and CPRA, Safe Haven customers who are residents of
            those states may request certain information about Safe Haven’s
            privacy practices including the personal information Safe Haven has
            collected, where it was sourced from, what it is being used for,
            whether it is being disclosed to third parties for business
            purposes, sold to third parties, and to whom it is being disclosed
            or sold in the previous 12 months.
        </Typography>

        <ol className="ol-indention" type="i">
            <li className="indention">
                Information Safe Haven Collects
            </li>
        </ol>

        <Typography variant='body2'>
            The table below summaries the
            personal information Safe Haven may collect from you and how
            Safe Haven may use or share that information.
        </Typography>

        <table className='privacy-table'>
            <thead>
                <tr>
                    <td>
                        <strong>Information Category</strong>
                    </td>
                    <td>
                        <strong>Examples</strong>
                    </td>
                    <td>
                        <strong>Collected?</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <p>1. Identifiers.</p>
                    </td>
                    <td>
                        Name, alias, postal address, unique personal identifier,
                        online identifier, IP address, email address, account name,
                        SSN, driver’s license number, passport number, or other
                        similar identifiers.
                    </td>
                    <td>
                        <strong>Yes</strong>
                    </td>
                </tr>

                <tr>
                    <td>
                        2. Personal information categories listed in the CCPA and CCPRA
                    </td>
                    <td>
                        Name, signature, SSN, physical characteristics/ description,
                        address, telephone number, passport number, driver’s license
                        or ID card number, insurance policy number, education,
                        employment, employment history, bank account/credit card/debit
                        card numbers, or any other financial information, medical
                        information, or health insurance information.
                    </td>
                    <td>
                        <strong>Yes</strong>
                    </td>
                </tr>

                <tr>
                    <td>
                        3. Protected classification characteristics under California
                        or federal law.
                    </td>
                    <td>
                        Age (40 years or older), race, color, ancestry, national
                        origin, citizenship, religion or creed, marital status,
                        medical condition, physical or mental disability, sex
                        (including gender, gender identity, gender expression,
                        pregnancy or childbirth and related medical conditions),
                        sexual orientation, veteran or military status, personal &amp;
                        familial genetic information.
                    </td>
                    <td>
                        <strong>Yes</strong>
                    </td>
                </tr>

                <tr>
                    <td>4. Commercial information.</td>
                    <td>
                        Records of personal property, products/services purchased,
                        obtained, or considered, or other purchasing or consuming
                        histories or tendencies.
                    </td>
                    <td>
                        <strong>Yes</strong>
                    </td>
                </tr>

                <tr>
                    <td>5. Biometric information.</td>
                    <td>
                        Genetic, physiological, behavioral, and biological
                        characteristics, or activity patterns used to extract a
                        template or other identifier or identifying information, such
                        as, fingerprints, faceprints, and voiceprints, iris or retina
                        scans, keystroke, gait, or other physical patterns, and sleep,
                        health, or exercise data.
                    </td>
                    <td>
                        <strong>Yes</strong>
                    </td>
                </tr>

                <tr>
                    <td>
                        6. Internet or other similar network activity.
                    </td>
                    <td>
                        Browsing/search history, information on a consumer’s
                        interaction with a website, application, or advertisement.
                    </td>
                    <td>
                        <strong>Yes</strong>
                    </td>
                </tr>

                <tr>
                    <td>7. Geolocation data.</td>
                    <td>GPS, physical location, or movements.</td>
                    <td>
                        <strong>Yes</strong>
                    </td>
                </tr>

                <tr>
                    <td>8. Sensory data.</td>
                    <td>
                        Audio, electronic, visual, thermal, olfactory, or similar
                        information.
                    </td>
                    <td>
                        <strong>Yes</strong>
                    </td>
                </tr>

                <tr>
                    <td>
                        9. Professional or employment-related information.
                    </td>
                    <td>
                        Current or past job history or performance evaluations.
                    </td>
                    <td>
                        <strong>Yes</strong>
                    </td>
                </tr>

                <tr>
                    <td>
                        10. Non-public education information (per the Family
                        Educational Rights and Privacy).
                    </td>
                    <td>
                        Education records such as student grades, transcripts, class
                        lists, schedules, ID codes, financial information, or
                        disciplinary records maintained by an educational institution
                        or party acting on its behalf.
                    </td>
                    <td>
                        <strong>No</strong>
                    </td>
                </tr>

                <tr>
                    <td>
                        11. Inferences drawn from other personal information.
                    </td>
                    <td>
                        Profile reflecting a person’s preferences, characteristics,
                        psychological trends, predispositions, behavior, attitudes,
                        intelligence, abilities, and aptitudes.
                    </td>
                    <td>
                        <strong>No</strong>
                    </td>
                </tr>
            </tbody>
        </table>

        <ol className="ol-indention" type="i" start={2}>
            <li className="indention">
                Information Safe Haven Collects
            </li>
        </ol>

        <Typography variant='body2'>
            See Section 1 of this Privacy Policy for details about the business and/or commercial purposes for which Safe Haven uses the Personal Information that it collects (see categories 1-8, above).
        </Typography>

        <ol className="ol-indention" type="i" start={3}>
            <li className="indention">
                Sources of Information
            </li>
        </ol>

        <Typography variant='body2'>
            Safe Haven receives the Personal Information that it collects (see categories 1-8, above) from the following sources:
        </Typography>

        <ul>
            <li>
                Directly from you, e.g., from forms you complete or products and services you purchase from Safe Haven
            </li>
            <li>
                Indirectly from you, e.g., from observing your actions on Safe Haven’s Site.
            </li>
            <li>
                From third parties, e.g., from Safe Haven’s third-party partners or service providers.
            </li>
        </ul>

        <ol className="ol-indention" type="i" start={4}>
            <li className="indention">
                Third-Party Sharing
            </li>
        </ol>

        <Typography variant='body2'>
            See Section 2 of this Privacy Policy for details about the business and/or commercial purposes for which Safe Haven shares the Personal Information that it collects (see categories 1-8, above).  Safe Haven may share or disclose the Personal Information with the following recipients:
        </Typography>

        <ul>
            <li>
                Sub-Dealers
            </li>
            <li>
                Service providers
            </li>
            <li>
                Affiliates
            </li>
            <li>
                Advertisers
            </li>
            <li>
                Partners
            </li>
            <li>
                Parent or subsidiary organizations
            </li>
            <li>
                Internet cookie information recipients
            </li>
        </ul>

        <Typography variant='body2'>
            Safe Haven does not sell or share personal information to third parties for the purpose of cross-contextual behavioral advertising.  Further, Safe haven does not knowingly sell or share personal information of minors under 16 years of age.
        </Typography>

        <Typography
            sx={{
                paddingTop: '10px',
                paddingLeft: '40px',
                fontWeight: '700',
            }}
        >
            B. <span className='indention headline-underline'>Sale of Personal Information</span>
        </Typography>
        <Typography
            variant='body2'
        >
            In the previous 12 months, Safe Haven has not sold your personal
            information in the enumerated categories above.
        </Typography>
        <Typography
            sx={{
                paddingLeft: '40px',
                fontWeight: '700',
            }}
        >
            C. <span className='indention headline-underline'>Consumer Rights Under State Privacy Laws</span>
        </Typography>
        <Typography
            variant='body2'
        >
            To the extent applicable under the State Privacy Laws, you may have
            the following rights in connection with your personal information:
            rights.
        </Typography>
        <Box sx={{ paddingLeft: '40px' }}>
            <Typography variant='body2'>
                1. The right to request Safe Haven to disclose the personal
                information we collect from you;
            </Typography>
            <Typography variant='body2'>
                2. The right to request Safe Haven to disclose if we sell your
                personal information;
            </Typography>
            <Typography variant='body2'>
                3. The right to request Safe Haven to delete your personal
                information, subject to exceptions under applicable State Privacy
                Laws;
            </Typography>
            <Typography variant='body2'>
                4. The right to request Safe Haven to opt-out of the sale, use, or
                disclosure of your personal information for targeted advertising
                purposes;
            </Typography>
            <Typography variant='body2'>
                5. The right to correct inaccurate personal information; and
            </Typography>
            <Typography variant='body2'>
                6. The right to be free from discrimination if you exercise and of
                the above
            </Typography>
        </Box>

        <Typography
            sx={{
                paddingLeft: '40px',
                fontWeight: '700',
            }}
        >
            D. <span className='indention headline-underline'>Exercising Your Rights: Verifiable Consumer Requests and Opting Out:</span>
        </Typography>

        <Box sx={{}}>
            <Typography variant='body2'>
                <strong>Submitting Requests:</strong> To exercise your rights
                stated above, contact Safe Haven by submitting a request on <Link href='https://mysafehaven.com/legal/affirmation/'>this link</Link> Request or Delete My Personal Information
                or by calling Safe Haven, toll-free, at <Link href='tel:877-842-0818'>(877) 842-0818</Link>.
            </Typography>

            <Typography variant='body2'>
                <strong>Authorizing an Agent:</strong> You may also authorize an
                agent to act on your behalf to exercise the rights stated above.
                To authorize an agent please provide written authorization to Safe
                Haven at the contact information listed in Section 12 of this
                Privacy Policy, below.
            </Typography>

            <Typography variant='body2'>
                <strong>Verifying your Request:</strong> Upon receiving your
                request Safe Haven will verify the request by matching the
                information provided to the personal information Safe Haven
                currently has.
            </Typography>
        </Box>
    </>
);

export const AGREEMENT_EIGHT = (
    <>
        <Typography className='title' variant='body2'>
            8. <span className='indention'>Cookies, Web Beacons, and Website Analytics</span>
        </Typography>

        <Typography variant='body2'>
            Cookies and similar tracking technologies, such as beacons, scripts,
            and tags, are small bits of code, usually stored on a user’s
            computer hard drive or device, which enable a website to
            “personalize” itself for each user by remembering information about
            the user’s visit to the website.
        </Typography>
        <Typography variant='body2'>
            As described in more detail below, our Site may use cookies to store
            your preferences, display content based upon what you view on our
            Site to personalize your visit, analyze trends, administer the Site,
            track users’ movements around the Site, serve targeted advertising
            and gather demographic information about our user base as a whole.
            On other User Interfaces, we may use Anonymous Device IDs and/or
            Advertiser Identifiers in a manner similar to our use of cookies on
            our Site.
        </Typography>

        <Typography
            sx={{
                paddingLeft: '40px',
                fontWeight: '700',
            }}
        >
            A. <span className='indention headline-underline'>Safe Haven’s Cookies (First-Party Cookies):</span>
        </Typography>
        <Typography variant='body2' sx={{}}>
            Safe Haven may use our cookies to improve your web-browsing
            experience. For example, we use a cookie to reduce the time it takes
            for you to submit purchase requests by storing a registered user’s
            e-mail address so that the user does not need to enter their e-mail
            address each time that they login to Safe Haven. We will also use a
            cookie to keep track of your search criteria while you are engaging
            Safe Haven’s services. Safe Haven’s cookies are associated with a
            user’s Personal Information. However, no third party may use the
            information we collect through our cookies for their own purposes.
        </Typography>

        <Typography
            sx={{
                paddingLeft: '40px',
                fontWeight: '700',
            }}
        >
            B. <span className='indention headline-underline'>Third Party Cookies</span>
        </Typography>
        <Typography variant='body2' sx={{}}>
            Safe Haven also works with third parties that place cookies on our
            Site to provide their services, including:
        </Typography>

        <ul>
            <li>
                <strong>Ad Targeting:</strong> We work with third-party
                advertising companies to serve ads while you are visiting our Site
                and permit these companies to place and access their own cookies
                on your computer while serving advertisements on this Site. These
                companies may use information obtained through their cookie (which
                does not include your name, address, e-mail address or telephone
                number) about your visits to our Site and other websites, in
                combination with non-personally identifiable information about
                your purchases and interests from other online sites in order to
                provide advertisements about goods and services of interest to
                you. Other companies’ use of their cookies is subject to their own
                privacy policies and not ours.
            </li>
            <li>
                <strong>Data Providers:</strong> We also allow data providers to
                collect web log data from you (including IP address and
                information about your browser or operating system), when you
                visit our Site, or place or recognize a unique cookie on your
                browser to enable you to receive customized ads or content. These
                cookies contain no personally identifiable information. The
                cookies may reflect de-identified demographic or other data linked
                to data you voluntarily have submitted to us, e.g., your email
                address, that we may share with data providers solely in hashed,
                non-human readable form.
            </li>
            <li>
                <strong>Analytics/Measurement:</strong> We also use third-party
                analytics cookies to gain insight into how our visitors use the
                Site, to find out what works and what does not, to optimize and
                improve our websites and to ensure we continue to be interesting
                and relevant. The data we gather includes which web pages you have
                viewed, which referring/exit pages you have entered and left from,
                which platform type you have used, date and time stamp information
                and details such as the number of clicks you make on a given page,
                your mouse movements and scrolling activity, the search words you
                use and the text you type while using our Site. We also make use
                of analytics cookies as part of our online advertising campaigns
                to learn how users interact with our website after they have been
                shown an online advertisement, which may include advertisements on
                third-party websites.
            </li>
            <li>
                <strong>Remarketing Pixel Tags:</strong> In addition, we may
                share web site usage information about visitors to our Sites with
                third-party advertising companies for the purpose of managing and
                targeting advertisements and for market research analysis on our
                Site and other sites. For these purposes, we and our third-party
                advertising companies may place pixel tags (also called clear
                gifs) on some of the pages you visit on our Site. We will then use
                the non-personally identifiable information that is collected
                using these pixel tags to serve you relevant advertisements when
                you are visiting other sites on the Internet. These advertisements
                may be targeted to specific searches you conducted on Safe Haven
                during earlier browsing sessions.
            </li>
            <li>
                <strong>Flash Cookies:</strong> Our partners, who may provide
                certain features on our site or display advertising based on your
                browsing activity, also use Local Shared Objects such as Flash
                cookies, and Local Storage such as HTML5, to collect and store
                content information and preferences. Various browsers may offer
                their own management tools for removing HTML5 local storage.
            </li>
        </ul>

        <Typography
            sx={{
                paddingLeft: '40px',
                fontWeight: '700',
            }}
        >
            C. <span className='indention headline-underline'>Opting Out of Cookies</span>
        </Typography>
        <Typography variant='body2' sx={{}}>
            We provide you with choices regarding the use of cookies as described
            in this Privacy Policy. However, our Site is not currently
            configured to respond to Do Not Track signals. If you would prefer
            that we did not use cookies when you visit our Site, check with your
            browser’s settings and “help” menu for how to configure your
            settings to reject cookies.
        </Typography>

        <Typography
            sx={{
                paddingLeft: '40px',
                fontWeight: '700',
            }}
        >
            D. <span className='indention headline-underline'>Mobile Devices, Anonymous Device Identifiers and Hashed IDs</span>
        </Typography>
        <Typography variant='body2' sx={{ paddingLeft: '80px' }}>
            We and/or data providers that we have engaged may collect and store
            a unique identifier matched to your mobile device, in order to
            deliver customized ads or content while you use applications or surf
            the internet or identify you in a unique manner across other devices
            or browsers. To customize these ads or content, we or a data
            provider may collect de-identified demographic or other data about
            you (received from third parties) either to data you voluntarily
            have submitted to us, e.g., your email address, or to data passively
            collected from you, such as your device identifier or IP address.
            However, we will share your email address solely in hashed,
            non-human readable form. If you no longer wish to receive
            interest-based advertising on your mobile device browser or
            applications, please refer to your device’s operating system
            settings.
        </Typography>
        <Typography variant='body2' sx={{ paddingLeft: '80px' }}>
            Please keep in mind that, without cookies or similar tracking
            technologies, you may not have access to certain features on our
            Site, including access to your profile/account and certain
            personalized content. Removing all cookies from your computer could
            also affect your subsequent visits to certain web sites, including
            our Site, by requiring that, for example, you enter your username
            and password to access the employee or dealer program components of
            the Site.
        </Typography>
    </>
);

export const AGREEMENT_NINE = (
    <>
        <Typography className='title' variant='body2'>
            9. <span className='indention'>Third-Party Websites</span>
        </Typography>

        <Typography variant='body2'>
            Safe Haven’s User Interfaces may include hyperlinks to other
            websites not under Safe Haven’s control. Safe Haven is not
            responsible for, and this Privacy Policy does not apply to, the
            privacy practices of those sites or of any companies that Safe Haven
            does not own or control. Safe Haven does not endorse any of these
            websites or pages, the services or products described or offered on
            such sites or pages, or any of the content contained on those sites
            or pages. In addition, should you initiate a transaction on a
            website linked to the Safe Haven User Interfaces, the information
            that you submit to complete that transaction becomes subject to the
            privacy practices of the operator of that linked website. Safe Haven
            encourages you to seek out and read that website’s privacy policies
            to understand how Personal Information that is collected about you
            is used and protected.
        </Typography>
    </>
);