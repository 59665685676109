import React, { useEffect } from "react";
import {
	Box,
	Button,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	Container,
	Divider,
	Grid,
	Icon,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Pagination,
	Stack,
	ThemeProvider,
	Typography,
	createTheme,
} from "@mui/material";
import SectionOneTechOnPhone from "../../Assets/Careers/Tech-on-Phone.jpg";
import SectionFiveEmployeeWithSign from "../Assets/Careers/ADT-employee.jpg";
import wereHiring from "../../Assets/Careers/hiring.jpg";
import blueQuote from "../../Assets/Careers/quote-blue.png";
import SectionSixYardSign from "../../Assets/Careers/ADT-Yard-Sign-3.jpg";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import LearusCarousel from "../../Components/LearusCarousel";
import * as Constants from "../../Constants/Pages";
import StarIcon from "@mui/icons-material/Star";
import SwiperCarousel from "../../Components/SwiperCarousel";
import "./index.scss";
import { customBreakpoints } from "../../themeOptions";
import { CareersService } from "../../Helpers/careers";
import MediaQuery from "react-responsive";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { isMobile, isTablet, isDesktop } from 'react-device-detect';

const SectionThree = () => {
	const [location, setLocation] = React.useState("");
	const [careers, setCareers] = React.useState<any>({ objects: [] });
	const [division, setDivision] = React.useState("");
	const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(
		window.innerWidth < 500 ? 2 : window.innerWidth < 900 ? 6 : 9,
	);
	const [pageCount, setPageCount] = React.useState(0);
	const [totalRows, setTotalRows] = React.useState(0);

	useEffect(() => {
		const getCareers = async () => {
			const _careers = await CareersService.getJobs(
				location,
				division,
				page,
				rowsPerPage,
			);
			getTotalPageCount(_careers.meta.total, rowsPerPage);
			setCareers(_careers);
		};
		setPage(1);
		getCareers();
	}, []);

	const handleLocationChange = async (event: SelectChangeEvent) => {
		const _careers = await CareersService.getJobs(
			event.target.value,
			division,
			1,
			rowsPerPage,
		);
		setPage(1);
		setLocation(event.target.value);
		getTotalPageCount(_careers.meta.total, rowsPerPage);
		setCareers(_careers);
	};

	const handlePageChange = async (
		event: React.ChangeEvent<unknown>,
		value: number,
	) => {
		setPage(value);
		const _careers = await CareersService.getJobs(
			location,
			division,
			value,
			rowsPerPage,
		);
		getTotalPageCount(_careers.meta.total, rowsPerPage);
		setCareers(_careers);
	};

	function getTotalPageCount(totalRows: any, rowsPerPage: any) {
		const pageCount = Math.ceil(totalRows / rowsPerPage);
		setPageCount(pageCount);
	}

	const handleDivisionChange = async (event: SelectChangeEvent) => {
		const _careers = await CareersService.getJobs(
			location,
			event.target.value,
			1,
			rowsPerPage,
		);
		//
		getTotalPageCount(_careers.meta.total, rowsPerPage);
		// setCareers(JSON.stringify(_careers));
		console.log(JSON.stringify(_careers));
		setDivision(event.target.value);
		setCareers(_careers);
		setPage(1);
	};

	return (
		<>
			<Box
				className="Careers-Section-3"
				sx={{
					width: "100%",
					maxWidth: "100%",
					height: "20%",
					minHeight: "20%",
					bgcolor: "#d1d1d3",
					background:
						"linear-gradient(to bottom right, #d8d8d8 55%, #d1d1d3 45%);",
				}}
			>
				<Container
					sx={{
						width: "100%",
						maxWidth: "100%",
						textAlign: "center",
						padding: { xs: '2rem 1rem', md: '3.5rem', lg: "5rem" },
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignContent: 'center'
					}}
				>
					<Typography
						sx={{
							fontSize: { xs: '32px', lg: '42px' },
							fontWeight: '700',
							marginBottom: '24px',
							lineHeight: 1.2,
						}}
					>
						Take the first step towards a rewarding career!
					</Typography>
					<Button
						variant={'contained'}
						className='careers-apply-now-btn'
						href="https://myjobs.adp.com/safehavencs/cx"
						target="_blank"
						sx={{
							margin: 'auto',
							fontWeight: '700',
							minHeight: '70px',
							minWidth: '185px',
							font: "normal normal 700 20px 'PT Sans', Helvetica, Arial, Verdana, sans-serif",
							background: '#1260a8',
							"&:hover": {
								background: "#00417e",
							},
						}}
					>
						Apply Now
					</Button>
				</Container>
			</Box>
		</>
	);
};

export default function Careers() {
	const navigate = useNavigate();
	const handleNavigate = (route: string) => navigate(`/${route}`);
	const isMobileOrTablet = isMobile || isTablet;

	return (
		<>
			<Helmet>
				<title>Safe Haven Careers: Join Our Team & Make a Difference | Safe Haven Security</title>
				<meta
					name="description"
					content="Join Safe Haven and build a rewarding career. We value your potential and empower you to make a positive impact."
				/>
				<meta
					name="keywords"
					content="Safe Haven careers, security jobs, career opportunities, work ethic, passion, teamwork, make a difference, rewarding career"
				/>
			</Helmet>
			<Stack sx={{ background: "#ffffff" }}>
				<Box
					sx={{
						backgroundColor: "#1260a8",
						display: "flex",
						justifyContent: "space-evenly",
					}}
				>
					<Box
						sx={{ padding: { xs: "35px 0 35px 35px", sm: "70px 0 70px 70px" } }}
					>
						<Typography sx={{ fontSize: "32px", color: "#ffffff" }}>
							<em>
								“If I did it, anyone can. I went from a rep to a branch manager in
								6 months.”
							</em>{" "}
						</Typography>
						<Typography sx={{ fontSize: "22px", color: "#ffffff" }}>
							-Ricardo Pradel
						</Typography>
					</Box>

					<Box sx={{ position: "relative" }}>
						<Box
							component="img"
							sx={{
								maxWidth: "100%",
								height: "auto",
							}}
							src={blueQuote}
						/>
					</Box>
				</Box>

				<Box>
					<Grid
						container
						maxWidth="xl"
						spacing={0}
						sx={{
							alignItems: "center",
							alignContent: "center",
							justifyContent: "center",
							flexDirection: { xs: "column-reverse", lg: "row" },
							margin: "auto",
						}}
					>
						<Grid
							item
							xs={12}
							lg={6}
							sm
							container
							className="title-grid-container"
						>
							<Grid
								item
								xs
								container
								direction="column"
								className="title-grid"
								sx={{ padding: { xs: '1rem', md: '3rem', lg: '0 4rem' } }}
							>
								<Typography
									variant="subtitle1"
									component="div"
									sx={{
										color: "#1260a8",
										fontSize: { xs: '28px', lg: "36px", xl: "48px" },
										textAlign: { xs: 'center' },
										fontWeight: "700",
									}}
								>
									SAFE HAVEN STRONG
								</Typography>
								<Typography
									variant="body2"
									gutterBottom
									sx={{
										fontSize: { xs: '28px', lg: "32px" },
										textAlign: { xs: 'center' },
										marginBottom: "32px"
									}}
								>
									JOIN OUR TEAM!
								</Typography>
								<Typography
									variant="body2"
									color="text.secondary"
									sx={{
										olor: "#000000",
										fontSize: "22px",
										marginBottom: "24px",
										textAlign: { xs: 'justify' }
									}}
								>
									At Safe Haven, we don’t care about who you’ve been. We care
									about who you want to become.
								</Typography>
								<Typography
									variant="body2"
									color="text.secondary"
									sx={{
										olor: "#777777",
										fontSize: "22px",
										marginBottom: "24px",
										textAlign: { xs: 'justify' }
									}}
								>
									“Safe Haven Strong” means that we are strengthened by our
									culture, strengthened by each other and our achievements, and
									strengthened by knowing that each day, we’ve made somebody’s
									life safer.
								</Typography>
								<Typography
									variant="body2"
									color="text.secondary"
									sx={{
										olor: "#777777",
										fontSize: "22px",
										marginBottom: "24px",
										textAlign: { xs: 'justify' }
									}}
								>
									If you’re driven to achieve success and have a strong work
									ethic, we’re looking for you. If you’re passionate and
									forward-thinking, we’re looking for you. If you want to
									challenge yourself with a rewarding career, we’re looking for
									you. There is no ideal set of skills that we’re looking for,
									only an attitude.
								</Typography>
								<Typography
									variant="body2"
									color="text.secondary"
									sx={{
										olor: "#777777",
										fontSize: "22px",
										marginBottom: "10px",
									}}
								>
									Be a part of our team.
								</Typography>
							</Grid>
						</Grid>
						<Grid item xs={12} lg={6}>
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								<Box
									component="img"
									sx={{
										width: { xs: '100%', md: '70%', lg: "100%" },
									}}
									alt="The house from the offer."
									src={wereHiring}
								/>

							</Box>

							<Divider></Divider>

						</Grid>
					</Grid>
				</Box>

				<Box>
					<SectionThree></SectionThree>
				</Box>

				<Grid
					container
					maxWidth="xl"
					spacing={0}
					sx={{
						padding: "70px 0",
						margin: "auto",
					}}
				>
					<Grid item xs={12} md={6}>
						<Box>
							<List sx={{ width: "100%", bgcolor: "background.paper" }}>
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<AddCircleIcon
											color="primary"
											sx={{ height: "40px", width: "40px" }}
										></AddCircleIcon>
									</ListItemAvatar>
									<ListItemText>
										<Typography sx={{ fontSize: "36px" }}>
											Paid Training
										</Typography>
									</ListItemText>
								</ListItem>

								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<AddCircleIcon
											color="primary"
											sx={{ height: "40px", width: "40px" }}
										></AddCircleIcon>
									</ListItemAvatar>

									<ListItemText>
										<Typography sx={{ fontSize: "36px" }}>
											Uncapped Commissions
										</Typography>
									</ListItemText>
								</ListItem>
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<AddCircleIcon
											color="primary"
											sx={{ height: "40px", width: "40px" }}
										></AddCircleIcon>
									</ListItemAvatar>

									<ListItemText>
										<Typography sx={{ fontSize: "36px" }}>
											Advancement Opportunities
										</Typography>
									</ListItemText>
								</ListItem>
							</List>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box>
							<List sx={{ width: "100%", bgcolor: "background.paper" }}>
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<AddCircleIcon
											color="primary"
											sx={{ height: "40px", width: "40px" }}
										></AddCircleIcon>
									</ListItemAvatar>

									<ListItemText>
										<Typography sx={{ fontSize: "36px" }}>
											W-2 Employee Positions
										</Typography>
									</ListItemText>
								</ListItem>

								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<AddCircleIcon
											color="primary"
											sx={{ height: "40px", width: "40px" }}
										></AddCircleIcon>
									</ListItemAvatar>

									<ListItemText>
										<Typography sx={{ fontSize: "36px" }}>
											Health, Dental, and Vision Benefits
										</Typography>
									</ListItemText>
								</ListItem>
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<AddCircleIcon
											color="primary"
											sx={{ height: "40px", width: "40px" }}
										></AddCircleIcon>
									</ListItemAvatar>

									<ListItemText>
										<Typography sx={{ fontSize: "36px" }}>
											Paid Vacations
										</Typography>
									</ListItemText>
								</ListItem>
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<AddCircleIcon
											color="primary"
											sx={{ height: "40px", width: "40px" }}
										></AddCircleIcon>
									</ListItemAvatar>

									<ListItemText>
										<Typography sx={{ fontSize: "36px" }}>401K Plans</Typography>
									</ListItemText>
								</ListItem>
							</List>
						</Box>
					</Grid>
				</Grid>

				<Box sx={{ padding: { xs: '32px', lg: "70px" } }}>
					<Button
						variant="text"
						sx={{ paddingLeft: "0", textDecoration: "underline" }}
						component={Link}
						to="https://www.cigna.com/legal/compliance/machine-readable-files"
					>
						<em>Federal Transparency in Coverage Rules</em>
					</Button>
					<Typography>
						<em>
							This link leads to the machine readable files that are made
							available in response to the federal Transparency in Coverage Rule
							and includes negotiated service rates and out-of-network allowed
							amounts between health plans and healthcare providers. The
							machine-readable files are formatted to allow researchers,
							regulators, and application developers to more easily access and
							analyze data.
						</em>
					</Typography>
				</Box>
			</Stack>
		</>

	);
}
