import { ReactElement } from 'react';
import HomeIcon from '@mui/icons-material/Home';

interface NavItem {
  label: string;
  path: string;
  value: string;
  subMenuItems?: any;
}

export const navItems: NavItem[] = [
  {
    label: "Partners",
    path: "/partners",
    value: "partners",
    subMenuItems: [
      { label: "Agent Referral", path: "/partners/agent-referral" },
      { label: "Homebuilder Partners", path: "/partners/homebuilder-partners" },
    ],
  },
  {
    label: "Customers",
    path: "",
    value: "customers",
    subMenuItems: [
      { label: "Customer Solutions", path: "/customer-solutions" },
      { label: "Customer Support", path: "/customer-support" },
    ],
  },
  {
    label: "Solar",
    path: "/solar",
    value: "solar",
  },
  {
    label: "Careers",
    path: "/careers",
    value: "careers",
    // subMenuItems: [
    //   { label: "Realtor Team", path: "/careers/realtor-team" },
    // ],
  },
  {
    label: "About Us",
    path: "/about-us",
    value: "aboutUs",
  },
  {
    label: "Contact",
    path: "/contact-us",
    value: "contact",
  },
];
