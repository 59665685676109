import { Box, Grid, Stack, Typography } from '@mui/material';
import panel1 from '../Assets/CustomerSupport/myadt1.png';
import panel2 from '../Assets/CustomerSupport/myadt1.2.png';
import panel3 from '../Assets/CustomerSupport/myadt1.3.png';
import panel4 from '../Assets/CustomerSupport/myadt2.jpg';
import PersonIcon from '@mui/icons-material/Person';

export const SECTION_ONE = (
  <>
    <Typography
      sx={{
        fontSize: '48px',
        color: '#1260a8',
        fontWeight: '700',
        marginBottom: '10px',
        lineHeight: 1.2,
      }}
    >
      WE'RE HERE TO HELP
    </Typography>
    <Typography sx={{ marginBottom: '25px', fontSize: '20px' }}>
      Part of what makes Safe Haven one of the fastest-growing segments of ADT
      is that we enjoy taking care of our customers. Whether you need help
      resolving an issue or simply need information, we are committed to
      providing you with exceptional customer service.
    </Typography>
    <Typography sx={{ marginBottom: '10px', fontSize: '20px' }}>
      If you can’t find what you’re looking for below, feel free to contact us
      with your issue. We’ll be happy to work with you to resolve it.
    </Typography>
  </>
);

export const LIST_SECTION = (
  <>
    <Typography
      sx={{
        fontSize: '48px',
        color: '#1260a8',
        fontWeight: '700',
        marginBottom: '10px',
        lineHeight: 1.2,
      }}
    >
      JOIN myADT
    </Typography>
    <Typography
      variant='body2'
      gutterBottom
      sx={{ fontSize: '32px', marginBottom: '10px' }}
    >
      GET MORE FROM YOUR SECURITY
    </Typography>
    <Box sx={{ marginBottom: '0px', fontSize: '20px' }}>
      <Typography className='customer-support-list'>
        Now that you’ve chosen ADT, join myADT to get more from your security all
        in one convenient place. Once you join, you’ll be able to:
      </Typography>

      <ul>
        <li className='list-spacing customer-support-list'>Pay your bill online</li>
        <li className='list-spacing customer-support-list'>Get insurance certificates</li>
        <li className='list-spacing customer-support-list'>Order yard signs</li>
        <li className='list-spacing customer-support-list'>
          View alarm activity, even while you’re away
        </li>{' '}
        <li className='list-spacing customer-support-list'>Update your emergency call list</li>
        <li className='list-spacing customer-support-list'>And more</li>
      </ul>
    </Box>
    <Typography sx={{ marginBottom: '10px', fontSize: '20px' }}>
      Joining is simple and takes just a few moments. Get started today.
    </Typography>
  </>
);

export const PANEL_ONE = (
  <>
    <Box
      className='my-adt-wrapper'
      sx={{
        backgroundColor: '#ffffff',
        padding: '15px',
        marginBottom: '35px',
      }}
    >
      <Box
        component='img'
        sx={{
          width: '100%',
          marginBottom: '10px',
        }}
        alt='The house from the offer.'
        src={panel1}
      />
      <Typography>
        <strong>Step 1:</strong>
      </Typography>
      <Typography>
        Go to myADT.com and you will see the screen above.
      </Typography>
      <Typography>
        Click <strong>“Create an Account.”</strong>
      </Typography>
      <Typography>
        This will bring up the <strong>“First, let’s find your account”</strong>
        Screen.
      </Typography>
      <Box
        component='img'
        sx={{
          width: '100%',
          marginTop: '10px',
        }}
        alt='The house from the offer.'
        src={panel2}
      />
      <Typography></Typography>
      <Typography>
        <strong>Step 2:</strong>
      </Typography>
      <Typography>Fill out your primary phone and verbal password.</Typography>
      <Typography>Fill in your First Name and Last Name.</Typography>
      <Typography>Fill in your Email Address.</Typography>
      <Typography>Create a Web Password and Confirm it.</Typography>
      <Typography>
        Finally, choose a Security Questions and and Answer.
      </Typography>
      <Typography>
        Check the box to accept the Terms of Agreement and click Complete
        Registration.
      </Typography>
      <Typography>
        When you click on <strong>“Find Account”</strong> the system will bring
        up your address.
      </Typography>{' '}
      <Box
        component='img'
        sx={{
          width: '100%',
          marginTop: '10px',
          marginBottom: '35px',
        }}
        alt='The house from the offer.'
        src={panel3}
      />
      <Typography>
        <strong>Step 3:</strong>
      </Typography>
      <Typography>Select your address and click “Enter”</Typography>
      <Typography sx={{ marginTop: '35px' }}>
        <strong>Step 4:</strong>
      </Typography>
      <Typography>
        Fill out the <strong>“Account Verified”</strong> screen:
      </Typography>
      <Typography>
        {' '}
        If you need help with any field, simply hover above the question marks
        for help.
      </Typography>{' '}
      <Typography>Fill in your First Name and Last Name.</Typography>{' '}
      <Typography>Fill in your Email Address.</Typography>{' '}
      <Typography>Create a Web Password and Confirm it.</Typography>{' '}
      <Typography>
        Finally, choose a Security Questions and and Answer.
      </Typography>{' '}
      <Typography>
        {' '}
        Check the box to accept the Terms of Agreement and click Complete
        Registration.
      </Typography>
      <Typography sx={{ marginTop: '35px' }}>
        <strong>Step 5:</strong>
      </Typography>
      <Typography>
        You will receive an email requiring you to click a verification link
        that verifies the email address that you used and has you verify the
        completion of the registration.{' '}
      </Typography>
      <Typography>
        {' '}
        Then you are ready to <strong>Log In.</strong>
      </Typography>
    </Box>
  </>
);

export const PANEL_TWO = (
  <>
    <Box
      className='my-adt-wrapper'
      sx={{
        backgroundColor: '#ffffff',
        padding: '15px',
        marginBottom: '35px',
        width: 'calc(100% - 30px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        component='img'
        sx={{
          width: 'auto',
          margin: 'auto auto 10px auto',
          maxWidth: '100%',
          height: 'auto',
        }}
        alt='The house from the offer.'
        src={panel4}
      />
      <Typography>
        Once logged in, you should poke around and check things out.
      </Typography>
      <Typography>
        Verifying your emergency list is a great idea at this point. Make sure
        those are really the people who should be called if an emergency were to
        happen and verify all numbers are correct. In this section you can also
        set up a <strong>Vacation Reminder.</strong> Now why would you need ADT
        to remind you to update contacts when you leave on vacation? Well let’s
        say that your family takes an annual camping trip or Christmas vacation?
        If all the people are on your trip with you, it might make sense to
        replace your son with a neighbor for this trip.
      </Typography>
      <Typography>
        You can also check the latest events with your system, order window
        decals or a sign, make a one time payment or go paperless.
      </Typography>
      <Typography>
        A great idea is to go into your Systems Tab and print a certificate of
        insurance (we will also email you one within a week of your install,)
        and set up an “inactivity alert.” This will alert you that you have not
        tested your system. It is an industry standard to test your system once
        a month.
      </Typography>
      <Typography>
        What’s next? If you are active on your smart phone…. download that
        myADT.com app!
      </Typography>
    </Box>
  </>
);

export const PANEL_THREE = (
  <>
    <Box
      className='my-adt-wrapper'
      sx={{
        backgroundColor: '#ffffff',
        padding: '15px',
        marginBottom: '35px',
        width: 'calc(100% - 30px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ minHeight: '350px' }}>
        <iframe
          width='100%'
          height='350px'
          src='https://www.youtube.com/embed/TVNKWid0YTw?feature=oembed'
        ></iframe>
      </Box>

      <Typography sx={{ textAlign: 'center' }}>
        You can download the myADT.com app for iPhone here and for Android here.
        If you prefer the app to the on-line site, you can also register your
        account with the app as shown in this video. The myADT.com app let’s you
        take all of the on-line capabilities with you where ever life may take
        you. Pay a bill at 3am from Hawaii or order a new sign right after your
        teenager runs it over on your drive to work.
      </Typography>
    </Box>
  </>
);

export const CUSTOMER_REVIEWS = (
  <>
    <Grid
      container
      spacing={6}
      maxWidth={'xl'}
      sx={{
        padding: 'calc(70px - 16px) 0',
        margin: 'auto',
      }}
    >
      <Grid item xs={4}>
        <Box sx={{ display: 'flex' }}>
          <PersonIcon
            sx={{
              color: '#4883bb',
              width: '45px',
              height: '45px',
              marginRight: '10px',
            }}
          ></PersonIcon>
          <Stack sx={{}}>
            <Typography
              sx={{
                color: '#777777',
                fontSize: '22px',
                marginBottom: '10px',
                minHeight: '165px',
              }}
            >
              This is a great company, and I got my rebate back in the exact
              time frame they said I’d get it. I would reccomend this company to
              anyone!
            </Typography>
            <Typography
              sx={{
                color: '#4d4f51',
                fontSize: '22px',
                fontWeight: '700',
              }}
            >
              Ally B.
            </Typography>
            <Typography sx={{ color: '#788bc2', fontSize: '22px' }}>
              Kansas City, MO
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ display: 'flex' }}>
          <PersonIcon
            sx={{
              color: '#4883bb',
              width: '45px',
              height: '45px',
              marginRight: '10px',
            }}
          ></PersonIcon>
          <Stack>
            <Typography
              sx={{
                color: '#777777',
                fontSize: '22px',
                marginBottom: '10px',
                minHeight: '165px',
              }}
            >
              The sales team was professional and the service installers were
              nice and courteous. That and a great price is all that anyone can
              ask for.
            </Typography>
            <Typography
              sx={{
                color: '#4d4f51',
                fontSize: '22px',
                fontWeight: '700',
              }}
            >
              Bryan H.
            </Typography>
            <Typography sx={{ color: '#788bc2', fontSize: '22px' }}>
              Cool Agency
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ display: 'flex' }}>
          <PersonIcon
            sx={{
              color: '#4883bb',
              width: '45px',
              height: '45px',
              marginRight: '10px',
            }}
          ></PersonIcon>
          <Stack>
            <Typography
              sx={{
                color: '#777777',
                fontSize: '22px',
                marginBottom: '10px',
                minHeight: '165px',
              }}
            >
              We are more than satisfied with the service we received from your
              organization. Brandon, Steve and Dustin went above and beyond and
              never faltered from giving us the best of customer service. The
              sales experience from start to finish was professional, pleasant
              and spot on.
            </Typography>
            <Typography
              sx={{
                color: '#4d4f51',
                fontSize: '22px',
                fontWeight: '700',
              }}
            >
              Luke B.
            </Typography>
            <Typography sx={{ color: '#788bc2', fontSize: '22px' }}>
              Kansas City, MO
            </Typography>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  </>
);
