import {
  Box,
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Autocomplete,
} from '@mui/material';
import { useState } from 'react';
import useFetch from 'use-http';
import './index.scss';
import { EMPTY_EMPLOYEE_PHOTO, STATE_CODES, STATE_LIST } from '../../../Constants';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSearchParams } from 'react-router-dom';

interface PartnerRequestFormProps {
  setConfirmationOpen: (arg0: boolean) => void;
}

export default function PartnerRequestForm({ setConfirmationOpen }: PartnerRequestFormProps) {
  const [queryParameters] = useSearchParams();
  const [inputValue, setInputValue] = useState('');

  const [formData, setFormData] = useState({
    contactTime: { value: queryParameters.get("msh-test") ? "Morning" : '', error: false, errorMessage: '' },
    firstName: { value: queryParameters.get("msh-test") ? "TestF" : '', error: false, errorMessage: '' },
    lastName: { value: queryParameters.get("msh-test") ? "TestL" : '', error: false, errorMessage: '' },
    city: { value: queryParameters.get("msh-test") ? "KC" : '', error: false, errorMessage: '' },
    stateCode: { value: queryParameters.get("msh-test") ? "Missouri" : '', error: false, errorMessage: '' },
    email: { value: queryParameters.get("msh-test") ? "test@gmail.com" : '', error: false, errorMessage: '' },
    phone: { value: queryParameters.get("msh-test") ? "7897897897" : '', error: false, errorMessage: '' },
    affiliatedName: { value: queryParameters.get("msh-test") ? "Test Real Estate Office" : '', error: false, errorMessage: '' },
    isBuilderPartner: { value: false, error: false, errorMessage: '' },
    tellUs: { value: '', error: false, errorMessage: '' },
    companyName: { value: queryParameters.get("msh-test") ? "Test Company Name" : '', error: false, errorMessage: '' },
  });

  const handleSelectChange = (event: SelectChangeEvent) => {
    setFormData((prevData) => ({
      ...prevData,
      [event.target.name]: { value: event.target.value as string },
    }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevData) => ({
      ...prevData,
      [event.target.name]: { value: event.target.checked as boolean },
    }));
  };

  const handleFieldChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const currentInput = event.target.value;
      let validationRegex = /^[a-zA-Z\s]+$/;
      if (
        field === 'firstName' ||
        field === 'lastName' ||
        field === 'affiliatedName' ||
        field === 'companyName' ||
        field === 'comments'
      ) {
        validationRegex = /^(?!\s+$)[a-zA-Z\s.]*$/;
      } else if (field === 'city') {
        validationRegex =
          /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
      } else if (field === 'phone') {
        validationRegex = /^\d+$/;
      } else if (field === 'email') {
        validationRegex = /^.+?$/;
      }

      if (!validationRegex.test(currentInput)) {
        setFormData((prevData) => ({
          ...prevData,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [field]: {
            ...[field],
            value: currentInput,
          },
        }));
      }
    };

  const handleFieldErrorChange = (field: string, value: boolean) => {
    setFormData((prevData) => ({
      ...prevData,
      [field as keyof typeof prevData]: {
        // @ts-ignore
        ...prevData[field],
        error: value,
      },
    }));
  };

  const handleFormSubmit = async () => {
    const requiredFields = [
      'affiliatedName',
      'companyName',
      'stateCode',
      'firstName',
      'lastName',
      'city',
      'email',
      'phone',
      'contactTime',
    ];

    let errorsFound = false;
    requiredFields.forEach((field) => {
      // @ts-ignore
      if (!formData[field].value) {
        handleFieldErrorChange(field, true);
        if (!errorsFound) {
          errorsFound = true;
        }
      } else {
        handleFieldErrorChange(field, false);
      }
    });

    if (
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        formData.email.value
      )
    ) {
      handleFieldErrorChange('email', true);
      errorsFound = true;
    }

    if (!errorsFound) {
      const mshTest = queryParameters.get("msh-test");
      let recipient = 'HomebuilderPartnerSubmit@mysafehaven.com';
      if (mshTest) {
        recipient = 'codey.bower@mysafehaven.com';
      }
      const subject = `New Homebuilder Partnership Information Request From ${formData.firstName.value
        } ${' '} ${formData.lastName.value}`;
      const content = `
        <style>
          table {
            border-collapse: collapse;
            width: 70%; 
          }
          th, td {
            padding: 8px;
            border: 1px solid #ddd;
          }
          th {
            text-align: left;
          }
        </style>
        <table>
          <tr style="background-color: #f2f2f2;">
            <th>Submission Page</th>
            <td><a href="https://mysafehaven.com/partners/homebuilder-partners/">Home Builder Partners</a></td>
          </tr>
          <tr>
            <th>Working with Builder Partner?</th>
            <td>${formData.isBuilderPartner.value}</td>
          </tr>
          <tr style="background-color: #f2f2f2;">
              <th>Builder Partner</th>
              <td>${formData.isBuilderPartner.value
          ? `${formData.tellUs.value}`
          : `N/A`
        }</td>
            </tr>
          <tr>
            <th>Company Name</th>
            <td>${formData.companyName.value}</td>
          </tr>
          <tr style="background-color: #f2f2f2;">
            <th>Affiliated Real Estate Office</th>
            <td>${formData.affiliatedName.value}</td>
          </tr>
          <tr>
            <th>Name</th>
            <td>${formData.firstName.value} ${formData.lastName.value}</td>
          </tr>
          <tr style="background-color: #f2f2f2;">
            <th>City</th>
            <td>${formData.city.value}</td>
          </tr>
          <tr>
            <th>State</th>
            <td>${formData.stateCode.value}</td>
          </tr>
          <tr style="background-color: #f2f2f2;">
            <th>Email</th>
            <td>${formData.email.value}</td>
          </tr>
          <tr>
            <th>Phone Number</th>
            <td>${formData.phone.value}</td>
          </tr>
          <tr style="background-color: #f2f2f2;">
            <th>Best Contact Time</th>
            <td>${formData.contactTime.value}</td>
          </tr>
          </table>
      `;

      const emailBody = {
        to: recipient,
        subject: subject,
        content: content,
      };

      const postResponse = await post(`/Email/SendEmail`, emailBody).then(
        (res: any) => {
          if (res) {
            setConfirmationOpen(true);
          } else {
            // Handle email sending failure here
          }
        }
      ).catch((error: any) => {
        // Handle any errors during the email send process
      });
    }
  };

  let apiKey = process.env.REACT_APP_API__ACCESS_KEY
    ? process.env.REACT_APP_API__ACCESS_KEY
    : '';

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Connection: 'keep-alive',
      'Accept-Encoding': 'gzip, deflate, br',
      'X-API-Key': apiKey,
    },
  };

  const { post, response, loading, error } = useFetch(
    process.env.REACT_APP_API,
    options
  );

  return (
    <>
      <Box
        sx={{
          '& .MuiTextField-root': { m: 1 },
          '& .MuiTextarea-root': { m: 1 },
          '& .quarter-width': { width: { xs: 'calc(50% - 16px)', sm: 'calc(25% - 16px)' } },
          '& .half-width': { width: { xs: 'calc(100% - 16px)', sm: 'calc(50% - 16px)' } },
          '& .full-width': { width: 'calc(100% - 16px)' },
        }}
      >
        <TextField
          required
          id='outlined-required'
          label='Affiliated Real Estate Office'
          className='full-width'
          onChange={handleFieldChange('affiliatedName')}
          value={queryParameters.get("msh-test") ? "Test Office" : formData.affiliatedName.value}
          error={formData.affiliatedName.error}
        />

        <FormControlLabel
          className={
            formData.isBuilderPartner.value ? 'half-width' : 'half-width'
          }
          name='isBuilderPartner'
          control={
            <Checkbox
              checked={formData.isBuilderPartner.value}
              onChange={handleCheckboxChange}
            />
          }
          label='Are you with one of our Builder Partners?'
          sx={{ margin: '8px' }}
        />

        {formData.isBuilderPartner.value && (
          <TextField
            required
            id='outlined-required'
            label='Please Tell Us Who'
            className='half-width'
            onChange={handleFieldChange('tellUs')}
            value={formData.tellUs.value}
            error={formData.tellUs.error}
          />
        )}

        <TextField
          required
          id='outlined-required'
          label='Name Of Company'
          className='half-width'
          onChange={handleFieldChange('companyName')}
          value={queryParameters.get("msh-test") ? "Test Name of Company" : formData.companyName.value}
          error={formData.companyName.error}
        />

        <TextField
          required
          id='outlined-required'
          label='First Name'
          className='half-width'
          onChange={handleFieldChange('firstName')}
          value={queryParameters.get("msh-test") ? "TestF" : formData.firstName.value}
          error={formData.firstName.error}
        />

        <TextField
          required
          id='outlined-required'
          label='Last Name'
          className='half-width'
          onChange={handleFieldChange('lastName')}
          value={queryParameters.get("msh-test") ? "TestL" : formData.lastName.value}
          error={formData.lastName.error}
        />

        <TextField
          id='outlined-helperText'
          label='City'
          className='quarter-width'
          onChange={handleFieldChange('city')}
          value={queryParameters.get("msh-test") ? "KC" : formData.city.value}
          error={formData.city.error}
        />


        <FormControl className='quarter-width' sx={{ margin: '8px' }}>
          <Autocomplete
            disablePortal
            value={queryParameters.get("msh-test") ? "Missouri" : formData.stateCode.value}
            onChange={(event: any, newValue: string | null) => {
              setFormData((prevData) => ({
                ...prevData,
                stateCode: { ...formData.stateCode, value: newValue ? newValue : '' },
              }));
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            options={STATE_LIST}
            renderInput={(params) => <TextField error={formData.stateCode.error} {...params} sx={{ margin: '0!important' }} label="State" />}
          />
        </FormControl>

        <TextField
          id='outlined-helperText'
          label='Email'
          className='half-width'
          onChange={handleFieldChange('email')}
          value={queryParameters.get("msh-test") ? "test.email@gmail.com" : formData.email.value}
          error={formData.email.error}
        />

        <TextField
          id='outlined-helperText'
          label='Phone'
          className='half-width'
          onChange={handleFieldChange('phone')}
          value={queryParameters.get("msh-test") ? "7897897897" : formData.phone.value}
          error={formData.phone.error}
        />

        <FormControl className='half-width' sx={{ margin: '8px' }}>
          <InputLabel>Best time to reach you: </InputLabel>
          <Select
            name='contactTime'
            label='State'
            value={queryParameters.get("msh-test") ? "Morning" : formData.contactTime.value}
            error={formData.contactTime.error}
            onChange={handleSelectChange}
          >
            <MenuItem value='Morning'>Morning</MenuItem>
            <MenuItem value='Noon'>Noon</MenuItem>
            <MenuItem value='Evening'>Evening</MenuItem>
          </Select>
        </FormControl>

        <Box sx={{ padding: '2rem', display: 'flex', justifyContent: 'center' }}>
          <LoadingButton
            variant='contained'
            className='contact-form-submit'
            onClick={() => {
              handleFormSubmit();
            }}
            loading={loading}
            sx={{
              minWidth: '100px',
              fontSize: '16px',
              textTransform: 'none',
              fontWeight: '700',
            }}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
}
