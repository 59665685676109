import { useState } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, Link } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Container } from '@mui/system';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import "./index.scss";
import useFetch from 'use-http';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';

interface GrayFormBannerExtendedProps {
    partnerInfo: PartnerInfo
}

export default function GrayFormBannerExtended({
    partnerInfo,
}: GrayFormBannerExtendedProps) {
    const navigate = useNavigate();
    const handleNavigate = (route: string) => navigate(`${route}`);
    const [leadCreationStatus, setLeadCreationStatus] = useState<boolean | null>(null);
    const [submitBtnText, setSubmitBtnText] = useState<string>('GET A FREE QUOTE');

    let apiKey = process.env.REACT_APP_API__ACCESS_KEY
        ? process.env.REACT_APP_API__ACCESS_KEY
        : '';
    const options = {
        headers: {
            'Content-Type': 'application/json',
            Connection: 'keep-alive',
            'Accept-Encoding': 'gzip, deflate, br',
            'X-API-Key': apiKey,
        },
    };
    const { post, response, loading, error } = useFetch(
        process.env.REACT_APP_API,
        options
    );

    const initialValues = {
        firstName: '',
        callbackDate: '',
        zip: '',
        phone: '',
        email: '',
        agentName: '',
        campaign: '',
        additionalInfo: '',
        language: 'english',
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required('Required')
            .min(2, 'First name must be at least 2 characters')
            .max(50, 'First name must be at most 50 characters')
            .matches(/^[a-zA-Z '-]+$/, 'First name can only contain letters, hyphens, apostrophes, and spaces'),
        callbackDate: Yup.string().required('Required'),
        Zip: Yup.string().matches(/^\d{5}(-\d{4})?$/, 'Invalid ZIP code format').required('Required'),
        phone: Yup.string().required('Required')
            .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
        agentName: Yup.string().required('Required'),
        campaign: Yup.string().required('Required'),
        additionalInfo: Yup.string(),
        language: Yup.string().required('Required'),
    });

    const openInNewTab = (url: string) => {
        window.open(url, "_blank", "noreferrer");
    };

    return (
        <Box sx={{ backgroundColor: 'rgb(237, 237, 237)' }} className='gray-form-banner-extended'>
            <Container maxWidth={'md'} sx={{ marginTop: '16px', padding: '64px 0 28px 0' }}>
                <Typography sx={{ paddingBottom: '32px', font: 'normal 500 24px/34px "Montserrat",Helvetica,Arial,Verdana,sans-serif', color: '#545859', maxWidth: '650px', textAlign: 'center', margin: 'auto' }}>
                    Fill out the form below and a Safe Haven Security Specialist will be reaching out shortly.
                </Typography>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        try {
                            const submissionData = {
                                ...values,
                                partnerCompanyId: partnerInfo.PartnerId,
                                partnerCampaignId: values.campaign,
                            };

                            const postResponse: ApiResponseModel = await post("/Partner/CreateZohoLeadByPartnerId", submissionData);

                            if (postResponse.code === 'OK') {
                                const parsedContent = JSON.parse(postResponse.content);

                                const newLeadId = parsedContent.details.output;
                                const userMessage = JSON.parse(parsedContent.details.userMessage[0]);

                                setLeadCreationStatus(true);

                                console.log("New ID: " + newLeadId);

                                navigate('/partners/confirmation', { state: { newLeadId, partnerInfo } });
                            } else {
                                console.log("Response code not OK: " + postResponse.code);
                                setLeadCreationStatus(false);
                            }

                            console.log("Request sent successfully:", postResponse);
                            resetForm();
                        } catch (error) {
                            console.error("Error sending request:", error);
                        } finally {
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ errors, touched, values, setFieldValue, isSubmitting }) => (
                        <Form>
                            <Box className='fields-container perfect-vision'>
                                <Field
                                    as={TextField}
                                    required
                                    className='half-width left-field nra-form-field freedom-mortgage-field'
                                    name="firstName"
                                    label="First Name"
                                    error={touched.firstName && Boolean(errors.firstName)}
                                    helperText={touched.firstName && errors.firstName}
                                />

                                <Field
                                    as={TextField}
                                    required
                                    className='half-width right-field nra-form-field freedom-mortgage-field'
                                    name="callbackDate"
                                    label="Best Callback Day/Time"
                                    error={touched.callbackDate && Boolean(errors.callbackDate)}
                                    helperText={touched.callbackDate && errors.callbackDate}
                                />

                                <Field
                                    as={TextField}
                                    required
                                    className='half-width left-field nra-form-field freedom-mortgage-field'
                                    name="email"
                                    label="Email"
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                />

                                <Field
                                    as={TextField}
                                    required
                                    className='half-width right-field nra-form-field freedom-mortgage-field'
                                    name="agentName"
                                    label="Agent Name"
                                    error={touched.agentName && Boolean(errors.agentName)}
                                    helperText={touched.agentName && errors.agentName}
                                />

                                <Field
                                    as={TextField}
                                    required
                                    className='half-width left-field nra-form-field freedom-mortgage-field'
                                    name="zip"
                                    label="Zip Code"
                                    error={touched.zip && Boolean(errors.zip)}
                                    helperText={touched.zip && errors.zip}
                                />

                                <FormControl className="half-width freedom-mortgage-field nra-form-field gray-form-ext-select" sx={{ marginLeft: { sm: '5px' } }}>
                                    <InputLabel>Select Campaign</InputLabel>
                                    <Field
                                        as={Select}
                                        name="package"
                                        label="Package Selection"
                                        value={values.campaign}
                                        className='pv-package-select'
                                        sx={{
                                            '& svg': {
                                                zIndex: '1'
                                            }
                                        }}
                                        onChange={(event: { target: { value: any; }; }) => setFieldValue("campaign", event.target.value)}
                                        error={touched.campaign && Boolean(errors.campaign)}
                                        helpertext={touched.campaign && errors.campaign}
                                        IconComponent={ArrowDropDownIcon}
                                    >
                                        <MenuItem value="3070">America Home Protect</MenuItem>
                                        <MenuItem value="3085">Porch App</MenuItem>
                                        <MenuItem value="3058">BIT</MenuItem>
                                        <MenuItem value="3062">Evergreen</MenuItem>
                                        <MenuItem value="3075">First American</MenuItem>
                                        <MenuItem value="3080">Floify</MenuItem>
                                        <MenuItem value="3055">Utilities</MenuItem>
                                        <MenuItem value="3066">Your Full Concierge</MenuItem>
                                    </Field>
                                    <FormHelperText sx={{ color: '#d32f2f' }}>{touched.campaign && errors.campaign}</FormHelperText>
                                </FormControl>

                                <Field
                                    as={TextField}
                                    required
                                    className='half-width left-field nra-form-field freedom-mortgage-field'
                                    name="phone"
                                    label="Phone Number"
                                    onChange={(event: { target: { value: any; }; }) => {
                                        const inputValue = event.target.value.replace(/\D/g, '');

                                        let formattedValue = '';
                                        if (inputValue.length > 0) {
                                            formattedValue = '(' + inputValue.substring(0, 3);
                                            if (inputValue.length > 3) {
                                                formattedValue += ') ' + inputValue.substring(3, 6);
                                            }
                                            if (inputValue.length > 6) {
                                                formattedValue += '-' + inputValue.substring(6, 10);
                                            }
                                        }

                                        setFieldValue('phone', formattedValue);
                                    }}
                                    onPaste={(event: { clipboardData: any; preventDefault: () => void; }) => {
                                        const clipboardData = event.clipboardData;
                                        const pastedData = clipboardData.getData('text');
                                        const cleanedData = pastedData.replace(/\D/g, '');
                                        const formattedValue = cleanedData.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                                        setFieldValue('phone', formattedValue);
                                        event.preventDefault();
                                    }}
                                    error={touched.phone && Boolean(errors.phone)}
                                    helperText={touched.phone && errors.phone}
                                />

                                <FormControl
                                    className='half-width right-field'
                                    error={touched.language && Boolean(errors.language)}
                                >
                                    <Field
                                        name="clientType"
                                        as={RadioGroup}
                                        sx={{ marginLeft: '8px' }}
                                        value={values.language}
                                        onChange={(event: { target: { value: any; }; }) => setFieldValue("language", event.target.value)}
                                    >
                                        <>
                                            <FormLabel>Primary Language: </FormLabel>
                                            <FormControlLabel
                                                value="english"
                                                sx={{ "& .MuiFormControlLabel-label": { font: 'normal 15px/27px "Montserrat",Helvetica,Arial,Verdana,sans-serif', color: '#545859' }, }}
                                                control={
                                                    <Radio sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 20,
                                                        },
                                                    }} />
                                                }
                                                label="English"
                                            />
                                            <FormControlLabel
                                                value="Spanish"
                                                sx={{ "& .MuiFormControlLabel-label": { font: 'normal 15px/27px "Montserrat",Helvetica,Arial,Verdana,sans-serif', color: '#545859' }, }}
                                                control={
                                                    <Radio sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 20,
                                                        },
                                                    }} />
                                                }
                                                label="spanish"
                                            />
                                            <FormControlLabel
                                                value="other"
                                                sx={{ "& .MuiFormControlLabel-label": { font: 'normal 15px/27px "Montserrat",Helvetica,Arial,Verdana,sans-serif', color: '#545859' }, }}
                                                control={
                                                    <Radio sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 20,
                                                        },
                                                    }} />
                                                }
                                                label="Other"
                                            />
                                        </>
                                    </Field>
                                    <FormHelperText>{touched.language && errors.language}</FormHelperText>
                                </FormControl>

                                <Field
                                    as={TextField}
                                    className='half-width left-field freedom-mortgage-field gfb-ext'
                                    name="additionalInfo"
                                    multiline
                                    rows={4}
                                    label="Place additional info here."
                                    error={touched.additionalInfo && Boolean(errors.additionalInfo)}
                                    helperText={touched.additionalInfo && errors.additionalInfo}
                                />

                                <Box sx={{ textAlign: "center", width: "100%" }}>
                                    <Typography sx={{
                                        textAlign: 'center',
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        maxWidth: '950px',
                                        margin: '16px auto auto auto',
                                    }}>
                                        By clicking ‘{submitBtnText},’ I consent to being contacted by Safe Haven Security Services, LLC, Safe Haven Solar, LLC, or All Access Service, LLC regarding products and services via live, automated, or prerecorded telephone calls, text messages*, or emails, even if I’m on a Do Not Call list. Consent is not a condition of purchase. I can revoke this consent by emailing dnc@mysafehaven.com.
                                    </Typography>
                                    <LoadingButton
                                        className="blue-rounded-btn"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                        loadingIndicator={<CircularProgress className='loading-button-indicator' size={24} />}
                                        disabled={isSubmitting}
                                        sx={{
                                            fontWeight: 700,
                                            minHeight: "50px!important",
                                            marginTop: "16px!important",
                                        }}
                                    >
                                        {isSubmitting ? 'Submitting...' : 'GET A FREE QUOTE'}
                                    </LoadingButton>
                                    <Typography sx={{
                                        textAlign: 'center',
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        maxWidth: '950px',
                                        margin: '16px auto auto auto',
                                    }}>
                                        Standard message and data rates may apply to text messages. You may opt out of receiving further text messages at any time by replying STOP.
                                        <br></br>
                                        † Your information is collected and used in accordance with Safe Haven’s {" "}
                                        <Link
                                            sx={{ textDecoration: "underline" }}
                                            href="https://mysafehaven.com/legal/privacy"
                                            onMouseDown={(event) => {
                                                if (event.button === 1) {
                                                    openInNewTab(`https://mysafehaven.com/legal/privacy`);
                                                }
                                            }}>
                                            Privacy Policy
                                        </Link>.
                                    </Typography>
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Container>
        </Box>
    );
}
