import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import team from '../../Assets/AboutUs/team.jpg';
import bannerImage from '../../../Assets/AboutUs/banner-image.jpg';
import customerService from '../../../Assets/AboutUs/iconmonstr-customer.png';
import installation from '../../../Assets/AboutUs/iconmonstr-delivery.png';
import equipment from '../../../Assets/AboutUs/iconmonstr-wrench.png';
import leadership1 from '../../../Assets/AboutUs/leadership1.jpg';
import family2 from '../../../Assets/Global/family2.jpg';
import familyHouse from '../../../Assets/AboutUs/family-house.jpg';
import { useDispatch } from 'react-redux';
import RoundedCarousel from './roundedCarousel';
import family from '../../../Assets/AboutUs/family.jpg';
import home1 from '../../../Assets/AboutUs/home1.jpg';
import mobile from '../../../Assets/AboutUs/mobile.jpg';

export default function AboutUs() {
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  return (
    <>
      <Box
        sx={{
          // backgroundColor: 'rgb(234, 214, 232)',
          backgroundImage: `url("${bannerImage}")`,
          backgroundSize: 'cover',
          backgroundColor: 'rgb(0 0 30 / 56%);',
          backgroundBlendMode: 'hue',
          backgroundPosition: '0px 55%',
          height: '500px',
          minHeight: '500px',
        }}
      >
        <Container
          maxWidth={'xl'}
          sx={{
            height: '100%',
          }}
        >
          <Grid container columns={10} sx={{ height: '100%' }}>
            <Grid item xs={0} md={1}></Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                height: '100%',
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignSelf: 'center',
                textAlign: 'center',
              }}
            >
              <Typography
                variant='h1'
                sx={{
                  fontWeight: '600',
                  margin: '0 0 24px',
                  lineHeight: 'calc(1em + 8px)',
                  fontFamily: 'Sharp Sans, Arial, sans-serif',
                  fontSize: { xs: '64px', md: '72px' },
                }}
              >
                We Are Safe Haven
              </Typography>
              <Typography
                sx={{
                  fontSize: '36px',
                  fontWeight: '300',
                  margin: '0 0 24px',
                  lineHeight: 'calc(1em + 8px)',
                  fontFamily: 'Sharp Sans, Arial, sans-serif',
                }}
              >
                The Nation’s #1 ADT-Authorized Dealer
              </Typography>
            </Grid>
            <Grid item xs={0} md={1} sx={{ paddingRight: '8%' }}></Grid>
          </Grid>
        </Container>
      </Box>
      <Box>
        <Container
          maxWidth={false}
          sx={{ maxWidth: '2000px', marginTop: '120px', marginBottom: '120px' }}
        >
          <Box sx={{ paddingBottom: '28px' }}>
            <Typography variant='h3'>At a Glance</Typography>
          </Box>

          <Box>
            <Grid
              container
              columnSpacing={{ xs: 0, md: 10 }}
              rowSpacing={{ xs: 5, md: 0 }}
              columns={12}
              sx={{ marginTop: '0px!important' }}
            >
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  paddingBottom: { xs: '36px', md: 'initial' },
                  borderRight: { md: '1px solid rgba(0, 0, 0, 0.12)' },
                  borderTop: {
                    xs: '1px solid rgba(0, 0, 0, 0.12)',
                    md: 'none',
                  },
                }}
              >
                <Typography
                  variant='h1'
                  sx={{
                    fontSize: { xs: '4rem', md: '6rem' },
                    letterSpacing: '0px',
                    lineHeight: 0.85,
                    fontWeight: 700,
                  }}
                >
                  17
                </Typography>
                <Typography sx={{ marginTop: '16px' }}>
                  Years of business & growth, since the Safe Haven's
                  establishing in XXXX
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  paddingBottom: { xs: '36px', md: 'initial' },
                  borderRight: { md: '1px solid rgba(0, 0, 0, 0.12)' },
                  borderTop: {
                    xs: '1px solid rgba(0, 0, 0, 0.12)',
                    md: 'none',
                  },
                }}
              >
                <Typography
                  variant='h1'
                  sx={{
                    fontSize: { xs: '4rem', md: '6rem' },
                    letterSpacing: '0px',
                    lineHeight: 0.85,
                    fontWeight: 700,
                  }}
                >
                  100,000+
                </Typography>
                <Typography sx={{ marginTop: '16px' }}>
                  Satisfied and protected customers around the nation
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  borderTop: {
                    xs: '1px solid rgba(0, 0, 0, 0.12)',
                    md: 'none',
                  },
                }}
              >
                <Typography
                  variant='h1'
                  sx={{
                    fontSize: { xs: '4rem', md: '6rem' },
                    letterSpacing: '0px',
                    lineHeight: 0.85,
                    fontWeight: 700,
                  }}
                >
                  1,000+
                </Typography>
                <Typography sx={{ marginTop: '16px' }}>
                  Employees across 105 office locations in 43 states
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: 'white',
          paddingTop: '120px',
          paddingBottom: '120px',
        }}
      >
        <Container maxWidth={'xl'} sx={{}}>
          <Grid
            container
            sx={{
              height: { md: '650px' },
              maxHeight: { md: '60vh' },
              flexDirection: { xs: 'column-reverse', md: 'initial' },
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{ height: { xs: '50vh', md: '100%' } }}
            >
              <Typography sx={{ fontSize: '2rem' }}>Our Mission</Typography>

              <Typography
                sx={{
                  maxWidth: '600px',
                  textAlign: 'justify',
                  fontSize: '20px',
                }}
              >
                <br></br>
                We're on a mission to provide peace of mind to homeowners by
                offering the most comprehensive and innovative home security
                solutions on the market. Every person who comes to your home
                meets all ADT standards as well as local and state-level
                licensing and has passed a federal background check. We only use
                our own employees and will never send a subcontractor.
              </Typography>
              <Typography
                sx={{
                  maxWidth: '600px',
                  textAlign: 'justify',
                  fontSize: '20px',
                }}
              >
                <br></br>
                Not only is every component and part we use brand new, but we
                only use factory OEM parts that meet all of ADT’s standards.
                When you’re hired at Safe Haven, you become a part of our
                family. We believe our employees learn the Safe Haven way by
                being trained and promoted from within. We don’t just meet ADT’s
                standard policies. We go above and beyond to make sure our
                clients are happy and that each system is performing properly.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ height: '100%', maxHeight: '100%' }}
            >
              <Box
                component='img'
                sx={{ width: '100%', borderRadius: '10px' }}
                alt='The house from the offer.'
                src={mobile}
              />
            </Grid>
          </Grid>

          <Grid container sx={{ height: '100%', paddingTop: '120px' }}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ height: '100%', maxHeight: '100%' }}
            >
              <Box
                component='img'
                sx={{ width: '100%', borderRadius: '10px' }}
                alt='The house from the offer.'
                src={leadership1}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ height: '100%' }}>
              <Typography
                sx={{
                  maxWidth: '600px',
                  fontSize: '2rem',
                  marginLeft: 'auto',
                  marginRight: 0,
                }}
              >
                Our Story
              </Typography>

              <Typography
                sx={{
                  maxWidth: '600px',
                  textAlign: 'justify',
                  fontSize: '20px',
                  marginLeft: 'auto',
                  marginRight: 0,
                }}
              >
                <br></br>
                Established in 1999, Safe Haven Security is the nation’s largest
                ADT-Authorized Dealer. Our corporate headquarters are in North
                Kansas City, MO; with 105 office locations in 43 states. Along
                with ADT, our key technology partners include Google, Alarm.com,
                and Qolsys. We are also the exclusive smart home integrator for
                D.R. Horton, the nation’s largest homebuilder.
              </Typography>
              <Typography
                sx={{
                  maxWidth: '600px',
                  textAlign: 'justify',
                  fontSize: '20px',
                  marginLeft: 'auto',
                  marginRight: 0,
                }}
              >
                <br></br>
                Not only is every component and part we use brand new, but we
                only use factory OEM parts that meet all of ADT’s standards.
                When you’re hired at Safe Haven, you become a part of our
                family. We believe our employees learn the Safe Haven way by
                being trained and promoted from within. We don’t just meet ADT’s
                standard policies. We go above and beyond to make sure our
                clients are happy and that each system is performing properly.
              </Typography>
            </Grid>
          </Grid>

          <Card
            sx={{
              marginTop: '133.21px',
              backgroundImage: `url("${familyHouse}")`,
              backgroundSize: 'cover',
              backgroundColor: 'rgba(0,0,100,.5)',
              backgroundBlendMode: 'multiply',
              backgroundPosition: { md: '-50% 45%' },
              borderRadius: '10px',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Box
              sx={{
                background: 'linear-gradient(to bottom, #4141419e , #6f6f6f70)',
              }}
            >
              <Typography
                sx={{
                  color: 'white',
                  textAlign: 'center',
                  padding: { xs: '10%', md: '10%' },
                  fontSize: { xs: '24px', md: '40px!important' },
                  lineHeight: '48px!important',
                  width: { md: '60%' },
                  margin: 'auto',
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: '55px', md: '110px' },
                    lineHeight: { xs: '55px', md: '110px' },
                    marginBottom: { xs: '-25px', md: '-50px' },
                    color: 'white',
                    fontStyle: 'italic',
                  }}
                >
                  "
                </Typography>
                We aim to empower customers to protect and connect to what
                matters most – their families, homes, and businesses
              </Typography>
            </Box>
          </Card>
        </Container>
      </Box>

      <Box sx={{ backgroundColor: 'rgb(248, 249, 249)', padding: '2.5vh 0' }}>
        <Container maxWidth={'sm'} sx={{ padding: '64px 0 0px 0' }}>
          <Typography
            sx={{
              fontSize: '32px',
              fontWeight: '800',
              margin: '0 0 0',
              lineHeight: '39px',
              fontFamily: 'Sharp Sans, Arial, sans-serif',
              textAlign: 'center',
            }}
          >
            Dedication to Your Safety
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '600',
              margin: '0 0 0',
              lineHeight: '21px',
              fontFamily: 'Sharp Sans, Arial, sans-serif',
              textAlign: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '600',
                margin: '32px 0 24px',
                lineHeight: '30px',
                fontFamily: 'Sharp Sans, Arial, sans-serif',
                textAlign: 'center',
              }}
            >
              As a customer-backed company, we know you’ve got our back— which
              is why we go above and beyond to deliver inclusive customer
              service and the protection you need to know you’re in full
              control.
            </Typography>
          </Typography>
        </Container>

        <Container maxWidth={'md'}>
          <Grid container>
            <Grid item xs={4} sx={{}}>
              <Stack sx={{ textAlign: 'center' }}>
                <Box
                  component='img'
                  sx={{ width: '55px', margin: 'auto' }}
                  alt='The house from the offer.'
                  src={installation}
                />
                <Typography sx={{ marginTop: '10px' }}>
                  Professional installation
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4} sx={{}}>
              <Stack sx={{ textAlign: 'center' }}>
                <Box
                  component='img'
                  sx={{ width: '55px', margin: 'auto' }}
                  alt='The house from the offer.'
                  src={customerService}
                />
                <Typography sx={{ marginTop: '10px' }}>
                  Professional installation
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4} sx={{}}>
              <Stack sx={{ textAlign: 'center' }}>
                <Box
                  component='img'
                  sx={{ width: '55px', margin: 'auto' }}
                  alt='The house from the offer.'
                  src={equipment}
                />
                <Typography sx={{ marginTop: '10px' }}>
                  Professional installation
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
