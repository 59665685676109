import axios from 'axios';

export const CareersService = {
  getJobs: async (
    city: string,
    team: string,
    offset: number,
    limit: number
  ) => {
    const res = await axios.get(
      `https://jsapi.recruiterbox.com/v1/openings?client_name=mysafehaven&city=${city}&team=${team}&offset=${offset}&limit=${limit}`
    );
    return res.data;
  },
};
