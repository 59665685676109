import Box from "@mui/material/Box";
import { Button, Container, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Footer() {
	const navigate = useNavigate();
	const handleNavigate = (route: string) => navigate(`${route}`);

	useEffect(() => {
		const scriptElement = document.createElement("script");
		scriptElement.src =
			"https://birdeye.com/embed/v4/153357839059541/7/2602589841";
		scriptElement.async = true;
		document.body.appendChild(scriptElement);
	}, []);

	return (
		<>
			<Box
				sx={{
					backgroundColor: "#1260a8",
					color: "#ffffff",
					padding: "20px 0",
				}}
			>
				<Container>
					<Container>
						{/* <Typography
							sx={{ fontSize: "14px", marginBottom: "35px", marginTop: "25px" }}
						>
							*$149.00 Customer Installation Charge. 36-Month Monitoring
							Agreement required at $63.99 per month ($2,303.64). 24-Month
							Agreement required at $63.99 per month for California. Form of
							payment must be by credit card or electronic charge to your
							checking account. Offer applies to homeowners only. Local permit
							fees may be required. Satisfactory credit history required.
							Certain restrictions may apply. Offer valid for new ADT Authorized
							Dealer customers only and not on purchases from ADT LLC. Other
							rate plans available. Cannot be combined with any other offer.
							Photos are illustrative purposes only and may not reflect the
							exact product/service actually provided.
						</Typography> */}
						<Typography sx={{ fontSize: "14px", marginBottom: "35px" }}>
							Dealer License Numbers by State: AL-1493, AR- CMPY.0002629, AZ-
							ROC-332758,18404-0, CA-ACO8016, CT-HIC.0661825, DC-602521000002,
							DE-17-39, FL-EF20000905, GA-LVU406182, IA-AS-0158, C115438,
							IL-127.001386, LA-F1672, MA-8150 A1, SS-002127, MD-107-1696,
							MI-3601208054, MN-TS710056, MS-15019531, NC-1150-CSA,
							SP.FA/LV.28805, NH-064, NJ- 34BX00006600, NM-403946, NV-0079533,
							NY-12000077734, OK-AC1802, OR-234026, PA-PA040539, RI-4604,
							TSC8204, SC-BAC.13574, TN-2280, TX-B14839,ACR-2625584,
							UT-10999117-6501, VA-11-6402, WA-SAFEHHS791CH, WV-WV060562
						</Typography>
					</Container>
				</Container>
			</Box>
		</>
	);
}
