import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupsIcon from '@mui/icons-material/Groups';
import React, { useState } from 'react';
import AutoNumberCounter from './AutoNumberCounter';
import CountUp from 'react-countup';
import './index.scss';

export default function AccoladesRibbon() {
  const [showCounter, setShowCounter] = useState(true);


  return (
    <>
      <Box
        sx={{
          backgroundColor: '#1260a8',
          display: 'inline-table',
          width: '100%',
        }}
      >
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            padding: '60px 0',
          }}
        >
          <Stack
            sx={{ alignItems: 'center', marginBottom: { xs: '45px', lg: '0' } }}
          >
            <Box className='aio-icon-partners'>
              <AccessTimeIcon
                sx={{ height: '1.5em', width: '1.5em' }}
              ></AccessTimeIcon>
            </Box>

            <CountUp
              className='count-up'
              start={0}
              end={25}
              duration={2.75}
              suffix="<span class='count-up-suffix'>+</span>"
              separator=","
            />

            <Typography
              sx={{ fontSize: '64px', color: '#98b0d2' }}
            ></Typography>
            <Typography
              sx={{ fontSize: '22px', color: '#ffffff', fontWeight: '700' }}
            >
              YEARS IN BUSINESS
            </Typography>
          </Stack>
          <Stack
            sx={{ alignItems: 'center', marginBottom: { xs: '45px', lg: '0' } }}
          >
            <Box className='aio-icon-partners'>
              <ApartmentIcon
                sx={{ height: '1.5em', width: '1.5em' }}
              ></ApartmentIcon>
            </Box>

            <CountUp
              className='count-up'
              start={0}
              end={43}
              duration={2.75}
            />

            <Typography
              sx={{ fontSize: '22px', color: '#ffffff', fontWeight: '700' }}
            >
              STATE AREAS SERVED
            </Typography>
          </Stack>
          <Stack
            sx={{ alignItems: 'center', marginBottom: { xs: '45px', lg: '0' } }}
          >
            <Box className='aio-icon-partners'>
              <GroupsIcon sx={{ height: '1.5em', width: '1.5em' }}></GroupsIcon>
            </Box>

            <CountUp
              className='count-up'
              start={0}
              end={1000000}
              duration={2.75}
              separator=","
            />

            <Typography
              sx={{
                fontSize: '22px',
                color: '#ffffff',
                fontWeight: '700',
                padding: '0 30px',
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              SATISFIED CLIENTS AND GROWING
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
