import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import image1 from '../../Assets/Home/Website-Main-page-pic.jpg';
import image2 from '../../Assets/Home/Tech-and-Customer-01.jpg';
import phone from '../../Assets/Home/phone.jpg';
import voiceIcon from '../../Assets/Home/voice-1-colored.png';
import garageIcon from '../../Assets/Home/garage-colored.png';
import lockIcon from '../../Assets/Home/lock-colored.png';
import climateIcon from '../../Assets/Home/climate-colored.png';
import lightsIcon from '../../Assets/Home/Lights-colored.png';
import videoIcon from '../../Assets/Home/video-colored.png';
import armIcon from '../../Assets/Home/arm-colored.png';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';
import testimonialBg from '../../Assets/Partner/mark-team-002.jpg';
import IconList from './IconList';
import './index.scss';
import { Helmet } from 'react-helmet';

export default function Home() {
  const navigate = useNavigate();
  const handleNavigate = (route: string) => navigate(`${route}`);

  return (
    <>
      <Helmet>
        <title>Home | Safe Haven Security</title>
        <meta name="description" content="Protect your home and family with a reliable security system from Safe Haven Security. Explore our comprehensive solutions and find the perfect fit for your needs." />
        <meta name="keywords" content="home security system, security system installation, home security monitoring, security cameras, alarm systems, Safe Haven Security" />
      </Helmet>
      <Box
        sx={{
          padding: '25px 0 55px 0',
          display: 'flex',
          backgroundColor: '#ffffff',
        }}
      >
        <Box
          component='img'
          sx={{
            width: 'auto',
            maxWidth: '100%',
            maxHeight: '75vh',
            margin: 'auto',
          }}
          alt='The house from the offer.'
          src={image1}
        />
      </Box>

      <Box>
        <IconList></IconList>
      </Box>

      <Box sx={{ backgroundColor: '#ffffff' }}>
        <Grid
          container
          spacing={2}
          maxWidth={'xl'}
          sx={{
            padding: 'calc(70px - 16px) 0',
            margin: 'auto',
          }}
        >
          <Grid item xs={12} md={6}>
            <Typography
              sx={{ color: '#1260a8', fontWeight: '700', fontSize: '3rem' }}
            >
              SAFE HAVEN STRONG
            </Typography>
            <Typography sx={{ fontSize: '2rem', marginBottom: '45px' }}>
              JOIN OUR TEAM!
            </Typography>
            <Typography
              sx={{
                fontSize: '1.4rem',
                fontWeight: '700',
                marginBottom: '10px',
              }}
            >
              At Safe Haven Security, we don’t care about who you’ve been. We
              care about who you want to become.
            </Typography>
            <Typography sx={{ fontSize: '1.4rem', marginBottom: '55px' }}>
              “Safe Haven Strong” means that we are strengthened by our culture,
              strengthened by each other and our achievements, and strengthened
              by knowing that each day, we’ve made somebody’s life safer.
            </Typography>
            <Button
              variant={'contained'}
              className='home-learn-more-button'
              endIcon={
                <NavigateNextIcon sx={{ height: '40px', width: '40px' }} />
              }
              onClick={() => {
                {
                  handleNavigate('/careers');
                }
              }}
              sx={{
                fontWeight: '700',
                font: "normal normal 700 18px 'PT Sans', Helvetica, Arial, Verdana, sans-serif",
              }}
            >
              Learn More
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              padding: { xs: '55px 15px 0 15px!important', md: '0!important' },
            }}
          >
            <Box
              component='img'
              sx={{
                width: 'calc(100% - 15px)',
                maxWidth: '100%',
              }}
              alt='Our Team Photo'
              src={image2}
            />
          </Grid>
        </Grid>
      </Box>

      <Container maxWidth={'xl'}>
        <Box sx={{ padding: '70px 0' }}>
          <Typography
            sx={{ color: '#1260a8', fontWeight: '700', fontSize: '3rem' }}
          >
            TESTIMONIALS
          </Typography>
          <Typography sx={{ fontSize: '2rem' }}>
            WHAT PEOPLE ARE SAYING ABOUT US
          </Typography>

          <Box sx={{ padding: '70px 0 0 0' }}>
            <Grid
              container
              columnSpacing={{ xs: 0, lg: 3 }}
              rowSpacing={3}
              sx={{
                padding: 'calc(70px - 16px) 0',
                margin: 'auto',
              }}
            >
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    height: '500px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center', color: '#ffffff',
                    backgroundImage: `url(${testimonialBg})`,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      backgroundColor: 'rgba(18,96,168,0.93)',
                      padding: '0 20px',
                      height: '100%',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '22px',
                        marginBottom: '20px',
                        height: '330px',
                        maxHeight: '330px',
                        overflowY: 'auto',
                      }}
                    >
                      We needed someone to come to our home for an immediate
                      installation of a system due to a break in at our home
                      recently. I called Heather after 7:00 pm and she answered
                      her phone and immediately told me she could have an
                      installer at my home the next day. The installer arrived at
                      my home about 12:30 and had me up and secured in about 3
                      hours. My home is now protected and I have a very impressive
                      system that is efficient and very high tech, I can access my
                      system via my iPhone.
                    </Typography>
                    <Typography sx={{ fontSize: '22px', textAlign: 'right' }}>
                      Don Jones
                    </Typography>
                    <Typography sx={{ fontSize: '22px', textAlign: 'right' }}>
                      GOOGLE Review
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    height: '500px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center', color: '#ffffff',
                    backgroundImage: `url(${testimonialBg})`,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      backgroundColor: 'rgba(18,96,168,0.93)',
                      padding: '0 20px',
                      height: '100%',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '22px',
                        marginBottom: '20px',
                        height: '330px',
                        maxHeight: '330px',
                        overflowY: 'auto',
                      }}
                    >
                      Teri Carroll takes such great care of my clients! Her timely
                      communication, professional nature, and great discounts
                      always impress my homeowners. Connecting with ADT as a
                      referral partner helps develop me as a full-service agent,
                      and Teri Carroll never disappoints!!
                    </Typography>
                    <Typography sx={{ fontSize: '22px', textAlign: 'right' }}>
                      Erin Ermon
                    </Typography>
                    <Typography sx={{ fontSize: '22px', textAlign: 'right' }}>
                      Facebook Review
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    height: '500px',
                    color: '#ffffff',
                    backgroundImage: `url(${testimonialBg})`,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      backgroundColor: 'rgba(18,96,168,0.93)',
                      padding: '0 20px',
                      height: '100%',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '22px',
                        marginBottom: '20px',
                        height: '330px',
                        maxHeight: '330px',
                        overflowY: 'auto',
                      }}
                    >
                      I am a State Farm Agent and recently one of my clients was
                      in need of an alarm system, so I referred them to Hilary
                      Umbles. She was amazing! She kept me in the loop through the
                      whole process to make sure the clients were taken care of
                      after discussing several options with them to determine
                      their exact needs. Thanks Hilary!!
                    </Typography>
                    <Typography sx={{ fontSize: '22px', textAlign: 'right' }}>
                      Tracy Seckington
                    </Typography>
                    <Typography sx={{ fontSize: '22px', textAlign: 'right' }}>
                      Facebook Review
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>

      <Box sx={{ backgroundColor: '#ffffff', paddingTop: '70px' }}>
        <Container maxWidth={'xl'} sx={{ backgroundColor: '#ffffff' }}>
          <Box sx={{ marginBottom: '35px' }}>
            <Typography
              sx={{
                color: '#1260a8',
                fontWeight: '700',
                fontSize: '3rem',
                marginBottom: '10px',
              }}
            >
              SOLUTIONS
            </Typography>
            <Typography sx={{ fontSize: '2rem' }}>SECURE YOUR HOME</Typography>
          </Box>

          <Box sx={{ backgroundColor: '#ffffff' }}>
            <Grid
              container
              spacing={0}
              sx={{
                padding: 'calc(70px - 16px) 0',
                margin: 'auto',
              }}
            >
              <Grid item xs={12} md={4}>
                <Box
                  component='img'
                  sx={{
                    width: '100%',
                    maxWidth: '400px',
                    height: 'auto',
                  }}
                  alt='The house from the offer.'
                  src={phone}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <List>
                  <ListItem alignItems='center'>
                    <ListItemAvatar>
                      {' '}
                      <Box
                        component='img'
                        sx={{
                          width: '80px',
                          height: '80px',
                          marginRight: '15px',
                        }}
                        alt='The house from the offer.'
                        src={voiceIcon}
                      />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography
                        sx={{
                          marginBottom: '10px',
                          fontWeight: '700',
                          font: 'normal normal bold 32px / 40px "Open Sans", Helvetica, Arial, Verdana, sans-serif',
                        }}
                      >
                        Voice Control
                      </Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem alignItems='center'>
                    <ListItemAvatar>
                      <Box
                        component='img'
                        sx={{
                          width: '80px',
                          height: '80px',
                          marginRight: '15px',
                        }}
                        alt='The house from the offer.'
                        src={garageIcon}
                      />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography
                        sx={{
                          marginBottom: '10px',
                          fontWeight: '700',
                          font: 'normal normal bold 32px / 40px "Open Sans", Helvetica, Arial, Verdana, sans-serif',
                        }}
                      >
                        Garage Control
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem alignItems='center'>
                    <ListItemAvatar>
                      <Box
                        component='img'
                        sx={{
                          width: '80px',
                          height: '80px',
                          marginRight: '15px',
                        }}
                        alt='The house from the offer.'
                        src={climateIcon}
                      />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography
                        sx={{
                          marginBottom: '10px',
                          fontWeight: '700',
                          font: 'normal normal bold 32px / 40px "Open Sans", Helvetica, Arial, Verdana, sans-serif',
                        }}
                      >
                        Climate Control
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem alignItems='center'>
                    <ListItemAvatar>
                      <Box
                        component='img'
                        sx={{
                          width: '80px',
                          height: '80px',
                          marginRight: '15px',
                        }}
                        alt='The house from the offer.'
                        src={lockIcon}
                      />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography
                        sx={{
                          marginBottom: '10px',
                          fontWeight: '700',
                          font: 'normal normal bold 32px / 40px "Open Sans", Helvetica, Arial, Verdana, sans-serif',
                        }}
                      >
                        Remote Locks
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={4}>
                <List>
                  <ListItem alignItems='center'>
                    <ListItemAvatar>
                      {' '}
                      <Box
                        component='img'
                        sx={{
                          width: '80px',
                          height: '80px',
                          marginRight: '15px',
                        }}
                        alt='The house from the offer.'
                        src={videoIcon}
                      />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography
                        sx={{
                          marginBottom: '10px',
                          fontWeight: '700',
                          font: 'normal normal bold 32px / 40px "Open Sans", Helvetica, Arial, Verdana, sans-serif',
                        }}
                      >
                        Surveillance
                      </Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem alignItems='center'>
                    <ListItemAvatar>
                      <Box
                        component='img'
                        sx={{
                          width: '80px',
                          height: '80px',
                          marginRight: '15px',
                        }}
                        alt='The house from the offer.'
                        src={armIcon}
                      />
                    </ListItemAvatar>{' '}
                    <ListItemText>
                      <Typography
                        sx={{
                          marginBottom: '10px',
                          fontWeight: '700',
                          font: 'normal normal bold 32px / 40px "Open Sans", Helvetica, Arial, Verdana, sans-serif',
                        }}
                      >
                        Remote Arming
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem alignItems='center'>
                    <ListItemAvatar>
                      <Box
                        component='img'
                        sx={{
                          width: '80px',
                          height: '80px',
                          marginRight: '15px',
                        }}
                        alt='The house from the offer.'
                        src={lightsIcon}
                      />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography
                        sx={{
                          marginBottom: '10px',
                          fontWeight: '700',
                          font: 'normal normal bold 32px / 40px "Open Sans", Helvetica, Arial, Verdana, sans-serif',
                        }}
                      >
                        Light Control
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem alignItems='center'>
                    <ListItemAvatar>
                      <Box
                        component='img'
                        sx={{
                          width: '80px',
                          height: '80px',
                          marginRight: '15px',
                        }}
                        alt='The house from the offer.'
                        src={armIcon}
                      />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography
                        sx={{
                          marginBottom: '10px',
                          fontWeight: '700',
                          font: 'normal normal bold 32px / 40px "Open Sans", Helvetica, Arial, Verdana, sans-serif',
                        }}
                      >
                        Remote Monitoring
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={0}
              maxWidth={'xl'}
              sx={{
                padding: 'calc(70px - 16px) 0',
                margin: 'auto',
              }}
            >
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '2rem', marginBottom: '35px' }}>
                  ADT Pulse puts your entire home at your fingertips, even if
                  you’re a thousand miles away.
                </Typography>
                <Button
                  variant={'contained'}
                  className='home-learn-more-button'
                  endIcon={
                    <NavigateNextIcon sx={{ height: '40px', width: '40px' }} />
                  }
                  onClick={() => {
                    {
                      handleNavigate('/customer-solutions');
                    }
                  }}
                  sx={{}}
                >
                  Learn More
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}
