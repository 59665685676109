import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useMediaQuery } from 'react-responsive';
import SwiperCarousel from '../../Components/SwiperCarousel';
import * as Constants from '../../Constants/Pages';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper';

export default function Reviews() {
  const isDesktop = useMediaQuery({ minWidth: 1200 });
  return (
    <>
      {isDesktop && (
        <Container maxWidth='xl' sx={{ padding: '70px 0' }}>
          <Grid
            container
            spacing={6}
            maxWidth={'xl'}
            sx={{
              padding: 'calc(70px - 16px) 0',
              margin: 'auto',
            }}
          >
            <Grid item xs={4}>
              <Box sx={{ display: 'flex' }}>
                <PersonIcon
                  sx={{
                    color: '#4883bb',
                    width: '45px',
                    height: '45px',
                    marginRight: '10px',
                  }}
                ></PersonIcon>
                <Stack sx={{}}>
                  <Typography
                    sx={{
                      color: '#777777',
                      fontSize: '22px',
                      marginBottom: '10px',
                      minHeight: '165px',
                    }}
                  >
                    I love new ADT home security service and as promised it was
                    installed fast and wasn’t too expensive. I feel safe at home
                    now.
                  </Typography>
                  <Typography
                    sx={{
                      color: '#4d4f51',
                      fontSize: '22px',
                      fontWeight: '700',
                    }}
                  >
                    Amanda Jo B.
                  </Typography>
                  <Typography sx={{ color: '#788bc2', fontSize: '22px' }}>
                    Liberty, Mo
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ display: 'flex' }}>
                <PersonIcon
                  sx={{
                    color: '#4883bb',
                    width: '45px',
                    height: '45px',
                    marginRight: '10px',
                  }}
                ></PersonIcon>
                <Stack>
                  <Typography
                    sx={{
                      color: '#777777',
                      fontSize: '22px',
                      marginBottom: '10px',
                      minHeight: '165px',
                    }}
                  >
                    Our family loves our new Pulse security that we can use with
                    our smartphone. Very cool features. Glad we found you guys!
                  </Typography>
                  <Typography
                    sx={{
                      color: '#4d4f51',
                      fontSize: '22px',
                      fontWeight: '700',
                    }}
                  >
                    Karrie B.
                  </Typography>
                  <Typography sx={{ color: '#788bc2', fontSize: '22px' }}>
                    Houston, Tx
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ display: 'flex' }}>
                <PersonIcon
                  sx={{
                    color: '#4883bb',
                    width: '45px',
                    height: '45px',
                    marginRight: '10px',
                  }}
                ></PersonIcon>
                <Stack>
                  <Typography
                    sx={{
                      color: '#777777',
                      fontSize: '22px',
                      marginBottom: '10px',
                      minHeight: '165px',
                    }}
                  >
                    I’ve never felt safer than I do right now! Thanks for
                    following up on your promise!
                  </Typography>
                  <Typography
                    sx={{
                      color: '#4d4f51',
                      fontSize: '22px',
                      fontWeight: '700',
                    }}
                  >
                    Jesse J.
                  </Typography>
                  <Typography sx={{ color: '#788bc2', fontSize: '22px' }}>
                    Cleveland, Oh
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
      {!isDesktop && (
        <Box sx={{ background: '#eee' }}>
          <Swiper
            cssMode={true}
            navigation={true}
            pagination={true}
            mousewheel={true}
            keyboard={true}
            loop={true}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            className='cust-solutions-Swiper'
          >
            {Constants.EMPLOYEE_REVIEWS.map((slide: any, index: any) => (
              <SwiperSlide
                className={'cust-solutions-Swiper-slide'}
                key={index}
              >
                {slide}
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      )}
    </>
  );
}
