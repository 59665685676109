import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import './index.scss';
import { useLocation } from 'react-router-dom';
import { Typography, Button, Grid, Link } from '@mui/material';
import { Container, Stack } from '@mui/system';
import topBgCropped from "../../../../../Assets/Partner/Paddio/banner-img-1136x666-cropped.jpg";
import CompanyLogo from '../../../../../Components/Header/CompanyLogo';
import PartnerLogo from '../../../../../Components/PartnerLogo';
import { HIDE_PARTNER_LOGO } from '../../Constants';

interface propsWrapper {
    partnerInfo: PartnerInfo;
    variant: string;
    phoneText: string,
    phoneNumber: string;
    quoteButtonText: string;
    buttonColor: string;
    numberLink: boolean;
}

export default function LogoBanner(props: any) {
    const {
        partnerInfo,
        variant = 'default',
        phoneText = "Call us: ",
        phoneNumber = "+1 (877) 664-0673",
        quoteButtonText = "GET A FREE QUOTE",
        buttonColor = '#0061aa!important',
        numberLink = true,
        formRef
    } = props;

    const handleScrollToForm = () => {
        if (formRef.current) {
            const elementTop = formRef.current.getBoundingClientRect().top;
            const offsetPosition =
                elementTop + window.pageYOffset - window.innerHeight * 0.2;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    };

    return (
        <>
            {variant === 'default' &&
                <Grid container className='default-logo-banner' sx={{ maxWidth: '1350px', margin: '35px auto' }}>
                    <Grid item xs={12} sm={6} sx={{
                        paddingLeft: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: { xs: 'center', sm: 'flex-start' },
                    }}>
                        <CompanyLogo></CompanyLogo>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ paddingRight: '16px', display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' }, alignItems: 'center', maxHeight: '80px', marginTop: { xs: '12px', sm: '0' } }}>
                        <PartnerLogo partnerInfo={partnerInfo}></PartnerLogo>
                    </Grid>
                </Grid>
            }


            {variant === 'button' &&
                <>
                    <Grid container className='regular-button' sx={{ maxWidth: '1350px', margin: '35px auto' }}>
                        <Grid item xs={12} sm={6} sx={{
                            paddingLeft: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Box sx={{ position: "relative" }}>
                                <CompanyLogo></CompanyLogo>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ paddingRight: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: { xs: 'column-reverse', lg: 'row' }, marginTop: { xs: '16px', sm: '0' } }}>
                            {numberLink &&
                                <Link className='header-link' sx={{ marginTop: { xs: '0', sm: '16px', md: '0' } }} href={`tel:${phoneNumber}`}>{phoneText} {phoneNumber}</Link>
                            }
                            <Button className='' variant='contained'
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    lineHeight: '20px',
                                    borderRadius: '50px',
                                    padding: '16px 24px',
                                    background: buttonColor,
                                    borderColor: buttonColor,
                                    textTransform: 'none',
                                    transition: 'none',
                                    '&:hover': {
                                        background: 'linear-gradient(135deg, #0061aa 30%, #eaeef5 100%)!important',
                                        borderColor: '#0061aa!important'
                                    },
                                    marginBottom: { xs: '16px', sm: '0' }
                                }}
                                onClick={() => {
                                    handleScrollToForm();
                                }}
                            >{quoteButtonText}</Button>
                        </Grid>
                    </Grid>
                </>
            }

            {variant === 'logoButton' &&
                <>
                    <Grid container className='logo-button' sx={{ maxWidth: '1350px', margin: '35px auto' }}>
                        <Grid item xs={12} sm={4} sx={{
                            paddingLeft: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Box sx={{ position: "relative" }}>
                                <CompanyLogo></CompanyLogo>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{
                            paddingLeft: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: { xs: '32px', sm: '0' },
                            maxHeight: '125px'
                        }}>
                            <PartnerLogo partnerInfo={partnerInfo}></PartnerLogo>
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{ paddingRight: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: { xs: 'column-reverse', lg: 'row' }, marginTop: { xs: '16px', sm: '0' } }}>
                            {numberLink &&
                                <Link className='header-link' sx={{ marginBottom: { xs: '8px', sm: 0 }, marginTop: { xs: '8px', sm: '0' } }} href={`tel:${phoneNumber}`}>{phoneText} {phoneNumber}</Link>
                            }
                            <Button className='' variant='contained'
                                href={`tel:${phoneNumber}`}
                                sx={{
                                    marginTop: { xs: '8px', sm: '0' },
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    lineHeight: '20px',
                                    fontFamilty: '"Roboto","Helvetica","Arial",sans-serif!important',
                                    borderRadius: '50px',
                                    padding: '16px 24px',
                                    background: buttonColor,
                                    borderColor: buttonColor,
                                    textTransform: 'none',
                                    transition: 'none',
                                    '&:hover': {
                                        background: 'linear-gradient(135deg, #0061aa 30%, #eaeef5 100%)!important',
                                        borderColor: '#0061aa!important'
                                    }
                                }}
                            >{quoteButtonText}</Button>
                        </Grid>
                    </Grid>
                </>
            }

            {variant === 'confirmation' &&
                <Grid container className='confirmation-logo-banner' sx={{ maxWidth: '1350px', margin: '35px auto' }}>
                    <Grid item xs={12} sm={6} sx={{
                        paddingLeft: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <CompanyLogo></CompanyLogo>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ paddingRight: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <PartnerLogo partnerInfo={partnerInfo}></PartnerLogo>
                    </Grid>
                </Grid>
            }

        </>
    );
}
