import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Container,
  Grid,
  Pagination,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import SectionOneTechOnPhone from '../../../Assets/Careers/Tech-on-Phone.jpg';
import SectionFiveEmployeeWithSign from '../Assets/Careers/ADT-employee.jpg';
import SectionSixYardSign from '../../../Assets/Careers/ADT-Yard-Sign-3.jpg';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import StarIcon from '@mui/icons-material/Star';
import './index.scss';
import MediaQuery from 'react-responsive';
import { CareersService } from '../../../Helpers/careers';
import SwiperCarousel from '../../../Components/SwiperCarousel';
import { customBreakpoints } from '../../../themeOptions';
import * as Constants from '../../../Constants/Pages';

const SectionOne = () => {
  return (
    <Box
      className='Careers-Section-1'
      sx={{
        width: '100%',
        maxWidth: '100%',
        position: 'relative',
        textAlign: 'center',
      }}
    >
      <MediaQuery query='(max-device-width: 599px)'>
        <Box
          sx={{
            width: '100%',
            maxWidth: '100%',
            textAlign: 'center',
            marginTop: '1rem',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: '1rem',
          }}
        >
          <Typography
            sx={{
              color: '#00549c',
              fontSize: { xxs: '2.25rem', sm: '2.5rem', md: '3rem' },
            }}
          >
            Safe Haven Careers
          </Typography>
          <Typography
            sx={{ fontSize: { xxs: '1rem', sm: '1.25rem', md: '1.5rem' } }}
          >
            We don't care about who you've been. <br></br>
            We care about who you want to become.
          </Typography>
        </Box>
      </MediaQuery>

      <Box
        component='img'
        sx={{
          width: { xxs: '200%', sm: '100%' },
        }}
        alt='The house from the offer.'
        src={SectionOneTechOnPhone}
      />
      <MediaQuery query='(max-device-width: 599px)'>
        <Box
          sx={{
            width: '100%',
            maxWidth: '100%',
            textAlign: 'center',
            marginTop: '1rem',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: '1rem',
          }}
        >
          <Typography
            sx={{ fontSize: { xxs: '1rem', sm: '.75rem', md: '1rem' } }}
          >
            Safe Haven jobs are lifelong careers with great pay, <br></br>
            relocation security, and a warm company culture.
          </Typography>
          <Button
            sx={{
              fontSize: { xxs: '1rem', sm: '.75rem', md: '1rem' },
              textTransform: 'initial',
            }}
          >
            Become a professional sales rep &gt;
          </Button>
          <Button
            sx={{
              fontSize: { xxs: '1rem', sm: '.75rem', md: '1rem' },
              textTransform: 'initial',
              color: '#de3c4b',
            }}
          >
            Become an installation tech &gt;
          </Button>
        </Box>
      </MediaQuery>
      <MediaQuery query='(min-device-width: 600px)'>
        <Box
          sx={{
            width: '50%',
            maxWidth: '50%',
            right: '0',
            top: '0',
            position: 'absolute',
            zIndex: 1,
            textAlign: 'center',
            marginTop: '1rem',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '0 1rem',
          }}
        >
          <Typography
            sx={{
              color: '#00549c',
              fontSize: { xxs: '1.5rem', sm: '2.5rem', md: '3rem' },
            }}
          >
            Safe Haven Careers
          </Typography>
          <Typography
            sx={{ fontSize: { xxs: '.75rem', sm: '1.25rem', md: '1.5rem' } }}
          >
            We don't care about who you've been. <br></br>
            We care about who you want to become.
          </Typography>
          <Typography
            sx={{ fontSize: { xxs: '.5rem', sm: '.75rem', md: '1rem' } }}
          >
            Safe Haven jobs are lifelong careers with great pay, <br></br>
            relocation security, and a warm company culture.
          </Typography>
          <Button
            sx={{
              fontSize: { xxs: '.5rem', sm: '.75rem', md: '1rem' },
              textTransform: 'initial',
            }}
          >
            Become a professional sales rep &gt;
          </Button>
          <Button
            sx={{
              fontSize: { xxs: '.5rem', sm: '.75rem', md: '1rem' },
              textTransform: 'initial',
              color: '#de3c4b',
            }}
          >
            Become an installation tech &gt;
          </Button>
        </Box>
      </MediaQuery>
    </Box>
  );
};

const SectionTwo = () => {
  return (
    <Box
      className='Careers-Section-2'
      sx={{
        width: '100%',
        maxWidth: '100%',
        height: '20%',
        minHeight: '20%',
        bgcolor: '#024d90',

        background:
          'linear-gradient(to bottom right, #024d90 55%, #014a8e 45%);',
      }}
    >
      <Container
        sx={{
          width: '100%',
          maxWidth: '100%',
          textAlign: 'center',
          padding: '2rem',
          color: '#fff',
        }}
      >
        <Typography
          sx={{
            fontSize: { xxs: '2rem', sm: '2.5rem', md: '3rem' },
            paddingBottom: '1rem',
          }}
        >
          Work With Us
        </Typography>
        <Grid
          container
          spacing={0}
          rowSpacing={{ xxs: 2, sm: 0 }}
          sx={{ maxWidth: '950px', margin: 'auto' }}
        >
          <Grid item xxs={2} xs={1}>
            <StarIcon
              sx={{
                color: '#80ced7',
                width: '2rem',
                height: '2rem',
                paddingTop: { xxs: '0', sm: '10px' },
              }}
            />
          </Grid>
          <Grid item xxs={10} xs={5}>
            <Typography
              sx={{
                fontSize: { xxs: '1.5rem', sm: '2.5rem', md: '2rem' },
                width: { xxs: '100%', sm: '80%' },
                textAlign: 'left',
              }}
            >
              Over 130 offices nationwide
            </Typography>
          </Grid>
          <Grid item xxs={2} xs={1}>
            <StarIcon
              sx={{
                color: '#80ced7',
                width: '2rem',
                height: '2rem',
                paddingTop: { xxs: '0', sm: '10px' },
              }}
            />
          </Grid>
          <Grid item xxs={10} xs={5}>
            <Typography
              sx={{
                fontSize: { xxs: '1.5rem', sm: '2.5rem', md: '2rem' },
                width: { xxs: '100%', sm: '80%' },
                textAlign: 'left',
              }}
            >
              401(k) plans & <br></br>paid vacation
            </Typography>
          </Grid>
          <Grid item xxs={2} xs={1}>
            <StarIcon
              sx={{
                color: '#80ced7',
                width: '2rem',
                height: '2rem',
                paddingTop: { xxs: '0', sm: '10px' },
              }}
            />
          </Grid>
          <Grid item xxs={10} xs={5}>
            <Typography
              sx={{
                fontSize: { xxs: '1.5rem', sm: '2.5rem', md: '2rem' },
                width: { xxs: '100%', sm: '80%' },
                textAlign: 'left',
              }}
            >
              Industry best compensation plans
            </Typography>
          </Grid>
          <Grid item xxs={2} xs={1}>
            <StarIcon
              sx={{
                color: '#80ced7',
                width: '2rem',
                height: '2rem',
                paddingTop: { xxs: '0', sm: '10px' },
              }}
            />
          </Grid>
          <Grid item xxs={10} xs={5}>
            <Typography
              sx={{
                fontSize: { xxs: '1.5rem', sm: '2.5rem', md: '2rem' },
                width: { xxs: '100%', sm: '80%' },
                textAlign: 'left',
              }}
            >
              Health, dental & vision benefits
            </Typography>
          </Grid>
        </Grid>

        <Stack sx={{ marginTop: '1rem' }}>
          <Button
            sx={{
              color: '#fff',
              textTransform: 'initial',
              fontSize: { xxs: '1rem', sm: '1.25rem' },
            }}
          >
            Download brochure &gt;
          </Button>
          <Button
            sx={{
              color: '#80ced7',
              textTransform: 'initial',
              fontSize: { xxs: '1rem', sm: '1.25rem' },
            }}
          >
            Apply now &gt;
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};

const SectionThree = () => {
  const [location, setLocation] = React.useState('');
  const [careers, setCareers] = React.useState<any>({ objects: [] });
  const [division, setDivision] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    window.innerWidth < 500 ? 2 : window.innerWidth < 900 ? 6 : 9
  );
  const [pageCount, setPageCount] = React.useState(0);
  const [totalRows, setTotalRows] = React.useState(0);

  useEffect(() => {
    const getCareers = async () => {
      const _careers = await CareersService.getJobs(
        location,
        division,
        page,
        rowsPerPage
      );
      getTotalPageCount(_careers.meta.total, rowsPerPage);
      setCareers(_careers);
    };
    setPage(1);
    getCareers();
  }, []);

  const handleLocationChange = async (event: SelectChangeEvent) => {
    const _careers = await CareersService.getJobs(
      event.target.value,
      division,
      1,
      rowsPerPage
    );
    setPage(1);
    setLocation(event.target.value);
    getTotalPageCount(_careers.meta.total, rowsPerPage);
    setCareers(_careers);
  };

  const handlePageChange = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    const _careers = await CareersService.getJobs(
      location,
      division,
      value,
      rowsPerPage
    );
    getTotalPageCount(_careers.meta.total, rowsPerPage);
    setCareers(_careers);
  };

  function getTotalPageCount(totalRows: any, rowsPerPage: any) {
    const pageCount = Math.ceil(totalRows / rowsPerPage);
    setPageCount(pageCount);
  }

  const handleDivisionChange = async (event: SelectChangeEvent) => {
    const _careers = await CareersService.getJobs(
      location,
      event.target.value,
      1,
      rowsPerPage
    );
    setPage(1);
    getTotalPageCount(_careers.meta.total, rowsPerPage);
    setCareers(JSON.stringify(_careers));

    setDivision(event.target.value);
  };

  return (
    <>
      <Box
        className='Careers-Section-3'
        sx={{
          width: '100%',
          maxWidth: '100%',
          height: '20%',
          minHeight: '20%',
          bgcolor: '#d1d1d3',
          background:
            'linear-gradient(to bottom right, #d8d8d8 55%, #d1d1d3 45%);',
        }}
      >
        <Container
          sx={{
            width: '100%',
            maxWidth: '100%',
            textAlign: 'center',
            padding: '2rem 2rem 4rem 2rem',
          }}
        >
          <Typography
            sx={{
              fontSize: { xxs: '2rem', sm: '2.5rem', md: '3rem' },
            }}
          >
            Job Search
          </Typography>
          <Typography
            sx={{ fontSize: { xxs: '1.25rem', sm: '1.25rem', md: '1.5rem' } }}
          >
            Define your search parameters, then click to apply.
          </Typography>
          <Container maxWidth={false} sx={{ maxWidth: '1000px' }}>
            <Typography
              sx={{
                marginTop: '2.5rem',
                marginBottom: { xxs: '1rem', sm: '0' },
                fontSize: { xxs: '1.5rem', sm: '2.5rem', md: '2rem' },
                textAlign: 'left',
                color: '#00549c',
              }}
            >
              Find jobs near you at Safe Haven:
            </Typography>
            <Grid
              container
              spacing={{ xxs: 0, sm: 2 }}
              rowSpacing={{ xxs: 2 }}
              sx={{ marginTop: '.25rem' }}
            >
              <Grid
                item
                xxs={6}
                sm={4}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'left',
                    fontWeight: '700',
                    fontSize: { xxs: '1.25rem', sm: '1.25rem', md: '1.25rem' },
                  }}
                >
                  SELECT LOCATION:
                </Typography>
              </Grid>
              <Grid item xxs={6} sm={4}>
                <FormControl variant='standard' sx={{ m: 1, minWidth: '100%' }}>
                  <Select
                    value={location}
                    onChange={handleLocationChange}
                    label='location'
                  >
                    <MenuItem value=''>All locations</MenuItem>
                    <MenuItem value={'All'}>
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value='San Antonio'>San Antonio</MenuItem>
                    <MenuItem value='Lakeland'>Lakeland</MenuItem>
                    <MenuItem value='Frederick'>Frederick</MenuItem>
                    <MenuItem value='Kansas City'>Kansas City</MenuItem>
                    <MenuItem value='Lexington'>Lexington</MenuItem>
                    <MenuItem value='Macon'>Macon</MenuItem>
                    <MenuItem value='San Juan'>San Juan</MenuItem>
                    <MenuItem value='Sarasota, FL'>Sarasota, FL</MenuItem>
                    <MenuItem value='Amarillo'>Amarillo</MenuItem>
                    <MenuItem value='Escondido'>Escondido</MenuItem>
                    <MenuItem value='Washington'>Washington</MenuItem>
                    <MenuItem value='Jersey City'>Jersey City</MenuItem>
                    <MenuItem value='Detroit'>Detroit</MenuItem>
                    <MenuItem value='Amarillo, TX'>Amarillo, TX</MenuItem>
                    <MenuItem value='Grand Rapids'>Grand Rapids</MenuItem>
                    <MenuItem value='Roanoke, VA'>Roanoke, VA</MenuItem>
                    <MenuItem value='Akron'>Akron</MenuItem>
                    <MenuItem value='Everett'>Everett</MenuItem>
                    <MenuItem value='Newburgh'>Newburgh</MenuItem>
                    <MenuItem value='Hartford'>Hartford</MenuItem>
                    <MenuItem value='Wilmington'>Wilmington</MenuItem>
                    <MenuItem value='Pensacola'>Pensacola</MenuItem>
                    <MenuItem value='New Haven'>New Haven</MenuItem>
                    <MenuItem value='Seminole'>Seminole</MenuItem>
                    <MenuItem value='Port St. Lucie'>Port St. Lucie</MenuItem>
                    <MenuItem value='Fredericksburg'>Fredericksburg</MenuItem>
                    <MenuItem value='Savannah'>Savannah</MenuItem>
                    <MenuItem value='Waco'>Waco</MenuItem>
                    <MenuItem value='Lincoln'>Lincoln</MenuItem>
                    <MenuItem value='Bend'>Bend</MenuItem>
                    <MenuItem value='Charlotte'>Charlotte</MenuItem>
                    <MenuItem value='Asheville'>Asheville</MenuItem>
                    <MenuItem value='Topeka'>Topeka</MenuItem>
                    <MenuItem value='Abilene'>Abilene</MenuItem>
                    <MenuItem value='Lubbock'>Lubbock</MenuItem>
                    <MenuItem value='Toledo'>Toledo</MenuItem>
                    <MenuItem value='Phoenix'>Phoenix</MenuItem>
                    <MenuItem value='Houston'>Houston</MenuItem>
                    <MenuItem value='Chicago'>Chicago</MenuItem>
                    <MenuItem value='Jacksonville'>Jacksonville</MenuItem>
                    <MenuItem value='Ocala'>Ocala</MenuItem>
                    <MenuItem value='Orlando'>Orlando</MenuItem>
                    <MenuItem value='Modesto'>Modesto</MenuItem>
                    <MenuItem value='Cedar Rapids'>Cedar Rapids</MenuItem>
                    <MenuItem value='Bakersfield'>Bakersfield</MenuItem>
                    <MenuItem value='South Bend'>South Bend</MenuItem>
                    <MenuItem value='South Bend, IN'>South Bend, IN</MenuItem>
                    <MenuItem value='Allentown'>Allentown</MenuItem>
                    <MenuItem value='Vineland'>Vineland</MenuItem>
                    <MenuItem value='Davenport'>Davenport</MenuItem>
                    <MenuItem value='Fort Wayne'>Fort Wayne</MenuItem>
                    <MenuItem value='Madison'>Madison</MenuItem>
                    <MenuItem value='Baton Rouge'>Baton Rouge</MenuItem>
                    <MenuItem value='Mobile'>Mobile</MenuItem>
                    <MenuItem value='Atlanta'>Atlanta</MenuItem>
                    <MenuItem value='Tampa'>Tampa</MenuItem>
                    <MenuItem value='Dallas'>Dallas</MenuItem>
                    <MenuItem value='Rockford'>Rockford</MenuItem>
                    <MenuItem value='Fayetteville'>Fayetteville</MenuItem>
                    <MenuItem value='Ft Wayne, IN'>Ft Wayne, IN</MenuItem>
                    <MenuItem value='Boise'>Boise</MenuItem>
                    <MenuItem value='Greenville'>Greenville</MenuItem>
                    <MenuItem value='Boise, ID'>Boise, ID</MenuItem>
                    <MenuItem value='Shreveport'>Shreveport</MenuItem>
                    <MenuItem value='Austin'>Austin</MenuItem>
                    <MenuItem value='Fayetteville, AR'>
                      Fayetteville, AR
                    </MenuItem>
                    <MenuItem value='Reno'>Reno</MenuItem>
                    <MenuItem value='Wichita, KS'>Wichita, KS</MenuItem>
                    <MenuItem value='Grand Rapids, MI'>
                      Grand Rapids, MI
                    </MenuItem>
                    <MenuItem value='Charles Town'>Charles Town</MenuItem>
                    <MenuItem value='Spokane'>Spokane</MenuItem>
                    <MenuItem value='Colorado Springs'>
                      Colorado Springs
                    </MenuItem>
                    <MenuItem value='Syracuse'>Syracuse</MenuItem>
                    <MenuItem value='Tallahassee'>Tallahassee</MenuItem>
                    <MenuItem value='Rochester'>Rochester</MenuItem>
                    <MenuItem value='Greensboro'>Greensboro</MenuItem>
                    <MenuItem value='Charleston'>Charleston</MenuItem>
                    <MenuItem value='Los Angeles'>Los Angeles</MenuItem>
                    <MenuItem value='Little Rock'>Little Rock</MenuItem>
                    <MenuItem value='Cleveland, OH'>Cleveland, OH</MenuItem>
                    <MenuItem value='Tucson'>Tucson</MenuItem>
                    <MenuItem value='Jackson'>Jackson</MenuItem>
                    <MenuItem value='Daytona Beach'>Daytona Beach</MenuItem>
                    <MenuItem value='Syracuse, NY'>Syracuse, NY</MenuItem>
                    <MenuItem value='San Jose, CA'>San Jose, CA</MenuItem>
                    <MenuItem value='Dayton, OH'>Dayton, OH</MenuItem>
                    <MenuItem value='Putnam'>Putnam</MenuItem>
                    <MenuItem value='Salt Lake City'>Salt Lake City</MenuItem>
                    <MenuItem value='Oakland, CA'>Oakland, CA</MenuItem>
                    <MenuItem value='Daytona, FL'>Daytona, FL</MenuItem>
                    <MenuItem value='Harrisburg, PA'>Harrisburg, PA</MenuItem>
                    <MenuItem value='Montgomery'>Montgomery</MenuItem>
                    <MenuItem value='New York, NY'>New York, NY</MenuItem>
                    <MenuItem value='Omaha'>Omaha</MenuItem>
                    <MenuItem value='North Augusta'>North Augusta</MenuItem>
                    <MenuItem value='Myrtle Beach'>Myrtle Beach</MenuItem>
                    <MenuItem value='Midland'>Midland</MenuItem>
                    <MenuItem value='Providence'>Providence</MenuItem>
                    <MenuItem value='Louisville'>Louisville</MenuItem>
                    <MenuItem value='Memphis'>Memphis</MenuItem>
                    <MenuItem value='Denver'>Denver</MenuItem>
                    <MenuItem value='Denver, CO'>Denver, CO</MenuItem>
                    <MenuItem value='Columbia'>Columbia</MenuItem>
                    <MenuItem value='North Kansas City'>
                      North Kansas City
                    </MenuItem>
                    <MenuItem value='Dayton'>Dayton</MenuItem>
                    <MenuItem value='Huntsville'>Huntsville</MenuItem>
                    <MenuItem value='Boston'>Boston</MenuItem>
                    <MenuItem value='Killeen'>Killeen</MenuItem>
                    <MenuItem value='Indianapolis'>Indianapolis</MenuItem>
                    <MenuItem value='Harrisburg'>Harrisburg</MenuItem>
                    <MenuItem value='Birmingham'>Birmingham</MenuItem>
                    <MenuItem value='Knoxville'>Knoxville</MenuItem>
                    <MenuItem value='Lafayette'>Lafayette</MenuItem>
                    <MenuItem value='Fresno'>Fresno</MenuItem>
                    <MenuItem value='Riverside'>Riverside</MenuItem>
                    <MenuItem value='Lansing'>Lansing</MenuItem>
                    <MenuItem value='Corpus Christi'>Corpus Christi</MenuItem>
                    <MenuItem value='Des Moines'>Des Moines</MenuItem>
                    <MenuItem value='Minneapolis'>Minneapolis</MenuItem>
                    <MenuItem value='El Paso'>El Paso</MenuItem>
                    <MenuItem value='Albany'>Albany</MenuItem>
                    <MenuItem value='Philadelphia'>Philadelphia</MenuItem>
                    <MenuItem value='Fort Worth'>Fort Worth</MenuItem>
                    <MenuItem value='Springfield'>Springfield</MenuItem>
                    <MenuItem value='Richmond'>Richmond</MenuItem>
                    <MenuItem value='Portland'>Portland</MenuItem>
                    <MenuItem value='Buffalo'>Buffalo</MenuItem>
                    <MenuItem value='Long Island'>Long Island</MenuItem>
                    <MenuItem value='McAllen'>McAllen</MenuItem>
                    <MenuItem value='Albuquerque'>Albuquerque</MenuItem>
                    <MenuItem value='Miami'>Miami</MenuItem>
                    <MenuItem value='Seattle'>Seattle</MenuItem>
                    <MenuItem value='San Diego'>San Diego</MenuItem>
                    <MenuItem value='Arlington Heights'>
                      Arlington Heights
                    </MenuItem>
                    <MenuItem value='Wichita'>Wichita</MenuItem>
                    <MenuItem value='Mountainside'>Mountainside</MenuItem>
                    <MenuItem value='New Orleans'>New Orleans</MenuItem>
                    <MenuItem value='Tulsa'>Tulsa</MenuItem>
                    <MenuItem value='Las Vegas'>Las Vegas</MenuItem>
                    <MenuItem value='Fort Myers'>Fort Myers</MenuItem>
                    <MenuItem value='Raleigh'>Raleigh</MenuItem>
                    <MenuItem value='Chattanooga'>Chattanooga</MenuItem>
                    <MenuItem value='Bellflower'>Bellflower</MenuItem>
                    <MenuItem value='Farmingdale'>Farmingdale</MenuItem>
                    <MenuItem value='Baltimore'>Baltimore</MenuItem>
                    <MenuItem value='Saint Paul'>Saint Paul</MenuItem>
                    <MenuItem value='Columbus'>Columbus</MenuItem>
                    <MenuItem value='South Houston'>South Houston</MenuItem>
                    <MenuItem value='Round Rock'>Round Rock</MenuItem>
                    <MenuItem value='Oklahoma City'>Oklahoma City</MenuItem>
                    <MenuItem value='St. Louis'>St. Louis</MenuItem>
                    <MenuItem value='Sacramento'>Sacramento</MenuItem>
                    <MenuItem value='Mesa'>Mesa</MenuItem>
                    <MenuItem value='Milwaukee'>Milwaukee</MenuItem>
                    <MenuItem value='Arlington'>Arlington</MenuItem>
                    <MenuItem value='Virginia Beach'>Virginia Beach</MenuItem>
                    <MenuItem value='Pittsburgh'>Pittsburgh</MenuItem>
                    <MenuItem value='Cleveland'>Cleveland</MenuItem>
                    <MenuItem value='Cincinnati'>Cincinnati</MenuItem>
                    <MenuItem value='Tacoma'>Tacoma</MenuItem>
                    <MenuItem value='St Louis'>St Louis</MenuItem>
                    <MenuItem value='Nashville'>Nashville</MenuItem>
                    <MenuItem value='San Francisco'>San Francisco</MenuItem>
                    <MenuItem value='Newark'>Newark</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xxs={0} sm={4}></Grid>
              <Grid
                item
                xxs={6}
                sm={4}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'left',
                    fontWeight: '700',
                    fontSize: { xxs: '1.25rem', sm: '1.25rem', md: '1.25rem' },
                  }}
                >
                  FILTER BY DIVISION:
                </Typography>
              </Grid>
              <Grid item xxs={6} sm={4}>
                <FormControl variant='standard' sx={{ m: 1, minWidth: '100%' }}>
                  <Select
                    value={division}
                    onChange={handleDivisionChange}
                    label='location'
                  >
                    <MenuItem defaultChecked value=''>
                      All teams
                    </MenuItem>
                    <MenuItem value={'All'}>
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value='Other'>Other</MenuItem>
                    <MenuItem value='Installations'>Installations</MenuItem>
                    <MenuItem value='Agent Referral Division'>
                      Agent Referral Division
                    </MenuItem>
                    <MenuItem value='Sales Management'>
                      Sales Management
                    </MenuItem>
                    <MenuItem value='Installation'>Installation</MenuItem>
                    <MenuItem value='Outside Sales'>Outside Sales</MenuItem>
                    <MenuItem value='Inside Sales'>Inside Sales</MenuItem>
                    <MenuItem value='Corporate Positions'>
                      Corporate Positions
                    </MenuItem>
                    <MenuItem value='Builder Division'>
                      Builder Division
                    </MenuItem>
                    <MenuItem value='Technical Support'>
                      Technical Support
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xxs={0} sm={4}></Grid>
            </Grid>
          </Container>
        </Container>
      </Box>
      <Container
        maxWidth={false}
        sx={{
          width: '100%',
          maxWidth: '100%',
          height: '20%',
          minHeight: '150px',
          padding: '2rem',
          textAlign: 'center',
          bgcolor: '#ffffff',
          background:
            'linear-gradient(to bottom right, #ffffff 55%, #f6f6f6 45%)',
        }}
      >
        <Typography
          sx={{
            color: '#00549c',
            fontSize: { xxs: '1.5rem', sm: '2rem', md: '2rem' },
          }}
        >
          Results:
        </Typography>
        <Grid
          container
          columnSpacing={{ xxs: 0, sm: 0, md: 10 }}
          rowSpacing={{ xxs: 0, sm: 2 }}
          sx={{ minHeight: '400px', padding: { md: '0 80px' } }}
        >
          {careers.objects.map((career: any, index: any) => (
            <Grid
              className='results-grid-item'
              item
              key={index}
              xxs={12}
              sm={6}
              md={4}
              sx={{ width: { xxs: '100%' } }}
            >
              <Card
                elevation={0}
                raised={false}
                sx={{
                  borderRadius: '15px',
                  background: 'transparent',
                  padding: '24px',
                }}
              >
                <CardActionArea
                  sx={{ paddingBottom: '24px', minHeight: '220px' }}
                >
                  <CardContent sx={{ padding: '0', paddingTop: '2rem' }}>
                    <Typography
                      gutterBottom
                      component='div'
                      sx={{ fontSize: '1.25rem', fontWeight: '700' }}
                    >
                      {career.title}
                    </Typography>
                    <Typography>
                      Location: {career.location.city}, {career.location.state}
                    </Typography>
                    <Typography sx={{ color: '#de3c4b' }}>
                      {career.position_type}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'center' }}>
                    <Button
                      href={career.hosted_url}
                      size='small'
                      sx={{ textTransform: 'none' }}
                    >
                      Apply now &gt;
                    </Button>
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
          {careers.objects.length === 0 ? (
            <Grid item xxs={12}>
              <Typography>No results found</Typography>
              <Typography>
                Please try adjusting your search criteria or check back later.
              </Typography>
            </Grid>
          ) : (
            <Pagination
              count={pageCount}
              page={page}
              onChange={handlePageChange}
              color='primary'
              sx={{ margin: 'auto' }}
            />
          )}
        </Grid>
      </Container>
    </>
  );
};

const SectionFive = () => {
  return (
    <Box
      className='Careers-Section-5'
      sx={{
        bgcolor: '#024d90',
        background:
          'linear-gradient(to bottom right, #024d90 55%, #014A8A 45%);',
        padding: '2rem 0',
        textAlign: 'center',
        minHeight: '600px',
      }}
    >
      <Box sx={{ marginBottom: '1.5rem' }}>
        <Typography
          sx={{
            fontSize: { xxs: '1.65rem', sm: '2.5rem', md: '3rem' },
            color: '#fff',
          }}
        >
          Team Member Experiences
        </Typography>
        <Typography
          sx={{
            fontSize: { xxs: '.85rem', sm: '1.25rem', md: '1.5rem' },
            color: '#fff',
          }}
        >
          What our team says about working for Safe Haven
        </Typography>
      </Box>

      <SwiperCarousel
        slides={Constants.EMPLOYEE_REVIEWS_REMODEL}
        type={'employeeReview'}
      ></SwiperCarousel>
    </Box>
  );
};

const SectionSix = () => {
  return (
    <Box sx={{ padding: '2rem', backgroundColor: '#fff' }}>
      <Button
        sx={{
          textTransform: 'initial',
          paddingLeft: '0',
          color: '#024d90',
          fontWeight: '700',
          textAlign: { xxs: 'justify', md: 'initial' },
        }}
      >
        Federal Transparency in Coverage Rules &gt;
      </Button>
      <Typography sx={{ textAlign: { xxs: 'justify', md: 'initial' } }}>
        This link leads to the machine readable files that are made available in
        response to the federal Transparency in Coverage Rule and includes
        negotiated service rates and out-of-network allowed amounts between
        health plans and healthcare providers. The machine-readable files are
        formatted to allow researchers, regulators, and application developers
        to more easily access and analyze data.
      </Typography>
    </Box>
  );
};

const SectionSeven = () => {
  return (
    <Box sx={{ maxHeight: { xxs: '600px' } }}>
      <Box
        component='img'
        sx={{
          width: '100%',
        }}
        alt='The house from the offer.'
        src={SectionSixYardSign}
      />
    </Box>
  );
};

export default function Careers() {
  return (
    <>
      <ThemeProvider theme={createTheme(customBreakpoints)}>
        <Container maxWidth={'xl'} sx={{ padding: { xxs: '0' } }}>
          <Stack
            sx={{
              minHeight: '100vh',
              overflow: 'hidden',
            }}
          >
            <SectionOne></SectionOne>
            <SectionTwo></SectionTwo>
            <SectionThree></SectionThree>
            <SectionFive></SectionFive>
            <SectionSix></SectionSix>
            <SectionSeven></SectionSeven>
          </Stack>
        </Container>
      </ThemeProvider>
    </>
  );
}
