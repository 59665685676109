import { createSlice } from '@reduxjs/toolkit';

export const alertBannerSlice = createSlice({
  name: 'alertBanner',
  initialState: {
    display: true,
  },
  reducers: {
    deactivate: (state) => {
      state.display = false;
    },
    activate: (state) => {
      state.display = true;
    },
    dynamicAssign: (state, action) => {
      if (action.payload == 'show' || action.payload == 'hide') {
        state.display = action.payload;
      }
    },
  },
});

export const { deactivate, activate } = alertBannerSlice.actions;

export default alertBannerSlice.reducer;
