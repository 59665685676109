import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import './index.scss';
import { useLocation } from 'react-router-dom';
import { Typography, Button } from '@mui/material';
import { Container, Stack } from '@mui/system';
import topBgCropped from "../../../../../Assets/Partner/Paddio/banner-img-1136x666-cropped.jpg";

interface BillboardProps {
    text: string;
    imageUrl?: string;
    buttonClass?: string;
    buttonText?: string;
    onClick?: () => void;
}

export default function Billboard({
    text,
    imageUrl = topBgCropped,
    buttonClass = '',
    buttonText = 'GET STARTED NOW',
    onClick
}: BillboardProps) {
    return (
        <Container maxWidth={false} className="panel-img-bg" >
            <Box
                component="img"
                sx={{
                    maxWidth: "100%",
                    height: "auto",
                    margin: 'auto',
                    width: '100%'
                }}
                src={imageUrl}
            />
            <Stack direction={"column"} className="panel-text-overlay" sx={{
                alignItems: { xs: 'center', sm: 'flex-start' },
            }}>
                <Box sx={{ paddingLeft: { xs: '0px', sm: '36px' }, }}>
                    <Typography className='white-title' sx={{
                        color: "#545859",
                        font: { xs: 'normal 800 24px/34px Montserrat,Helvetica,Arial,Verdana,sans-serif', sm: "normal 800 32px/48px Montserrat,Helvetica,Arial,Verdana,sans-serif", lg: "normal 800 44px/54px Montserrat,Helvetica,Arial,Verdana,sans-serif" },
                        maxWidth: { xs: '300px', sm: '350px', md: '550px' },
                        marginBottom: { xs: '16px', md: '48px' },
                        textAlign: { xs: 'center', sm: 'left' }
                    }}>
                        {text}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                        <Button
                            variant={'contained'}
                            className={`${buttonClass}`}
                            onClick={onClick}
                        >
                            {buttonText}
                        </Button>
                    </Box>
                </Box>
            </Stack>
        </Container>
    );
}
