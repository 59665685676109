import React from 'react';
import Carousel from 'react-material-ui-carousel';
import Divider from '@mui/material/Divider';

import './index.scss';
import { Box, Grid, Stack, Typography } from '@mui/material';

export default function LearusCarousel(props: any) {
    if (props.type === 'customerReview') {
        return (
            <>
                <Carousel
                    className={props.type + '-carousel'}
                    sx={{}}
                    autoPlay={false}
                    navButtonsAlwaysVisible={true}
                    indicatorContainerProps={{
                        style: {
                            height: '20px',
                        },
                    }}
                    activeIndicatorIconButtonProps={{
                        style: {
                            backgroundColor: 'initial',
                        },
                    }}
                    navButtonsWrapperProps={{ className: 'carouselNavButtonWrapper' }}
                    navButtonsProps={{
                        className: 'carouselNavButton',
                        style: {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    {props.slides.map((slide: any, index: any) => (
                        <Box
                            key={index}
                            sx={{
                                fontSize: { xs: '1.2rem' },
                                padding: '0 1rem 1rem 1rem',
                                minHeight: {
                                    xs: '400px',
                                    sm: '350px',
                                    lg: '250px',
                                    xl: '250px',
                                },
                                maxWidth: '70%',
                                margin: 'auto',
                                position: 'relative',
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                }}
                            >
                                <Box
                                    className='slide-content'
                                    sx={{
                                        marginBottom: '2rem',
                                        maxHeight: '250px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                    }}
                                >
                                    {slide.content}
                                </Box>
                                <Divider
                                    sx={{
                                        backgroundColor: '#80ced7',
                                        maxWidth: '135px',
                                        margin: 'auto',
                                        height: '4px',
                                        marginTop: '1rem',
                                        marginBottom: '1rem',
                                    }}
                                ></Divider>
                                <Box>-{slide.author}</Box>

                                <Box sx={{ marginTop: '.5rem' }}>{slide.source}</Box>
                            </Box>
                        </Box>
                    ))}
                </Carousel>
            </>
        );
    } else if (props.type === 'employeeReview') {
        return (
            <>
                <Carousel className={props.type + '-carousel'} autoPlay={false}>
                    {props.slides.map((slide: any, index: any) => (
                        <Grid container spacing={2} key={index}>
                            <Grid item xs={6}>
                                <Box
                                    key={slide.id}
                                    sx={{
                                        fontSize: '1.2rem',
                                        padding: '0 1rem 1rem 1rem',
                                        minHeight: '215px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'PalanquinDark',
                                            fontSize: '5rem',
                                            color: '#80ced7',
                                            lineHeight: '.25',
                                            marginTop: '1rem',
                                            marginBottom: '2rem',
                                            transform: 'rotateY(180deg) rotatex(180deg)',
                                        }}
                                    >
                                        "
                                    </Typography>
                                    {slide.content}
                                    <Divider
                                        sx={{
                                            backgroundColor: '#80ced7',
                                            maxWidth: '135px',
                                            margin: 'auto',
                                            height: '4px',
                                            marginTop: '13px',
                                            marginBottom: '13px',
                                        }}
                                    ></Divider>
                                    -{slide.author}
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box
                                    component='img'
                                    sx={{
                                        width: '100%',
                                    }}
                                    alt='The house from the offer.'
                                    src={slide.image}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Carousel>
            </>
        );
    } else return <></>;
}
