import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl, InputLabel, Select } from '@mui/material';

export default function RequestDemoForm() {
  return (
    <Box
      component='form'
      sx={{
        '& .MuiTextField-root': { m: 1 },
        '& .MuiTextarea-root': { m: 1 },
        '& .half-width': { width: 'calc(50% - 16px)' },
        '& .full-width': { width: 'calc(100% - 16px)' },
      }}
      noValidate
      autoComplete='off'
    >
      <TextField
        required
        id='outlined-required'
        label='First Name'
        defaultValue=''
        className='half-width'
      />
      <TextField
        required
        id='outlined-required'
        label='Last Name'
        defaultValue=''
        className='half-width'
      />
      <TextField
        required
        id='outlined-required'
        label='First Name'
        defaultValue=''
        className='half-width'
      />
      <TextField
        required
        id='outlined-required'
        label='Last Name'
        defaultValue=''
        className='half-width'
      />
      <TextField
        required
        id='outlined-required'
        label='First Name'
        defaultValue=' Test'
        className='half-width'
      />
      <FormControl sx={{ width: '50%' }}>
        <Select
          native
          id='outlined-helperText'
          label='Request Type'
          defaultValue='Default Value'
          className='full-width'
          sx={{ display: 'flex', margin: 'auto' }}
        />
      </FormControl>
      <FormControl sx={{ width: '100%' }}>
        <Select
          native
          id='outlined-helperText'
          label='Request Type'
          defaultValue='Default Value'
          className='full-width'
          sx={{ display: 'flex', margin: 'auto' }}
        />
      </FormControl>

      <TextField
        id='outlined-multiline-static'
        label='Multiline'
        className='full-width'
        multiline
        rows={4}
        placeholder='Default Value'
      />
    </Box>
  );
}
