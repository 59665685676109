import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchPartnerCompanyInfoByPagePath } from "./Functions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import handshake from "../../../Assets/Templates/handshake_8747255.png";
import notFound404 from "../../../Assets/Templates/undraw_Page_not_found_re_e9o6.png";
import { disable as hideHeader } from "../../../App/State/headerSlice.tsx";
import TemplateOne from "./Templates/1";
import TemplateTwo from "./Templates/2";
import TemplateThree from "./Templates/3";
import TemplateFour from "./Templates/4";
import TemplateFive from "./Templates/5";
import Nerdwallet from "./Templates/7";
import TemplateEight from "./Templates/8";
import { Box, Container } from "@mui/system";
import "./index.scss";
import { Helmet } from "react-helmet";
import { PASSWORD_PROTECTED } from "./Constants";
import LockIcon from '@mui/icons-material/Lock';
import { Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup'; // For form validation

export default function LandingPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleNavigate = (route: string) => navigate(`${route}`);
	const { pathname } = useLocation();

	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [isPasswordProtected, setIsPasswordProtected] = useState(PASSWORD_PROTECTED.includes(pathname));

	const { pagePath = "default" } = useParams();
	const [partnerInfo, setPartnerInfo] = useState<PartnerInfo>({
		CampaignName: "Default Campaign",
		CampaignId: "0000",
		PartnerId: "0000",
		PartnerName: "Default Partner",
		Template: "0",
	});

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response: PartnerInfo = await fetchPartnerCompanyInfoByPagePath(pagePath);
				console.log("API Response:", response)

				if (response != null) {
					setPartnerInfo(response);
				}
			} catch (err) {
				setError("Error fetching partner information.");
				console.error(err);
			} finally {
				setIsLoading(false);
			}
		};
		fetchData();
		dispatch(hideHeader());
	}, [pagePath]);

	// Formik Initial Values and Validation Schema
	const initialValues = {
		password: '',
		rememberMe: false,
	};

	const validationSchema = Yup.object({
		password: Yup.string().required('Required'),
		rememberMe: Yup.boolean().required('Required'),
	});

	if (error) {
		return <Box>Error: {error}</Box>;
	} else if (isLoading) {
		return (
			<Box className="Bcircles-container">
				<Box className="Bcircles">
					<Box className='c-one circle'></Box>
					<Box
						component="img"
						sx={{
							maxWidth: "200px",
							height: "auto",
						}}
						src={handshake}
					/>
				</Box>
			</Box>
		);
	} else {
		return (
			<>
				<Helmet>
					<title>{`${partnerInfo.PartnerName} | Safe Haven Security`} </title>
				</Helmet>
				<Box className="landing-page-container">
					{isPasswordProtected ? (
						<Box sx={{ background: 'linear-gradient(137deg, rgba(2,0,36,1) 0%, rgba(18,96,168,1) 61%, rgba(18,96,168,0.34515056022408963) 100%);' }}>
							<Box sx={{
								background: 'url(https://cdn.forms.office.net/forms/images/aio/wave-pattern-v1.svg)',
								backgroundRepeat: 'no-repeat',
								backgroundSize: 'cover'
							}}>
								<Container maxWidth={false} sx={{
									maxWidth: '800px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									minHeight: '100vh',
								}}>
									<Box sx={{ border: '1px solid #1260a8', padding: '4vw', borderRadius: 8, background: '#fff' }}>
										<Box sx={{ backgroundColor: '#8ac7ff66', borderRadius: '50%', width: 'auto', display: 'inline-flex', padding: '28px', marginBottom: '32px' }}>
											<LockIcon sx={{ color: '#1260a8', height: '85px', width: '85px' }}></LockIcon>
										</Box>
										<Typography variant="h3" sx={{ marginBottom: '32px', fontWeight: '700' }}>This page is protected by the owner.</Typography>
										<Formik
											initialValues={initialValues}
											validationSchema={validationSchema}
											onSubmit={async (values, { setSubmitting, resetForm }) => {
												try {

												} catch (error) {
													console.error("Error sending request:", error);
												} finally {
													setSubmitting(false);
												}
											}}
										>
											{({ errors, touched, values, setFieldValue, isSubmitting }) => (
												<Form>
													<Box className='fields-container'>
														<Field
															as={TextField}
															sx={{
																minHeight: 75,
																maxHeight: 75,
																width: '100%'
															}}
															name="password"
															label="Enter your password"
															value={values.password}
															onChange={(event: any) => { setFieldValue("password", event.target.value) }}
															error={touched.password && Boolean(errors.password)}
															helperText={touched.password && errors.password}
														/>

														<Box sx={{}}>
															<LoadingButton
																variant='contained'
																type="submit"
																loading={isSubmitting}
																disabled={isSubmitting}
																sx={{
																	fontSize: '20px',
																	width: '100%',
																	minHeight: '56px',
																	textTransform: 'none'
																}}
															>
																{isSubmitting ? 'Submitting...' : 'Submit'}
															</LoadingButton>
														</Box>

														<Box className='full-width perfect-vision-consent' sx={{ paddingTop: '10px' }}>
															<FormControlLabel
																name='rememberMe'
																className={touched.rememberMe && !values.rememberMe ? 'error-checkbox' : ''}
																control={
																	<Checkbox
																		checked={values.rememberMe}
																		onChange={(event: { target: { checked: boolean; }; }) => { setFieldValue("rememberMe", event.target.checked) }
																		}
																	/>
																}
																label=" Remember Me"
																sx={{
																	margin: '0 8px 8px 8px',
																	alignItems: 'flex-start',
																	'& .MuiFormControlLabel-asterisk': {
																		paddingTop: '9px',
																		height: '5px',
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'flex-start',
																		justifyContent: 'space-around',
																	},
																	'& .MuiTypography-root': {
																		paddingTop: '8px',
																	},
																	'& span': {
																		fontWeight: 700
																	},
																}}
															/>
														</Box>
													</Box>
												</Form>
											)}
										</Formik>
									</Box>
								</Container>
							</Box>
						</Box>
					) : (
						<>
							{partnerInfo.Template === "0" && <>
								<Container>
									<Box
										component="img"
										alt="The house from the offer."
										src={notFound404}
										sx={{ width: "100%" }}
									/>
								</Container>
							</>}
							{partnerInfo.Template === "1" && <TemplateOne partnerInfo={partnerInfo} />}
							{partnerInfo.Template === "2" && <TemplateTwo partnerInfo={partnerInfo} />}
							{partnerInfo.Template === "3" && <TemplateThree partnerInfo={partnerInfo} />}
							{partnerInfo.Template === "4" && <TemplateFour partnerInfo={partnerInfo} />}
							{partnerInfo.Template === "5" && <TemplateFive partnerInfo={partnerInfo} />}
							{partnerInfo.Template === "6" && <></>}
							{partnerInfo.Template === "7" && <Nerdwallet />}
							{partnerInfo.Template === "8" && <TemplateEight partnerInfo={partnerInfo} />}
						</>
					)}

				</Box>
			</>

		);
	}
}
