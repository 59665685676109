import AlarmDotComLogo from "../Assets/Nerdwallet/Logo_Alarmdotcom.png";
import HoneyWellLogo from "../Assets/Nerdwallet/Logo_Honeywell.png";
import JohnsonLogo from "../Assets/Nerdwallet/Logo_JohnsonControls.png";
import kwiksetLogo from "../Assets/Nerdwallet/Logo_Kwikset.png";
import CircleIndoorCamera from "../Assets/Nerdwallet/CircleIndoorCamera.png";
import CircleOutdoorCamera from "../Assets/Nerdwallet/CircleOutdoorCamera.png";
import CircleSmoke from "../Assets/Nerdwallet/CircleSmoke.png";
import CircleThermostat from "../Assets/Nerdwallet/CircleThermostat.png";
import CircleDoorbell from "../Assets/Nerdwallet/CircleVideoDoorbell.png";
import CircleDoorLock from "../Assets/Nerdwallet/CircleDoorLock.png";
import CircleAlarmdotcomApp from "../Assets/Nerdwallet/CircleAlarm.comApp.png";
import CirclCO from "../Assets/Nerdwallet/CircleCO.png";

export const logo_slides = [
	{ image: kwiksetLogo },
	{ image: AlarmDotComLogo },
	{ image: HoneyWellLogo },
	{ image: JohnsonLogo },
];

export const reviews = [
	{
		name: "Carrie B.",
		description:
			"I had an exceptional experience with Safe Haven Security and am thrilled with the installation of our household's ADT security system. Our technician, Austin Whitaker, was extremely knowledgeable about the equipment, very informed about home security in general, and genuinely dedicated to customizing the system for our needs.",
	},
	{
		name: "Chris R.",
		description:
			"If all service companies working inside my home could be like Safe Haven, life as a homeowner would be a much more enjoyable journey. Great staff, thorough, professional, and friendly. They even stayed longer to ensure I had a good understanding of the ADT system.",
	},
	{
		name: "Marilyn W, KS",
		description:
			"I highly recommend Safe Haven Security for your home. Not only do they have a wide selection and various options of security products/resources but their technicians are wonderful. ",
	},

	{
		name: "Stephanie C.",
		description:
			"Safe Haven Security was great; we set up an appointment time, the person they sent out was here a little early, and he didn’t leave until everything was set up how it was supposed to be and all questions were answered!",
	},
];

export const products = [
	{
		image: CircleIndoorCamera,
	},

	{
		image: CircleOutdoorCamera,
	},
	{
		image: CircleDoorbell,
	},
	{
		image: CircleThermostat,
	},
	{
		image: CircleAlarmdotcomApp,
	},
	{
		image: CircleSmoke,
	},

	{
		image: CircleDoorLock,
	},

	{
		image: CirclCO,
	},
];
