import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import EmployeeImage1 from '../Assets/Careers/ADT-employee.jpg';
import PersonIcon from '@mui/icons-material/Person';
import SwiperImg1 from '../Assets/Partner/Paddio/swiper-img-1.jpg';
import SwiperImg2 from '../Assets/Partner/Paddio/swiper-img-2.jpg';
import SwiperImg3 from '../Assets/Partner/Paddio/swiper-img-3.jpg';

export const CUSTOMER_REVIEWS_REMODEL = [
  {
    id: 0,
    author: 'Don Jones',
    source: 'Google Review',
    content:
      'We needed someone to come to our home for an immediate installation of a system due to a break-in at our home recently. I called Heather after 7:00pm and she answered her phone and immediately told me she could have an installer at my home the next day. The installer arrived at my home about 12:30 and had me up and secured in about 3 hours. My home is now protected and I have a very impressive system that is efficient and very high-tech; I can access my system via my iPhone.',
  },
  {
    id: 1,
    author: 'Jane Doe',
    source: 'Google Review',
    content:
      'I was very impressed with the customer service I received from Heather. I called them to inquire about their security system and I was immediately connected with a friendly and knowledgeable representative. She answered all of my questions and helped me to choose the right system for my needs. I would definitely recommend This to anyone looking for a security system.',
  },
  {
    id: 2,
    author: 'John Smith',
    source: 'Yelp Review',
    content:
      'I had a great experience with Heather. The installer was very professional and he did a great job installing my security system. I feel much safer knowing that my home is protected.',
  },
  {
    id: 3,
    author: 'Mary Johnson',
    source: 'Facebook Review',
    content:
      "I'm so glad I decided to get a security system from Heather. I feel much more secure knowing that my home is protected. The customer service is excellent and the system is easy to use.",
  },
  {
    id: 4,
    author: 'Peter Parker',
    source: 'Twitter Review',
    content:
      'This security system is the best! I feel so much safer knowing that my home is protected. The customer service is top-notch and the system is easy to use.',
  },
  {
    id: 5,
    author: 'Clark Kent',
    source: 'LinkedIn Review',
    content:
      "I highly recommend this security system. It's easy to use, affordable, and provides peace of mind knowing that my home is protected.",
  },
];

export const EMPLOYEE_REVIEWS = [
  <Box
    sx={{
      display: 'flex',
      padding: { xs: '0 60px 0 45px', sm: '0' },
      maxWidth: '550px',
      margin: 'auto',
    }}
  >
    <PersonIcon
      sx={{
        color: '#4883bb',
        width: '45px',
        height: '45px',
        marginRight: '10px',
      }}
    ></PersonIcon>
    <Stack sx={{}}>
      <Typography
        sx={{
          color: '#777777',
          fontSize: '18px',
          marginBottom: '10px',
          minHeight: '165px',
        }}
      >
        I love new ADT home security service and as promised it was installed
        fast and wasn’t too expensive. I feel safe at home now.
      </Typography>
      <Typography
        sx={{ color: '#4d4f51', fontSize: '22px', fontWeight: '700' }}
      >
        Amanda Jo B.
      </Typography>
      <Typography sx={{ color: '#788bc2', fontSize: '22px' }}>
        Liberty, Mo
      </Typography>
    </Stack>
  </Box>,
  <Box
    sx={{
      display: 'flex',
      padding: { xs: '0 60px 0 45px', sm: '0' },
      maxWidth: '550px',
      margin: 'auto',
    }}
  >
    <PersonIcon
      sx={{
        color: '#4883bb',
        width: '45px',
        height: '45px',
        marginRight: '10px',
      }}
    ></PersonIcon>
    <Stack>
      <Typography
        sx={{
          color: '#777777',
          fontSize: '18px',
          marginBottom: '10px',
          minHeight: '165px',
        }}
      >
        Our family loves our new Pulse security that we can use with our
        smartphone. Very cool features. Glad we found you guys!
      </Typography>
      <Typography
        sx={{ color: '#4d4f51', fontSize: '22px', fontWeight: '700' }}
      >
        Karrie B.
      </Typography>
      <Typography sx={{ color: '#788bc2', fontSize: '22px' }}>
        Houston, Tx
      </Typography>
    </Stack>
  </Box>,
  <Box
    sx={{
      display: 'flex',
      padding: { xs: '0 60px 0 45px', sm: '0' },
      maxWidth: '550px',
      margin: 'auto',
    }}
  >
    <PersonIcon
      sx={{
        color: '#4883bb',
        width: '45px',
        height: '45px',
        marginRight: '10px',
      }}
    ></PersonIcon>
    <Stack>
      <Typography
        sx={{
          color: '#777777',
          fontSize: '18px',
          marginBottom: '10px',
          minHeight: '165px',
        }}
      >
        I’ve never felt safer than I do right now! Thanks for following up on
        your promise!
      </Typography>
      <Typography
        sx={{ color: '#4d4f51', fontSize: '22px', fontWeight: '700' }}
      >
        Jesse J.
      </Typography>
      <Typography sx={{ color: '#788bc2', fontSize: '22px' }}>
        Cleveland, Oh
      </Typography>
    </Stack>
  </Box>,
];

export const CUSTOMER_SOLUTIONS_REVIEWS = [
  {
    id: 0,
    author: 'Will Roberts',
    source: 'Employee',
    image: EmployeeImage1,
    content:
      'Working for Safe Haven has been better than I ever dreamed of. I have a great career and they reward my hard work.',
  },
  {
    id: 1,
    author: 'Jane Doe',
    source: 'Employee',
    image: EmployeeImage1,
    content:
      'I was very impressed with the customer service I received from Heather. I called them to inquire about their security system and I was immediately connected with a friendly and knowledgeable representative. She answered all of my questions and helped me to choose the right system for my needs. I would definitely recommend This to anyone looking for a security system.',
  },
  {
    id: 2,
    author: 'John Smith',
    source: 'Employee',
    image: EmployeeImage1,
    content:
      'I had a great experience with Heather. The installer was very professional and he did a great job installing my security system. I feel much safer knowing that my home is protected.',
  },
  {
    id: 3,
    author: 'Mary Johnson',
    source: 'Employee',
    image: EmployeeImage1,
    content:
      "I'm so glad I decided to get a security system from Heather. I feel much more secure knowing that my home is protected. The customer service is excellent and the system is easy to use.",
  },
  {
    id: 4,
    author: 'Peter Parker',
    source: 'Employee',
    image: EmployeeImage1,
    content:
      'This security system is the best! I feel so much safer knowing that my home is protected. The customer service is top-notch and the system is easy to use.',
  },
  {
    id: 5,
    author: 'Clark Kent',
    source: 'Employee',
    image: EmployeeImage1,
    content:
      "I highly recommend this security system. It's easy to use, affordable, and provides peace of mind knowing that my home is protected.",
  },
];

export const EMPLOYEE_REVIEWS_REMODEL = [
  {
    id: 0,
    author: 'Will Roberts',
    source: 'Employee',
    image: EmployeeImage1,
    content:
      'Working for Safe Haven has been better than I ever dreamed of. I have a great career and they reward my hard work.',
  },
  {
    id: 1,
    author: 'Jane Doe',
    source: 'Employee',
    image: EmployeeImage1,
    content:
      'I was very impressed with the customer service I received from Heather. I called them to inquire about their security system and I was immediately connected with a friendly and knowledgeable representative. She answered all of my questions and helped me to choose the right system for my needs. I would definitely recommend This to anyone looking for a security system.',
  },
  {
    id: 2,
    author: 'John Smith',
    source: 'Employee',
    image: EmployeeImage1,
    content:
      'I had a great experience with Heather. The installer was very professional and he did a great job installing my security system. I feel much safer knowing that my home is protected.',
  },
  {
    id: 3,
    author: 'Mary Johnson',
    source: 'Employee',
    image: EmployeeImage1,
    content:
      "I'm so glad I decided to get a security system from Heather. I feel much more secure knowing that my home is protected. The customer service is excellent and the system is easy to use.",
  },
  {
    id: 4,
    author: 'Peter Parker',
    source: 'Employee',
    image: EmployeeImage1,
    content:
      'This security system is the best! I feel so much safer knowing that my home is protected. The customer service is top-notch and the system is easy to use.',
  },
  {
    id: 5,
    author: 'Clark Kent',
    source: 'Employee',
    image: EmployeeImage1,
    content:
      "I highly recommend this security system. It's easy to use, affordable, and provides peace of mind knowing that my home is protected.",
  },
];

export const CUSTOMER_REVIEWS_LIST = [
  <Box
    sx={{
      display: 'flex',
      padding: { xs: '0 60px 0 45px', sm: '0' },
      maxWidth: '550px',
      margin: 'auto',
    }}
  >
    <PersonIcon
      sx={{
        color: '#4883bb',
        width: '45px',
        height: '45px',
        marginRight: '10px',
      }}
    ></PersonIcon>
    <Stack sx={{}}>
      <Typography
        sx={{
          color: '#777777',
          fontSize: '18px',
          marginBottom: '10px',
          minHeight: '165px',
        }}
      >
        The sales team was professional and the service installers were nice and
        courteous. That and a great price is all that anyone can ask for.
      </Typography>
      <Typography
        sx={{ color: '#4d4f51', fontSize: '22px', fontWeight: '700' }}
      >
        Bryan H.
      </Typography>
      <Typography sx={{ color: '#788bc2', fontSize: '22px' }}>
        Cool Agency
      </Typography>
    </Stack>
  </Box>,
  <Box
    sx={{
      display: 'flex',
      padding: { xs: '0 60px 0 45px', sm: '0' },
      maxWidth: '550px',
      margin: 'auto',
    }}
  >
    <PersonIcon
      sx={{
        color: '#4883bb',
        width: '45px',
        height: '45px',
        marginRight: '10px',
      }}
    ></PersonIcon>
    <Stack>
      <Typography
        sx={{
          color: '#777777',
          fontSize: '18px',
          marginBottom: '10px',
          minHeight: '165px',
        }}
      >
        This is a great company, and I got my rebate back in the exact time
        frame they said I’d get it. I would reccomend this company to anyone!
      </Typography>
      <Typography
        sx={{ color: '#4d4f51', fontSize: '22px', fontWeight: '700' }}
      >
        Ally B.
      </Typography>
      <Typography sx={{ color: '#788bc2', fontSize: '22px' }}>
        Kansas City, MO
      </Typography>
    </Stack>
  </Box>,
  <Box
    sx={{
      display: 'flex',
      padding: { xs: '0 60px 0 45px', sm: '0' },
      maxWidth: '550px',
      margin: 'auto',
    }}
  >
    <PersonIcon
      sx={{
        color: '#4883bb',
        width: '45px',
        height: '45px',
        marginRight: '10px',
      }}
    ></PersonIcon>
    <Stack>
      <Typography
        sx={{
          color: '#777777',
          fontSize: '18px',
          marginBottom: '10px',
          minHeight: '165px',
        }}
      >
        We are more than satisfied with the service we received from your
        organization. Brandon, Steve and Dustin went above and beyond and never
        faltered from giving us the best of customer service. The sales
        experience from start to finish was professional, pleasant
      </Typography>
      <Typography
        sx={{ color: '#4d4f51', fontSize: '22px', fontWeight: '700' }}
      >
        Luke B.
      </Typography>
      <Typography sx={{ color: '#788bc2', fontSize: '22px' }}>
        Kansas City, MO
      </Typography>
    </Stack>
  </Box>,
];

export const PADDIO_PARTNER_PAGE = [
  {
    id: 0,
    header: 'What do you want to protect?',
    image: SwiperImg1,
    content:
      'Safeguarding what is most important to you has never been simpler. At Safe Haven, we prioritize delivering exceptional 5-star service to our customers every single day. Whether you require traditional monitoring or advanced features such as video surveillance and automation, our dedicated concierge team is eagerly prepared to assist you in protecting what you hold dear.',
  },
  {
    id: 1,
    header: 'SECURITY + HOME AUTOMATION',
    image: SwiperImg2,
    content:
      <>
        <Typography>Take full control of your system using your smartphone, no matter where you are.</Typography>
        <ul>
          <li>Remotely arm or disarm your system with ease.</li>
          <li>Receive immediate alerts regarding any changes in your system's status.</li>
          <li>Monitor your home through live video streaming to ensure peace of mind.</li>
          <li>Manage your lights, locks, thermostat, and more from virtually anywhere with convenient control at your fingertips.</li>
        </ul>
      </>

  },
  {
    id: 2,
    header: 'John Smith',
    image: SwiperImg3,
    content:
      'I had a great experience with Heather. The installer was very professional and he did a great job installing my security system. I feel much safer knowing that my home is protected.',
  },
];
