import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupsIcon from '@mui/icons-material/Groups';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import AccoladesRibbon from '../../Components/NumbersBanner';
import { Helmet } from 'react-helmet';

export default function AboutUs() {
  const navigate = useNavigate();
  const handleNavigate = (route: string) => navigate(`${route}`);

  return (
    <>
      <Helmet>
        <title>About Us | Safe Haven Security</title>
        <meta name="description" content="Learn about Safe Haven Strong, your trusted provider for home security solutions." />
        <meta name="keywords" content="home security, ADT authorized dealer, Safe Haven Strong" />
      </Helmet>
      <Box sx={{ backgroundColor: '#ffffff' }}>
        <Stack direction={'column'} sx={{}}>
          <Container
            maxWidth='xl'
            sx={{
              padding: '2rem',
              minHeight: '45vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ marginBottom: '32px' }}>
              <Typography
                sx={{ color: '#1260a8', fontWeight: '700', fontSize: '48px' }}
              >
                WHO WE ARE
              </Typography>
              <Typography sx={{ fontSize: '32px' }}>
                SAFE HAVEN STRONG
              </Typography>
            </Box>
            <Box sx={{ color: '#777777', fontSize: '16px' }}>
              <Typography sx={{ marginBottom: '10px' }}>
                Not all ADT Authorized Dealers are alike. Don’t settle for
                anything but the best for your security.
              </Typography>
              <Typography sx={{ marginBottom: '10px' }}>
                As the nation’s largest ADT Authorized Dealer and one of the
                fastest-growing segments of the ADT business family, we’ve
                developed an understanding of the needs of our clients, partners
                and employees you’ll find nowhere else.
              </Typography>
              <Typography sx={{ marginBottom: '10px' }}>
                When you work with Safe Haven, you can depend on our team of
                high-level, forward-thinking men and women committed to creating
                a home security system tailored to your needs. We look forward
                to forming real relationships with our clients, our partners and
                each other and serving as a solid resource for home security.
                Most importantly, we’re motivated by keeping you safe.
              </Typography>
            </Box>
          </Container>

          <AccoladesRibbon></AccoladesRibbon>

          <Container maxWidth='xl' sx={{ padding: '70px 0' }}>
            <Typography
              sx={{
                color: '#1260a8',
                fontWeight: '700',
                fontSize: '48px',
                marginBottom: '10px',
              }}
            >
              SAFE HAVEN PROMISE
            </Typography>
            <Typography sx={{ fontSize: '32px', marginBottom: '45px' }}>
              SAFE HAVEN STRONG
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                <List>
                  <ListItem alignItems='flex-start'>
                    <ListItemAvatar>
                      <div className='wpb_text_column wpb_content_element  dt-circle-content'>
                        <div className='wpb_wrapper'>
                          <p>1</p>
                        </div>
                      </div>
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography
                        sx={{ fontSize: '22px', marginBottom: '10px' }}
                      >
                        WE ARE PROFESSIONAL
                      </Typography>
                      <Typography sx={{ color: '#777777' }}>
                        Our team is dedicated to your safety. Every person who
                        comes to your home meets all ADT standards as well as
                        local and state-level licensing and has passed a federal
                        background check. We only use our own employees and will
                        never send a subcontractor.
                      </Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem alignItems='flex-start'>
                    <ListItemAvatar>
                      <div className='wpb_text_column wpb_content_element  dt-circle-content'>
                        <div className='wpb_wrapper'>
                          <p>2</p>
                        </div>
                      </div>
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography
                        sx={{ fontSize: '22px', marginBottom: '10px' }}
                      >
                        WE ONLY USE QUALITY EQUIPMENT{' '}
                      </Typography>
                      <Typography sx={{ color: '#777777' }}>
                        Not only is every component and part we use brand new,
                        but we only use factory OEM parts that meet all of ADT’s
                        standards.
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem alignItems='flex-start'>
                    <ListItemAvatar>
                      <div className='wpb_text_column wpb_content_element  dt-circle-content'>
                        <div className='wpb_wrapper'>
                          <p>3</p>
                        </div>
                      </div>
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography
                        sx={{ fontSize: '22px', marginBottom: '10px' }}
                      >
                        WE ARE A TEAM
                      </Typography>
                      <Typography sx={{ color: '#777777' }}>
                        When you’re hired at Safe Haven, you become a part of
                        our family. We believe our employees learn the Safe
                        Haven way by being trained and promoted from within.
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem alignItems='flex-start'>
                    <ListItemAvatar>
                      <div className='wpb_text_column wpb_content_element  dt-circle-content'>
                        <div className='wpb_wrapper'>
                          <p>4</p>
                        </div>
                      </div>
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography
                        sx={{ fontSize: '22px', marginBottom: '10px' }}
                      >
                        WE ARE COMMITTED TO 100% CLIENT SATISFACTION
                      </Typography>
                      <Typography sx={{ color: '#777777' }}>
                        We don’t just meet ADT’s standard policies. We go above
                        and beyond to make sure our clients are happy and that
                        each system is performing properly.
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={5}>
                <Stack sx={{ alignItems: 'center' }}>
                  <Button
                    variant={'contained'}
                    className='about-us-promise-buttons'
                    endIcon={
                      <NavigateNextIcon
                        sx={{ height: '40px', width: '40px' }}
                      />
                    }
                    onClick={() => {
                      {
                        handleNavigate('/partners');
                      }
                    }}
                  >
                    PARTNERS
                  </Button>
                  <Button
                    variant={'contained'}
                    className='about-us-promise-buttons'
                    endIcon={
                      <NavigateNextIcon
                        sx={{ height: '40px', width: '40px' }}
                      />
                    }
                    onClick={() => {
                      {
                        handleNavigate('/careers');
                      }
                    }}
                  >
                    CAREERS
                  </Button>
                  <Button
                    variant={'contained'}
                    className='about-us-promise-buttons'
                    endIcon={
                      <NavigateNextIcon
                        sx={{ height: '40px', width: '40px' }}
                      />
                    }
                    onClick={() => {
                      {
                        handleNavigate('/contact-us');
                      }
                    }}
                  >
                    CONTACT US
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Container>
          <Box></Box>
        </Stack>
      </Box>
    </>
  );
}
