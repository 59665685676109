import {
  Box,
  TextField,
  Button,
  FormControl,
  IconButton,
  Autocomplete,
  Divider,
} from '@mui/material';
import { useState } from 'react';
import useFetch from 'use-http';
import { Helmet } from 'react-helmet';
import './index.scss';
import ClearIcon from '@mui/icons-material/Clear';
import { useParams } from 'react-router-dom';
import { STATE_LIST } from '../../../Constants';
import { useSearchParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, Form, Field, ErrorMessage, useFormikContext, FieldArray, getIn, FormikErrors, FormikTouched, FormikValues } from 'formik';
import * as Yup from 'yup'; // For form validation
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface PartnerRequestFormProps {
  setConfirmationOpen: (arg0: boolean) => void;
}

export default function RealtorAgentReferralForm({ setConfirmationOpen }: PartnerRequestFormProps) {
  const [queryParameters] = useSearchParams();
  const { AgentName } = useParams();
  const agentFirstInitial = AgentName?.charAt(0) ? AgentName?.charAt(0) : "";
  const agentLastName = AgentName?.slice(AgentName?.indexOf('-') + 1);

  let apiKey = process.env.REACT_APP_API__ACCESS_KEY
    ? process.env.REACT_APP_API__ACCESS_KEY
    : '';
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Connection: 'keep-alive',
      'Accept-Encoding': 'gzip, deflate, br',
      'X-API-Key': apiKey,
    },
  };

  const { post, response, loading, error } = useFetch(
    process.env.REACT_APP_API,
    options
  );

  const initialValues = {
    referrals: [
      {
        firstName: '',
        lastName: '',
        realtorOffice: '',
        address: '',
        city: '',
        state: '',
        email: '',
        emailConfirmation: ''
      }
    ]
  };

  const validationSchema = Yup.object().shape(
    {
      referrals: Yup.array().of(
        Yup.object().shape(
          {
            firstName: Yup.string().required('Required')
              .min(2, 'First name must be at least 2 characters')
              .max(50, 'First name must be at most 50 characters')
              .matches(/^[a-zA-Z '-]+$/, 'First name can only contain letters, hyphens, apostrophes, and spaces'),
            lastName: Yup.string().required('Required'),
            realtorOffice: Yup.string().required('Required'),
            address: Yup.string().required('Required'),
            city: Yup.string().required('Required'),
            state: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email address').required('Required'),
            emailConfirmation: Yup.string().oneOf([Yup.ref('email'), ""], 'Emails must match').required('Required'),
          }
        )
      ),
    }
  );

  type ErrorMessageProps = {
    name: string;
    className?: string;
  };

  const ErrorMessage = ({ name }: ErrorMessageProps) => {
    return (
      <Field name={name}>
        {({ form: { errors, touched } }: { form: { errors: FormikErrors<FormikValues>, touched: FormikTouched<FormikValues> } }) => {
          const error = getIn(errors, name);
          const touch = getIn(touched, name);

          if (touch && error) {
            return (
              <Box className="form-field-error">
                {error}
              </Box>
            );
          }

          return null;
        }}
      </Field>
    );
  };

  return (
    <>
      <Helmet>
        <title>Realtor Agent Referral Form || {AgentName}</title>
        <meta name="description" content="Fill out this form to connect with a realtor agent." />
        <meta name="keywords" content="realtor agent, referral, real estate, property, connect" />
      </Helmet>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          let recipients = agentFirstInitial + agentLastName + '@mysafehaven.com';

          if (queryParameters.get("msh-test")) {
            recipients = 'Samual.Tarpenning@mysafehaven.com, codey.bower@mysafehaven.com, wai.cheng@mysafehaven.com';
          }

          let subject = 'Realtor Agent Referral Alert';
          const content = `
            <h2>Realtor Agent Referrals</h2>

            <table style="border-collapse: collapse; width: 100%;">
              <thead>
                <tr style="background-color: #f2f2f2;">
                  <th style="text-align: left; padding: 8px; border-bottom: 1px solid #ddd;">Referral #</th>
                  <th style="text-align: left; padding: 8px; border-bottom: 1px solid #ddd;">First Name</th>
                  <th style="text-align: left; padding: 8px; border-bottom: 1px solid #ddd;">Last Name</th>
                  <th style="text-align: left; padding: 8px; border-bottom: 1px solid #ddd;">Realtor Office</th>
                  <th style="text-align: left; padding: 8px; border-bottom: 1px solid #ddd;">Address</th>
                  <th style="text-align: left; padding: 8px; border-bottom: 1px solid #ddd;">City</th>
                  <th style="text-align: left; padding: 8px; border-bottom: 1px solid #ddd;">State</th>
                  <th style="text-align: left; padding: 8px; border-bottom: 1px solid #ddd;">Email</th>
                </tr>
              </thead>
              <tbody>
                ${values.referrals.map((referral, index) => `
                  <tr>
                    <td style="padding: 8px; border-bottom: 1px solid #ddd;">${index + 1}</td> 
                    <td style="padding: 8px; border-bottom: 1px solid #ddd;">${referral.firstName}</td>
                    <td style="padding: 8px; border-bottom: 1px solid #ddd;">${referral.lastName}</td>
                    <td style="padding: 8px; border-bottom: 1px solid #ddd;">${referral.realtorOffice}</td>
                    <td style="padding: 8px; border-bottom: 1px solid #ddd;">${referral.address}</td>
                    <td style="padding: 8px; border-bottom: 1px solid #ddd;">${referral.city}</td>
                    <td style="padding: 8px; border-bottom: 1px solid #ddd;">${referral.state}</td>
                    <td style="padding: 8px; border-bottom: 1px solid #ddd;">${referral.email}</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
          `;

          const emailReqBody = {
            to: recipients,
            subject: subject,
            content: content,
          }

          const postResponse = await post(`/Email/SendEmail`, emailReqBody)
            .then((res: any) => {
              if (res) {
                setConfirmationOpen(true);
                resetForm();
              } else {
                console.error("Error caught by API", error);
              }
            }
            ).catch((error: any) => {
              console.error("Catastrophic error in API", error);
            }).finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Box className='' sx={{
              maxWidth: '1024px',
              margin: 'auto',
              '& .quarter-width': { width: { xs: 'calc(50%)', sm: 'calc(25%)' }, minHeight: '90px' },
              '& .half-width': { width: { xs: 'calc(100%)', sm: 'calc(50%)' }, minHeight: '90px' },
              '& .full-width': { width: 'calc(100%)', minHeight: '90px' },
              '& .quarter-width > div': { margin: '0 5px 24px 5px' },
              '& .half-width > div': { margin: '0 5px 24px 5px' },
              '& .full-width > div': { margin: '0 5px 24px 5px' },
            }}>
              <FieldArray name="referrals">
                {({ remove, push }) => (
                  <>
                    {values.referrals && values.referrals.length > 0 &&
                      values.referrals.map((referral, index) => (
                        <Box key={index} className='fields-container'>
                          {index > 0 && (
                            <>
                              <Divider sx={{ margin: '32px 0 8px 0', borderColor: "black", width: '100%' }}></Divider>
                              <Box className="full-width" sx={{ display: 'flex', marginRight: 0 }}>
                                <IconButton
                                  sx={{ margin: 'auto 0 auto auto' }}
                                  onClick={() => remove(index)}
                                >
                                  <RemoveIcon></RemoveIcon>
                                </IconButton>
                              </Box>
                            </>
                          )}

                          <Box className="half-width">
                            <Field
                              as={TextField}
                              name={`referrals.${index}.firstName`}
                              label="First Name *"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              validateOnChange={true}
                              sx={{ width: 'calc(100% - 16px)', margin: '8px!important' }}
                              value={values.referrals[index].firstName}
                              error={getIn(errors, `referrals.${index}.firstName`) && touched.referrals?.[index]?.firstName}
                            />
                            <ErrorMessage
                              name={`referrals.${index}.firstName`}
                              className="form-field-error"
                            />

                          </Box>

                          <Box className="half-width">
                            <Field
                              as={TextField}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`referrals[${index}].lastName`}
                              label="Last Name *"
                              value={values.referrals[index].lastName}
                              sx={{ width: 'calc(100% - 16px)', margin: '8px!important' }}
                              error={getIn(errors, `referrals.${index}.lastName`) && touched.referrals?.[index]?.lastName}
                            />
                            <ErrorMessage
                              name={`referrals.${index}.lastName`}
                              className="form-field-error"
                            />
                          </Box>

                          <Box className="full-width">
                            <Field
                              as={TextField}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`referrals[${index}].realtorOffice`}
                              label="Realtor Office *"
                              value={values.referrals[index].realtorOffice}
                              sx={{ width: 'calc(100% - 16px)', margin: '8px!important' }}
                              error={getIn(errors, `referrals.${index}.realtorOffice`) && touched.referrals?.[index]?.realtorOffice}
                            />
                            <ErrorMessage
                              name={`referrals.${index}.realtorOffice`}
                              className="form-field-error"
                            />
                          </Box>

                          <Box className="half-width">
                            <Field
                              as={TextField}
                              name={`referrals[${index}].address`}
                              label="Address *"
                              value={values.referrals[index].address}
                              sx={{ width: 'calc(100% - 16px)', margin: '8px!important' }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={getIn(errors, `referrals.${index}.address`) && touched.referrals?.[index]?.address}
                            />
                            <ErrorMessage
                              name={`referrals.${index}.address`}
                              className="form-field-error"
                            />
                          </Box>

                          <Box className="quarter-width">
                            <Field
                              as={TextField}
                              className='form-field'
                              name={`referrals[${index}].city`}
                              label="City *"
                              value={values.referrals[index].city}
                              sx={{ width: 'calc(100% - 16px)', margin: '8px!important' }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={getIn(errors, `referrals.${index}.city`) && touched.referrals?.[index]?.city}
                            />
                            <ErrorMessage
                              name={`referrals.${index}.city`}
                              className="form-field-error"
                            />
                          </Box>

                          <Box className="quarter-width" sx={{ minHeight: '90px' }}>
                            <Autocomplete
                              disablePortal
                              id={`referrals.${index}.state`}
                              onBlur={handleBlur}
                              sx={{ display: 'inline-flex', margin: '0!important', width: 'calc(100% - 16px)' }}
                              options={STATE_LIST}
                              onChange={(event, newValue) => {
                                setFieldValue(`referrals.${index}.state`, newValue);
                              }}

                              renderInput={params => (
                                <TextField
                                  sx={{ margin: '8px' }}
                                  {...params}
                                  name={`referrals[${index}].state`}
                                  label="State *"
                                  error={getIn(errors, `referrals.${index}.state`) && touched.referrals?.[index]?.state}
                                />
                              )}
                            />
                            <ErrorMessage
                              name={`referrals.${index}.state`}
                              className="form-field-error"
                            />
                          </Box>

                          <Box className="half-width">
                            <Field
                              as={TextField}
                              name={`referrals[${index}].email`}
                              label="Email *"
                              value={values.referrals[index].email}
                              sx={{ width: 'calc(100% - 16px)', margin: '8px!important' }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={getIn(errors, `referrals.${index}.email`) && touched.referrals?.[index]?.email}
                            />
                            <ErrorMessage
                              name={`referrals.${index}.email`}
                              className="form-field-error"
                            />
                          </Box>

                          <Box className="half-width">
                            <Field
                              as={TextField}
                              name={`referrals[${index}].emailConfirmation`}
                              label="Confirm Email *"
                              value={values.referrals[index].emailConfirmation}
                              sx={{ width: 'calc(100% - 16px)', margin: '8px!important' }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={getIn(errors, `referrals.${index}.emailConfirmation`) && touched.referrals?.[index]?.emailConfirmation}
                            />
                            <ErrorMessage
                              name={`referrals.${index}.emailConfirmation`}
                              className="form-field-error"
                            />
                          </Box>
                        </Box>
                      ))}

                    <Button
                      variant={'contained'}
                      className='contact-form-submit'
                      startIcon={<AddIcon />}
                      onClick={() => push({ firstName: '', lastName: '', realtorOffice: '', address: '', city: '', state: '', email: '', emailConfirmation: '' })}
                      sx={{
                        margin: '16px 0 0 16px',
                        minWidth: '100px',
                        fontSize: '16px',
                        textTransform: 'none',
                        fontWeight: '700',
                      }}
                    >
                      Add Referral
                    </Button>
                    <Box sx={{ display: 'flex', justifyContent: 'center', margin: '24px 0', width: '100%' }}>
                      <LoadingButton
                        variant='contained'
                        className='contact-form-submit'
                        type="submit"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        sx={{
                          minWidth: '100px',
                          fontSize: '16px',
                          textTransform: 'none',
                          fontWeight: '700',
                        }}
                      >
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                      </LoadingButton>
                      {values.referrals.length > 2 && values.referrals.some((_, index) => getIn(errors, `referrals.${index}`)) && (
                        <Box className="form-field-error error-summary-box" >
                          Please fix the errors in the form.
                        </Box>
                      )}
                    </Box>
                  </>
                )}
              </FieldArray>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}
