import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { ReactTagManager } from "react-gtm-ts";
import reportWebVitals from "./reportWebVitals";

const useAnalytics = () => {
	const location = useLocation();
	const hasConsent = localStorage.getItem("cookie-consent") === "true";

	ReactTagManager.init({
		code: "GTM-K3XGL8HK",
		debug: false,
		performance: false,
	});
	useEffect(() => {
		if (hasConsent) {
			// console.log("test")
			// ReactTagManager.action({
			// 	event: "nerd_wallet_page_view",
			// 	data: {
			// 		page_path: location.pathname,
			// 		page_title: document.title,
			// 	},
			// });
			// Report web vitals
		}
	}, [localStorage.getItem("cookie-consent")]);
};

export default useAnalytics;
