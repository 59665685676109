import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import partner from '../../../../Assets/Partner/become-a-partner.png';
import homeBuilder from '../../../../Assets/Partner/partner-graphic-3.PNG';
import partnerIcon1 from '../../../../Assets/Partner/partner-icon-1.png';
import partnerIcon2 from '../../../../Assets/Partner/partner-icon-2.png';
import partnerIcon3 from '../../../../Assets/Partner/partner-icon-3.png';
import rightFit from '../../../../Assets/Partner/right-fit-3.png';
import howItWorks3 from '../../../../Assets/Partner/how-it-works-1.png';
import howItWorks2 from '../../../../Assets/Partner/how-it-works-2.png';
import howItWorks1 from '../../../../Assets/Partner/how-it-works-3.png';
import './index.scss';
import { useState } from 'react';

export default function HomeBuilder() {
  const [expanded, setExpanded] = useState<string | false>('');
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  return (
    <>
      <Box sx={{ minHeight: 'calc(100vh - 100px)' }}>
        <Box sx={{ backgroundColor: 'white', height: 'auto' }}>
          <Container maxWidth={'xl'}>
            <Grid
              container
              sx={{
                flexDirection: { xs: 'column-reverse', md: 'row' },
                padding: { md: '4rem' },
                height: 'auto',
                paddingBottom: { xs: '2rem', md: '4rem' },
              }}
            >
              <Grid
                item
                xs={12}
                md={5}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  className='animated animatedFadeInUp fadeInUp twoHundred'
                  sx={{
                    color: '#616467',
                    textTransform: 'uppercase',
                    fontSize: { xs: '16px', md: '1.2rem' },
                    textAlign: { xs: 'center', md: 'initial' },
                    fontWeight: '700',
                    lineHeight: '1.5',
                    marginTop: { xs: '32px', md: '0' },
                  }}
                >
                  SAFE HAVEN HOME-BUILDER PROGRAM
                </Typography>
                <Typography
                  variant='h1'
                  className='animated animatedFadeInUp fadeInUp fourHundred'
                  sx={{
                    color: '#006fb9',
                    fontSize: { xs: '2.2rem', md: '3.6rem' },
                    textAlign: { xs: 'center', md: 'initial' },
                    fontWeight: 700,
                    lineHeight: 1.1,
                    margin: '25px 0',
                  }}
                >
                  Participate directly in the revenue stream
                </Typography>
                <Typography
                  className='animated animatedFadeInUp fadeInUp sixHundred'
                  sx={{
                    marginTop: { xs: 0, md: '1rem' },
                    lineHeight: 1.5,
                    fontSize: { sm: '1rem', md: '1.25rem' },
                    textAlign: { xs: 'center', md: 'initial' },
                    fontWeight: 300,
                  }}
                >
                  Our Homebuilder Program was designed specifically for
                  homebuilders by a builder and developer, so we understand how
                  important builders’ relationships with clients are. That’s why
                  we designed our program to take care of the client first.
                </Typography>
              </Grid>
              <Grid item xs={0} md={1}></Grid>
              <Grid item xs={12} md={6}>
                <Box
                  component='img'
                  className='fadeIn'
                  sx={{ width: '100%', borderRadius: '10px' }}
                  alt='The house from the offer.'
                  src={homeBuilder}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box sx={{ backgroundColor: 'rgb(235 246 252)', height: 'auto' }}>
          <Container maxWidth={'xl'} sx={{ padding: '48px' }}>
            <Box
              sx={{
                maxWidth: '1180px',
                textAlign: 'center',
                paddingBottom: '40px',
                margin: 'auto',
              }}
            >
              <Typography
                sx={{
                  marginTop: '20px',
                  fontSize: { xs: '28px', md: '32px' },
                  lineHeight: '42px',
                  fontWeight: 700,
                }}
              >
                Grow exponentially and deliver winning solutions to clients
              </Typography>
              <Typography
                sx={{
                  marginTop: '16px',
                  fontSize: { xs: '16px', md: '20px' },
                  lineHeight: '30px',
                  fontWeight: 300,
                }}
              >
                Referral partners use Safe Haven Security as a trusted source to
                drive new business, build meaningful relationships, and provide
                long-term value.
              </Typography>
            </Box>
            <Grid container sx={{ marginBottom: '24px', height: 'auto' }}>
              <Grid item xs={12} md={4} sx={{}}>
                <Stack sx={{ textAlign: 'center' }}>
                  <Box
                    component='img'
                    sx={{ width: '85px', margin: 'auto' }}
                    alt='The house from the offer.'
                    src={partnerIcon1}
                  />
                  <Typography
                    sx={{
                      marginTop: '20px',
                      lineHeight: '26px',
                      fontSize: '24px',
                      fontWeight: 700,
                    }}
                  >
                    Trust goes a long way
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: '10px',
                      lineHeight: '26px',
                      fontSize: '16px',
                      color: '#333',
                    }}
                  >
                    People trust your word with their buying decisions and are
                    more likely to buy when you refer them.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4} sx={{}}>
                <Stack
                  sx={{
                    textAlign: 'center',
                    marginTop: { xs: '36px', md: '0' },
                  }}
                >
                  <Box
                    component='img'
                    sx={{ width: '85px', margin: 'auto' }}
                    alt='The house from the offer.'
                    src={partnerIcon2}
                  />
                  <Typography
                    sx={{
                      marginTop: '20px',
                      lineHeight: '26px',
                      fontSize: '24px',
                      fontWeight: 700,
                    }}
                  >
                    Hit your target audience
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: '10px',
                      lineHeight: '26px',
                      fontSize: '16px',
                      color: '#333',
                    }}
                  >
                    Expand your marketing to reach people you know (and don’t)
                    to share solutions they’ve been looking for.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4} sx={{}}>
                <Stack
                  sx={{
                    textAlign: 'center',
                    marginTop: { xs: '36px', md: '0' },
                  }}
                >
                  <Box
                    component='img'
                    sx={{ width: '85px', margin: 'auto' }}
                    alt='The house from the offer.'
                    src={partnerIcon3}
                  />
                  <Typography
                    sx={{
                      marginTop: '20px',
                      lineHeight: '26px',
                      fontSize: '24px',
                      fontWeight: 700,
                    }}
                  >
                    Gain customer loyalty
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: '10px',
                      lineHeight: '26px',
                      fontSize: '16px',
                      color: '#333',
                    }}
                  >
                    Win favor among your growing customer base who can quickly
                    become your brand ambassadors.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box sx={{ backgroundColor: '#fff', height: 'auto' }}>
          <Container
            maxWidth={'xl'}
            sx={{ paddingTop: '76px', paddingBottom: '76px' }}
          >
            <Box
              sx={{
                maxWidth: '1180px',
                textAlign: 'center',
                paddingBottom: '40px',
                margin: 'auto',
              }}
            >
              <Typography
                sx={{
                  marginTop: '20px',
                  fontSize: '32px',
                  lineHeight: '42px',
                  fontWeight: 700,
                }}
              >
                How It Works
              </Typography>
            </Box>
            <Grid
              container
              spacing={8}
              sx={{
                marginBottom: '24px',
                height: 'auto',
              }}
            >
              <Grid item xs={12} md={4} sx={{}}>
                <Stack sx={{ textAlign: 'center' }}>
                  <Box
                    component='img'
                    sx={{ maxWidth: '100%', margin: 'auto' }}
                    alt='The house from the offer.'
                    src={howItWorks1}
                  />
                  <Typography
                    sx={{
                      marginTop: '20px',
                      lineHeight: '26px',
                      fontSize: '24px',
                      fontWeight: 700,
                    }}
                  >
                    Step 1
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: '10px',
                      lineHeight: '26px',
                      fontSize: '16px',
                      color: '#333',
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4} sx={{}}>
                <Stack sx={{ textAlign: 'center' }}>
                  <Box
                    component='img'
                    sx={{ maxWidth: '100%', margin: 'auto' }}
                    alt='The house from the offer.'
                    src={howItWorks2}
                  />
                  <Typography
                    sx={{
                      marginTop: '20px',
                      lineHeight: '26px',
                      fontSize: '24px',
                      fontWeight: 700,
                    }}
                  >
                    Step 2
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: '10px',
                      lineHeight: '26px',
                      fontSize: '16px',
                      color: '#333',
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4} sx={{}}>
                <Stack sx={{ textAlign: 'center' }}>
                  <Box
                    component='img'
                    sx={{ maxWidth: '100%', margin: 'auto' }}
                    alt='The house from the offer.'
                    src={howItWorks3}
                  />
                  <Typography
                    sx={{
                      marginTop: '20px',
                      lineHeight: '26px',
                      fontSize: '24px',
                      fontWeight: 700,
                    }}
                  >
                    Step 3
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: '10px',
                      lineHeight: '26px',
                      fontSize: '16px',
                      color: '#333',
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box sx={{ backgroundColor: '#006fb9' }}>
          <Container maxWidth={'xl'} sx={{ padding: '40px 0' }}>
            <Grid
              container
              spacing={6}
              sx={{
                alignItems: 'center',
                flexDirection: { xs: 'column-reverse', md: 'row' },
              }}
            >
              <Grid item xs={0} md={1}></Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    marginBottom: '10px',
                    fontSize: { xs: '1.3rem', md: '2rem' },
                    fontWeight: 700,
                    lineHeight: 1.3,
                    color: '#fff',
                    textAlign: { xs: 'center', md: 'initial' },
                  }}
                >
                  Are You a Right Fit?
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: '1rem', md: '1.5rem' },
                    marginBottom: '16px',
                    lineHeight: 1.6,
                    color: '#fff',
                    margin: { xs: '16px', md: '0' },
                    textAlign: { xs: 'center', md: 'initial' },
                  }}
                >
                  The best-fit affiliate partners are ones that are committed to
                  providing their clients with the best possible experience and
                  who is looking for a partner that can help them do just that.
                </Typography>
                <Box
                  sx={{
                    textAlign: { xs: 'center', md: 'initial' },
                    marginTop: '16px',
                  }}
                >
                  <Button variant='contained' sx={{ padding: '12px 32px' }}>
                    Apply Now{' '}
                    <Box
                      sx={{
                        width: '16px',
                        height: '18px',
                        marginLeft: '8px',
                        marginBottom: '2px',
                      }}
                    >
                      <svg
                        width='100%'
                        height='100%'
                        viewBox='0 0 18 11'
                        fill='#fff'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M17.9129 5.856L12.5849 0.888L11.4089 2.064L14.6249 4.92H0.584906V6.768H14.6249L11.4089 9.648L12.5849 10.8L17.9129 5.856Z'
                          fill='inherit'
                        ></path>
                      </svg>
                    </Box>
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  component='img'
                  sx={{ width: { xs: '100%', md: '500px' }, margin: 'auto' }}
                  alt='The house from the offer.'
                  src={rightFit}
                />
              </Grid>
              <Grid item xs={0} md={1}></Grid>
            </Grid>
          </Container>
        </Box>
        <Box sx={{ backgroundColor: 'white', padding: '0 16px' }}>
          <Container sx={{ padding: '4rem 0' }}>
            <Box sx={{ paddingBottom: '3rem' }}>
              <Typography
                variant='h3'
                sx={{
                  fontSize: '32px',
                  fontWeight: 700,
                  lineHeight: '42px',
                  margin: '20px 0 16px 0',
                }}
              >
                FAQ
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '30px',
                }}
              >
                New partnerships mean new questions. Here are some FAQs that
                commonly pop up before joining the Safe Haven Security Affiliate
                Program.
              </Typography>
            </Box>
            <Box>
              <Accordion
                className='accordian'
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
                sx={{ paddingRight: '0' }}
              >
                <AccordionSummary
                  aria-controls='panel1d-content'
                  id='panel1d-header'
                  sx={{ maxWidth: '100%' }}
                >
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: 700,
                    }}
                  >
                    {expanded === 'panel1' ? '-' : '+'} How do we make money?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingRight: '0' }}>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                    blandit leo lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className='accordian'
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
                sx={{ paddingRight: '0' }}
              >
                <AccordionSummary
                  aria-controls='panel2d-content'
                  id='panel2d-header'
                >
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: 700,
                    }}
                  >
                    {expanded === 'panel2' ? '-' : '+'} What is the turnover
                    rate?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                    blandit leo lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className='accordian'
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
                sx={{ paddingRight: '0' }}
              >
                <AccordionSummary
                  aria-controls='panel3d-content'
                  id='panel3d-header'
                >
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: 700,
                    }}
                  >
                    {expanded === 'panel3' ? '-' : '+'} Are these programs
                    offered to any company?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                    blandit leo lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}
