import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import SectionOneBg from '../../Assets/Home/pexels-binyamin-mellish-106399_Day.jpg';
import HomeGradient from '../../Assets/Home/home-gradient.png';
import HomeGradient2 from '../../Assets/Home/home-gradient-2.png';
import HomeGradient3 from '../../Assets/Home/home-gradient-3.png';
import HomeGradient4 from '../../../Assets/Home/home-gradient-4.png';
import Home2 from '../../Assets/Home/house-2.jpg';
import SectionOneHouse3 from '../Assets/Home/home-3.jpg';
import SectionOneHouse4 from '../../Assets/Home/home-4.jpg';
import SectionOneHouse5 from '../../Assets/Home/home-5.jpg';
import SectionOneHouse6 from '../../Assets/Home/home-6.jpg';
import SectionOneHouse7 from '../../Assets/Home/home-7.jpg';
import SectionOneHouse8 from '../../Assets/Home/home-1.jpg';
import SectionTwoDevices from '../../../Assets/Home/Equipment-Packed.png';
import SectionThreeYardSign from '../../../Assets/Home/ADT-Yard-Sign-9_1.jpg';
import SectionFourInstallTech from '../../../Assets/Home/ADT-Install-Tech.jpg';
import SectionFiveMedallion from '../../../Assets/Home/Inc5000_Medallion_.png';
import SectionSixFamily from '../../../Assets/Home/mylittlearmy_04_HighRes-1.jpg';
import equipment1 from '../../Assets/Home/iPhone-13-tilt-screen.png';
import equipment2 from '../../../Assets/Home/Equipment-Packed.png';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import birdEyeRibbon from '../../../Assets/Home/Bird-eye-ribbon.png';
import * as Constants from '../../../Constants/Pages';
import MediaQuery from 'react-responsive';
import { useDispatch } from 'react-redux';
import './index.scss';
import { isMobile } from 'react-device-detect';
import { activate } from '../../../App/State/alertBannerSlice';
import { enable, hideAboutUs } from '../../../App/State/headerSlice.tsx';
import LearusCarousel from '../../../Components/LearusCarousel';

const SectionOne = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(activate());
    dispatch(enable());
    dispatch(hideAboutUs());
  }, []);

  return (
    <>
      <Box
        className='Home-Section-1'
        sx={{
          width: '100%',
          maxWidth: '100%',
          position: 'relative',
          textAlign: 'center',
          zIndex: -1,
        }}
      >
        <Grid
          columns={20}
          container
          spacing={7}
          flexDirection={{ xs: 'column-reverse', md: 'row' }}
          sx={{
            height: '100%',
            maxHeight: '100%',
          }}
        >
          <Grid item xs={20} md={10}>
            <Box
              sx={{
                maxWidth: '100%',
                width: '100%',
                height: '100%',
                display: 'flex',
                margin: 'auto',
                alignItems: 'end',
                justifyContent: 'right',
              }}
            >
              <Box sx={{ maxWidth: '100%', width: '100%' }}>
                <Typography
                  className='animated animatedFadeInUp fadeInUp twoHundred'
                  sx={{
                    color: '#00549c',
                    textTransform: 'uppercase',
                    fontSize: '1.2rem',
                    fontWeight: '500',
                    lineHeight: '1.5',
                    textAlign: { xs: 'center' },
                  }}
                >
                  SAFE HAVEN SECURITY {isMobile && <br></br>} #1 ADT-AUTHORIZED
                  DEALER
                </Typography>
                <Typography
                  variant='h1'
                  className='animated animatedFadeInUp fadeInUp fourHundred'
                  sx={{
                    color: '#000',
                    fontSize: { xs: '2.2rem', md: '3.6rem' },
                    fontWeight: 700,
                    lineHeight: 1.1,
                    margin: '25px 0',
                  }}
                >
                  Live Smart, Live Safe with ADT
                </Typography>
                <Typography
                  className='animated animatedFadeInUp fadeInUp sixHundred'
                  sx={{
                    padding: '0 20px',
                    maxWidth: '500px',
                    textAlign: 'center',
                    margin: 'auto',
                    marginTop: '1rem',
                    lineHeight: 1.5,
                    fontSize: '1.25rem',
                    fontWeight: 300,
                  }}
                >
                  Cutting-edge technology and professional monitoring by trained
                  experts, your home is safer than ever.
                </Typography>
                <Button
                  className='animated animatedFadeInUp fadeInUp eightHundred'
                  sx={{
                    color: '#00549c',
                    fontWeight: 700,
                    fontSize: {
                      xs: '1rem',
                      lg: '1.2rem',
                      xl: '1.5rem',
                    },
                    lineHeight: {
                      xs: '1.6rem',
                      lg: '2rem',
                      xl: '3rem',
                    },
                    textTransform: 'initial',
                    paddingTop: '3px',
                  }}
                >
                  Request free system quote &gt;
                </Button>

                <Box>
                  <Box
                    className='animated animatedFadeInUp fadeInUp eightHundred'
                    component='img'
                    sx={{
                      marginTop: '3rem',
                      height: { xs: 'auto', md: '300px' },
                      maxWidth: '100%',
                    }}
                    alt='The house from the offer.'
                    src={equipment2}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={20} md={10}>
            <IconButton
              aria-label='delete'
              sx={{
                position: 'absolute',
                left: '50%',
                display: 'block',
                fontSize: { xs: '10px', sm: '1rem' },
                padding: '0',
                color: '#00549c',
                width: { xs: '20%', sm: '135px' },
                '&:hover > .home-solutions-button-1': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  borderRadius: '30px',
                },
                '&:hover': {
                  backgroundColor: 'initial',
                },
              }}
            >
              <Box
                className='home-solutions-button-1'
                sx={{
                  width: { xs: '44px', md: '54px', lg: '54px' },
                  padding: { xs: 0, sm: '5px' },
                  margin: 'auto',
                }}
              >
                <VideoCameraFrontIcon
                  sx={{
                    width: { xs: '44px', md: '54px', lg: '54px' },
                    height: 'auto',
                  }}
                />
              </Box>

              <Box
                sx={{
                  margin: 'auto',
                  maxWidth: {
                    xs: '54px',
                    md: '54px',
                    lg: '54px',
                    xl: '64px',
                  },
                  color: '#00549c',
                }}
              >
                Video Monitoring
                <MediaQuery query='(max-device-width: 500px)'>
                  <br></br>
                  <br></br>
                </MediaQuery>
              </Box>
            </IconButton>
            <Box
              className='fadeIn'
              sx={{
                height: 'auto',
                width: 'auto',
                maxWidth: '100%',
                maxHeight: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'relative',
              }}
            >
              <Box
                component='img'
                sx={{
                  height: 'auto',
                  width: 'auto',
                  maxWidth: '100%',
                }}
                alt='The house from the offer.'
                src={HomeGradient4}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const SectionTwo = () => {
  return (
    <Box
      className='Home-Section-2'
      sx={{
        width: '100%',
        maxWidth: '100%',
        backgroundColor: '#d0d0d2',
      }}
    >
      <Container
        sx={{
          marginTop: '1rem',
          textAlign: 'center',
          paddingLeft: { xs: '8px', sm: '16px' },
          paddingRight: { xs: '8px', sm: '16px' },
        }}
      >
        <Stack>
          <Typography
            sx={{
              fontSize: {
                xs: '1.1rem',
                sm: '2.5rem',
                md: '3.0rem',
              },
              color: '#00549c',
            }}
          >
            ADT Security & Smart Home Systems
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: '.85rem',
                sm: '1.6rem',
              },
            }}
          >
            Helping protect what's most important to you.
          </Typography>
          <Button
            sx={{
              color: '#de3c4b',
              fontWeight: 700,
              fontSize: {
                xs: '.55rem',
                sm: '1rem',
              },
              textTransform: 'initial',
            }}
          >
            Browse our security solutions &gt;
          </Button>
          <Stack
            direction={'row'}
            spacing={0}
            sx={{
              marginTop: '1rem',
              placeContent: 'center',
              overflow: 'hidden',
            }}
          >
            <IconButton
              aria-label='delete'
              sx={{
                display: 'block',
                fontSize: { xs: '10px', sm: '1rem' },
                padding: '0',
                color: '#00549c',
                width: { xs: '20%', sm: '135px' },
                '&:hover > .home-solutions-button-1': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  borderRadius: '30px',
                },
                '&:hover': {
                  backgroundColor: 'initial',
                },
              }}
            >
              <Box
                className='home-solutions-button-1'
                sx={{
                  width: { xs: '44px', md: '54px', lg: '54px' },
                  padding: { xs: 0, sm: '5px' },
                  margin: 'auto',
                }}
              >
                <VideoCameraFrontIcon
                  sx={{
                    width: { xs: '44px', md: '54px', lg: '54px' },
                    height: 'auto',
                  }}
                />
              </Box>

              <Box
                sx={{
                  margin: 'auto',
                  maxWidth: {
                    xs: '54px',
                    md: '54px',
                    lg: '54px',
                    xl: '64px',
                  },
                  color: '#00549c',
                }}
              >
                Video Monitoring
                <MediaQuery query='(max-device-width: 500px)'>
                  <br></br>
                  <br></br>
                </MediaQuery>
              </Box>
            </IconButton>
            <IconButton
              aria-label='delete'
              sx={{
                padding: '0',
                width: { xs: '20%', sm: '135px' },
                display: 'block',
                fontSize: { xs: '10px', sm: '1rem' },
                color: '#00549c',
                '&:hover > .home-solutions-button-2': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  borderRadius: '30px',
                },
                '&:hover': {
                  backgroundColor: 'initial',
                },
              }}
            >
              <Box
                className='home-solutions-button-2'
                sx={{
                  width: { xs: '44px', md: '54px', lg: '54px' },
                  padding: { xs: 0, sm: '5px' },
                  margin: 'auto',
                }}
              >
                <AdminPanelSettingsIcon
                  sx={{
                    width: { xs: '44px', md: '54px', lg: '54px' },
                    height: 'auto',
                  }}
                />
              </Box>

              <Box
                sx={{
                  margin: 'auto',

                  color: '#00549c',
                }}
              >
                Home Security Systems
                <MediaQuery query='(max-device-width: 500px)'>
                  <br></br>
                  <br></br>
                </MediaQuery>
              </Box>
            </IconButton>
            <IconButton
              aria-label='delete'
              sx={{
                padding: '0',
                width: { xs: '20%', sm: '135px' },
                display: 'block',
                fontSize: { xs: '10px', sm: '1rem' },
                marginBottom: 'auto',
                color: '#00549c',
                '&:hover > .home-solutions-button-3': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  borderRadius: '30px',
                },
                '&:hover': {
                  backgroundColor: 'initial',
                },
              }}
            >
              <Box
                className='home-solutions-button-3'
                sx={{
                  width: { xs: '44px', md: '54px', lg: '54px' },
                  padding: { xs: 0, sm: '0 0 10px 0' },
                  margin: 'auto',
                }}
              >
                <HealthAndSafetyIcon
                  sx={{
                    width: { xs: '44px', md: '54px', lg: '54px' },
                    height: 'auto',
                  }}
                />
              </Box>

              <Box
                sx={{
                  margin: 'auto',

                  color: '#00549c',
                }}
              >
                Life & Fire Safety
                <MediaQuery query='(max-device-width: 500px)'>
                  <br></br>
                  <br></br>
                </MediaQuery>
              </Box>
            </IconButton>
            <IconButton
              aria-label='delete'
              sx={{
                padding: '0',
                width: { xs: '20%', sm: '135px' },
                display: 'block',
                fontSize: { xs: '10px', sm: '1rem' },
                color: '#00549c',
                '&:hover > .home-solutions-button-4': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  borderRadius: '30px',
                },
                '&:hover': {
                  backgroundColor: 'initial',
                },
              }}
            >
              <Box
                className='home-solutions-button-4'
                sx={{
                  width: { xs: '44px', md: '54px', lg: '54px' },
                  padding: { xs: 0, sm: '5px' },
                  margin: 'auto',
                }}
              >
                <SolarPowerIcon
                  sx={{
                    width: { xs: '44px', md: '54px', lg: '54px' },
                    height: 'auto',
                  }}
                />
              </Box>

              <Box
                sx={{
                  margin: 'auto',
                  maxWidth: {
                    xs: '54px',
                    md: '54px',
                    lg: '54px',
                    xl: '64px',
                  },
                  color: '#00549c',
                }}
              >
                Solar
                <br></br>
                <br></br>
                <MediaQuery query='(max-device-width: 500px)'>
                  <br></br>
                </MediaQuery>
              </Box>
            </IconButton>
            <IconButton
              aria-label='delete'
              sx={{
                padding: '0',
                width: { xs: '20%', sm: '135px' },
                display: 'block',
                fontSize: { xs: '10px', sm: '1rem' },
                color: '#00549c',
                '&:hover > .home-solutions-button-5': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  borderRadius: '30px',
                },
                '&:hover': {
                  backgroundColor: 'initial',
                },
              }}
            >
              <Box
                className='home-solutions-button-5'
                sx={{
                  width: { xs: '44px', md: '54px', lg: '54px' },
                  padding: { xs: 0, sm: '5px' },
                  margin: 'auto',
                }}
              >
                <RoomPreferencesIcon
                  sx={{
                    width: { xs: '44px', md: '54px', lg: '54px' },
                    height: 'auto',
                  }}
                />
              </Box>

              <Box
                sx={{
                  margin: 'auto',
                  maxWidth: {
                    xs: '54px',
                    md: '54px',
                    lg: '54px',
                    xl: '64px',
                  },
                  color: '#00549c',
                }}
              >
                Home Automation
                <MediaQuery query='(max-device-width: 500px)'>
                  <br></br>
                  <br></br>
                </MediaQuery>
              </Box>
            </IconButton>
          </Stack>
          <Box
            component='img'
            sx={{ marginTop: '2rem' }}
            alt='The house from the offer.'
            src={SectionTwoDevices}
          />
        </Stack>
      </Container>
    </Box>
  );
};

const SectionThree = () => {
  return (
    <Box className='Home-Section-3' sx={{ width: '100%', maxWidth: '100%' }}>
      <Stack direction={{ xs: 'column', sm: 'row' }}>
        <Box
          sx={{
            bgcolor: '#045195',
            width: { xs: '100%', sm: '50%' },
            maxWidth: { xs: '100%', sm: '50%' },
            position: 'relative',
            textAlign: 'center',
          }}
        >
          <Box
            component='img'
            sx={{
              zIndex: 0,
              width: '100%',
              maxWidth: '100%',
              marginTop: { xs: '1.5rem', xl: '0' },
              height: { xs: 'calc(100% - 1.5rem)', xl: '100%' },
            }}
            alt='The house from the offer.'
            src={SectionThreeYardSign}
          />
          <Box
            sx={{
              top: '0',
              position: 'absolute',
              zIndex: 1,
              textAlign: 'center',
              width: '100%',
              maxWidth: '100%',
              marginTop: {
                xs: '.65rem',
                md: '1rem',
                lg: '1.5rem',
                xl: '2.5rem',
              },
            }}
          >
            <Typography
              sx={{
                color: '#ffffff',
                fontSize: {
                  xs: '1.5rem',
                  lg: '2rem',
                  xl: '2.5rem',
                },
              }}
            >
              Largest ADT Dealer in the U.S.
            </Typography>
            <Typography
              sx={{
                color: '#ffffff',
                fontSize: {
                  xs: '1rem',
                  lg: '1.3rem',
                  xl: '1.65rem',
                },
              }}
            >
              Trusted to protect families nationwide.
            </Typography>
            <Button
              sx={{
                color: '#80ced7',
                fontWeight: 700,
                fontSize: {
                  xs: '.7rem',
                  sm: '.6rem',
                  md: '.7rem',
                  lg: '.8rem',
                },
                paddingTop: { xs: '2px' },
                textTransform: 'initial',
              }}
            >
              About Safe Haven &gt;
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: '#bccacb',
            width: { xs: '100%', sm: '50%' },
            maxWidth: { xs: '100%', sm: '50%' },
            position: 'relative',
            textAlign: 'center',
            overflow: 'hidden',
          }}
        >
          <Box
            component='img'
            sx={{
              zIndex: 0,
              width: '100%',
              maxWidth: '100%',
              marginTop: { xs: '1.5rem', xl: '0' },
              height: { xs: 'calc(100% - 1.5rem)', xl: '100%' },
            }}
            alt='The house from the offer.'
            src={SectionFourInstallTech}
          />
          <Box
            sx={{
              top: '0',
              position: 'absolute',
              zIndex: 1,
              textAlign: 'center',
              width: '100%',
              maxWidth: '100%',
              marginTop: {
                xs: '.65rem',
                md: '1rem',
                lg: '1.5rem',
                xl: '2.5rem',
              },
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: '1.5rem',
                  lg: '2rem',
                  xl: '2.5rem',
                },
              }}
            >
              Join Our Team
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: '1rem',
                  lg: '1.3rem',
                  xl: '1.65rem',
                },
                lineHeight: {
                  xs: 'initial',
                  sm: '1.2rem',
                  md: 'initial',
                  xl: '2rem',
                },
                maxWidth: { xs: '80%', xl: '70%' },
                margin: 'auto',
              }}
            >
              Become a part of our culture of strength in togetherness &
              achievement.
            </Typography>
            <Box>
              <Button
                sx={{
                  display: 'block',
                  color: '#00549c',
                  fontWeight: 700,
                  fontSize: {
                    xs: '.7rem',
                    sm: '.6rem',
                    md: '.7rem',
                    lg: '.8rem',
                  },
                  lineHeight: {
                    xs: '.7rem',
                    sm: '.6rem',
                    md: '.7rem',
                    lg: '.8rem',
                  },
                  textTransform: 'initial',
                  padding: '4px 5px',
                  margin: 'auto',
                }}
              >
                Learn about careers &gt;
              </Button>
            </Box>
            <Box>
              <Button
                sx={{
                  display: 'block',
                  color: '#de3c4b',
                  fontWeight: 700,
                  fontSize: {
                    xs: '.7rem',
                    sm: '.6rem',
                    md: '.7rem',
                    lg: '.8rem',
                  },
                  lineHeight: {
                    xs: '.7rem',
                    sm: '.6rem',
                    md: '.7rem',
                    lg: '.8rem',
                  },
                  textTransform: 'initial',
                  padding: '4px 5px',
                  margin: 'auto',
                }}
              >
                Apply now &gt;
              </Button>
            </Box>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

const SectionFour = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#546b81',
        background:
          'linear-gradient(to bottom right, #546b81 55%, #50677c 45%);',
        padding: '2rem 0',
      }}
    >
      <Box
        className='Home-Section-4'
        sx={{
          color: '#ffffff',
          textAlign: 'center',
          maxWidth: { xl: '90%' },
          margin: 'auto',
        }}
      >
        <Typography
          sx={{ fontSize: { xs: '1.5rem', sm: '1.2rem', xl: '2rem' } }}
        >
          Client Experiences
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '1rem', sm: '1.2rem' },
            maxWidth: { xs: '60%', sm: 'initial' },
            margin: 'auto',
          }}
        >
          What people are saying about Safe Haven
        </Typography>
        <Typography
          sx={{
            fontFamily: 'PalanquinDark',
            fontSize: '4rem',
            color: '#80ced7',
            transform: 'rotateY(180deg) rotatex(180deg)',
          }}
        >
          "
        </Typography>
        <LearusCarousel
          slides={Constants.EMPLOYEE_REVIEWS_REMODEL}
          type='customerReview'
        ></LearusCarousel>
      </Box>
    </Box>
  );
};

const SectionFive = () => {
  return (
    <Box sx={{ backgroundColor: '#cbd2d0' }}>
      <Container
        className='Home-Section-5'
        sx={{
          minHeight: '10vh',
          width: '100vw',
          display: 'flex',
          flexWrap: 'wrap',
          padding: '30px',
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', sm: '50%' },
            maxWidth: { xs: '100%', sm: '50%' },
            margin: 'auto',
          }}
        >
          <Grid
            container
            sx={{
              width: { xs: '100%', sm: '70%', md: '60%' },
              maxWidth: { xs: '100%', sm: '70%', md: '60%' },
              margin: 'auto',
            }}
          >
            <Grid item xs={10}>
              <Typography
                sx={{
                  width: '100%',
                  paddingTop: '10px',
                  fontSize: '2rem',
                  color: '#00549c',
                  lineHeight: '1',
                }}
              >
                8803 Customer Reviews
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  color: '#00549c',
                  paddingTop: '10px',
                  margin: 'auto',
                  display: 'flex',
                }}
              >
                <StarIcon
                  sx={{ color: 'red', width: '2rem', height: '2rem' }}
                />
                <StarIcon
                  sx={{ color: 'red', width: '2rem', height: '2rem' }}
                />
                <StarIcon
                  sx={{ color: 'red', width: '2rem', height: '2rem' }}
                />
                <StarIcon
                  sx={{ color: 'red', width: '2rem', height: '2rem' }}
                />
                <StarIcon
                  sx={{ color: 'red', width: '2rem', height: '2rem' }}
                />
                <Typography sx={{ fontSize: '1.5rem' }}>&nbsp;5</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              {' '}
              <Box
                component='img'
                sx={{
                  zIndex: 0,
                  width: '100%',
                  maxWidth: '100%',
                  height: 'auto',
                }}
                alt='The house from the offer.'
                src={birdEyeRibbon}
              />
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: '10px' }}>
              <Button
                variant='contained'
                sx={{
                  backgroundColor: 'white',
                  color: '#00549c',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{
                    color: 'black',
                    textTransform: 'initial',
                    lineHeight: '1.75',
                  }}
                >
                  powered by
                </Typography>
                &nbsp;BirdEye
              </Button>
            </Grid>
          </Grid>
        </Box>
        <MediaQuery query='(max-device-width: 500px)'>
          <Divider sx={{ width: 'calc(100% - 0px)', margin: '1rem 0' }} />
        </MediaQuery>

        <Box
          sx={{
            width: { xs: '100%', sm: '50%' },
            maxWidth: { xs: '100%', sm: '50%' },
            margin: 'auto',
          }}
        >
          <Stack direction={'row'}>
            <Typography
              sx={{
                width: { xs: '100%', sm: '50%' },
                fontSize: '2rem',
                color: '#00549c',
                lineHeight: '1',
              }}
            >
              Named an <br></br>
              Inc. 5000 <br></br>
              Fastest Growing Company
            </Typography>
            <Box
              sx={{ width: { xs: '100%', sm: '50%' }, position: 'relative' }}
            >
              <Box
                component='img'
                sx={{
                  maxWidth: {
                    xs: '100%',
                  },
                  margin: 'auto',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
                alt='The house from the offer.'
                src={SectionFiveMedallion}
              />
            </Box>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

const SectionSix = () => {
  return (
    <Box
      className='Home-Section-6'
      sx={{
        height: { xs: '20vh', sm: '25vh', md: '55vh', lg: '60vh' },
        width: '100%',
        backgroundImage: `url(${SectionSixFamily})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom 70% right 0px',
        overflow: 'hidden',
      }}
    ></Box>
  );
};

export default function Home() {
  return (
    <>
      <CssBaseline />
      <Container maxWidth={false} sx={{ padding: '0!important' }}>
        <Box
          sx={{
            minHeight: 'calc(80vh - 200px)',
            overflow: 'hidden',
          }}
        >
          <SectionOne></SectionOne>
          <SectionTwo></SectionTwo>
          <SectionThree></SectionThree>
          <SectionFour></SectionFour>
          <SectionFive></SectionFive>
          <SectionSix></SectionSix>
        </Box>
      </Container>
    </>
  );
}
