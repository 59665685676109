declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

// Ensure the `gtag` function is defined on the global `window` object.
function initializeGtag(): void {
  window.gtag = (...args: any[]): void => {
    window.dataLayer.push(args);
  };
}

// Initialize the dataLayer and gtag function if not already initialized.
function ensureGtagInitialization(): void {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  if (!window.gtag) {
    initializeGtag();
  }
}

export function gtagDeny(): void {
  ensureGtagInitialization();
  console.log("Denying consent");
  window.gtag("consent", "default", {
    ad_storage: "denied",
    analytics_storage: "denied",
    wait_for_update: 500,
  });
}

export function gtagAccept(): void {
  ensureGtagInitialization();
  console.log("Accepting consent");
  window.gtag("consent", "update", {
    ad_storage: "granted",
    analytics_storage: "granted",
    wait_for_update: 500,
  });
}
