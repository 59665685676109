import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import repAlex from '../../Assets/Solar/Alex Zhurba.png';
import repBrandon from '../../Assets/Solar/Brandon Prater.png';
import repAnahi from '../../Assets/Solar/New_Anahi Mejia.png';
import repCharles from '../../Assets/Solar/New_Charles Yennie.png';
import repDovie from '../../Assets/Solar/New_Dovie Koening.png';
import repGarren from '../../Assets/Solar/New_Garren Azama.png';
import repJaymond from '../../Assets/Solar/New_Jaymond Kelly.png';
import repJesse from '../../Assets/Solar/New_Jesse Roades.png';
import repMatt from '../../Assets/Solar/New_Matt Gabel.png';
import solarLogo from '../../Assets/Solar/Safe-Haven-Solar-logo-1536x409.png';
import solarPanelHouse from '../../Assets/Solar/shutterstock_1368232784-1536x988.jpg';
import googleReviews from '../../Assets/Solar/Google-reviews-300x145.jpeg';
import sunIcon from '../../Assets/Solar/Sun-icon.png';
import './index.scss';
import { Helmet } from 'react-helmet';

export default function Solar() {
  return (
    <>
      <Helmet>
        <title>Safe Haven Solar - Experience the Power of Renewable Energy | Safe Haven Security</title>
        <meta name="description" content="Safe Haven Solar helps homeowners gain energy independence by investing in solar panels. We offer virtual consultations and a white-glove installation process." />
        <meta name="keywords" content="Safe Haven Solar, solar panels, renewable energy, energy independence, virtual consultations, white-glove installation" />
      </Helmet>
      <Box sx={{ backgroundColor: '#ffffff' }}>
        <Box
          sx={{
            background: {
              md: 'linear-gradient(to bottom, #1260a8 50%, #ffffff 50%)',
            },
          }}
        >
          <Container
            maxWidth={'xl'}
            sx={{
              display: 'flex',
              padding: '0!important',
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Stack sx={{ maxWidth: { md: '50%' } }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  background: { xs: '#1260a8', md: 'initial' },
                  padding: { xs: '20px', md: '0' },
                }}
              >
                <Box
                  component='img'
                  sx={{
                    width: 'auto',
                    maxWidth: '100%',
                    height: { xs: 'auto', md: '250px' },
                    maxHeight: '50%',
                    margin: 'auto',
                  }}
                  alt='The house from the offer.'
                  src={solarLogo}
                />
              </Box>
              <Box
                sx={{
                  padding: { xs: '20px', md: '0 20px' },
                  height: { md: '250px' },
                  maxHeight: { md: '50%' },
                  display: 'flex',
                }}
              >
                <Typography
                  sx={{
                    color: '#4b4b4b',
                    fontSize: '20px',
                    fontWeight: '600',
                    margin: 'auto',
                  }}
                >
                  Safe Haven Solar is headquartered in Kansas City, Missouri.
                  Our team specializes in virtual consultations from the comfort
                  of your own home. We have installed thousands of solar
                  projects to happy clients nationwide. Our mission is to help
                  our clients gain energy independence by investing in renewable
                  energy.
                </Typography>
              </Box>
            </Stack>
            <Box sx={{ display: 'flex' }}>
              <Box
                component='img'
                sx={{
                  width: 'auto',
                  maxWidth: '100%',
                  height: '100%',
                  maxHeight: '100%',
                  margin: 'auto',
                }}
                alt='The house from the offer.'
                src={solarPanelHouse}
              />
            </Box>
          </Container>
        </Box>

        <Container maxWidth='xl'>
          <Grid
            container
            spacing={0}
            sx={{
              display: 'inline-flex',
              margin: { xs: '70px 0 0px 0', md: '70px 0 70px 0' },
            }}
          >
            <Grid
              item
              xs={12}
              md={3}
              sx={{ paddingBottom: { xs: '30px', md: '' } }}
            >
              <Box
                className='google-reviews-img-wrapper'
                sx={{ display: 'flex' }}
              >
                <Box
                  component='img'
                  sx={{
                    width: 'auto',
                    maxWidth: '100%',
                    maxHeight: '75vh',
                    margin: 'auto',
                  }}
                  alt='The house from the offer.'
                  src={googleReviews}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={9} sx={{ display: 'flex' }}>
              <Box sx={{ paddingLeft: '35px', margin: 'auto' }}>
                <Typography
                  sx={{
                    fontSize: '22px',
                    color: '#4b4b4b',
                    marginBottom: '10px',
                  }}
                >
                  At Safe Haven Solar, customer experience and satisfaction is
                  what matters most to us.
                </Typography>
                <Typography sx={{ color: '#4b4b4b', fontSize: '22px' }}>
                  We want to provide great communication to you with a white
                  glove process from the start of the process to the
                  installation of your panels.
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container columns={10}>
            <Grid
              item
              xs={10}
              sm={5}
              md={3}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Typography
                sx={{
                  color: '#4b4b4b',
                  fontSize: '32px',
                  fontWeight: '600',
                  margin: { xs: '35px auto', md: '70px 0' },
                }}
              >
                SOLAR FACTS
              </Typography>
            </Grid>
            <Grid item xs={10} sm={5} md={7}>
              <Divider
                sx={{
                  borderBottomWidth: 5,
                  margin: 'auto',
                  height: '50%',
                }}
              />
            </Grid>
          </Grid>

          <Box sx={{ marginBottom: '' }}>
            <List>
              <ListItem sx={{ marginBottom: '20px' }}>
                <ListItemAvatar>
                  <Avatar
                    src={sunIcon}
                    sx={{ height: '70px', width: '70px' }}
                  />
                </ListItemAvatar>
                <ListItemText>
                  <Typography
                    sx={{
                      color: '#4b4b4b',
                      fontSize: '21px',
                      fontWeight: '600',
                      marginLeft: '20px',
                    }}
                  >
                    More than 1 in 7 U.S. homeowners will have solar panels on
                    their roof by 2030.
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem sx={{ marginBottom: '20px' }}>
                <ListItemIcon>
                  <Avatar
                    src={sunIcon}
                    sx={{ height: '70px', width: '70px' }}
                  />
                </ListItemIcon>

                <ListItemText>
                  <Typography
                    sx={{
                      color: '#4b4b4b',
                      fontSize: '21px',
                      fontWeight: '600',
                      marginLeft: '20px',
                    }}
                  >
                    Solar power has become the fastest-growing electricity
                    source in the U.S.1, representing 54% of generation projects
                    planned for 2023.
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem sx={{ marginBottom: '' }}>
                <ListItemIcon>
                  <Avatar
                    src={sunIcon}
                    sx={{ height: '70px', width: '70px', marginBottom: '35px' }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      color: '#4b4b4b',
                      fontSize: '21px',
                      fontWeight: '600',
                      marginLeft: '20px',
                    }}
                  >
                    According to a study by the National Renewable Energy
                    Laboratory2, each $1 of annual savings achieved by solar
                    panels increases your home value by $20. Based on this
                    figure, a home solar system that saves you $1,500 per year
                    would increase your home value by $30,000.
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Box>

          <Grid container columns={10} sx={{ paddingBottom: '35px' }}>
            <Grid
              item
              xs={10}
              sm={5}
              md={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography
                sx={{
                  color: '#4b4b4b',
                  fontSize: '32px',
                  fontWeight: '600',
                  // margin: { xs: '35px auto', md: '40px 0' },
                }}
              >
               Solar Leadership
              </Typography>
            </Grid>
            <Grid item xs={10} sm={5} md={7}>
              <Divider
                sx={{
                  borderBottomWidth: 5,
                  margin: 'auto',
                  height: '50%',
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={4}>
              <Box
                component='img'
                sx={{
                  width: 'auto',
                  maxWidth: '340px',
                  maxHeight: '55vh',
                  margin: 'auto',
                }}
                alt='The house from the offer.'
                src={repGarren}
              />
            </Grid>
            {/* <Grid item xs={6} sm={4}>
              <Box
                component='img'
                sx={{
                  width: 'auto',
                  maxWidth: '100%',
                  maxHeight: '75vh',
                  margin: 'auto',
                }}
                alt='The house from the offer.'
                src={repAnahi}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box
                component='img'
                sx={{
                  width: 'auto',
                  maxWidth: '100%',
                  maxHeight: '75vh',
                  margin: 'auto',
                }}
                alt='The house from the offer.'
                src={repJesse}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box
                component='img'
                sx={{
                  width: 'auto',
                  maxWidth: '100%',
                  maxHeight: '75vh',
                  margin: 'auto',
                }}
                alt='The house from the offer.'
                src={repJaymond}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box
                component='img'
                sx={{
                  width: 'auto',
                  maxWidth: '100%',
                  maxHeight: '75vh',
                  margin: 'auto',
                }}
                alt='The house from the offer.'
                src={repAlex}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box
                component='img'
                sx={{
                  width: 'auto',
                  maxWidth: '100%',
                  maxHeight: '75vh',
                  margin: 'auto',
                }}
                alt='The house from the offer.'
                src={repBrandon}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box
                component='img'
                sx={{
                  width: 'auto',
                  maxWidth: '100%',
                  maxHeight: '75vh',
                  margin: 'auto',
                }}
                alt='The house from the offer.'
                src={repDovie}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box
                component='img'
                sx={{
                  width: 'auto',
                  maxWidth: '100%',
                  maxHeight: '75vh',
                  margin: 'auto',
                }}
                alt='The house from the offer.'
                src={repMatt}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box
                component='img'
                sx={{
                  width: 'auto',
                  maxWidth: '100%',
                  maxHeight: '75vh',
                  margin: 'auto',
                }}
                alt='The house from the offer.'
                src={repCharles}
              />
            </Grid> */}
          </Grid>
          <Box sx={{ margin: '140px 0 0 0', paddingBottom: '35px' }}>
            <Typography>
              <sup>1</sup>https://www.eia.gov/todayinenergy/detail.php?id=55419
            </Typography>
            <Typography>
              <sup>2</sup>https://www.nrel.gov/docs/fy08osti/42733.pdf
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
}
