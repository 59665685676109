import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, minHeight, Stack } from '@mui/system';
import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; // For form validation
import nraLogo from "../../../../../Assets/Partner/NRA/NRA-logo-300x168.png";
import packageGraph from "../../../../../Assets/Templates/perfectVisionGraph.png";
import llpPackages from "../../../../../Assets/Templates/llp-enterprises-packages.png";
import CompanyLogo from '../../../../../Components/Header/CompanyLogo';
import "./index.scss";
import PartnerLogo from '../../../../../Components/PartnerLogo';
import useFetch from 'use-http';
import { HIDE_DOB_FIELD, HIDE_PARTNER_LOGO, HIDE_SSN_FIELD } from '../../Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { STATE_CODES, STATE_LIST, TRAX_LEAD } from '../../../../../Constants';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DateValidationError } from '@mui/x-date-pickers/models';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface propsWrapper {
    partnerInfo: PartnerInfo
}

export default function TemplateFive(props: propsWrapper) {
    const { partnerInfo } = props;
    const { pathname } = useLocation();
    const [showButton, setShowButton] = useState(false);
    const SHOW_SCROLL_BUTTON_THRESHOLD = 300;
    const navigate = useNavigate();

    const handleNavigate = (route: string, location: string | null = null) => {
        if (location === '_blank') {
            window.open(route, location);
        } else {
            navigate(route);
        }
    };

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        const scrollListener = () => {
            setShowButton(window.scrollY > SHOW_SCROLL_BUTTON_THRESHOLD);
        };

        window.addEventListener('scroll', scrollListener);
        return () => window.removeEventListener('scroll', scrollListener);
    }, []);

    let apiKey = process.env.REACT_APP_API__ACCESS_KEY
        ? process.env.REACT_APP_API__ACCESS_KEY
        : '';
    const options = {
        headers: {
            'Content-Type': 'application/json',
            Connection: 'keep-alive',
            'Accept-Encoding': 'gzip, deflate, br',
            'X-API-Key': apiKey,
        },
        cacheLife: 1000,
    };

    const { post, response, loading } = useFetch(
        process.env.REACT_APP_API,
        options
    );

    // Formik Initial Values and Validation Schema
    const initialValues = {
        campaignId: partnerInfo.CampaignId,
        fname: '',
        lname: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        primary_phone: '',
        phoneCell: '',
        ssn: '',
        dob: null,
        email: '',
        emailConfirm: '',
        repName: '',
        repId: '',
        notes: '',
        package: '',
        consent: false,
    };

    const validationSchema = Yup.object({
        fname: Yup.string().required('Required'),
        lname: Yup.string().required('Required'),
        address: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        state: Yup.string().required('Required'),
        Zip: Yup.string().matches(/^\d{5}(-\d{4})?$/, 'Invalid ZIP code format').required('Required'),
        primary_phone: Yup.string().required('Required'),
        phoneCell: Yup.string().required('Required'),
        repName: Yup.string().required('Required'),
        repId: Yup.string().required('Required'),
        consent: Yup.boolean().required('Required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
        emailConfirm: Yup.string().required('Required'),
        package: Yup.string().required('Required'),

        ssn: Yup.string().when('campaignId', {
            is: "3046" || "2238",
            then: () => Yup.date().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        dob: Yup.string().when('campaignId', {
            is: "3046" || "2238",
            then: () => Yup.date().required('Required'),
            otherwise: () => Yup.date().nullable()
        }),
    });

    function constructTraxLeadSubmissionData(values: any) {
        const { repId, state, ...remainingValues } = values;

        const stateInput = state;

        const stateCode = STATE_CODES.find(state => state.label === stateInput)?.value;

        return {
            ...remainingValues,
            state: stateCode,
            leadCompanyId: "18",
            leadCompanyCampaignId: "620",
        };
    }

    function constructZohoLeadSubmissionData(values: any) {
        const { state, ...remainingValues } = values;

        const stateInput = state;

        const stateCode = STATE_CODES.find(state => state.label === stateInput)?.value;

        return {
            ...remainingValues,
            state: stateCode,
            partnerCompanyId: '1265',
            partnerCampaignId: '2759',
        };
    }

    return (
        <Box id='template5-wrapper'>
            {showButton && (
                <Box className='scroll-top-button' onClick={handleScrollToTop}>
                    <ArrowUpwardIcon className='arrow-icon'></ArrowUpwardIcon>
                </Box>
            )}

            <Grid container className='mobile-center' sx={{ maxWidth: "xl", margin: '45px auto', }}>
                <Grid className='mobile-center' item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <CompanyLogo className=''></CompanyLogo>
                </Grid>
                <Grid className='mobile-center' item xs={12} md={6} sx={{
                    display: 'flex',
                    textAlign: 'right',
                    justifyContent: 'right',
                }}>
                    {HIDE_PARTNER_LOGO.includes(pathname) ? null : <PartnerLogo partnerInfo={partnerInfo}></PartnerLogo>}
                </Grid>
            </Grid>

            <Container maxWidth={"lg"} className='blue-gradient-bg'>
                <Typography className='entry-form-title'>Order Entry Form</Typography>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        try {
                            const isTraxLead = TRAX_LEAD.includes(pathname.toLowerCase());

                            const submissionData = isTraxLead
                                ? constructTraxLeadSubmissionData(values)
                                : constructZohoLeadSubmissionData(values);

                            const endpoint = isTraxLead
                                ? "/Partner/CreateTraxLead"
                                : "/Partner/CreateZohoLeadByPartnerId";

                            const postResponse = await post(endpoint, submissionData);
                            console.log("Request sent to " + endpoint + " successfully:", postResponse);
                            resetForm();
                        } catch (error) {
                            console.error("Error sending request:", error);
                        } finally {
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ errors, touched, values, setFieldValue, isSubmitting }) => (
                        <Form>
                            <Box className='fields-container perfect-vision black-field-container'>
                                <Field
                                    as={TextField}
                                    className='half-width left-field'
                                    sx={{
                                        minHeight: 60,
                                        maxHeight: 60
                                    }}
                                    name="fname"
                                    label="First Name"
                                    value={values.fname}
                                    onChange={(event: any) => { setFieldValue("fname", event.target.value) }}
                                    error={touched.fname && Boolean(errors.fname)}
                                    helperText={touched.fname && errors.fname}
                                />

                                <Field
                                    as={TextField}
                                    className='half-width right-field'
                                    sx={{
                                        minHeight: 60,
                                        maxHeight: 60
                                    }}
                                    name="lname"
                                    label="Last Name"
                                    value={values.lname}
                                    onChange={(event: any) => { setFieldValue("lname", event.target.value) }}
                                    error={touched.lname && Boolean(errors.lname)}
                                    helperText={touched.lname && errors.lname}
                                />

                                <Field
                                    as={TextField}
                                    className='full-width'
                                    sx={{
                                        minHeight: 60,
                                        maxHeight: 60
                                    }}
                                    name="address"
                                    label="Address"
                                    value={values.address}
                                    onChange={(event: any) => { setFieldValue("address", event.target.value) }}
                                    error={touched.address && Boolean(errors.address)}
                                    helperText={touched.address && errors.address}
                                />

                                <Field
                                    as={TextField}
                                    className='half-width left-field'
                                    sx={{
                                        minHeight: 60,
                                        maxHeight: 60
                                    }}
                                    name="city"
                                    label="City"
                                    value={values.city}
                                    onChange={(event: any) => { setFieldValue("city", event.target.value) }}
                                    error={touched.city && Boolean(errors.city)}
                                    helperText={touched.city && errors.city}
                                />

                                <Autocomplete
                                    disablePortal
                                    className={`half-width right-field`}
                                    sx={{
                                        display: 'inline-flex',
                                        margin: "0!important",
                                        '& div>div>input': {
                                            padding: '12px 12px!important',
                                        },
                                        '& div>div>fieldset': {
                                            maxHeight: '43px',
                                        },
                                        '& div>div': {
                                            maxHeight: '38px',
                                        },
                                        '& div>div>div.MuiAutocomplete-endAdornment': {
                                            zIndex: 1,
                                        },
                                        minHeight: 60,
                                        maxHeight: 60,
                                    }}
                                    options={STATE_CODES}
                                    inputValue={values.state}
                                    onInputChange={(event, newInputValue) => {
                                        setFieldValue("state", newInputValue);
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            sx={{ marginLeft: { md: '0px' } }}
                                            {...params}
                                            name="state"
                                            label="State *"
                                            error={touched.state && Boolean(errors.state)}
                                            helperText={touched.state && errors.state}
                                        />
                                    )}
                                />

                                <Field
                                    as={TextField}
                                    sx={{
                                        minHeight: 60,
                                        maxHeight: 60
                                    }}
                                    className='full-width'
                                    name="zip"
                                    label="Zip"
                                    value={values.zip}
                                    onChange={(event: any) => { setFieldValue("zip", event.target.value) }}
                                    error={touched.zip && Boolean(errors.zip)}
                                    helperText={touched.zip && errors.zip}
                                />

                                <Box className='perfect-vision-spacer' sx={{ width: '100%', minHeight: '16px' }}></Box>

                                <Field
                                    as={TextField}
                                    sx={{
                                        minHeight: 60,
                                        maxHeight: 60
                                    }}
                                    type="text"
                                    className='half-width left-field'
                                    name="primary_phone"
                                    label="Phone - Main"
                                    value={values.primary_phone}
                                    onChange={(event: any) => { setFieldValue("primary_phone", event.target.value) }}
                                    error={touched.primary_phone && Boolean(errors.primary_phone)}
                                    helperText={touched.primary_phone && errors.primary_phone}
                                />

                                <Field
                                    as={TextField}
                                    sx={{
                                        minHeight: 60,
                                        maxHeight: 60
                                    }}
                                    type="text"
                                    className='half-width right-field'
                                    name="phoneCell"
                                    label="Phone - Cell"
                                    value={values.phoneCell}
                                    onChange={(event: any) => { setFieldValue("phoneCell", event.target.value) }}
                                    error={touched.phoneCell && Boolean(errors.phoneCell)}
                                    helperText={touched.phoneCell && errors.phoneCell}
                                />

                                {!HIDE_SSN_FIELD.includes(partnerInfo.CampaignId) &&
                                    <Field
                                        as={TextField}
                                        sx={{
                                            minHeight: 60,
                                            maxHeight: 60
                                        }}
                                        type="text"
                                        className='half-width left-field'
                                        name="ssn"
                                        label="Social Security #"
                                        value={values.ssn}
                                        onChange={(event: any) => { setFieldValue("ssn", event.target.value) }}
                                        error={touched.ssn && Boolean(errors.ssn)}
                                        helperText={touched.ssn && errors.ssn}
                                    />
                                }

                                {!HIDE_DOB_FIELD.includes(partnerInfo.CampaignId) &&
                                    <FormControl
                                        className='half-width right-field'
                                        sx={{ width: '100%', minHeight: '60px', maxHeight: '60px' }}
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Field
                                                as={DatePicker}
                                                name="dob"
                                                className='full-width'
                                                sx={{
                                                    "& div>div": {
                                                        minHeight: '38px'
                                                    },
                                                    "& div>div>button>svg": {
                                                        zIndex: 999,
                                                    },
                                                    minHeight: 60,
                                                    maxHeight: 60
                                                }}
                                                disableFuture
                                                label="Date of Birth"
                                                format="MM/DD/YYYY"
                                                value={values.dob}
                                                onChange={(date: any) => { setFieldValue("dob", date) }}
                                                renderInput={(params: any) => <TextField {...params} />}
                                                error={touched.dob && Boolean(errors.dob)}
                                                helperText={touched.dob && errors.dob}
                                            />
                                        </LocalizationProvider>
                                        <FormHelperText sx={{ color: '#d32f2f' }}>{touched.dob && errors.dob}</FormHelperText>
                                    </FormControl>
                                }

                                <Field
                                    as={TextField}
                                    sx={{
                                        minHeight: 60,
                                        maxHeight: 60
                                    }}
                                    type="text"
                                    className='half-width left-field'
                                    name="email"
                                    label="Customer Email"
                                    value={values.email}
                                    onChange={(event: any) => { setFieldValue("email", event.target.value) }}
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                />

                                <Field
                                    as={TextField}
                                    sx={{
                                        minHeight: 60,
                                        maxHeight: 60
                                    }}
                                    type="text"
                                    className='half-width right-field'
                                    name="emailConfirm"
                                    label="Confirm Customer Email"
                                    value={values.emailConfirm}
                                    onChange={(event: any) => { setFieldValue("emailConfirm", event.target.value) }}
                                    error={touched.emailConfirm && Boolean(errors.emailConfirm)}
                                    helperText={touched.emailConfirm && errors.emailConfirm}
                                />

                                <Field
                                    as={TextField}
                                    sx={{
                                        minHeight: 60,
                                        maxHeight: 60
                                    }}
                                    className='half-width left-field'
                                    name="repName"
                                    label={`${partnerInfo.PartnerName} - Name`}
                                    value={values.repName}
                                    onChange={(event: any) => { setFieldValue("repName", event.target.value) }}
                                    error={touched.repName && Boolean(errors.repName)}
                                    helperText={touched.repName && errors.repName}
                                />

                                <Field
                                    as={TextField}
                                    sx={{
                                        minHeight: 60,
                                        maxHeight: 60
                                    }}
                                    className='half-width right-field'
                                    name="repId"
                                    label={`${partnerInfo.PartnerName} - Rep Id`}
                                    value={values.repId}
                                    onChange={(event: any) => { setFieldValue("repId", event.target.value) }}
                                    error={touched.repId && Boolean(errors.repId)}
                                    helperText={touched.repId && errors.repId}
                                />

                                <Field
                                    as={TextField}
                                    rows={4}
                                    multiline
                                    className='full-width'
                                    name="notes"
                                    label="Notes"
                                    value={values.notes}
                                    onChange={(event: any) => { setFieldValue("notes", event.target.value) }}
                                    error={touched.notes && Boolean(errors.notes)}
                                    helperText={touched.notes && errors.notes}
                                />

                                {pathname != '/partners/isg-leads' &&
                                    <>
                                        <Box
                                            component='img'
                                            sx={{
                                                display: 'flex',
                                                height: 'auto',
                                                width: '100%',
                                                maxWidth: '1200px',
                                                margin: '16px auto'
                                            }}
                                            alt='Employee Image'
                                            src={pathname === '/partners/llp-enterprises' ? llpPackages : packageGraph}
                                        />

                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', height: '60px', minHeight: '60px' }}>
                                            <FormControl className="half-width" sx={{}}>
                                                <InputLabel>Package Selection *</InputLabel>
                                                <Field
                                                    as={Select}
                                                    name="package"
                                                    label="Package Selection *"
                                                    value={values.package}
                                                    className='pv-package-select'
                                                    sx={{
                                                        height: '40px', minHeight: '40px',
                                                        '& svg': {
                                                            zIndex: '1'
                                                        },
                                                    }}
                                                    onChange={(event: { target: { value: any; }; }) => setFieldValue("package", event.target.value)}
                                                    error={touched.package && Boolean(errors.package)}
                                                    helpertext={touched.package && errors.package}
                                                    IconComponent={ArrowDropDownIcon}
                                                >
                                                    <MenuItem value="cellular">Cellular $45.99</MenuItem>
                                                    <MenuItem value="interactive">Interactive $55.99</MenuItem>
                                                    <MenuItem value="cameras">Cameras $63.99</MenuItem>
                                                    <MenuItem value="automation">Camera + Automation $65.99</MenuItem>
                                                </Field>
                                                <FormHelperText sx={{ color: '#d32f2f' }}>{touched.package && errors.package}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </>
                                }



                                {pathname != '/partners/jmg-leads' && pathname != '/partners/isg-leads' &&
                                    <Box className='full-width'>
                                        <Typography className='perfect-vision-script'>
                                            Alright, ________ as I said, the last step is for me to bring an ADT representative on the line.
                                            They are going to verify the details of your order, schedule the installation date, verify your credit, and collect the $____ activation fee we discussed.
                                            I will be with you on the line to ensure everything is setup correctly, however if we were to get disconnected do I have your authorization to have ADT call you back at this number?
                                        </Typography>
                                    </Box>
                                }

                                <Box className='full-width perfect-vision-consent' sx={{ paddingTop: '10px' }}>
                                    <FormControlLabel
                                        name='consent'
                                        className={touched.consent && !values.consent ? 'error-checkbox' : ''}
                                        control={
                                            <Checkbox
                                                checked={values.consent}
                                                onChange={(event: { target: { checked: boolean; }; }) => { setFieldValue("consent", event.target.checked) }
                                                }
                                            />
                                        }
                                        label=" Customer has given opt-in consent for Safe Haven ADT to contact them in the event call is dropped during transfer.*"
                                        sx={{
                                            margin: '0 8px 8px 8px',
                                            alignItems: 'flex-start',
                                            '& .MuiFormControlLabel-asterisk': {
                                                paddingTop: '9px',
                                                height: '5px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                justifyContent: 'space-around',
                                            },
                                            '& .MuiTypography-root': {
                                                paddingTop: '8px',
                                            },
                                            '& span': {
                                                fontWeight: 700
                                            },
                                        }}
                                    />
                                </Box>

                                <Box sx={{ textAlign: 'center', marginBottom: '' }}>
                                    <LoadingButton
                                        variant='contained'
                                        className='template5-submit'
                                        type="submit"
                                        loading={isSubmitting}
                                        disabled={isSubmitting}
                                        sx={{
                                            maxHeight: '56px',
                                            minWidth: '100px',
                                            fontSize: '16px',
                                            textTransform: 'none',
                                            fontWeight: '700',
                                            borderRadius: '0'
                                        }}
                                    >
                                        {isSubmitting ? 'Submitting...' : 'Submit'}
                                    </LoadingButton>
                                </Box>

                                <Box sx={{ marginTop: '16px' }}>
                                    <Typography className='bottom-disclaimer-text'>By clicking “Submit” I hereby acknowledge the customer has provided consent for Safe Haven ADT to obtain their consumer credit report, charge their card the applicable activation fee, and have requested opt-in for a callback from Safe Haven ADT should the call be disconnected during the transfer process</Typography>
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Container>
            <Box sx={{
                background: '#2a5ab9',
                color: '#fff',
                height: '8vh',
                padding: '70px 20px 0px'
            }}>
                <Typography className='' sx={{ textAlign: 'center', fontSize: '12px' }}>
                    License numbers by State: AL-1493, AR- CMPY.0002629, AZ- ROC-332758,18404-0, CA-ACO8016, CT-HIC.0661825, DC-602521000002, DE-17-39, FL-EF20000905, GA-LVU406182, IA-AS-0158, C115438, IL-127.001386, LA-F1672, MA-8150 A1, SS-002127, MD-107-1696, MI-3601208054, MN-TS710056, MS-15019531, NC-1150-CSA, SP.FA/LV.28805, NH-064, NJ- 34BX00006600, NM-403946, NV-0079533, NY-12000077734, OK-AC1802, OR-234026, PA-PA040539, RI-4604, TSC8204, SC-BAC.13574, TN-2280, TX-B14839,ACR-2625584, UT-10999117-6501, VA-11-6402, WA-SAFEHHS791CH, WV-WV060562
                </Typography>
                <Typography className='' sx={{ textAlign: 'center', fontSize: '12px' }}>
                    <br></br>
                    Copyright: Smart Home Advantage 2021 | <Link sx={{
                        color: '#fff',
                        font: 'normal 12px / 22px "Montserrat", Helvetica, Arial, Verdana, sans-serif',
                        cursor: 'pointer',
                        textDecoration: 'underline'
                    }}
                        onMouseDown={(event) => {
                            if (event.button === 1) {
                                handleNavigate('/terms-and-conditions', '_blank');
                            }
                        }}
                        onClick={(event) => {
                            handleNavigate('/terms-and-conditions');
                        }}
                    >
                        Terms & Conditions
                    </Link>
                </Typography>
            </Box>
        </Box>
    );
} 