import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import law1 from '../../Assets/Legal/law-balance.jpg';
import CircleIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

export default function Legal() {
  const navigate = useNavigate();
  const handleNavigate = (route: string) => navigate(`${route}`);

  return (
    <>
      <Helmet>
        <title>Safe Haven Security Services - Legal Information | Safe Haven Security</title>
        <meta name="description" content="Access Safe Haven Security's legal information, including the Privacy Policy, Terms & Conditions, and information on requesting or deleting your personal information." />
        <meta name="keywords" content="Safe Haven Security, legal information, privacy policy, terms and conditions, personal information request, deletion" />
      </Helmet>

      <Header></Header>

      <Box sx={{ backgroundColor: '#ffffff' }}>
        <Container
          maxWidth='xl'
          sx={{
            backgroundColor: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ marginBottom: '128px' }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              <ListItem alignItems='center'>
                <ListItemAvatar>
                  <CircleIcon
                    sx={{ height: '18px', width: '18px' }}
                  ></CircleIcon>
                </ListItemAvatar>
                <ListItemText>
                  <Button
                    variant='text'
                    sx={{
                      fontSize: { xs: '18px', md: '24px' },
                      textAlign: 'left',
                      padding: '0',
                      textTransform: 'inherit',
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      {
                        handleNavigate('/legal/privacy/');
                      }
                    }}
                  >
                    Privacy Policy
                  </Button>
                </ListItemText>
              </ListItem>

              <ListItem alignItems='center'>
                <ListItemAvatar>
                  <CircleIcon
                    sx={{ height: '18px', width: '18px' }}
                  ></CircleIcon>
                </ListItemAvatar>

                <ListItemText>
                  <Button
                    variant='text'
                    sx={{
                      fontSize: { xs: '18px', md: '24px' },
                      textAlign: 'left',
                      padding: '0',
                      textTransform: 'inherit',
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      {
                        handleNavigate('/legal/affirmation/');
                      }
                    }}
                  >
                    Request, Correct, or Delete My Personal Information
                  </Button>
                </ListItemText>
              </ListItem>

              <ListItem alignItems='center'>
                <ListItemAvatar>
                  <CircleIcon
                    sx={{ height: '18px', width: '18px' }}
                  ></CircleIcon>
                </ListItemAvatar>

                <ListItemText>
                  <Button
                    variant='text'
                    sx={{
                      fontSize: { xs: '18px', md: '24px' },
                      textAlign: 'left',
                      padding: '0',
                      textTransform: 'inherit',
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      {
                        handleNavigate(
                          '/legal/safe-haven-terms-and-conditions/'
                        );
                      }
                    }}
                  >
                    Terms & Conditions for Safe Haven Subscriptions
                  </Button>
                </ListItemText>
              </ListItem>

              <ListItem alignItems='center'>
                <ListItemAvatar>
                  <CircleIcon
                    sx={{ height: '18px', width: '18px' }}
                  ></CircleIcon>
                </ListItemAvatar>

                <ListItemText>
                  <Button
                    variant='text'
                    sx={{
                      fontSize: { xs: '18px', md: '24px' },
                      textAlign: 'left',
                      padding: '0',
                      textTransform: 'inherit',
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      {
                        handleNavigate('/terms-and-conditions/');
                      }
                    }}
                  >
                    Terms & Conditions for ADT Security Services
                  </Button>
                </ListItemText>
              </ListItem>
            </List>
          </Box>
        </Container>
      </Box>
    </>
  );
}

function Header() {
  return <>
    <Box
      sx={{
        backgroundColor: '#ffffff',
        minHeight: '25vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{ color: '#1260a8', fontWeight: '700', fontSize: { xs: '24px', md: '32px', lg: '48px' }, textAlign: 'center' }}
      >
        Safe Haven Security Services – Legal Information
      </Typography>
    </Box>
  </>;
}