import { Box, IconButton, Modal, Slide, TextField, Typography } from '@mui/material';
import defaultEmployee from '../../Assets/misc/best-employee.png';
import { Container, Stack } from '@mui/system';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import useFetch from 'use-http';
import './index.scss';
import { EMPTY_EMPLOYEE_PHOTO } from '../../Constants';
import ClearIcon from '@mui/icons-material/Clear';

export default function EmployeeVerification() {
  const [employeeId, setEmployeeId] = useState({
    value: '',
    error: { value: false, message: '' },
  });

  const [employeeImage, setEmployeeImage] = useState('');
  const [employeeStartDate, setEmployeeStartDate] = useState('');
  const [employeeName, setEmployeeName] = useState({ fname: '', lname: '' });
  const [employeeActiveStatus, setEmployeeActiveStatus] = useState(false);
  const [yearsOfExperience, setYearsOfExperience] = useState(0);

  const handleFieldChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentInput = event.target.value;

    let validationRegex = /^\d+|$/;

    if (!validationRegex.test(currentInput)) {
      setEmployeeId((prevData) => ({
        ...prevData,
        error: { value: true, message: 'Incorrect entry.' },
      }));
    } else {
      setEmployeeId({
        value: currentInput,
        error: { value: false, message: '' },
      });
    }
  };

  let apiKey = process.env.REACT_APP_API__ACCESS_KEY
    ? process.env.REACT_APP_API__ACCESS_KEY
    : '';
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Connection: 'keep-alive',
      'Accept-Encoding': 'gzip, deflate, br',
      'X-API-Key': apiKey,
    },
    cacheLife: 1000,
  };

  const { get, response, loading } = useFetch(
    process.env.REACT_APP_API,
    options
  );

  const { post, response: postResponse, loading: postLoading } = useFetch(
    process.env.REACT_APP_API,
    options
  );

  async function handleLookUpSubmit() {
    let activeValue = null;

    const initialTodosV2 = await post(`/Employee/V2/${employeeId.value}`);
    const typedInitialTodosV2 = initialTodosV2 as { code: string; content: string; };

    if (postResponse.ok && typedInitialTodosV2.code === "OK") {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(typedInitialTodosV2.content, "text/xml");

      const modelElements = xmlDoc.querySelectorAll('response data models model');

      modelElements.forEach(modelElement => {
        const idElement = modelElement.querySelector('field[name="id"]');
        const fnameElement = modelElement.querySelector('field[name="fname"]');
        const lnameElement = modelElement.querySelector('field[name="lname"]');
        const activeElement = modelElement.querySelector('field[name="active"]');
        const startDateElement = modelElement.querySelector('field[name="created_at"]');

        const fnameValue = fnameElement ? fnameElement.textContent : null;
        const lnameValue = lnameElement ? lnameElement.textContent : null;
        activeValue = activeElement ? activeElement.textContent : null;
        const idValue = idElement ? idElement.textContent : null;
        const startDateValue = startDateElement ? startDateElement.textContent : null;

        if (activeValue) {
          setEmployeeActiveStatus(true);
        }
        if (idValue) {
          setEmployeeId((prevData) => ({
            ...prevData,
            value: idValue,
          }));
        }

        if (startDateValue) {
          const originalDateString = startDateValue;

          const date = new Date(startDateValue);
          const formattedDate = date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          });

          const currentDate = new Date();
          let yearsDiff = currentDate.getFullYear() - date.getFullYear();

          if (
            currentDate.getMonth() < date.getMonth() ||
            (currentDate.getMonth() === date.getMonth() && currentDate.getDate() < date.getDate())
          ) {
            yearsDiff--;
          }

          setYearsOfExperience(yearsDiff);
          setEmployeeStartDate(formattedDate);
        }

        console.log(initialTodosV2)
        console.log(xmlDoc)
        console.log(activeValue)

        if (!activeValue) {
          setEmployeeId((prevData) => ({
            ...prevData,
            error: { value: true, message: 'Employee Not Found.' },
          }));
        } else if (fnameValue || lnameValue) {
          setEmployeeName({ fname: fnameValue ? fnameValue : '', lname: lnameValue ? lnameValue : '' })
        }
      });

      const initialTodos = await get(`/Employee/${employeeId.value}`);
      const typedInitialTodos = initialTodos as { code: string; content: string; };

      console.log("error value: " + employeeId.error.value + " activeValue value: " + activeValue)

      if (response.ok && typedInitialTodos.code === "OK" && activeValue === "true") {
        const parsedData = JSON.parse(typedInitialTodos.content);
        const profileImageUrl = parsedData.data.meta.profile_image_url;

        setEmployeeImage(profileImageUrl);
        handleModalOpen();
      } else {
        setEmployeeId((prevData) => ({
          ...prevData,
          error: { value: true, message: 'Employee Not Found.' },
        }));
      }

    } else {
      setEmployeeId((prevData) => ({
        ...prevData,
        error: { value: true, message: 'Employee Not Found.' },
      }));
    }
  }

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Container
        sx={{
          border: '1px solid #1260a8',
          borderRadius: '6px',
          margin: '70px 0',
          padding: '32px!important',
        }}
      >
        <Typography
          sx={{ fontSize: '40px', fontWeight: '700', color: '#1260a8' }}
        >
          EMPLOYEE VERIFICATION
        </Typography>
        <Typography sx={{ fontSize: '32px' }}>
          Please Enter Below The Employee ID Number You Wish To Confirm
        </Typography>

        <Box
          id='employee-id-input-wrapper'
          sx={{ padding: '2rem 0', display: 'flex' }}
        >
          <TextField
            id='employee-id-input'
            label=''
            className=''
            sx={{ marginRight: '1rem' }}
            value={employeeId.value}
            onChange={handleFieldChange()}
            error={employeeId.error.value}
            helperText={employeeId.error.message}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleLookUpSubmit();
              }
            }}
          />
          <LoadingButton
            variant='contained'
            className='contact-form-submit'
            onClick={() => {
              handleLookUpSubmit();
            }}
            loading={loading || postLoading}
            sx={{
              maxHeight: '56px',
              minWidth: '100px',
              fontSize: '16px',
              textTransform: 'none',
              fontWeight: '700',
            }}
          >
            Submit
          </LoadingButton>

          <Modal
            open={modalOpen}
            closeAfterTransition
            onClose={handleModalClose}
            className=''
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Slide direction='up' in={modalOpen}>
              <Container
                sx={{
                  width: { xs: '100%', sm: 'auto' },
                  height: { xs: 'auto', sm: '400px' },
                  bgcolor: '#fff',
                  border: '2px solid #000',
                  borderRadius: '9px',
                  boxShadow: 24,
                  padding: { sm: '36px!important', md: '48px!important', },
                  display: 'flex',
                  position: 'relative'
                }}
              >
                <Box sx={{
                  position: "absolute",
                  right: "12px",
                  top: '12px'
                }}>
                  <IconButton
                    sx={{ height: '25px', width: '25px' }}
                    onClick={handleModalClose}
                  >
                    <ClearIcon sx={{ color: 'rgb(159 159 159)', height: '25px', width: '25px' }}></ClearIcon>
                  </IconButton>
                </Box>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  sx={{ alignItems: 'center', justifyContent: 'space-evenly', paddingTop: { xs: '36px', sm: '0' } }}
                >
                  <Box
                    component='img'
                    sx={{
                      maxHeight: { sm: '100%' },
                      minHeight: { sm: '250px' },
                      width: 'auto',
                      maxWidth: { xs: '100%', sm: 'auto' },
                      minWidth: { sm: '250px' },
                    }}
                    alt='Employee Image Not Found'
                    src={
                      employeeImage &&
                        employeeImage.includes('?')
                        ? employeeImage
                        : defaultEmployee
                    }
                  />
                  <Box
                    sx={{
                      paddingLeft: { sm: '36px' },
                      minHeight: '250px',
                      minWidth: '250px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                    }}
                  >
                    <Box className='emp-ver-info'>
                      <Typography sx={{ fontSize: '48px', fontWeight: '700' }}>
                        {employeeName.fname} {employeeName.lname}
                      </Typography>
                      {/* <Typography sx={{ fontSize: '24px', color: '#1260a8' }}>
                        Employee ID: {employeeId.value}
                      </Typography>
                      <Typography sx={{ fontSize: '24px', color: '#1260a8' }}>
                        {yearsOfExperience} years of Experience
                      </Typography> */}
                      <Typography sx={{ fontSize: { xs: '36px', sm: '32px' }, color: '#1260a8', }}>
                        Safe Haven Certified
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
              </Container>
            </Slide>
          </Modal>
        </Box>
      </Container>
    </>
  );
}
