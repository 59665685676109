import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import './index.scss';
import defaultImg from '../../Assets/Partner/FreedomMortgage/Freedom-logo-Blue-writing-1-300x90.png'
import isgLogo from '../../Assets/Templates/ISG-LOGO-BLUE-83x83.png'
import llpLogo from '../../Assets/Templates/llp-enterprises-logo.png'
import jocelynLogo from '../../Assets/Templates/bethany-white-group-logo.png'
import { fetchContentfulImage, fetchContentfulImageId } from '../../Pages/Partners/Affiliates/Functions';
import { useLocation } from 'react-router-dom';

interface propsWrapper {
  partnerInfo: PartnerInfo
}

export default function PartnerLogo(props: propsWrapper) {
  const { pathname } = useLocation();
  const [imageInfoRes, setImageInfoRes] = useState<ImageInfo | null>(null);
  const [partnerLogo, setPartnerLogo] = useState(null);

  useEffect(() => {
    if (props.partnerInfo.CampaignId) {
      fetchContentfulImageId(props.partnerInfo.CampaignId)
        .then((res: any) => {
          if (res) {
            setImageInfoRes(res)
          }
        }
        ).catch((error: any) => {
          console.log("fetchContentfulImageId error: " + error);
        });
    }
  }, [props.partnerInfo.CampaignId])

  useEffect(() => {
    if (imageInfoRes && imageInfoRes.contentfulId) {
      fetchContentfulImage(imageInfoRes.contentfulId)
        .then((res: any) => {
          if (res) {
            setPartnerLogo(res)
          }
        }
        ).catch((error: any) => {
          console.log("fetchContentfulImage error: " + error);
        });
    }
  }, [imageInfoRes])

  return (
    <>
      {partnerLogo ? (
        <>
          <Box
            component="img"
            src={
              pathname === '/partners/isg' ? isgLogo
                : pathname === '/partners/llp-enterprises' ? llpLogo : pathname === '/partners/jocelyn-rivard' ? jocelynLogo : partnerLogo}
            alt={`Partner Logo ${props.partnerInfo.PartnerId}`}
            sx={{ maxWidth: "100%", height: "auto", maxHeight: '100%' }}
          />
        </>

      ) : (
        // Possibly show a placeholder or loading indicator while fetching and have a parameter to set it to true/false
        <Box sx={{ width: '70px', height: '70px', backgroundColor: 'white' }}>
        </Box>
      )}
    </>
  );
}
