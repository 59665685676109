import '@progress/kendo-theme-default/dist/all.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
// import {
//   Map,
//   TileUrlTemplateArgs,
//   MapLayers,
//   MapTileLayer,
//   MapMarkerLayer,
//   MarkerTooltipContext,
//   MapMarkerLayerTooltip,
// } from '@progress/kendo-react-map';

const mainOfficeLocation = [39.1417852, -94.5743722];

const tileSubdomains = ['a', 'b', 'c'];

// const tileUrl = (e: TileUrlTemplateArgs) =>
//   `https://${e.subdomain}.tile.openstreetmap.org/${e.zoom}/${e.x}/${e.y}.png`;

// const attribution =
//   '&copy; <a href="https://osm.org/copyright">OpenStreetMap contributors</a>';

// const markers = [{ latlng: [39.1417852, -94.5743722], name: 'Office Name' }];

// const renderMarkerTooltip = (props: MarkerTooltipContext) => (
//   <span>{props.dataItem.name}</span>
// );

export default function OfficeMap() {
  return (
    <>
      <div>
        {/* <Map center={mainOfficeLocation} zoom={15}>
          <MapLayers>
            <MapTileLayer
              urlTemplate={tileUrl}
              subdomains={tileSubdomains}
              attribution={attribution}
            />
            <MapMarkerLayer
              data={markers}
              locationField='latlng'
              titleField='name'
            >
              <MapMarkerLayerTooltip render={renderMarkerTooltip} />
            </MapMarkerLayer>
          </MapLayers>
        </Map> */}
      </div>
    </>
  );
}
