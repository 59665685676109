import { Box, Button, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import './index.scss';
import { Helmet } from 'react-helmet';
import Vimeo from '@u-wave/react-vimeo';
import { useState } from 'react';

const initialVideoAccess = {
  showVideo: false,
  inputValue: "",
};

export default function SalesManagerOverview() {
  const [videoAccess, setVideoAccess] = useState(initialVideoAccess);

  const handleChange = (event: { target: { value: any; }; }) => {
    setVideoAccess({ ...videoAccess, inputValue: event.target.value });
  };

  const handleClick = () => {
    if (videoAccess.inputValue === "safehaven546") {
      setVideoAccess({ ...videoAccess, showVideo: true });
    } else {
      setVideoAccess({ ...videoAccess, inputValue: "" });
    }
  };

  return (
    <>
      <Helmet>
        <title>Sales Manager Overview: Key Information and Insights | Safe Haven Security</title>
        <meta
          name="description"
          content="Empower your sales managers with essential knowledge and strategies to drive success. This comprehensive overview covers crucial topics for effective sales leadership."
        />
        <meta
          name="keywords"
          content="sales manager, sales leadership, sales training, sales strategies, sales performance, sales coaching, sales team management, sales goals, sales metrics, sales incentives"
        />
      </Helmet>
      <Box sx={{ background: '#fff', width: '100vw' }}>
        <Container maxWidth={"xl"} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: "100%", padding: { sm: '35px 0', md: "70px 0" } }}>
          <Vimeo className="vimeo-player-ISMO" video={"https://player.vimeo.com/video/498132756?dnt=1&app_id=122963"} width={"100%"} height={"auto"} controls autoplay />
        </Container>
      </Box>
    </>
  );
}
