import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useMediaQuery } from 'react-responsive';
import SwiperCarousel from '../../Components/SwiperCarousel';
import * as Constants from '../../Constants/Pages';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper';
import { CUSTOMER_REVIEWS } from '../../Constants/CustomerSupport';

export default function Reviews() {
  const isDesktop = useMediaQuery({ minWidth: 1200 });
  return (
    <>
      {isDesktop && CUSTOMER_REVIEWS}
      {!isDesktop && (
        <Box sx={{ background: '#eee', padding: '16px 0 45px 0' }}>
          <Swiper
            cssMode={true}
            navigation={true}
            pagination={true}
            mousewheel={true}
            keyboard={true}
            loop={true}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            className='cust-solutions-Swiper'
          >
            {Constants.CUSTOMER_REVIEWS_LIST.map((slide: any, index: any) => (
              <SwiperSlide
                className={'cust-solutions-Swiper-slide'}
                key={index}
              >
                {slide}
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      )}
    </>
  );
}
