import './index.scss';
import {
    AppBar,
    Box,
    Button,
    Container,
    Divider,
    Drawer,
    IconButton,
    ListItemButton,
    ListItemText,
    MenuItem,
    Toolbar,
    Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { HEADER_HEIGHT } from '../../Constants/index';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../App/hooks';
import { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Accoladebanner from './AccoladeBanner';
import CompanyLogo from './CompanyLogo';
import { navItems } from '../../Constants/Header';

export default function Header(props: any) {
    const navigate = useNavigate();
    const handleNavigate = (route: string) => navigate(`${route}`);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [partnersDropdown, setPartnersDropdown] = useState(false);
    const [customersDropdown, setCustomersDropdown] = useState(false);
    const [careersDropdown, setCareersDropdown] = useState(false);

    const [isHovered, setIsHovered] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState<number>(-1);
    const handleMouseEnter = (index: number) => {
        setIsHovered(true);
        setHoveredIndex(index);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const handleClose = () => {
        setIsHovered(false);
    };

    const generalHeaderDisplay = useAppSelector((state) => state.header.display);

    const openInNewTab = (url: string) => {
        window.open(url, "_blank", "noreferrer");
    };

    return (
        <Box>
            {generalHeaderDisplay && (
                <Box sx={{}}>
                    <Accoladebanner></Accoladebanner>

                    <AppBar position='static'
                        onMouseLeave={handleMouseLeave}
                        sx={{
                            backgroundColor: '#fff',
                            height: HEADER_HEIGHT,
                        }}>
                        <Container
                            maxWidth={false}
                            sx={{ maxWidth: '1550px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Toolbar
                                disableGutters
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                    maxHeight: { xs: '60px', lg: '78px' },
                                    width: '100%',
                                }}
                            >
                                {/* Hamburger menu and drawer for mobile/tablet */}
                                <Box sx={{ display: { xs: 'flex', lg: 'none' }, marginRight: { xs: '0', sm: '32px', md: '64px' } }}>
                                    <IconButton
                                        size='large'
                                        onClick={() => {
                                            setDrawerOpen(!drawerOpen);
                                        }}
                                    >
                                        <MenuIcon />
                                    </IconButton>

                                    <Drawer
                                        anchor={'left'}
                                        open={drawerOpen}
                                        onClose={() => {
                                            setDrawerOpen(false);
                                        }}
                                    >
                                        <Box sx={{ minHeight: '70px' }}></Box>
                                        <Divider></Divider>

                                        <Box sx={{ width: { xs: '80vw' } }}>
                                            <Box
                                                className='partners-wrapper'
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Button
                                                    className='partners-button drawer-button'
                                                    endIcon={<ArrowDropDownIcon />}
                                                    onClick={() => {
                                                        {
                                                            setPartnersDropdown(!partnersDropdown);
                                                            setCustomersDropdown(false);
                                                            setCareersDropdown(false);
                                                        }
                                                    }}
                                                    sx={{
                                                        minWidth: '120px',
                                                        minHeight: '70px',
                                                        padding: '8px 16px',
                                                        justifyContent: 'flex-start',
                                                        '& .MuiButton-endIcon': {
                                                            margin: '0!important',
                                                        },
                                                    }}
                                                >
                                                    <Typography className='partners-button-text'>
                                                        Partners
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    className='partners-button drawer-button drawer-subButton'
                                                    onClick={() => {
                                                        {
                                                            handleNavigate('/partners');
                                                            setDrawerOpen(false);
                                                        }
                                                    }}
                                                    sx={{
                                                        display: partnersDropdown
                                                            ? 'flex'
                                                            : 'none!important',
                                                        minWidth: '120px',
                                                        minHeight: '70px',
                                                        padding: '8px 16px',
                                                        justifyContent: 'flex-start',
                                                        '& .MuiButton-endIcon': {
                                                            margin: '0!important',
                                                        },
                                                    }}
                                                >
                                                    <Typography className='partners-button-text'>
                                                        Home
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    className='partners-button drawer-button drawer-subButton'
                                                    onClick={() => {
                                                        {
                                                            handleNavigate('/partners/agent-referral');
                                                            setDrawerOpen(false);
                                                            setPartnersDropdown(false);
                                                        }
                                                    }}
                                                    sx={{
                                                        display: partnersDropdown
                                                            ? 'flex'
                                                            : 'none!important',
                                                        minWidth: '120px',
                                                        minHeight: '70px',
                                                        padding: '8px 16px',
                                                        justifyContent: 'flex-start',
                                                        '& .MuiButton-endIcon': {
                                                            margin: '0!important',
                                                        },
                                                    }}
                                                >
                                                    <Typography className='partners-button-text'>
                                                        Agent Referral
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    className='partners-button drawer-button drawer-subButton'
                                                    onClick={() => {
                                                        {
                                                            handleNavigate('/partners/homebuilder-partners');
                                                            setDrawerOpen(false);
                                                            setPartnersDropdown(false);
                                                        }
                                                    }}
                                                    sx={{
                                                        display: partnersDropdown
                                                            ? 'flex'
                                                            : 'none!important',
                                                        minWidth: '120px',
                                                        minHeight: '70px',
                                                        padding: '8px 16px',
                                                        justifyContent: 'flex-start',
                                                        '& .MuiButton-endIcon': {
                                                            margin: '0!important',
                                                        },
                                                    }}
                                                >
                                                    <Typography className='partners-button-text'>
                                                        Homebuilder Partners
                                                    </Typography>
                                                </Button>
                                            </Box>

                                            <Box className='customers-wrapper'>
                                                <Button
                                                    className='customers-button drawer-button'
                                                    endIcon={<ArrowDropDownIcon />}
                                                    sx={{
                                                        minWidth: '120px',
                                                        minHeight: '70px',
                                                        padding: '8px 16px',
                                                        justifyContent: 'flex-start',
                                                        '& .MuiButton-endIcon': {
                                                            margin: '0!important',
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        {
                                                            setCustomersDropdown(!customersDropdown);
                                                            setPartnersDropdown(false);
                                                            setCareersDropdown(false);
                                                        }
                                                    }}
                                                >
                                                    <Typography className='customers-button-text'>
                                                        Customers
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    className='partners-button drawer-button'
                                                    onClick={() => {
                                                        {
                                                            handleNavigate('/customer-support');
                                                            setDrawerOpen(false);
                                                            setCustomersDropdown(false);
                                                            setCareersDropdown(false);
                                                        }
                                                    }}
                                                    sx={{
                                                        display: customersDropdown
                                                            ? 'flex'
                                                            : 'none!important',
                                                        minWidth: '120px',
                                                        minHeight: '70px',
                                                        padding: '8px 16px',
                                                        justifyContent: 'flex-start',
                                                        '& .MuiButton-endIcon': {
                                                            margin: '0!important',
                                                        },
                                                    }}
                                                >
                                                    <Typography className='partners-button-text'>
                                                        Customer Support
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    className='partners-button drawer-button'
                                                    onClick={() => {
                                                        {
                                                            handleNavigate('/customer-solutions');
                                                            setDrawerOpen(false);
                                                            setCustomersDropdown(false);
                                                            setCareersDropdown(false);
                                                        }
                                                    }}
                                                    sx={{
                                                        display: customersDropdown
                                                            ? 'flex'
                                                            : 'none!important',
                                                        minWidth: '120px',
                                                        minHeight: '70px',
                                                        padding: '8px 16px',
                                                        justifyContent: 'flex-start',
                                                        '& .MuiButton-endIcon': {
                                                            margin: '0!important',
                                                        },
                                                    }}
                                                >
                                                    <Typography className='partners-button-text'>
                                                        Customer Solutions
                                                    </Typography>
                                                </Button>
                                            </Box>

                                            <Box>
                                                <Button
                                                    onClick={() => {
                                                        {
                                                            handleNavigate('/solar');
                                                        }
                                                    }}
                                                    className='drawer-button'
                                                >
                                                    Solar
                                                </Button>
                                            </Box>

                                            <Box
                                                className='partners-wrapper'
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Button
                                                    className='partners-button drawer-button'
                                                    // endIcon={<ArrowDropDownIcon />}
                                                    onClick={() => {
                                                        {
                                                            handleNavigate('/careers');
                                                            setDrawerOpen(false);
                                                            setPartnersDropdown(false);
                                                            setCustomersDropdown(false);
                                                            setCareersDropdown(!careersDropdown);
                                                        }
                                                    }}
                                                    sx={{
                                                        minWidth: '120px',
                                                        minHeight: '70px',
                                                        padding: '8px 16px',
                                                        justifyContent: 'flex-start',
                                                        '& .MuiButton-endIcon': {
                                                            margin: '0!important',
                                                        },
                                                    }}
                                                >
                                                    <Typography className='partners-button-text'>
                                                        Careers
                                                    </Typography>
                                                </Button>
                                                {/* <Button
                          className='partners-button drawer-button drawer-subButton'
                          onClick={() => {
                            {
                              handleNavigate('/careers');
                              setDrawerOpen(false);
                            }
                          }}
                          sx={{
                            display: careersDropdown
                              ? 'flex'
                              : 'none!important',
                            minWidth: '120px',
                            minHeight: '70px',
                            padding: '8px 16px',
                            justifyContent: 'flex-start',
                            '& .MuiButton-endIcon': {
                              margin: '0!important',
                            },
                          }}
                        >
                          <Typography className='partners-button-text'>
                            Home
                          </Typography>
                        </Button>
                        <Button
                          className='partners-button drawer-button drawer-subButton'
                          onClick={() => {
                            {
                              handleNavigate('/careers/realtor-team');
                              setDrawerOpen(false);
                              setPartnersDropdown(false);
                            }
                          }}
                          sx={{
                            display: careersDropdown
                              ? 'flex'
                              : 'none!important',
                            minWidth: '120px',
                            minHeight: '70px',
                            padding: '8px 16px',
                            justifyContent: 'flex-start',
                            '& .MuiButton-endIcon': {
                              margin: '0!important',
                            },
                          }}
                        >
                          <Typography className='partners-button-text'>
                            Realtor Team
                          </Typography>
                        </Button> */}
                                            </Box>

                                            <Box>
                                                <Button
                                                    onClick={() => {
                                                        {
                                                            handleNavigate('/about-us');
                                                        }
                                                    }}
                                                    className='drawer-button'
                                                >
                                                    About Us
                                                </Button>
                                            </Box>

                                            <Box>
                                                <Button
                                                    className='drawer-button'
                                                    onClick={() => {
                                                        {
                                                            handleNavigate('/contact-us');
                                                        }
                                                    }}
                                                >
                                                    Contact
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Drawer>
                                </Box>

                                <CompanyLogo></CompanyLogo>

                                {/* Desktop header menu */}
                                <Box
                                    onMouseLeave={handleMouseLeave}
                                    sx={{
                                        flexGrow: 1,
                                        minWidth: '100px',
                                        display: {
                                            xs: 'none',
                                            lg: 'flex',
                                            justifyContent: 'space-evenly',
                                        },
                                        height: '100%',
                                        maxHeight: { xs: '60px', lg: '78px' },
                                    }}>
                                    {navItems.map((item, index) => (
                                        <Box key={item.value}
                                            className='partners-wrapper'
                                            sx={{ display: 'flex', flexDirection: 'column' }}
                                        >
                                            <Button
                                                className={`partners-button`}
                                                endIcon={<>
                                                    {item.subMenuItems?.length ? (
                                                        <ArrowDropDownIcon />
                                                    ) : null}
                                                </>}
                                                sx={{
                                                    height: "100%",
                                                    minWidth: "120px",
                                                    "& .MuiButton-endIcon": { margin: "0!important" },
                                                }}
                                                onClick={() => { item.path && handleNavigate(item.path) }}
                                                onMouseDown={(event) => {
                                                    if (event.button === 1) {
                                                        item.path && openInNewTab(`${item.path}`);
                                                    }
                                                }}
                                                onMouseEnter={() => handleMouseEnter(index)}
                                            >
                                                <Typography className={`partners-button-text`} sx={{ color: "#075faa", fontWeight: 700, fontSize: "1.2rem", textTransform: "none" }}>
                                                    {item.label}
                                                    <i className="underline"></i>
                                                </Typography>

                                            </Button>
                                            {isHovered &&
                                                hoveredIndex === index &&
                                                item.subMenuItems?.length && (
                                                    <Box
                                                        onMouseLeave={handleMouseLeave}
                                                        style={{
                                                            top: '100%',
                                                            position: 'absolute',
                                                            width: '240px',
                                                            background: '#fff',
                                                            padding: '10px',
                                                            boxShadow: '0 0 4px rgba(0,0,0,.2)',
                                                            boxSizing: 'border-box',
                                                            borderRadius: '3px',
                                                            zIndex: '999999999'
                                                        }}
                                                    >
                                                        {item.subMenuItems.map(
                                                            (subMenuItem: any) => (
                                                                <MenuItem
                                                                    className='partners-dropdown'
                                                                    sx={{
                                                                        padding: 0,
                                                                        minHeight: 32,
                                                                        zIndex: '999999999'
                                                                    }}
                                                                    key={index + subMenuItem.path}
                                                                    onClick={() => {
                                                                        handleClose();
                                                                        handleNavigate(subMenuItem.path);
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            color: '#075faa',
                                                                            fontSize: '16px!important',
                                                                            fontWeight: '700',
                                                                            padding: '10px 12px',
                                                                        }}
                                                                    >
                                                                        {subMenuItem.label}
                                                                    </Typography>
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Box>
                                                )}
                                        </Box>
                                    ))}
                                </Box>

                            </Toolbar>
                        </Container>

                    </AppBar>
                </Box>
            )}
        </Box>
    );
}
