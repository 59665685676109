export const HEADER_HEIGHT = '125px';

export const EMPTY_EMPLOYEE_PHOTO =
  'https://api.securitytrax.com/safehaven/user/v1/profile-image/W9uaWFUcmNTY2NDQ=lbWVudC';

export const STATE_CODES = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const STATE_LIST = [
  "",
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "District of Columbia",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
]

export const HIDE_HEADER_PATHS = ['/partners/century-model', '/partners/all-access-service', '/partners/', '/partners/confirmation', '/partners/drh-model'];
export const HIDE_FOOTER_PATHS = ['/NerdWallet/', '/nerdwallet/', '/NerdWallet', '/nerdwallet', '/partners/century-model', '/partners/all-access-service', '/partners/marketing-essentials', '/partners/drh-model'];

export const TRAX_LEAD = [
  '/partners/perfectvisionpglatin',
  '/partners/perfectvisionqrslegacy',
  '/partners/perfectvisionhtvisions',
  '/partners/perfectvisionstallions',
  '/partners/perfectvisionnewwave',
  '/partners/ahp',
  '/partners/perfectvisionwhitegloves',
  '/partners/perfectvision-solaris-smart-home',
  '/partners/perfectvisionlism',
  '/partners/perfectvisionallinone',
  '/partners/perfectvisionpowerontech',
  '/partners/perfectvisionconnectionone',
  '/partners/perfectvisiontitletronix',
  '/partners/perfectvisionpelican',
  '/partners/perfectvisioncoreceptive',
  '/partners/perfectvisionlinkingflux',
  '/partners/perfectvisionasdm',
  '/partners/perfectvisionpypsales',
  '/partners/perfectvisionsimplyactivate',
  '/partners/perfectvisiondartech',
  '/partners/perfectvisiongreenhaya',
  '/partners/perfectvisionibuyer',
  '/partners/perfectvisionstarms',
  '/partners/perfectvisionmaxvision',
  '/partners/perfectvisionrightlink',
  '/partners/perfectvisioncitizenhome',
  '/partners/perfectvisionrbeeze',
  '/partners/perfectvisionbundlefinders',
  '/partners/perfectvisioncable',
  '/partners/perfectvisioncorecentrix',
  '/partners/perfectvisionipfa',
  '/partners/perfectvisionmmr',
  '/partners/perfectvisionbamo',
  '/partners/perfectvisioniconcomm',
  '/partners/perfectvisionsatcomm',
  '/partners/perfectvisionsellwell',
  '/partners/perfectvisionsjglobal',
  '/partners/perfectvisionsummitmedia',
  '/partners/perfectvisionastrosdigital',
  '/partners/perfectvisiongem',
  '/partners/perfectvisionenmalpha',
  '/partners/perfectvisionavdsolutions',
  '/partners/perfectvisioncompelpasollc',
  '/partners/perfectvisionweatherford',
  '/partners/perfectvision-emaan-commerce',
  '/partners/perfectvisionxtrategysolutions',
  '/partners/perfectvisionjamalprime',
  '/partners/perfectvisioncamcom',
  '/partners/perfectvisionblacksheepllc',
  '/partners/perfectvisionmedicators',
  '/partners/perfectvision',
  '/partners/isg',
  '/partners/llpenterprises',
  '/partners/myhomegroup',
  '/partners/pmhsold',
  '/partners/perfectvisionintechnologies',
  '/partners/perfectvisionleilani',
  '/partners/perfectvisiongabcyn',
  '/partners/perfectvisionrexza',
  '/partners/perfectvisionalliance',
  '/partners/perfectvisionelina',
];

export const CONSENT_KEY = "cookie-consent";