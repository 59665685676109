import React, { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Container, Stack } from "@mui/system";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Formik, Form, Field, FormikProps, useFormik } from "formik";
import * as Yup from "yup";
import yardSign2 from "../../../../../Assets/Nerdwallet/ADTYardSign.png";
import TechInHome from "../../../../../Assets/Nerdwallet/TechInhome.png";
import exceptionalOfferImg from "../../../../../Assets/Nerdwallet/SpecialOffer_rev.png";
import heroImg from "../../../../../Assets/Nerdwallet/HeroPic.png";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./index.scss";
import { useSpring, animated } from "@react-spring/web";
import { isMobile, isTablet } from "react-device-detect";
import LearnMoreButton from "../../../../../Assets/Nerdwallet/Blue button_Learn More.png";
import getYourQuoteButton from "../../../../../Assets/Nerdwallet/Blue button_Get Your Quote.png";
import getStartedButton from "../../../../../Assets/Nerdwallet/ButtonGetStarted.png";
import useFetch from "use-http";
import nerdWalletHeader from "../../../../../Assets/Nerdwallet/NW_Logo_Hor_RGB_Screen.png";
import "./index.scss";
import { logo_slides, reviews } from "../../../../../Constants/NerdWallet";
import phoneButton from "../../../../../Assets/Nerdwallet/ButtonPhoneNumber.png";
import contactUsButton from "../../../../../Assets/Nerdwallet/BlueButton_Contact Us.png";
import NerdwalletFooter from "./Components/NerdwalletFooter";
import ExceptopnalOfferMobile from "../../../../../Assets/Nerdwallet/SpecialOffer_Mobileversion_01.png";
import HeroImgMobile from "../../../../../Assets/Nerdwallet/MobileHeroimage.png";
import {
	Link,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import Logo from "../../../../../Assets/Global/logo.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import findOutHowButton from "../../../../../Assets/Nerdwallet/Bluebutton_FindOutHow.png";
import ScrollableImageGrid from "./Components/ScrollImageGrid";
import Shsicon from "../../../../../Components/ShsIcon";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from "@mui/icons-material/Error";
import { ReactTagManager } from "react-gtm-ts";
import useAnalytics from "../../../../../utils/useAnalytics";
import CookieConsentPopup from "../../../../../Components/CookieConsent/CookieConsentPopup";
export default function Nerdwallet() {
	const url = new URL(window.location.href); // Use current URL
	const searchParams = url.searchParams;
	const [clickId, setClickId] = useState<any>("");
	const [campaignId, setCampaignId] = useState<any>("");
	const [formSubmitting, setFormSubmitting] = useState(false);
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [networkError, setNetworkError] = useState(false);
	const navigate = useNavigate();
	const handleNavigate = (route: string) => navigate(`${route}`);
	const targetDivRef = useRef<HTMLDivElement>(null); // Add type annotation to useRef
	const [currentLogoSlide, setCurrentLogoSlide] = useState(0);
	const [currentReviewSlide, setCurrentReviewSlide] = useState(0);
	const [springs, api] = useSpring(() => ({
		from: { x: 0 },
	}));

	useMemo(() => {
		const hasConsent = localStorage.getItem("cookie-consent") === "true";
		console.log("hasConsent", hasConsent);
		if (hasConsent) {
			console.log("test");
			// console.log("test")
			ReactTagManager.init({
				code: "GTM-K3XGL8HK",
				debug: false,
				performance: false,
			});
			ReactTagManager.action({
				event: "nerd_wallet_page_view",
				data: {
					page_path: url.pathname,
					page_title: document.title,
				},
			});
		}
	}, []);
	const onconsent = () => {
		ReactTagManager.init({
			code: "GTM-K3XGL8HK",
			debug: false,
			performance: false,
		});
		ReactTagManager.action({
			event: "nerd_wallet_page_view",
			data: {
				page_path: url.pathname,
				page_title: document.title,
			},
		});
	}
	useEffect(() => {
		const timeOutId = setTimeout(() => {
			nextSlide();
		}, 5000);
		return () => clearTimeout(timeOutId);
	}, [currentLogoSlide]);

	useEffect(() => {
		nextSlide();
	}, []);

	const nextSlide = () => {
		api.start({
			from: {
				x: 130,
			},
			to: {
				x: isMobile ? -9 : -16,
			},
		});
		const nextSlideIndex = (currentLogoSlide + 1) % logo_slides.length;
		setCurrentLogoSlide(nextSlideIndex);
	};

	const prevSlide = () => {
		api.start({
			from: {
				x: -130,
			},
			to: {
				x: isMobile ? -8 : -8,
			},
		});
		const prevSlideIndex =
			currentLogoSlide === 0 ? logo_slides.length - 1 : currentLogoSlide - 1;
		setCurrentLogoSlide(prevSlideIndex);
	};
	const handleScrollClick = () => {
		if (targetDivRef.current) {
			const targetDivPosition = targetDivRef.current.getBoundingClientRect();
			const scrollOffset =
				window.scrollY ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			const adjustedScrollPosition = targetDivPosition.top + scrollOffset;
			window.scrollTo({ behavior: "smooth", top: adjustedScrollPosition });
		}
	};
	useEffect(() => {
		setClickId(searchParams.get("clickid"));
		setCampaignId(searchParams.get("campaignid"));
		ReactTagManager.init({
			code: "GTM-T5T79RDF",
			debug: true,
			performance: false,
		});
	}, []);
	let apiKey = process.env.REACT_APP_API__ACCESS_KEY
		? process.env.REACT_APP_API__ACCESS_KEY
		: "";
	const options = {
		headers: {
			"Content-Type": "application/json",
			Connection: "keep-alive",
			"Accept-Encoding": "gzip, deflate, br",
			"X-API-Key": apiKey,
		},
		cacheLife: 1000,
	};

	const { post, response, loading } = useFetch(
		process.env.REACT_APP_API,
		options,
	);

	const initialValues = {
		firstName: "",
		lastName: "",
		email: "",
		zip: "",
		phone: "",
	};

	const validationSchema = Yup.object({
		firstName: Yup.string().required("Required"),
		lastName: Yup.string().required("Required"),
		email: Yup.string().required("Required"),
		zip: Yup.string().required("Required"),
		phone: Yup.string().required("Required"),
	});

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			setFormSubmitting(true);
			try {
				// Add the hidden values to the submission data
				const submissionData = {
					...values,
					partnerCompanyId: "1505",
					partnerCampaignId: campaignId,
					callBack: "Call Back",
					clickId: clickId,
					campaignId: campaignId,
				};

				const postResponse = await post(
					"/Partner/CreateZohoLeadByPartnerId",
					submissionData,
				);
				setFormSubmitted(true);

				setFormSubmitting(false);
				resetForm();
			} catch (error) {
				// Handle errors (e.g., show an error message)
				console.error("Error sending request:", error);
				setNetworkError(true);
			} finally {
				setSubmitting(false);
			}
		},
	});
	useEffect(() => {
		// On component mount, ensure no side-to-side scrolling
		document.body.style.overflowX = "hidden";
		document.body.style.margin = "0"; // Reset body margin

		// Clean up on unmount to reset overflow and margin behavior
		return () => {
			document.body.style.overflowX = "auto";
			document.body.style.margin = ""; // Reset to default or remove if not needed
		};
	}, []);

	return (
		<>
			{/* <Helmet>
				<title>{`Nerdwallet | Safe Haven Security`}</title>
				<meta name="description" content="Explore partnership opportunities with Safe Haven Security, including the Agent Referral Program and Homebuilder Program. Gain access to exclusive benefits and grow your business." />
				<meta name="keywords" content="Safe Haven Security, partner programs, agent referral program, homebuilder program, business growth, security solutions" />
			</Helmet> */}

			<Grid
				container
				sx={{
					maxWidth: { xs: "100vw", md: "1200px" },
					margin: "0 auto",
					overflowX: "hidden",
					padding: 0,
				}}
			>
				<Grid item xs={12}>
					<div className="spacer-header"></div>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Typography
							variant="h6"
							sx={{ marginRight: "5px", fontSize: "22px", fontWeight: 580 }}
						>
							Welcome
						</Typography>
						<img
							className="nerdwallet-logo"
							src={nerdWalletHeader}
							alt="Header Image"
						/>
						<Typography
							variant="h5"
							sx={{ marginRight: "10px", fontSize: "22px", fontWeight: 580 }}
						>
							Users
						</Typography>
						<br />
					</Box>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Typography
							sx={{
								marginRight: "10px",
								fontSize: "10px",
								color: "CaptionText",
							}}
						>
							©2014-2024 and TM, NerdWallet, Inc. All Rights Reserved.
						</Typography>
					</Box>
				</Grid>
			</Grid>
			<div className="spacer-header"></div>
			<Grid
				container
				sx={{
					maxWidth: "1500px",
					margin: "auto",
					backgroundColor: "rgba(224, 224, 224, .3)",
					paddingTop: { sm: "10px", md: "10px" },
					paddingBottom: { sm: "10px", xs: "19px", md: "5px" },
				}}
			>
				<Grid
					item
					xs={6}
					sx={{
						paddingLeft: { xs: "0px", md: "4px" },
						float: { xs: "left", md: "none" },
					}}
				>
					<Box
						sx={{
							marginTop: { xs: "8px", md: "2px" },
							maxHeight: {
								xs: "11px",
								lg: "50px",
							},

							maxWidth: "345px",
						}}
					>
						<img
							src={Logo}
							alt="logo"
							className="nerwallet-header-shs-icon-logo"
						/>
					</Box>
				</Grid>
				<Grid item xs={6}>
					{
						<Box className="nerdwallet-header-button-wrapper">
							<Typography
								sx={{
									margin: "auto",
									color: "#0061aa",
									width: "100%",
									textAlign: "center",
									fontSize: { xs: "12px", md: "14px" },
									marginRight: { xs: "25px", md: "0px" },
									display: {
										xs: "none",
										sm: "block",
										md: "block",
										lg: "block",
									}, // Hide text on mobile, show on medium and larger screens
								}}
							>
								Help Protect What Matters Most
								<br />
							</Typography>
							<a href="tel:8778502088">
								<img
									className="header-button-img"
									src={phoneButton}
									alt="Contact Us"
								/>
							</a>
						</Box>
					}
				</Grid>
			</Grid>
			<Container maxWidth={false} className="nerdwallet-img-bg">
				<Box
					onClick={() => handleScrollClick()}
					component="img"
					sx={{
						display: { xs: "none", md: "block" },
						maxWidth: "100%",
					}}
					src={heroImg}
				/>
				<Box
					onClick={() => handleScrollClick()}
					component="img"
					sx={{
						display: { xs: "block", md: "none" },
						maxWidth: "100%",
					}}
					src={HeroImgMobile}
				/>
				<Stack
					direction={"column"}
					className="nerdwallet-panel-text-overlay"
					sx={{ marginTop: { xs: "25%", md: "11%" } }}
				>
					<span className="spacer-header"></span>

					{/* New element with positioning adjustments */}
					<div
						style={{
							position: "absolute",
							top: "calc(90% + 80px)",
							float: "left",
							left: "-20%",
						}}
					>
						{/* Adjust top and left values as needed */}
						<img
							onClick={() => handleScrollClick()}
							className="button-img-overlay"
							style={{
								paddingTop: "20px",
							}}
							src={findOutHowButton}
							alt="find out how"
						/>
					</div>
				</Stack>
			</Container>
			<div ref={targetDivRef} id="target-div"></div>
			<Container
				sx={{
					backgroundColor: "rgba(237, 237, 237, .4)",
					width: { xs: "90%", md: "60%" },
					margin: "auto",
					marginTop: "44px",
					marginBottom: "44px",
					borderRadius: "18px",
				}}
			>
				<Stack
					direction={"column"}
					sx={{
						paddingTop: "14px",
						alignItems: "center",
					}}
				>
					<Typography
						className="nerdwallet-entry-form-title"
						sx={{
							color: "black",
							font: {
								xs: "normal 800 24px/34px Avenir Next,Helvetica,Arial,Verdana,sans-serif",
								md: "normal 800 44px/54px Avenir Next,Helvetica,Arial,Verdana,sans-serif",
							},
							fontWeight: 800,
							maxWidth: { xs: "300px", md: "750px" },
						}}
					>
						<b>Home Security You Can Trust</b>
					</Typography>
					{formSubmitted ? (
						<div>
							<Typography
								sx={{
									display: "flex",
									alignItems: "center",
									color: "green",
									backdropFilter: "blur(10px)",
									borderRadius: "10px",
									padding: "10px",
									marginTop: "10px",
									backgroundColor: "rgba(0, 128, 0, 0.1)",
								}}
							>
								<CheckCircleOutlineIcon sx={{ marginRight: "10px" }} />
								<b>Submitted Successfully! We will be in touch.</b>
							</Typography>
						</div>
					) : null}
					{networkError ? (
						<div>
							<Typography
								sx={{
									display: "flex",
									alignItems: "center",
									color: "#eb4034", // Change color to indicate error
									backdropFilter: "blur(10px)",
									borderRadius: "10px",
									padding: "10px",
									marginTop: "10px",
									backgroundColor: "rgba(235, 0, 0, 0.1)", // Reddish background for error
								}}
							>
								<ErrorIcon sx={{ marginRight: "10px" }} />
								{/* Replace with your error icon component */}
								<b>
									This was an error with your submission. Please try again later
								</b>
							</Typography>
						</div>
					) : null}
					<Box
						sx={{
							maxWidth: { xs: "300px", md: "85%" },
							marginBottom: { xs: "16px", md: "28px" },
						}}
					>
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={async (values, { setSubmitting, resetForm }) => {
								try {
									// Add the hidden values to the submission data
									const submissionData = {
										...values,
										partnerCompanyId: "1505",
										partnerCampaignId: campaignId,
										leadSourceType: "Call Back",
										clickId: clickId,
										campaignId: campaignId,
									};

									const postResponse = await post(
										"/Partner/CreateZohoLeadByPartnerId",
										submissionData,
									);
									if (postResponse !== undefined) {
										setFormSubmitted(true);
										setTimeout(() => {
											setFormSubmitted(false);
										}, 5000);
										setFormSubmitting(false);
										resetForm();
									} else {
										setNetworkError(true);
										setTimeout(() => {
											setNetworkError(false);
										}, 5000);
									}
								} catch (error) {
									// Handle errors (e.g., show an error message)
									console.error("Error sending request:", error);
									setNetworkError(true);
									setTimeout(() => {
										setNetworkError(false);
									}, 5000);
								}
							}}
						>
							{(
								{ errors, touched, setFieldValue }, // Access Formik state here
							) => (
								<Form>
									<Box
										className="fields-container perfect-vision"
										sx={{
											alignContent: "center",
											justifyContent: "center",
										}}
									>
										<Grid
											container
											sx={{
												margin: "auto",
												alignItems: "center",
												justifyContent: "center",

												maxWidth: "1200px",
											}}
											style={{ justifyContent: "space-between" }}
										>
											{<p>{formSubmitted}</p>}
											{<p>{formSubmitting}</p>}
											<Grid item xs={12} md={6} className="contact-grid-form">
												<Field
													as={TextField}
													variant="standard"
													fullWidth
													className="contact-grid-form"
													name="firstName"
													label="First Name"
													error={touched.firstName && Boolean(errors.firstName)} // Check if touched and error exists
													helperText={touched.firstName && errors.firstName} // Show error message if touched
												/>
											</Grid>
											<Grid item xs={12} md={6} className="contact-grid-form">
												<Field
													as={TextField}
													fullWidth
													variant="standard"
													className="contact-grid-form"
													name="email"
													label="Email"
													error={touched.email && Boolean(errors.email)}
													helperText={touched.email && errors.email}
												/>
											</Grid>
											<Grid item xs={12} md={6} className="contact-grid-form">
												<Field
													as={TextField}
													variant="standard"
													fullWidth
													name="lastName"
													className="contact-grid-form"
													label="Last Name"
													error={touched.lastName && Boolean(errors.lastName)}
													helperText={touched.lastName && errors.lastName}
												/>
											</Grid>
											<Grid item xs={12} md={6} className="contact-grid-form">
												<Field
													as={TextField}
													fullWidth
													variant="standard"
													name="phone"
													label="Phone"
													onChange={(event: { target: { value: any; }; }) => {
														const inputValue = event.target.value.replace(/\D/g, '');

														let formattedValue = '';
														if (inputValue.length > 0) {
															formattedValue = '(' + inputValue.substring(0, 3);
															if (inputValue.length > 3) {
																formattedValue += ') ' + inputValue.substring(3, 6);
															}
															if (inputValue.length > 6) {
																formattedValue += '-' + inputValue.substring(6, 10);
															}
														}

														setFieldValue('phone', formattedValue);
													}}
													onPaste={(event: { clipboardData: any; preventDefault: () => void; }) => {
														const clipboardData = event.clipboardData;
														const pastedData = clipboardData.getData('text');
														const cleanedData = pastedData.replace(/\D/g, '');
														const formattedValue = cleanedData.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
														setFieldValue('phone', formattedValue);
														event.preventDefault();
													}}
													error={touched.phone && Boolean(errors.phone)}
													helperText={touched.phone && errors.phone}
												/>
											</Grid>
											<Grid item xs={12} md={6} className="contact-grid-form">
												<Field
													sx={{
														marginBottom: { xs: "10px", md: "180px" },
													}}
													as={TextField}
													fullWidth
													variant="standard"
													name="zip"
													label="Zip"
													error={touched.zip && Boolean(errors.zip)}
													helperText={touched.zip && errors.zip}
												/>
											</Grid>
											<Grid item xs={12} md={6} className="contact-grid-form">
												<Typography
													sx={{
														fontSize: "11px",
														color: "#545859",
														float: "right",
														alignContent: "center",
													}}
												>
													By clicking 'Submit,' I consent to being contacted by
													Safe Haven Security Services, LLC, Safe Haven Solar,
													LLC, or All Access Service, LLC regarding products and
													services via live, automated, or prerecorded telephone
													calls, text messages*, or emails, even if I'm on a Do
													Not Call list. Consent is not a condition of purchase.
													I can revoke this consent by emailing{" "}
													<Link to="dnc@mysafehaven.com">
														dnc@mysafehaven.com.
													</Link>
													<br />
													<Button
														type="submit"
														sx={{
															marginLeft: { xs: "18%", md: "32%" },
															margin: "15px",
															width: { xs: "65%", md: "50%" },
															height: { xs: "45px", md: "45px" },
															borderRadius: { xs: "12px", md: "12px" },
														}}
														variant="contained"
													>
														Submit
													</Button>
													<br />* Standard message and data rates may apply to
													text messages. You may opt out of receiving further
													text messages at any time by replying STOP. <br />{" "}
													<br />† Your information is collected and used in
													accordance with Safe Haven’s{" "}
													<Link to="https://mysafehaven.com/legal/privacy/">
														Privacy Policy.
													</Link>
												</Typography>
											</Grid>
											{/* <Box
												sx={{
													width: { xs: "85%", md: "95%", lg: "79%" },
													float: { xs: "none", md: "none", lg: "right" },
													margin: { xs: "auto", md: "0" },
												}}
												typeof="submit"
											>
												<div
													typeof="submit"
													style={{
														top: "0",
													}}
												>
													<button
														type="submit"
														className="button-img"
														style={{
															opacity: 0,
															position: "absolute",
															float: "right",
															zIndex: -999, // Ensure button is above the image
														}} // Hide button visually
														onClick={() => formik.submitForm()}
													/>
													<img
														typeof="submit"
														className="button-img"
														style={{
															zIndex: 0,
															float: "right",
														}}
														onClick={() => {
															formik.submitForm();
															(
																document.querySelector(
																	"button[type='submit']",
																) as HTMLButtonElement
															)?.click();
														}}
														src={getYourQuoteButton}
														alt="Get Your Quote"
													/>
												</div>
											</Box> */}
										</Grid>
									</Box>
								</Form>
							)}
						</Formik>
					</Box>
				</Stack>
			</Container>
			<Container
				maxWidth={false}
				className="panel-img-bg"
				sx={{
					height: "80%",
				}}
			>
				<div
					style={{
						height: "90%",
					}}
				>
					<Box
						onClick={() => {
							// go to url
							window.open(
								"https://smarthomeadvantage.com/terms-and-conditions/",
								"_blank",
							);
						}}
						component="img"
						sx={{
							display: { xs: "none", md: "block" },
							maxWidth: "100%",
						}}
						src={exceptionalOfferImg}
					/>
					<Box
						onClick={() => {
							window.open(
								"https://smarthomeadvantage.com/terms-and-conditions/",
								"_blank",
							);
						}}
						component="img"
						sx={{
							display: { xs: "block", md: "none" },
							maxWidth: "100%",
						}}
						src={ExceptopnalOfferMobile}
					/>
				</div>
			</Container>
			<Container
				sx={{
					backgroundColor: "rgb(237, 237, 237, .4)",
					width: { xs: "90%", md: "79%" },
					margin: "auto",
					marginTop: "44px",
					marginBottom: "44px",
					borderRadius: "18px",
				}}
			>
				<Grid container sx={{ padding: "10px 0" }} spacing={2}>
					<Grid
						item
						xs={12}
						md={12}
						sx={{
							alignContent: "center",
							justifyContent: "center",
							margin: "auto",
						}}
					>
						<Typography
							sx={{
								textAlign: { xs: "center", md: "center" },
								color: "black",
								fontSize: { xs: "24px", md: "34px" },
								fontFamily: "Montserrat",
							}}
						>
							<b>Safe Haven Presents a Special Opportunity...</b>
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={12}
						sx={{
							alignContent: "center",
							justifyContent: "center",
							margin: "auto",
						}}
					>
						<Box
							sx={{
								marginTop: "10px",
								margin: "auto",
								maxHeight: {
									xs: "78px",
									lg: "78px",
								},
								padding: "10px",
								marginBottom: "20px",
								maxWidth: "330px",
								display: "flex",
							}}
						>
							<Shsicon></Shsicon>
						</Box>
					</Grid>

					<Grid item xs={12} md={5}>
						<Box>
							<Box
								component="img"
								sx={{
									maxWidth: "100%",
									height: { xs: "auto", md: "auto" },
									float: "left",
									marginRight: { xs: "0px", md: "60px" },
									marginBottom: { xs: "20px", md: "0px" },
								}}
								src={yardSign2}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={7}>
						<Typography
							sx={{
								color: "black",
								marginBottom: "5px",
								margin: "5px",
								fontSize: { xs: "14px", md: "15px" },
								fontWeight: { xs: 700, md: 600 },
								fontFamily: "Montserrat",
							}}
							className="nerdwallet-nerdwallet-partnership-body-text"
						>
							Safe Haven Security + ADT monitoring is a winning combination
							where you reap the benefits!
						</Typography>
						<br />
						<Typography
							sx={{
								color: "black",
								marginBottom: "5px",
								margin: "5px",
								fontSize: { xs: "14px", md: "15px" },
								fontWeight: { xs: 700, md: 600 },
								fontFamily: "Montserrat",
							}}
							className="nerdwallet-partnership-body-text"
						>
							Not only do you get the exceptional reliability you've come to
							expect from ADT, but you'll also receive the expertise of Safe
							Haven providing you with:
						</Typography>
						<ul>
							<li>
								<Typography
									sx={{
										color: "black",
										marginBottom: "5px",
										margin: "5px",
										fontSize: { xs: "14px", md: "15px" },
										fontWeight: { xs: 600, md: 600 },
										fontFamily: "Montserrat",
									}}
									className="nerdwallet-partnership-body-text"
								>
									Comprehensive access to the renowned ADT-monitored security
									systems.
								</Typography>
							</li>
							<li>
								<Typography
									sx={{
										color: "black",
										marginBottom: "5px",
										margin: "5px",
										fontSize: { xs: "14px", md: "15px" },
										fontWeight: { xs: 600, md: 600 },
										fontFamily: "Montserrat",
									}}
									className="nerdwallet-partnership-body-text"
								>
									The latest security devices, professionally installed by Safe
									Haven Technicians.
								</Typography>
							</li>
							<li>
								<Typography
									sx={{
										color: "black",
										marginBottom: "5px",
										margin: "5px",
										fontSize: { xs: "14px", md: "15px" },
										fontWeight: { xs: 600, md: 600 },
										fontFamily: "Montserrat",
									}}
									className="nerdwallet-partnership-body-text"
								>
									Convenience of accessing and controlling your entire system
									from your smartphone.
								</Typography>
							</li>
							<li>
								<Typography
									sx={{
										color: "black",
										marginBottom: "5px",
										margin: "5px",
										fontSize: { xs: "14px", md: "15px" },
										fontWeight: { xs: 600, md: 600 },
										fontFamily: "Montserrat",
									}}
									className="nerdwallet-partnership-body-text"
								>
									Safe Haven's SmartHome Advantage - an all-in-one security
									solution.
								</Typography>
							</li>
						</ul>
						{/* <button
							className="nerdwallet-quote-btn"
							style={{ float: "right", marginRight: "60px" }}
							onClick={() => {
								handleScrollClick();
							}}
						>
							Learn More {" >"}
						</button> */}
						<img
							onClick={() => {
								handleScrollClick();
							}}
							className="button-img-contact"
							style={{
								float: "right",
								// marginRight: "80px",
								paddingTop: "10px",
							}}
							src={LearnMoreButton}
							alt="learn more"
						/>
						<div style={{ textAlign: "center" }}>
							<img
								onClick={() => {
									handleNavigate("/contact-us");
								}}
								className="button-img-mobile"
								style={{
									width: "190px",
									height: "58px",
									margin: "auto",
									padding: "20px 0", // Adjust padding as needed
									cursor: "pointer",
								}}
								src={LearnMoreButton}
								alt="learn more"
							/>
						</div>
					</Grid>
				</Grid>
			</Container>
			<Container
				sx={{
					backgroundColor: "rgba(237, 237, 237, .4)",
					width: { xs: "90%", md: "79%" },
					margin: "auto",
					marginTop: "44px",
					marginBottom: "44px",
					borderRadius: "18px",
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<Typography
							sx={{
								color: "black",
								width: "100%",
								marginBottom: "30px",
								float: "left",
								margin: "20px",
								fontSize: { xs: "34px", md: "38px" },
								fontWeight: { xs: 510, md: 620 },
								fontFamily: "Montserrat",
							}}
						>
							Why Safe Haven?
						</Typography>
						<ul>
							<li>
								<Typography
									sx={{
										color: "black",
										marginBottom: "5px",
										margin: "5px",
										fontSize: { xs: "14px", md: "15px" },
										fontWeight: { xs: 600, md: 600 },
										fontFamily: "Montserrat",
									}}
								>
									Locations across 100 metro areas serving communities
									nationwode.
								</Typography>
							</li>
							<li>
								<Typography
									className="nerdwallet-partnership-body-text"
									sx={{
										color: "black",
										marginBottom: "5px",
										margin: "5px",
										fontSize: { xs: "14px", md: "15px" },
										fontWeight: { xs: 600, md: 600 },
										fontFamily: "Montserrat",
									}}
								>
									20,000+ positive customer experiences resulting in an A+ BBB
									Rating.
								</Typography>
							</li>
							<li>
								<Typography
									className="nerdwallet-partnership-body-text"
									sx={{
										color: "black",
										marginBottom: "5px",
										margin: "5px",
										fontSize: { xs: "14px", md: "15px" },
										fontWeight: { xs: 600, md: 600 },
										fontFamily: "Montserrat",
									}}
								>
									Well-trained, friendly staff who will look after your unique
									home security needs.
								</Typography>
							</li>
						</ul>
						<Typography
							className="nerdwallet-partnership-body-text"
							sx={{
								color: "black",
								marginBottom: "5px",
								margin: "5px",
								fontSize: { xs: "14px", md: "15px" },
								fontWeight: { xs: 600, md: 600 },
								fontFamily: "Montserrat",
							}}
						>
							When you reach out to us, you'll speak to a knowledgeable team who
							are ready to help. Click below to get started today and experience
							the difference Safe Haven makes for you.
						</Typography>
						{/* <button
							className="nerdwallet-quote-btn"
							style={{ float: "left", marginRight: "60px" }}
							onClick={() => {
								handleNavigate("/contact-us");
							}}
						>
							Contact Us{" >"}
						</button> */}
						<img
							onClick={() => {
								handleNavigate("/contact-us");
							}}
							className="button-img-contact"
							src={contactUsButton}
							alt="contact us"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box>
							<Box
								component="img"
								sx={{
									maxWidth: { xs: "100%", md: "100%" },
									height: { xs: "auto", md: "420px", lg: "480px" },
									float: { xs: "", md: "right" },
									padding: "10px",
									margin: "auto",
									marginLeft: { xs: "0px", md: "60px" },
									marginTop: { xs: "20px", md: "0px" },
								}}
								src={TechInHome}
							/>
						</Box>
					</Grid>
				</Grid>
				<div style={{ textAlign: "center" }}>
					<img
						onClick={() => {
							handleNavigate("/contact-us");
						}}
						className="button-img-mobile"
						style={{
							width: "190px",
							height: "58px",
							margin: "auto",
							padding: "20px 0", // Adjust padding as needed
							cursor: "pointer",
						}}
						src={contactUsButton}
						alt="learn more"
					/>
				</div>
			</Container>
			<Container
				className="lifestyle-text"
				sx={{
					width: { xs: "90%", md: "79%" },
				}}
			>
				<Typography
					component="h2"
					sx={{
						fontSize: "24px",
						color: "black",
						// width: { xs: "90%", md: "80%", lg: "80%" },
						borderRadius: "18px",
						margin: "auto",
						marginTop: "44px",
					}}
				>
					<b>Customize Your Home To Fit Your Lifestyle</b>
				</Typography>
				<Typography
					sx={{
						fontSize: { xs: "14px", md: "18px" },
						fontWeight: 400,
						color: "black",
						margin: "auto",
						marginRight: "20px",
						borderRadius: "18px",
						fontFamily: "Montserrat",
					}}
				>
					Your equipment voucher can be used to add additional security and
					smarthome technology such as interior/exterior cameras, smart
					thermostats, life safety devices, and more.
				</Typography>
			</Container>

			<ScrollableImageGrid />
			<Container
				sx={{
					backgroundColor: "rgba(30, 30, 30, 0.8)",
					width: { xs: "90%", md: "79%" },
					height: "195px", // If needed, adjust height
					marginBottom: "44px",
					borderRadius: "18px",
					textAlign: "center",
					padding: "30px 0",
				}}
			>
				<Typography
					sx={{
						color: "white",
						marginTop: "10px",
						fontSize: { xs: "14px", md: "24px" },
						fontFamily: "Montserrat",
					}}
				>
					<b>Talk to a Safe Haven Security Specialist Today</b>
				</Typography>
				<div style={{ marginTop: "5px" }}>
					<img
						onClick={() => {
							handleScrollClick();
						}}
						className="get-started-button"
						src={getStartedButton}
						alt="get started"
					/>
				</div>
			</Container>
			<Container
				sx={{
					backgroundColor: "rgba(237, 237, 237, .4)",
					width: { xs: "90%", md: "79%" },
					margin: "auto",
					paddingTop: "64px",
					marginBottom: { xs: "14px", md: "24px" },
					borderRadius: "18px",
					textAlign: "center",
					minHeight: { xs: "80%", md: "100%" },
				}}
			>
				<>
					<Box
						sx={{
							textAlign: "center",
							bottom: "0",
							minHeight: { xs: "153px", md: "160px" },
						}}
					>
						<Typography
							sx={{
								color: "rgba(30, 30, 30, 0.88)",
								fontFamily: "Montserrat",
								fontWeight: 550,
								fontSize: { xs: "14px", md: "19px" },
								marginBottom: "10px",
								width: { xs: "90%", md: "80%" },
								margin: "auto",
							}}
						>
							"{reviews[currentReviewSlide].description}"
						</Typography>
						<Typography
							sx={{
								fontSize: { xs: "15px", md: "21px" },
								color: "rgba(30, 30, 30, 0.88)",
								marginBottom: "10px",
								fontFamily: "Montserrat",
								width: "50%",
								margin: "10px auto",
							}}
						>
							<b>
								<u>{reviews[currentReviewSlide].name}</u>
							</b>
						</Typography>
					</Box>
				</>

				<button
					style={{
						backgroundColor: "transparent",
						border: "none",
						cursor: "pointer",
						padding: "10px",
						color: "rgba(30, 30, 30, 0.5)",
					}}
					onClick={() => {
						currentReviewSlide === 0
							? setCurrentReviewSlide(reviews.length - 1)
							: setCurrentReviewSlide(currentReviewSlide - 1);
					}}
					disabled={currentLogoSlide === 0} // Disable button if on first slide
				>
					<ChevronLeftIcon fontSize="large" />
				</button>
				<button
					style={{
						backgroundColor: "transparent",
						border: "none",
						cursor: "pointer",
						color: "rgba(30, 30, 30, 0.5)",
						padding: "10px",
						marginLeft: "50px",
					}}
					onClick={() => {
						currentReviewSlide === reviews.length - 1
							? setCurrentReviewSlide(0)
							: setCurrentReviewSlide(currentReviewSlide + 1);
					}}
				>
					<ChevronRightIcon fontSize="large" />
				</button>
			</Container>

			<Container
				sx={{
					width: { xs: "90%", md: "79%" },
					margin: "auto",
					height: "80%",
					// marginTop: "30px",
					marginBottom: "10px",
					borderRadius: "18px",
					textAlign: "center",
					padding: "50px 0",
				}}
			>
				<Typography
					sx={{
						color: "rgba(30, 30, 30, 0.88)",
						marginTop: "10px",
						fontWeight: 600,
						font: {
							xs: "normal 800 24px/34px Montserrat,Helvetica,Arial,Verdana,sans-serif",
							md: "normal 800 44px/54px Montserrat,Helvetica,Arial,Verdana,sans-serif",
						},
						fontSize: { xs: "17px", md: "38px" },
						// marginBottom: "20px",
					}}
				>
					<b>Quality Partners with Quality Experience</b>
				</Typography>

				<Grid container spacing={0}>
					<Grid item xs={12}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								overflow: "hidden", // Ensure overflow is hidden for the carousel effect

								minHeight: { xs: "130px", md: "290px" },
							}}
						>
							{/* Previous slide image */}
							<Box
								component="img"
								sx={{
									width: { xs: "22%", md: "20%" },
									height: { xs: "100%", md: "0%" },
									margin: "auto",
									padding: "20px",
									opacity: 0.3,
									cursor: "pointer",
									transition: "opacity 0.3s ease",
									"&:hover": {
										opacity: 0.5,
									},
								}}
								alt="Previous Slide"
								src={
									logo_slides[
										currentLogoSlide === 0
											? logo_slides.length - 1
											: currentLogoSlide - 1
									]?.image
								}
								onClick={prevSlide}
							/>

							{/* Center slide image */}
							<animated.div
								style={{
									...springs,
									width: "100%",
									height: "100%",
									margin: "auto",
								}}
							>
								<Box
									component="img"
									sx={{
										width: { xs: "100%", md: "50%" },
										height: { xs: "100%", md: "70%" },
										maxHeight: "200px",
										margin: "auto",
										padding: "20px",
										cursor: "pointer",
										transition: "transform 0.3s ease",
										"&:hover": {
											cursor: "pointer",
										},
										right: "20%",
									}}
									alt="Current Slide"
									src={logo_slides[currentLogoSlide]?.image}
									onClick={nextSlide}
								/>
							</animated.div>

							{/* Next slide image */}
							<Box
								component="img"
								sx={{
									width: { xs: "22%", md: "20%" },
									height: { xs: "80%", md: "0%" },
									margin: "auto",
									padding: "20px",
									opacity: 0.3,
									cursor: "pointer",
									transition: "opacity 0.3s ease",
									"&:hover": {
										opacity: 0.5,
									},
								}}
								alt="Next Slide"
								src={
									logo_slides[(currentLogoSlide + 1) % logo_slides.length]
										?.image
								}
								onClick={nextSlide}
							/>
						</Box>
					</Grid>
				</Grid>
				<Box>
					<div
						style={{
							justifyContent: "center",
							marginTop: "10px",
						}}
					>
						{logo_slides.map((_, index) => (
							<button
								key={index}
								style={{
									backgroundColor:
										currentLogoSlide === index
											? "rgba(30, 30, 30, 0.7)"
											: "rgba(30, 30, 30, 0.1)",
									border: "none",
									opacity: 1,
									margin: "3px", // Adjust margin for spacing
									marginTop: "5px", // Adjust top margin
									borderRadius: "50%",
									width: "8px", // Adjust width for smaller size
									height: "8px", // Adjust height for smaller size
									cursor: "pointer",
									padding: 0, // Remove padding to ensure exact dimensions
									outline: "none", // Remove outline on focus
								}}
								onClick={() => {
									if (index > currentLogoSlide) {
										api.start({
											from: {
												x: 130,
											},
											to: {
												x: isMobile ? -8 : -8,
											},
										});
									} else {
										api.start({
											from: {
												x: -100,
											},
											to: {
												x: isMobile ? -8 : -8,
											},
										});
									}
									setCurrentLogoSlide(index);
								}}
							/>
						))}
					</div>
				</Box>
			</Container>
			<Container
				sx={{
					backgroundColor: "rgba(30, 30, 30, 0.8)",
					width: { xs: "90%", md: "79%" },
					height: "195px", // If needed, adjust height
					marginBottom: "44px",
					borderRadius: "18px",
					textAlign: "center",
					padding: "50px 0",
				}}
			>
				<Typography
					sx={{
						color: "white",
						marginTop: "5px",
						fontSize: { xs: "14px", md: "24px" },
						fontFamily: "Montserrat",
					}}
				>
					<b>Let Us Help Secure Your Home</b>
				</Typography>

				<img
					onClick={() => {
						handleScrollClick();
					}}
					className="get-started-button"
					src={getStartedButton}
					alt="get started"
				/>
			</Container>
			<CookieConsentPopup onconsent={() => onconsent()} />
			<NerdwalletFooter></NerdwalletFooter>
		</>
	);
}
