
import { useState, useEffect } from 'react';
import './index.scss';
import VideoRecorder from "react-video-recorder";
import useFetch from "use-http";
import ReactPlayer from "react-player";

export default function VideoRec (videoQuestion: any){

    const [isRecordingComplete, setRecordingComplete] = useState<boolean>(false);
    const [isUploaded, setUploaded] = useState<boolean>(false);
    const [videoDataUrl, setVideoDataUrl] = useState<string>();
    const [videoTooShort, setVideoTooShort] = useState<boolean>(false);
    const [videoButtonText, setVideoButtonText] = useState<string>("Take video");
    const [isUploading, setUploading] = useState<boolean>(false);
    const [videoData, setVideoData] = useState<Blob>();

    useEffect( () => {
        if (videoQuestion.question.hasVideo){
            setRecordingComplete(true)
            setUploaded(true)
            setVideoButtonText("Retake video")
        }
    }, []);
    
    const handleRecordingComplete=(video: Blob) => {
      setRecordingComplete(true)
      setVideoData(video)
      setVideoDataUrl(window.URL.createObjectURL(video)) 
      setUploaded(false) 
      setVideoTooShort(false) 
      setVideoButtonText("Retake video")     
   
    };
  
    const handleCreateNewVideo = () => {
      setRecordingComplete(false)
      setVideoDataUrl('')   
    };

    let apiKey = process.env.REACT_APP_API__ACCESS_KEY
    ? process.env.REACT_APP_API__ACCESS_KEY
    : ''; 

    const options = {
    headers: {
    'Content-Type': 'application/json',
    Connection: 'keep-alive',
    'Accept-Encoding': 'gzip, deflate, br',
    'X-API-Key': apiKey,
    },
    cacheLife: 1000,
    };

    const { post, response } = useFetch(process.env.REACT_APP_API, options);

    async function getBase64(file: any) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => {
            resolve(reader.result)
          }
          reader.onerror = reject
        })
      }

    const UploadVideo = async () =>{
        setUploading(true)
        if (VideoLength() < 10 ){
            setVideoTooShort(true) 
        }else{           
            //create Base64String string
            let videoBase64String: any = ''            
            await getBase64(videoData) 
                .then(res => videoBase64String = res) 
                .catch(err => console.log(err))
            //build object for api call
            const candidateVideo = {
                'ApplicantId': videoQuestion.candidateId,
                'QuestionId': videoQuestion.question.id,
                'ApplicantFullName': videoQuestion.candidateName,
                'Base64String': videoBase64String
            }

            const postResponse = await post(`/CandidateVideo/uploadCandidateVideo`, candidateVideo);
            if (postResponse === 'AllDone'){
                videoQuestion.setAllUploaded(true)
            }
            setUploaded(true)
        }        
        setUploading(false)       
    }

    const VideoLength = () => {
        let vid = document.getElementById("Recorded_Video");
        {/* @ts-ignore */}   
        return vid.duration;
    };  
   

    return (
            <div>                                      
                {!isRecordingComplete && (
                <div  style={{ width:640, height:480}}> 
                    {/* @ts-ignore */}                                                                   
                    <VideoRecorder
                        isFlipped={false}
                        countdownTime={0}
                        //mimeType = "video/webm" // mp4 not supported
                        constraints={{
                                    audio: true,
                                    video: {
                                        width: { exact: 640, ideal: 640 },
                                        height: { exact: 480, ideal: 480 }
                                    }
                                }}
                        timeLimit={60000}
                        onRecordingComplete={ (videoBlob: Blob) => {handleRecordingComplete(videoBlob) }}
                    />                                      
                </div> 
                )}
                {isRecordingComplete && (
                <span> 
                    {videoDataUrl && (
                        <div>                                    
                            <ReactPlayer
                            id="Recorded_Video"                                            
                            url={videoDataUrl}                        
                            controls
                            />    
                        </div>
                    )}                    
                    {isUploaded &&
                        <div >
                                <b>Video Uploaded!</b>
                        </div>
                    }
                    <div>                                  
                    <button onClick={handleCreateNewVideo}>
                    {videoButtonText}
                    </button>
                    <button disabled={isUploaded} onClick={ () => UploadVideo()}>
                      Upload video
                    </button>
                    </div> 
                    {videoTooShort &&
                        <div >
                                Video too short - need at least 10 second, please retake.
                        </div>
                    }
                    {isUploading &&
                        <div >
                                Uploading.....
                        </div>
                    }
                    
                </span>
                
                )}
            </div> 
    );
}