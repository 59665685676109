import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

interface SplitBlockProps {
    backgroundImage: string;
    leftContent: React.ReactNode;
    rightImage: string;
    onButtonClick: () => void;
}

export default function TemplateThree(props: SplitBlockProps) {
    const { backgroundImage, leftContent, rightImage, onButtonClick } = props;

    return (
        <>
            <Container maxWidth={false} className='blue-gradient-bg' sx={{ padding: '0!important', maxWidth: '1500px' }}>
                <Box
                    component="img"
                    sx={{
                        minHeight: "600px",
                        maxWidth: "100%",
                        width: "100%",
                        height: "auto",
                    }}
                    src={backgroundImage}
                />

                <Grid container className="blue-gradient-overlay" sx={{ padding: { xs: '0 16px', sm: '0 32px', lg: '0' }, alignContent: { xs: 'center', md: 'initial' } }}>
                    <Grid item xs={12} md={6}>
                        <Stack sx={{ maxWidth: "100%", alignItems: "flex-start", paddingRight: '12px' }}>
                            {leftContent}

                            <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                <Button variant='contained' className='get-quote-btn' onClick={onButtonClick} sx={{ transition: 'none' }}>
                                    GET A FREE QUOTE
                                </Button>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            component="img"
                            sx={{
                                paddingLeft: '12px',
                                maxWidth: "100%",
                                height: "auto",
                                display: { xs: 'none', md: 'initial' }
                            }}
                            src={rightImage}
                        />
                    </Grid>
                </Grid>
            </Container>
            <Box
                component="img"
                sx={{
                    maxWidth: "100%",
                    width: '100%',
                    height: "auto",
                    display: { xs: 'initial', md: 'none' }
                }}
                src={rightImage}
            />
        </>
    );
};