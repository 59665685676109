import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Logo from '../../Assets/Global/logo.png';
import './index.scss';

export default function Shsicon(props: any) {
  return (
    <Box component={Link} to='/'>
      <img src={Logo} alt='logo' className='shs-icon-logo' />
    </Box>
  );
}
