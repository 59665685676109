import React, { useState, useEffect } from "react";
import reportWebVitals from "../../utils/reportWebVitals";
import { ReactTagManager } from "react-gtm-ts";
import { Box, Button, Grid, IconButton, Slide, Typography } from "@mui/material";
import useAnalytics from "../../utils/useAnalytics";
import { gtagDeny, gtagAccept } from "./consent";
import "./index.scss";
import ClearIcon from '@mui/icons-material/Clear';
import { CONSENT_KEY } from "../../Constants";

interface ICookieProps {
	onconsent?: () => void;
}

const CookieConsentPopup = (props: ICookieProps) => {
	const [isVisible, setIsVisible] = useState(false);
	// useAnalytics();

	useEffect(() => {
		const consent = localStorage.getItem(CONSENT_KEY);
		if (!consent) {
			setIsVisible(true);
		} else {
			console.log("GTM-K3XGL8HK");
			ReactTagManager.init({
				code: "GTM-T5T79RDF",
				debug: true,
				performance: false,
			});
		}
	}, [CONSENT_KEY]);

	const handleConsent = () => {
		gtagAccept();
		localStorage.setItem(CONSENT_KEY, "true");

		ReactTagManager.init({
			code: "GTM-K3XGL8HK",
			debug: false,
			performance: false,
		});

		if (props.onconsent) {
			props.onconsent();
		}

		setIsVisible(false);
	};

	const handleReject = () => {
		localStorage.setItem(CONSENT_KEY, "false");
		setIsVisible(false);
		gtagDeny();
	};

	return (
		<Slide direction='up' in={isVisible}
			timeout={{
				enter: 250,
				exit: 200,
			}}
		>
			<Box className="cookies-consent-container" sx={{}}>
				<Box sx={{
					position: "absolute",
					right: "5px",
					top: '5px'
				}}>
					<IconButton
						sx={{}}
						onClick={handleConsent}
					>
						<ClearIcon sx={{ color: '#fff' }}></ClearIcon>
					</IconButton>
				</Box>

				<Box
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						height: "100%",
					}}
				>
					<Typography style={{ color: "#fff", fontWeight: 700, fontSize: '20px', marginBottom: "12px" }}>
						We value your privacy
					</Typography>
					<Typography style={{ color: "#fff", marginBottom: ".5em" }}>
						This site uses cookies to enhance your experience. By continuing to
						browse, you agree to our use of cookies.
					</Typography>
				</Box>

				<Grid
					container
					sx={{ maxWidth: '400px', margin: '10px auto' }}
					flexDirection={{ xs: 'column-reverse', sm: 'row' }}
				>
					<Grid item xs={12} sm={4} sx={{ textAlign: { xs: 'center', md: 'initial' } }}>
						<Button
							variant="outlined"
							href="https://mysafehaven.com/legal/privacy/"
							target="_blank"
							sx={{
								width: 'calc(100% - 10px)',
								color: "#fff",
								borderColor: "#fff",
							}}
						>
							Read More
						</Button>

					</Grid>
					<Grid item xs={12} sm={4}>
						<Button
							variant="outlined"
							onClick={handleReject}
							sx={{
								width: 'calc(100% - 10px)',
								color: "#fff",
								borderColor: "#fff",
							}}
						>
							Reject ALL
						</Button>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Button
							variant="outlined"
							onClick={handleConsent}
							sx={{
								width: 'calc(100% - 10px)',
								color: "#142f5a",
								backgroundColor: '#fff',
								borderColor: "#fff",
								'&:hover': {
									color: "#fff",
								}
							}}
						>
							Accept ALL
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Slide>

	);
};

export default CookieConsentPopup;
