import { Box, Container } from '@mui/system';
import companyAccolade from '../../Assets/Global/Safe-Haven-Top-Bar-2.png';

export default function Accoladebanner(props: any) {


  return (
    <Box sx={{ backgroundColor: '#1260a8' }}>
      <Container
        maxWidth={false}
        sx={{
          height: '100%',
          overflow: 'hidden',
          background: 'transparent',

        }}
      >
        <Box
          component='img'
          sx={{
            width: 'auto',
            maxWidth: '100%',
            padding: '20px 0',
            display: 'flex',
            margin: 'auto'
          }}
          alt='COMPANY ACCOLADE: INC. 5000 FASTEST GROWING COMPANY'
          src={companyAccolade}
        />
      </Container>
    </Box>
  );
}
