import { ThemeOptions } from "@mui/material/styles/createTheme";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xxs: true;
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

// Screen Size Breakpoints
// xs, extra-small: 0px    -> Phones
// sm, small: 600px        -> iPad/Tablet
// md, medium: 900px       -> Laptop
// lg, large: 1200px       -> Desktop/Laptop
// xl, extra-large: 1536px -> Large Desktop
// xxl, extra-extra-large: 1900px -> X-Large Desktop
export const customBreakpoints: ThemeOptions = {
  breakpoints: {
    values: {
      xxs: 0,
      xs: 500,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1900,
    },
  },
};
