import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Modal,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import image1 from '../../Assets/CustomerSolutions/inc-auth-bbb-horizontal2.png';
import { useEffect, useState } from 'react';
import ContactUsForm from '../../Components/Forms/ContactUsForm';
import Reviews from './Reviews';
import './index.scss';
import { Helmet } from 'react-helmet';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function CustomerSolutions() {
  const navigate = useNavigate();
  const handleNavigate = (route: string) => navigate(`${route}`);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const [formOpen, setFormOpen] = useState(false);
  const handleFormOpen = () => {
    setFormOpen(true);
  };
  const handleFormClose = () => {
    setFormOpen(false);
  };

  useEffect(() => {
    if (confirmationOpen) {
      handleFormClose();
    }
  }, [confirmationOpen]);

  return (
    <>
      <Helmet>
        <title>Customer Solutions | Safe Haven Security</title>
        <meta
          name="description"
          content="Explore Safe Haven Security's comprehensive customer solutions to protect your home and family. Find the right security system for your needs."
        />
        <meta
          name="keywords"
          content="Safe Haven Security, customer solutions, home security, security systems, protection, family safety, peace of mind, contact us"
        />
      </Helmet>
      <Box sx={{ backgroundColor: 'rgb(7, 95, 170)' }}>
        <Stack
          sx={{
            padding: { xs: '65px 0 75px 0', lg: '95px 0 75px 0' },
            textAlign: 'center',
          }}
        >
          <Typography
            sx={{
              color: '#98b0d2',
              fontWeight: '700',
              fontSize: { xs: '45px', lg: '65px' },
            }}
          >
            SECURE YOUR HOME TODAY
          </Typography>
          <Typography
            sx={{
              fontWeight: '700',
              fontSize: { xs: '28px', lg: '40px' },
              color: '#ffffff',
            }}
          >
            CALL US AT 877.842.0818 OR
          </Typography>
          <Box>
            <Button
              sx={{
                border: '1px solid rgb(7, 95, 170)',
                background: '#ffffff',
                color: '#1260a8',
                fontWeight: '700',
                padding: '12px 16px',
                fontSize: '20px',
                lineHeight: ' 20px;',
                boxShadow: '2px 2px gray;',
                marginTop: '10px',
                '&:hover': {
                  color: '#ffffff',
                  border: '1px solid #fff'
                }
              }}
              onClick={handleFormOpen}
            >
              Click Here
            </Button>
            <Modal
              open={formOpen}
              closeAfterTransition
              onClose={handleFormClose}
              className=''
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Slide direction='up' in={formOpen}>
                <Box
                  sx={{
                    maxWidth: 900,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    overflowX: 'auto',
                    maxHeight: '100vh',

                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography sx={{ paddingLeft: '8px', fontWeight: 700, fontSize: '24px' }}>
                      Contact Us
                    </Typography>
                    <Box className="" sx={{ display: 'flex', marginRight: 0, maxWidth: "100%" }}>
                      <IconButton
                        sx={{ margin: 'auto 0 auto auto' }}
                        onClick={() => { handleFormClose() }}
                      >
                        <ClearIcon></ClearIcon>
                      </IconButton>
                    </Box>
                  </Box>

                  <Divider sx={{ margin: '10px' }}></Divider>

                  <ContactUsForm setConfirmationOpen={setConfirmationOpen}></ContactUsForm>

                </Box>
              </Slide>
            </Modal>

            <Modal
              open={confirmationOpen}
              closeAfterTransition
              onClose={() => {
                handleConfirmationClose();
              }}
              className=''
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Slide direction='up' in={confirmationOpen}>
                <Container
                  maxWidth={false}
                  sx={{
                    maxWidth: '700px',
                    minHeight: '400px',
                    height: 'auto',
                    bgcolor: '#fff',
                    borderRadius: '9px',
                    boxShadow: 24,
                    padding: '0!important'
                  }}
                >
                  <Box sx={{ background: 'rgb(18, 96, 168)', width: '100%', minHeight: '64px', borderRadius: '9px 9px 0 0px', padding: '10px 0', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                    <Typography sx={{ fontSize: '24px', fontWeight: '700', textAlign: 'center', color: '#fff' }}>
                      Thank you for contacting Safe Haven!
                    </Typography>
                    <Box sx={{
                      position: "absolute",
                      right: "5px",
                    }}>
                      <IconButton
                        sx={{}}
                        onClick={() => {
                          handleConfirmationClose();
                          handleFormClose();
                        }}
                      >
                        <ClearIcon sx={{ color: '#fff' }}></ClearIcon>
                      </IconButton>
                    </Box>
                  </Box>
                  <Stack
                    direction={'row'}
                    sx={{ alignItems: 'center', justifyContent: 'space-evenly' }}
                  >

                    <Box
                      sx={{
                        paddingLeft: '36px',
                        minHeight: '350px',
                        minWidth: '250px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                      }}
                    >
                      <Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                          <CheckCircleOutlineIcon sx={{ color: 'rgb(18, 96, 168)', margin: 'auto', height: '4rem', width: 'auto', marginBottom: '15px' }}></CheckCircleOutlineIcon>
                        </Box>

                        <Typography sx={{ fontSize: '24px', maxWidth: '600px' }}>
                          We received your request for more information and a member of our team will be in touch with you shortly to answer any questions you have.
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '24px 0' }}>
                          <Button
                            variant={'contained'}
                            onClick={() => {
                              handleNavigate('/');
                            }}
                            sx={{
                              minWidth: '100px',
                              minHeight: '45px',
                              fontSize: '16px',
                              textTransform: 'none',
                              fontWeight: '700',
                            }}
                          >
                            Back To Home
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Stack>
                </Container>
              </Slide>
            </Modal>
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{ padding: '60px 0', display: 'flex', backgroundColor: '#ffffff' }}
      >
        <Box
          component='img'
          sx={{
            width: 'auto',
            maxWidth: '100%',
            maxHeight: '75vh',
            margin: 'auto',
          }}
          alt='The house from the offer.'
          src={image1}
        />
      </Box>
      <Reviews></Reviews>
    </>
  );
}
