import {
  Box,
  TextField,
  Button,
  FormControl,
  SelectChangeEvent,
  Autocomplete,
  Container,
  Modal,
  Slide,
  Stack,
  Typography,
  IconButton,
} from '@mui/material';
import { useState } from 'react';
import useFetch from 'use-http';
import './index.scss';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { EMPTY_EMPLOYEE_PHOTO, STATE_CODES, STATE_LIST } from '../../../Constants';
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';

export default function HomeIsConnectedForm() {
  const navigate = useNavigate();
  const handleNavigate = (route: string) => navigate(`${route}`);
  const [value, setValue] = useState<string | null>(STATE_LIST[0]);
  const [inputValue, setInputValue] = useState('');
  const [sendEmailResult, setSendEmailResult] = useState();
  const [emailReqBody, setEmailReqBody] = useState({
    to: 'recipient',
    subject: 'subject',
    content: 'content',
  });

  const [formData, setFormData] = useState({
    firstName: { value: '', error: false, errorMessage: '' },
    lastName: { value: '', error: false, errorMessage: '' },
    phone: { value: '', error: false, errorMessage: '' },
    email: { value: '', error: false, errorMessage: '' },
    city: { value: '', error: false, errorMessage: '' },
    stateCode: { value: '', error: false, errorMessage: '' },
    address: { value: '', error: false, errorMessage: '' },
    notes: { value: '', error: false, errorMessage: '' },
  });

  function HandleSetReqEmailBody() {
    let recipients = 'DRHortonQR@mysafehaven.com';
    const subject = `New Submission From DR Horton QR Page For: ${formData.firstName.value} ${' '} ${formData.lastName.value}`;
    const content = `
    <strong>Lead Information : From DR Horton QR page Below</strong>
    
    First Name : ${formData.firstName.value} Last Name:  ${formData.lastName.value}    
    <br></br>
    Phone : ${formData.phone.value}
    <br></br>
    Street Address : ${formData.address.value}
    <br></br>
    Phone Number: ${formData.phone.value}
    <br></br>
    Email: ${formData.email.value}
    <br></br>
    City: ${formData.city.value}
    <br></br>
    State: ${formData.stateCode.value}
    <br></br>   
    Notes: ${formData.notes.value}
    <br></br>  
    `;

    setEmailReqBody({
      to: recipients,
      subject: subject,
      content: content,
    });
  }

  const handleStateChange = (state: string) => {
    setFormData((prevData) => ({
      ...prevData,
      stateCode: { ...formData.stateCode, value: state },
    }));
  };

  const handleFieldChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const currentInput = event.target.value;

      let validationRegex = /^[a-zA-Z]+$/;
      if (field === 'firstName' || field === 'lastName') {
        validationRegex = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
      } else if (field === 'city') {
        validationRegex =
          /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
      } else if (field === 'phone' || field === 'zip') {
        validationRegex = /^\d+|$/;
      } else if (field === 'email' || field === 'address' || 'notes') {
        validationRegex = /^.+?|$/;
      }

      if (!validationRegex.test(currentInput)) {
        setFormData((prevData) => ({
          ...prevData,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [field]: {
            ...[field],
            value: currentInput,
          },
        }));
      }
      HandleSetReqEmailBody();
    };

  const handleFieldErrorChange = (field: string, value: boolean) => {
    setFormData((prevData) => ({
      ...prevData,
      [field as keyof typeof prevData]: {
        // @ts-ignore
        ...prevData[field],
        error: value,
      },
    }));
  };

  const handleFormSubmit = () => {
    if (
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        formData.email.value
      )
    ) {
      handleFieldErrorChange('email', true);
    }

    const requiredFields = [
      'firstName',
      'lastName',
      'phone',
      'email',
      'city',
      'stateCode',
      'address',
    ];

    requiredFields.forEach((field) => {
      // @ts-ignore
      if (!formData[field].value) {
        handleFieldErrorChange(field, true);
      }
    });

    HandleSetReqEmailBody();

    sendEmail().then((res: any) => setSendEmailResult(res));

    if (sendEmailResult) {

    }
  };

  let apiKey = process.env.REACT_APP_API__ACCESS_KEY
    ? process.env.REACT_APP_API__ACCESS_KEY
    : '';
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Connection: 'keep-alive',
      'Accept-Encoding': 'gzip, deflate, br',
      'X-API-Key': apiKey,
    },
  };
  const { post, response, loading, error } = useFetch(
    process.env.REACT_APP_API,
    options
  );

  const sendEmail = async () => {
    const postResponse = await post(`/Email/SendEmail`, emailReqBody);
    if (postResponse) {
      setModalOpen(true);
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Box
      sx={{
        '& .MuiTextField-root': { m: 1 },
        '& .MuiTextarea-root': { m: 1 },
        '& .quarter-width': { width: { xs: 'calc(50% - 16px)', sm: 'calc(25% - 16px)' } },
        '& .half-width': { width: { xs: 'calc(100% - 16px)', sm: 'calc(50% - 16px)' } },
        '& .full-width': { width: 'calc(100% - 16px)' },
      }}
    >
      <TextField
        required
        id='outlined-required'
        label='First Name'
        className='half-width'
        onChange={handleFieldChange('firstName')}
        value={formData.firstName.value}
        error={formData.firstName.error}
      />

      <TextField
        required
        id='outlined-required'
        label='Last Name'
        className='half-width'
        onChange={handleFieldChange('lastName')}
        value={formData.lastName.value}
        error={formData.lastName.error}
      />

      <TextField
        id='outlined-helperText'
        label='Phone'
        className='half-width'
        onChange={handleFieldChange('phone')}
        value={formData.phone.value}
        error={formData.phone.error}
      />

      <TextField
        id='outlined-helperText'
        label='Email'
        className='half-width'
        onChange={handleFieldChange('email')}
        value={formData.email.value}
        error={formData.email.error}
      />

      <TextField
        id='outlined-helperText'
        label='Address'
        className='half-width'
        onChange={handleFieldChange('address')}
        value={formData.address.value}
        error={formData.address.error}
      />

      <TextField
        id='outlined-helperText'
        label='City'
        className='quarter-width'
        onChange={handleFieldChange('city')}
        value={formData.city.value}
        error={formData.city.error}
      />

      <FormControl className='quarter-width' sx={{ margin: '8px' }}>
        <Autocomplete
          disablePortal
          options={STATE_LIST}

          value={formData.stateCode.value}
          onChange={(event: any, newValue: string | null) => {
            handleFieldChange('state');
          }}
          inputValue={formData.stateCode.value}
          onInputChange={(event, newInputValue) => {
            handleStateChange(newInputValue);
          }}
          renderInput={(params) =>
            <TextField
              error={formData.stateCode.error}
              sx={{ margin: "0!important" }}
              {...params} label="State"
            />
          }
        />
      </FormControl>

      <TextField
        className='full-width'
        multiline
        rows={4}
        label="Here you can put any additional notes that you would like for the concierge team/installers to know."
        onChange={handleFieldChange('notes')}
        value={formData.notes.value}
      />

      <Box sx={{ padding: '2rem', display: 'flex', justifyContent: 'center' }}>
        <LoadingButton
          variant='contained'
          className='contact-form-submit'
          onClick={() => {
            handleFormSubmit();
          }}
          loading={loading}
          sx={{
            maxHeight: '56px',
            minWidth: '100px',
            fontSize: '16px',
            textTransform: 'none',
            fontWeight: '700',
          }}
        >
          Submit
        </LoadingButton>
        <Modal
          open={modalOpen}
          closeAfterTransition
          onClose={handleModalClose}
          className=''
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Slide direction='up' in={modalOpen}>
            <Container
              maxWidth={false}
              sx={{
                maxWidth: '700px',
                minHeight: '400px',
                height: 'auto',
                bgcolor: '#fff',
                borderRadius: '9px',
                boxShadow: 24,
                padding: '0!important'
              }}
            >
              <Box sx={{ background: 'rgb(18, 96, 168)', width: '100%', height: '64px', borderRadius: '9px 9px 0 0px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                <Typography sx={{ fontSize: '24px', fontWeight: '700', textAlign: 'center', color: '#fff' }}>
                  Thank you for contacting Safe Haven!
                </Typography>
                <Box sx={{
                  position: "absolute",
                  right: "5px",
                }}>
                  <IconButton
                    sx={{}}
                    onClick={() => { handleModalClose() }}
                  >
                    <ClearIcon sx={{ color: '#fff' }}></ClearIcon>
                  </IconButton>
                </Box>
              </Box>
              <Stack
                direction={'row'}
                sx={{ alignItems: 'center', justifyContent: 'space-evenly' }}
              >

                <Box
                  sx={{
                    paddingLeft: '36px',
                    minHeight: '350px',
                    minWidth: '250px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                  }}
                >
                  <Box>
                    <Box sx={{ width: '100%', display: 'flex' }}>
                      <CheckCircleOutlineIcon sx={{ color: 'rgb(18, 96, 168)', margin: 'auto', height: '4rem', width: 'auto', marginBottom: '15px' }}></CheckCircleOutlineIcon>
                    </Box>

                    <Typography sx={{ fontSize: '24px', maxWidth: '600px' }}>
                      We received your information and will be in touch shortly to discuss your Home is Connected® system for your new D.R. Horton home.
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '24px 0' }}>
                      <Button
                        variant={'contained'}
                        onClick={() => {
                          handleNavigate('/');
                        }}
                        sx={{
                          minWidth: '100px',
                          minHeight: '45px',
                          fontSize: '16px',
                          textTransform: 'none',
                          fontWeight: '700',
                        }}
                      >
                        Back To Home
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Stack>
            </Container>
          </Slide>
        </Modal>
      </Box>
    </Box>
  );
}



