import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Container,
  Grid,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import item1VideoMonitoring from '../../../Assets/Products/Video-monitoring.jpg';
import item2Tablet from '../../../Assets/Products/IQ4-tablet.jpg';
import item3SmokeAlarm from '../../../Assets/Products/Smoke-sensor.jpg';
import item4HomeAutomation from '../../../Assets/Products/Home-automation.jpg';
import solarImage from '../../../Assets/Products/Solar-image.jpg';
import tabletWithFamily from '../../../Assets/Products/IQ-Panel-4-Music-Streaming.jpg';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import { customBreakpoints } from '../../../themeOptions';
import MediaQuery from 'react-responsive';

const SectionOne = () => {
  return (
    <ThemeProvider theme={createTheme(customBreakpoints)}>
      <Box
        className='Product-Section-1'
        sx={{ width: '100%', maxWidth: '100%', bgcolor: '#edecee' }}
      >
        <Box
          className='Home-Section-2'
          sx={{ width: '100%', maxWidth: '100%', backgroundColor: '#d0d0d2' }}
        >
          <Container sx={{ textAlign: 'center', padding: '0' }}>
            <Stack
              spacing={{ xxs: 0, sm: 4 }}
              direction={'row'}
              sx={{
                placeContent: 'center',
                height: { xxs: '15vh', xl: '10vh' },
                padding: '15px',
                justifyContent: { xxs: 'space-between', sm: 'initial' },
              }}
            >
              <IconButton
                aria-label='delete'
                sx={{
                  width: { xxs: '20%', sm: '15%' },
                  display: 'block',
                  fontSize: '.5rem',
                  height: '100%',
                  color: '#00549c',
                  borderRadius: '0',
                  padding: '0',
                }}
              >
                <VideoCameraFrontIcon
                  sx={{
                    width: { xxs: '34px', sm: '44px', md: '54px' },
                    height: 'auto',
                    top: '0px',
                  }}
                />
                <Typography
                  sx={{
                    width: { xxs: '100%', lg: '70%' },
                    margin: 'auto',

                    fontSize: { xxs: '12px', sm: 'initial' },
                  }}
                >
                  Video Monitoring
                  <MediaQuery query='(max-device-width: 500px)'>
                    <br></br>
                    <br></br>
                  </MediaQuery>
                </Typography>
              </IconButton>
              <IconButton
                aria-label='delete'
                sx={{
                  width: { xxs: '20%', sm: '15%' },
                  display: 'block',
                  fontSize: '.5rem',
                  height: '100%',
                  color: '#00549c',
                  borderRadius: '0',
                  padding: '0',
                }}
              >
                <AdminPanelSettingsIcon
                  sx={{
                    width: { xxs: '34px', sm: '44px', md: '54px' },
                    height: 'auto',
                    top: '0px',
                  }}
                />
                <Typography
                  sx={{
                    width: { xxs: '100%', lg: '70%' },
                    margin: 'auto',
                    fontSize: { xxs: '12px', sm: 'initial' },
                  }}
                >
                  Home Security Systems
                </Typography>
              </IconButton>
              <IconButton
                aria-label='delete'
                sx={{
                  width: { xxs: '20%', sm: '15%' },
                  display: 'block',
                  fontSize: '.5rem',
                  height: '100%',
                  color: '#00549c',
                  borderRadius: '0',
                  padding: '0',
                }}
              >
                <HealthAndSafetyIcon
                  sx={{
                    width: { xxs: '34px', sm: '44px', md: '54px' },
                    height: 'auto',
                    top: '0px',
                  }}
                />
                <Typography
                  sx={{
                    width: { xxs: '100%', lg: '70%' },
                    margin: 'auto',
                    fontSize: { xxs: '12px', sm: 'initial' },
                  }}
                >
                  Life & Fire Safety
                  <MediaQuery query='(max-device-width: 500px)'>
                    <br></br>
                    <br></br>
                  </MediaQuery>
                </Typography>
              </IconButton>
              <IconButton
                aria-label='delete'
                sx={{
                  width: { xxs: '20%', sm: '15%' },
                  height: '100%',
                  display: 'block',
                  fontSize: '.5rem',
                  color: '#00549c',
                  borderRadius: '0',
                  padding: '0',
                }}
              >
                <SolarPowerIcon
                  sx={{
                    top: '0px',
                    width: { xxs: '34px', sm: '44px', md: '54px' },
                    height: 'auto',
                  }}
                />
                <Typography
                  sx={{
                    width: { xxs: '100%', lg: '70%' },
                    margin: 'auto',
                    fontSize: { xxs: '12px', sm: 'initial' },
                  }}
                >
                  Solar
                  <MediaQuery query='(max-device-width: 500px)'>
                    <br></br>
                    <br></br>
                    <br></br>
                  </MediaQuery>
                </Typography>
              </IconButton>
              <IconButton
                aria-label='delete'
                sx={{
                  width: { xxs: '20%', sm: '15%' },
                  display: 'block',
                  fontSize: { xxs: '.5rem' },
                  height: '100%',
                  color: '#00549c',
                  borderRadius: '0',
                  padding: '0',
                }}
              >
                <RoomPreferencesIcon
                  sx={{
                    width: { xxs: '34px', sm: '44px', md: '54px' },
                    height: 'auto',
                    top: '0px',
                  }}
                />
                <Typography
                  sx={{
                    width: { xxs: '100%', lg: '70%' },
                    margin: 'auto',
                    fontSize: { xxs: '12px', sm: 'initial' },
                  }}
                >
                  Home Automation
                  <MediaQuery query='(max-device-width: 500px)'>
                    <br></br>
                    <br></br>
                  </MediaQuery>
                </Typography>
              </IconButton>
            </Stack>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const SectionTwo = () => {
  return (
    <ThemeProvider theme={createTheme(customBreakpoints)}>
      <Box
        className='Product-Section-2'
        sx={{ margin: { xxs: '1rem', sm: '3rem' } }}
      >
        <Stack sx={{ textAlign: 'center' }}>
          <Typography
            sx={{
              color: '#00549c',
              fontSize: { xxs: '1.5rem', sm: '2.5rem', md: '3.5rem' },
            }}
          >
            Safe Haven Security Solutions
          </Typography>
          <Typography
            sx={{ fontSize: { xxs: '.75rem', sm: '1.25rem', md: '1.75rem' } }}
          >
            Dedicated to ensuring peace of mind from the inside out.
          </Typography>
          <Box sx={{ marginTop: '6px' }}>
            <Button variant='text'>Request A Free Quote &gt;</Button>
          </Box>
          <Box>
            <Button variant='text' sx={{ color: '#de3c4b' }}>
              Send An Inquiry &gt;
            </Button>
          </Box>
        </Stack>
      </Box>
    </ThemeProvider>
  );
};

const SectionThree = () => {
  // TODO: Make these hook names more descriptive
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  return (
    <Box sx={{ backgroundColor: '#cbd2d0' }}>
      <Container className='Product-Section-3'>
        <ThemeProvider theme={createTheme(customBreakpoints)}>
          <Grid container spacing={0} sx={{}}>
            {/* TODO: change this to a dynamic map of selectable objects */}
            <Grid
              item
              xxs={12}
              xs={6}
              sx={{
                position: 'relative',
                textAlign: 'center',
                maxHeight: { xxs: '480px' },
              }}
            >
              {show1 && (
                <Box
                  sx={{
                    backgroundColor: '#00549c',
                    color: '#ffffff',
                    opacity: '.9',
                    top: '-10%',
                    left: '-5%',
                    position: 'absolute',
                    zIndex: 2,
                    textAlign: 'center',
                    height: '120%',
                    maxHeight: '120%',
                    width: '85%',
                    maxWidth: '85%',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: '12.5%',
                    paddingRight: '12.5%',
                  }}
                  onClick={() => setShow1(false)}
                >
                  <Typography
                    sx={{
                      fontSize: { xxs: '1rem', md: '2.5rem' },
                    }}
                  >
                    Video Monitoring
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: '.5rem',
                      fontSize: { xxs: '.7rem', md: '1rem', xl: '1.25rem' },
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Typography>
                  <Box>
                    <Button
                      variant='text'
                      sx={{
                        color: '#7bc9d4',
                        textTransform: 'initial',
                        fontSize: { xxs: '.7rem', md: '1rem' },
                      }}
                    >
                      Shop now &gt;
                    </Button>
                  </Box>

                  <Stack
                    spacing={0}
                    direction={'row'}
                    sx={{
                      placeContent: 'center',
                      height: { xxs: '15vh', xl: '10vh' },
                      justifyContent: { xxs: 'space-between', sm: 'initial' },
                    }}
                  >
                    <IconButton
                      aria-label='delete'
                      sx={{
                        width: '33%',
                        display: 'block',
                        fontSize: '.5rem',
                        height: '100%',
                        color: '#fff',
                        borderRadius: '0',
                        padding: '0',
                      }}
                    >
                      <AdminPanelSettingsIcon
                        sx={{
                          width: { xxs: '24px', sm: '44px' },
                          height: 'auto',
                          top: '0px',
                        }}
                      />
                      <Typography
                        sx={{
                          width: { xxs: '100%', lg: '70%' },
                          margin: 'auto',
                          fontSize: { xxs: '.7rem', sm: 'initial' },
                        }}
                      >
                        Home Security Systems
                      </Typography>
                    </IconButton>
                    <IconButton
                      aria-label='delete'
                      sx={{
                        width: '33%',
                        display: 'block',
                        fontSize: '.5rem',
                        height: '100%',
                        color: '#fff',
                        borderRadius: '0',
                        padding: '0',
                      }}
                    >
                      <AdminPanelSettingsIcon
                        sx={{
                          width: { xxs: '24px', sm: '44px' },
                          height: 'auto',
                          top: '0px',
                        }}
                      />
                      <Typography
                        sx={{
                          width: { xxs: '100%', lg: '70%' },
                          margin: 'auto',
                          fontSize: { xxs: '12px', sm: 'initial' },
                        }}
                      >
                        Home Security Systems
                      </Typography>
                    </IconButton>
                    <IconButton
                      aria-label='delete'
                      sx={{
                        width: '33%',
                        display: 'block',
                        fontSize: '.5rem',
                        height: '100%',
                        color: '#fff',
                        borderRadius: '0',
                        padding: '0',
                      }}
                    >
                      <AdminPanelSettingsIcon
                        sx={{
                          width: { xxs: '24px', sm: '44px' },
                          height: 'auto',
                          top: '0px',
                        }}
                      />
                      <Typography
                        sx={{
                          width: { xxs: '100%', lg: '70%' },
                          margin: 'auto',
                          fontSize: { xxs: '12px', sm: 'initial' },
                        }}
                      >
                        Home Security Systems
                      </Typography>
                    </IconButton>
                  </Stack>
                </Box>
              )}

              <Box
                sx={{
                  backgroundColor: '#bac8c9',
                  position: 'relative',
                  textAlign: 'center',
                  overflow: 'hidden',
                  maxHeight: { xxs: '480px' },
                  overflowX: 'hidden',
                  overflowY: 'hidden',
                }}
              >
                <Box
                  component='img'
                  sx={{
                    zIndex: 0,
                    width: '100%',
                    maxWidth: '100%',
                    height: 'auto',
                    marginTop: {
                      xxs: '100px',
                      sm: '120px',
                      md: '120px',
                      lg: '95px',
                    },
                  }}
                  alt='The house from the offer.'
                  src={item1VideoMonitoring}
                />
                <Card
                  sx={{
                    backgroundColor: 'transparent',
                    top: '0',
                    position: 'absolute',
                    zIndex: 1,
                    textAlign: 'center',
                    height: '100%',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                >
                  <CardActionArea
                    sx={{ height: '100%' }}
                    onClick={() => setShow1(true)}
                  >
                    <Box
                      sx={{
                        height: '100%',
                        paddingTop: {
                          xxs: '1.5rem',
                          sm: '2rem',
                          md: '3rem',
                          xl: '2.5rem',
                        },
                      }}
                    >
                      {!show1 && (
                        <>
                          <Typography
                            sx={{ fontSize: { xxs: '1.75rem', md: '2.5rem' } }}
                          >
                            Video Monitoring
                          </Typography>
                          <Typography
                            sx={{ color: '#00549c', fontSize: '1.25rem' }}
                          >
                            Learn more &gt;
                          </Typography>
                        </>
                      )}
                    </Box>
                  </CardActionArea>
                </Card>
              </Box>
            </Grid>
            <Grid
              item
              xxs={12}
              xs={6}
              sx={{
                position: 'relative',
                textAlign: 'center',
                maxHeight: { xxs: '480px' },
              }}
            >
              {show2 && (
                <Box
                  sx={{
                    backgroundColor: '#00549c',
                    color: '#ffffff',
                    opacity: '.9',
                    top: '-10%',
                    left: '-5%',
                    position: 'absolute',
                    zIndex: 2,
                    textAlign: 'center',
                    height: '120%',
                    maxHeight: '120%',
                    width: '85%',
                    maxWidth: '85%',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: '12.5%',
                    paddingRight: '12.5%',
                  }}
                  onClick={() => setShow2(false)}
                >
                  <Typography
                    sx={{
                      fontSize: { xxs: '1rem', md: '2.5rem' },
                    }}
                  >
                    Home Security Systems
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: '.5rem',
                      fontSize: { xxs: '.7rem', md: '1rem', xl: '1.25rem' },
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Typography>
                  <Box>
                    <Button
                      variant='text'
                      sx={{
                        color: '#7bc9d4',
                        textTransform: 'initial',
                        fontSize: { xxs: '.7rem', md: '1rem' },
                      }}
                    >
                      Shop now &gt;
                    </Button>
                  </Box>

                  <Stack
                    spacing={0}
                    direction={'row'}
                    sx={{
                      placeContent: 'center',
                      height: { xxs: '15vh', xl: '10vh' },
                      justifyContent: { xxs: 'space-between', sm: 'initial' },
                    }}
                  >
                    <IconButton
                      aria-label='delete'
                      sx={{
                        width: '33%',
                        display: 'block',
                        fontSize: '.5rem',
                        height: '100%',
                        color: '#fff',
                        borderRadius: '0',
                        padding: '0',
                      }}
                    >
                      <AdminPanelSettingsIcon
                        sx={{
                          width: { xxs: '24px', sm: '44px' },
                          height: 'auto',
                          top: '0px',
                        }}
                      />
                      <Typography
                        sx={{
                          width: { xxs: '100%', lg: '70%' },
                          margin: 'auto',
                          fontSize: { xxs: '.7rem', sm: 'initial' },
                        }}
                      >
                        Home Security Systems
                      </Typography>
                    </IconButton>
                    <IconButton
                      aria-label='delete'
                      sx={{
                        width: '33%',
                        display: 'block',
                        fontSize: '.5rem',
                        height: '100%',
                        color: '#fff',
                        borderRadius: '0',
                        padding: '0',
                      }}
                    >
                      <AdminPanelSettingsIcon
                        sx={{
                          width: { xxs: '24px', sm: '44px' },
                          height: 'auto',
                          top: '0px',
                        }}
                      />
                      <Typography
                        sx={{
                          width: { xxs: '100%', lg: '70%' },
                          margin: 'auto',
                          fontSize: { xxs: '12px', sm: 'initial' },
                        }}
                      >
                        Home Security Systems
                      </Typography>
                    </IconButton>
                    <IconButton
                      aria-label='delete'
                      sx={{
                        width: '33%',
                        display: 'block',
                        fontSize: '.5rem',
                        height: '100%',
                        color: '#fff',
                        borderRadius: '0',
                        padding: '0',
                      }}
                    >
                      <AdminPanelSettingsIcon
                        sx={{
                          width: { xxs: '24px', sm: '44px' },
                          height: 'auto',
                          top: '0px',
                        }}
                      />
                      <Typography
                        sx={{
                          width: { xxs: '100%', lg: '70%' },
                          margin: 'auto',
                          fontSize: { xxs: '12px', sm: 'initial' },
                        }}
                      >
                        Home Security Systems
                      </Typography>
                    </IconButton>
                  </Stack>
                </Box>
              )}

              <Box
                sx={{
                  background: '#cecfd1',
                  position: 'relative',
                  textAlign: 'center',
                  overflow: 'hidden',
                  maxHeight: { xxs: '480px' },
                  overflowX: 'hidden',
                  overflowY: 'hidden',
                }}
              >
                <Box
                  component='img'
                  sx={{
                    zIndex: 0,
                    width: '100%',
                    maxWidth: '100%',
                    height: 'auto',
                    marginTop: {
                      xxs: '100px',
                      sm: '120px',
                      md: '120px',
                      lg: '80px',
                    },
                  }}
                  alt='The house from the offer.'
                  src={item2Tablet}
                />
                <Card
                  sx={{
                    backgroundColor: 'transparent',
                    top: '0',
                    position: 'absolute',
                    zIndex: 1,
                    textAlign: 'center',
                    height: '100%',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                >
                  <CardActionArea
                    sx={{ height: '100%' }}
                    onClick={() => setShow2(true)}
                  >
                    <Box
                      sx={{
                        height: '100%',
                        paddingTop: {
                          xxs: '1.5rem',
                          sm: '2rem',
                          md: '3rem',
                          xl: '2.5rem',
                        },
                      }}
                    >
                      {!show2 && (
                        <>
                          <Typography
                            sx={{ fontSize: { xxs: '1.75rem', md: '2.5rem' } }}
                          >
                            Home Security Systems
                          </Typography>
                          <Typography
                            sx={{ color: '#00549c', fontSize: '1.25rem' }}
                          >
                            Learn more &gt;
                          </Typography>
                        </>
                      )}
                    </Box>
                  </CardActionArea>
                </Card>
              </Box>
            </Grid>
            <Grid
              item
              xxs={12}
              xs={6}
              sx={{
                position: 'relative',
                textAlign: 'center',
                maxHeight: { xxs: '480px' },
              }}
            >
              {show3 && (
                <Box
                  sx={{
                    backgroundColor: '#00549c',
                    color: '#ffffff',
                    opacity: '.9',
                    top: '-10%',
                    left: '-5%',
                    position: 'absolute',
                    zIndex: 2,
                    textAlign: 'center',
                    height: '120%',
                    maxHeight: '120%',
                    width: '85%',
                    maxWidth: '85%',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: '12.5%',
                    paddingRight: '12.5%',
                  }}
                  onClick={() => setShow3(false)}
                >
                  <Typography
                    sx={{
                      fontSize: { xxs: '1rem', md: '2.5rem' },
                    }}
                  >
                    Video Monitoring
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: '.5rem',
                      fontSize: { xxs: '.7rem', md: '1rem', xl: '1.25rem' },
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Typography>
                  <Box>
                    <Button
                      variant='text'
                      sx={{
                        color: '#7bc9d4',
                        textTransform: 'initial',
                        fontSize: { xxs: '.7rem', md: '1rem' },
                      }}
                    >
                      Shop now &gt;
                    </Button>
                  </Box>

                  <Stack
                    spacing={0}
                    direction={'row'}
                    sx={{
                      placeContent: 'center',
                      height: { xxs: '15vh', xl: '10vh' },
                      justifyContent: { xxs: 'space-between', sm: 'initial' },
                    }}
                  >
                    <IconButton
                      aria-label='delete'
                      sx={{
                        width: '33%',
                        display: 'block',
                        fontSize: '.5rem',
                        height: '100%',
                        color: '#fff',
                        borderRadius: '0',
                        padding: '0',
                      }}
                    >
                      <AdminPanelSettingsIcon
                        sx={{
                          width: { xxs: '24px', sm: '44px' },
                          height: 'auto',
                          top: '0px',
                        }}
                      />
                      <Typography
                        sx={{
                          width: { xxs: '100%', lg: '70%' },
                          margin: 'auto',
                          fontSize: { xxs: '.7rem', sm: 'initial' },
                        }}
                      >
                        Home Security Systems
                      </Typography>
                    </IconButton>
                    <IconButton
                      aria-label='delete'
                      sx={{
                        width: '33%',
                        display: 'block',
                        fontSize: '.5rem',
                        height: '100%',
                        color: '#fff',
                        borderRadius: '0',
                        padding: '0',
                      }}
                    >
                      <AdminPanelSettingsIcon
                        sx={{
                          width: { xxs: '24px', sm: '44px' },
                          height: 'auto',
                          top: '0px',
                        }}
                      />
                      <Typography
                        sx={{
                          width: { xxs: '100%', lg: '70%' },
                          margin: 'auto',
                          fontSize: { xxs: '12px', sm: 'initial' },
                        }}
                      >
                        Home Security Systems
                      </Typography>
                    </IconButton>
                    <IconButton
                      aria-label='delete'
                      sx={{
                        width: '33%',
                        display: 'block',
                        fontSize: '.5rem',
                        height: '100%',
                        color: '#fff',
                        borderRadius: '0',
                        padding: '0',
                      }}
                    >
                      <AdminPanelSettingsIcon
                        sx={{
                          width: { xxs: '24px', sm: '44px' },
                          height: 'auto',
                          top: '0px',
                        }}
                      />
                      <Typography
                        sx={{
                          width: { xxs: '100%', lg: '70%' },
                          margin: 'auto',
                          fontSize: { xxs: '12px', sm: 'initial' },
                        }}
                      >
                        Home Security Systems
                      </Typography>
                    </IconButton>
                  </Stack>
                </Box>
              )}

              <Box
                sx={{
                  background: '#c5cbcb',
                  position: 'relative',
                  textAlign: 'center',
                  overflow: 'hidden',
                  maxHeight: { xxs: '480px' },
                  overflowX: 'hidden',
                  overflowY: 'hidden',
                }}
              >
                <Box
                  component='img'
                  sx={{
                    zIndex: 0,
                    width: '100%',
                    maxWidth: '100%',
                    height: 'auto',
                    marginTop: {
                      xxs: '100px',
                      sm: '120px',
                      md: '120px',
                      lg: '60px',
                    },
                  }}
                  alt='The house from the offer.'
                  src={item3SmokeAlarm}
                />
                <Card
                  sx={{
                    backgroundColor: 'transparent',
                    top: '0',
                    position: 'absolute',
                    zIndex: 1,
                    textAlign: 'center',
                    height: '100%',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                >
                  <CardActionArea
                    sx={{ height: '100%' }}
                    onClick={() => setShow3(true)}
                  >
                    <Box
                      sx={{
                        height: '100%',
                        paddingTop: {
                          xxs: '1.5rem',
                          sm: '2rem',
                          md: '3rem',
                          xl: '2.5rem',
                        },
                      }}
                    >
                      {!show3 && (
                        <>
                          <Typography
                            sx={{ fontSize: { xxs: '1.75rem', md: '2.5rem' } }}
                          >
                            Life & Fire Safety
                          </Typography>
                          <Typography
                            sx={{ color: '#00549c', fontSize: '1.25rem' }}
                          >
                            Learn more &gt;
                          </Typography>
                        </>
                      )}
                    </Box>
                  </CardActionArea>
                </Card>
              </Box>
            </Grid>
            <Grid
              item
              xxs={12}
              xs={6}
              sx={{
                position: 'relative',
                textAlign: 'center',
                maxHeight: { xxs: '480px' },
              }}
            >
              {show4 && (
                <Box
                  sx={{
                    backgroundColor: '#00549c',
                    color: '#ffffff',
                    opacity: '.9',
                    top: '-10%',
                    left: '-5%',
                    position: 'absolute',
                    zIndex: 2,
                    textAlign: 'center',
                    height: '120%',
                    maxHeight: '120%',
                    width: '85%',
                    maxWidth: '85%',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: '12.5%',
                    paddingRight: '12.5%',
                  }}
                  onClick={() => setShow4(false)}
                >
                  <Typography
                    sx={{
                      fontSize: { xxs: '1rem', md: '2.5rem' },
                    }}
                  >
                    Video Monitoring
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: '.5rem',
                      fontSize: { xxs: '.7rem', md: '1rem', xl: '1.25rem' },
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Typography>
                  <Box>
                    <Button
                      variant='text'
                      sx={{
                        color: '#7bc9d4',
                        textTransform: 'initial',
                        fontSize: { xxs: '.7rem', md: '1rem' },
                      }}
                    >
                      Shop now &gt;
                    </Button>
                  </Box>

                  <Stack
                    spacing={0}
                    direction={'row'}
                    sx={{
                      placeContent: 'center',
                      height: { xxs: '15vh', xl: '10vh' },
                      justifyContent: { xxs: 'space-between', sm: 'initial' },
                    }}
                  >
                    <IconButton
                      aria-label='delete'
                      sx={{
                        width: '33%',
                        display: 'block',
                        fontSize: '.5rem',
                        height: '100%',
                        color: '#fff',
                        borderRadius: '0',
                        padding: '0',
                      }}
                    >
                      <AdminPanelSettingsIcon
                        sx={{
                          width: { xxs: '24px', sm: '44px' },
                          height: 'auto',
                          top: '0px',
                        }}
                      />
                      <Typography
                        sx={{
                          width: { xxs: '100%', lg: '70%' },
                          margin: 'auto',
                          fontSize: { xxs: '.7rem', sm: 'initial' },
                        }}
                      >
                        Home Security Systems
                      </Typography>
                    </IconButton>
                    <IconButton
                      aria-label='delete'
                      sx={{
                        width: '33%',
                        display: 'block',
                        fontSize: '.5rem',
                        height: '100%',
                        color: '#fff',
                        borderRadius: '0',
                        padding: '0',
                      }}
                    >
                      <AdminPanelSettingsIcon
                        sx={{
                          width: { xxs: '24px', sm: '44px' },
                          height: 'auto',
                          top: '0px',
                        }}
                      />
                      <Typography
                        sx={{
                          width: { xxs: '100%', lg: '70%' },
                          margin: 'auto',
                          fontSize: { xxs: '12px', sm: 'initial' },
                        }}
                      >
                        Home Security Systems
                      </Typography>
                    </IconButton>
                    <IconButton
                      aria-label='delete'
                      sx={{
                        width: '33%',
                        display: 'block',
                        fontSize: '.5rem',
                        height: '100%',
                        color: '#fff',
                        borderRadius: '0',
                        padding: '0',
                      }}
                    >
                      <AdminPanelSettingsIcon
                        sx={{
                          width: { xxs: '24px', sm: '44px' },
                          height: 'auto',
                          top: '0px',
                        }}
                      />
                      <Typography
                        sx={{
                          width: { xxs: '100%', lg: '70%' },
                          margin: 'auto',
                          fontSize: { xxs: '12px', sm: 'initial' },
                        }}
                      >
                        Home Security Systems
                      </Typography>
                    </IconButton>
                  </Stack>
                </Box>
              )}

              <Box
                sx={{
                  background: '#c8c6b9',
                  position: 'relative',
                  textAlign: 'center',
                  overflow: 'hidden',
                  maxHeight: { xxs: '480px' },
                  overflowX: 'hidden',
                  overflowY: 'hidden',
                }}
              >
                <Box
                  component='img'
                  sx={{
                    zIndex: 0,
                    width: '100%',
                    maxWidth: '100%',
                    height: 'auto',
                    marginTop: {
                      xxs: '100px',
                      sm: '120px',
                      md: '120px',
                      lg: '60px',
                    },
                  }}
                  alt='The house from the offer.'
                  src={item4HomeAutomation}
                />
                <Card
                  sx={{
                    backgroundColor: 'transparent',
                    top: '0',
                    position: 'absolute',
                    zIndex: 1,
                    textAlign: 'center',
                    height: '100%',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                >
                  <CardActionArea
                    sx={{ height: '100%' }}
                    onClick={() => setShow4(true)}
                  >
                    <Box
                      sx={{
                        height: '100%',
                        paddingTop: {
                          xxs: '1.5rem',
                          sm: '2rem',
                          md: '3rem',
                          xl: '2.5rem',
                        },
                      }}
                    >
                      {!show4 && (
                        <>
                          <Typography
                            sx={{ fontSize: { xxs: '1.75rem', md: '2.5rem' } }}
                          >
                            Home Automation
                          </Typography>
                          <Typography
                            sx={{ color: '#00549c', fontSize: '1.25rem' }}
                          >
                            Learn more &gt;
                          </Typography>
                        </>
                      )}
                    </Box>
                  </CardActionArea>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </Container>
    </Box>
  );
};

const SectionFour = () => {
  return (
    <ThemeProvider theme={createTheme(customBreakpoints)}>
      <Box
        className='Product-Section-4'
        sx={{
          width: '100%',
          maxWidth: '100%',
          position: 'relative',
          textAlign: 'center',
        }}
      >
        <Box
          component='img'
          sx={{ width: '100%', minWidth: '100%' }}
          alt='The house from the offer.'
          src={solarImage}
        />
        <Stack
          sx={{
            top: '0',
            left: '0',
            position: 'absolute',
            zIndex: 1,
            textAlign: 'center',
            width: '50%',
            maxWidth: '50%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ verticalAlign: 'middle' }}>
            <ThemeProvider theme={createTheme(customBreakpoints)}>
              <Typography
                sx={{
                  fontSize: {
                    xxs: '1rem',
                    sm: '2rem',
                    lg: '3rem',
                  },
                }}
              >
                Home Solar Energy
              </Typography>
              <Box>
                <Button
                  sx={{
                    fontSize: { xxs: '.7rem', sm: '1rem', md: '1.25rem' },
                    textTransform: 'initial',
                    padding: { xxs: '0', xs: '6px 8px' },
                  }}
                >
                  Learn more &gt;
                </Button>
              </Box>
              <Box>
                <Button
                  sx={{
                    fontSize: { xxs: '.7rem', sm: '1rem', md: '1.25rem' },
                    textTransform: 'initial',
                    padding: { xxs: '0', xs: '6px 8px' },
                  }}
                >
                  View solar deals &gt;
                </Button>
              </Box>
            </ThemeProvider>
          </Box>
        </Stack>
      </Box>
    </ThemeProvider>
  );
};

const SectionFive = () => {
  return (
    <Box className='Product-Section-5' sx={{}}>
      <Box
        className='image-container'
        sx={{
          position: 'relative',
          width: '100%',
          height: '0',
          aspectRatio: '2000/678',
          overflow: 'hidden',
        }}
      >
        <Box
          component='img'
          sx={{
            position: 'absolute',
            top: '-27%',
            width: '100%',
            height: 'auto',
          }}
          alt='The house from the offer.'
          src={tabletWithFamily}
        />
      </Box>
    </Box>
  );
};

export default function Products() {
  return (
    <>
      <Container maxWidth={'xl'} sx={{ bgcolor: '', padding: '0' }}>
        <Stack
          sx={{
            minHeight: '100vh',
            overflow: 'hidden',
          }}
        >
          {/* TODO: make section names more descriptive */}
          <SectionOne></SectionOne>
          <SectionTwo></SectionTwo>
          <SectionThree></SectionThree>
          <SectionFour></SectionFour>
          <SectionFive></SectionFive>
        </Stack>
      </Container>
    </>
  );
}
